import { KeyValuePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { IPrefixMetadataRequest, IPrefixMetadataResponse } from '../../../fad/search-providers/interfaces/iPrefixMetadata';
import { ISearchParameters } from '../../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { GA_TERMINATION_PROVIDERS, STATE_GA } from '../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { RouteUtil } from '../../utilities/routeUtil';
import { InfoListService } from '../info-list/services/infoListSvc';
import { ScenarioAlertSvc } from '../scenarioAlert/services/pfScenarioAlertSvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { Locale } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { LocationResponse } from './../../../common/models/locationAddress';
import { DataHelper } from './../../../common/services/dataHelper';
import { AppConfig } from './../../../common/values/appConfig';
import { IZipCode } from './../../../fad/search-providers/interfaces/iAddressForm';
import { SAVNavigationService } from './../../../fad/search-providers/services/savNavigationSvc';
import { EventHandler } from './../../services/eventHandler';
import { NavigationService } from './../../services/navigationService';
import { AppUtility } from './../../utilities/appUtil';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { LocationService } from './services/locationService';

@Component({
  moduleId: module.id,
  selector: 'app-location',
  templateUrl: './locationCmp.html'
})
export class LocationComponent extends BaseComponent implements OnInit {
  @Input()
  searchParams: ISearchParameters;
  @Input()
  zipCodeProperties: IZipCode;
  @Output()
  onCtrlFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  reloadSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  reloadSAVPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  loadResult: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('location')
  locationInput: ElementRef;
  isCaHmoDmhc: boolean;
  locale: string;
  geoSuggestionArr: any = null;
  geoSuggestionTopList: any = [];
  geoSuggestionMoreList: any = [];
  countyList = [];
  countyTopList = [];
  countyMore: boolean = false;
  locationMore: boolean = false;
  location: string = '';
  locationError: boolean = false;
  locationApiStatus: boolean = false;
  errorText: string;
  locationWarningMessage: boolean = false;
  appConfig: AppConfig;

  _locationTxt;
  get locationTxt() {
    return this._locationTxt ? this._locationTxt : '';
  }
  set locationTxt(val) {
    this._locationTxt = val;
    if (this.searchParams) {
      this.searchParams.zip = val;
    }
  }

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _keyValuePipe: KeyValuePipe,
    private _providerUtilityService: ProviderUtilityService,
    private _appUtility: AppUtility,
    private _infoListSvc: InfoListService,
    private _routeUtil: RouteUtil,
    private _locationService: LocationService,
    private _savNavigationService?: SAVNavigationService,
    private _navigationService?: NavigationService,
    private _scenarioAlertSvc?: ScenarioAlertSvc
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.appConfig = this._appSession.appConfig;
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;

    this.locationTxt = this.searchParams && this.searchParams.zip ? this.searchParams.zip : '';
    if (this.searchParams.zip || (this._appSession.searchParams && this._appSession.searchParams.zip)) {
      this.getGeoCoordinates(true);
    }
    this.bindGeoLocation();
    this.isCaHmoDmhc = this._appSession.isCaHmoDmhc;
  }

  toggleFocusFn() {
    let _hasCities = false;
    let _hasCounties = false;
    const _hasLocationTxt = this.locationTxt.trim() !== '';

    if (!_hasLocationTxt) {
      //Location empty
    } else {
      if (this.geoSuggestionArr) {
        const geoKeys = Object.keys(this.geoSuggestionArr);

        if (geoKeys.length > 0) {
          _hasCities = true;
          const name = this.matchItem(geoKeys, this.locationTxt);
          if (name) {
            this.setCoordinates(this.geoSuggestionArr[name]);
          }
        }
      }

      if (this.countyList && this.countyList.length > 0) {
        _hasCounties = true;
        if (this.searchParams.countyCode) {
          const _index = this.countyList.findIndex((x) => x.countyCode === this.searchParams.countyCode);
          if (_index > -1) {
            this.setCoordinates(this.countyList[_index], true);
          }
        } else {
          const _keywords = this.locationTxt.split(',').map((c) => c.trim());
          if (_keywords && _keywords.length > 1) {
            let _county = _keywords[0].toUpperCase();
            const _state = _keywords[1].toUpperCase();
            if (_county.indexOf(' COUNTY') >= 0) {
              _county = _county.replace('COUNTY', '').trim();
              const _index = this.countyList.findIndex((x) => x.countyName.toUpperCase() === _county && x.stateCode.toUpperCase() === _state);
              if (_index > -1) {
                this.setCoordinates(this.countyList[_index], true);
              }
            }
          }
        }
      }
    }

    if (!_hasCities && !_hasCounties) {
      this.setLocationError();
    }
  }

  triggerGeoSearch(isInit: boolean = false) {
    const _searchTerm: string = this.locationTxt;
    // added a wait for 300ms to avoid race around situation during slow network performance
    setTimeout(
      () => {
        if (_searchTerm !== this.locationTxt) {
          return;
        } else {
          this.getGeoCoordinates(isInit);
        }
      },
      300,
      _searchTerm,
      isInit
    );
    if (this._locationTxt) {
      this.locationWarningMessage = false;
    }
    this._appSession.customAddressSearch = false;
  }

  getGeoCoordinates(isInit: boolean = false) {
    let _searchTerm = this.locationTxt;
    const _countyPattrn = /county/gi;
    const _match = _countyPattrn.exec(_searchTerm);
    const _isCounty = _match && _match.length > 0 ? true : false;
    if (_isCounty) {
      _searchTerm = _searchTerm.replace(_countyPattrn, '').trim();
    }
    this.clearAll();

    if (!isInit && !this._appSession?.customAddressSearch) {
      this.searchParams.countyCode = null;
      this.searchParams.stateCd = null;
      this.searchParams.city = '';
      this.searchParams.coordinates = {
        latitude: '',
        longitude: ''
      };
    }

    _searchTerm = _searchTerm.replace(/[,|\s]*$/, '');
    if (_searchTerm.match(/^[0-9]+$/) != null) {
      if (_searchTerm.length >= 5) {
        this.locationApiStatus = true;
        this._providerUtilityService
          .getGeoLocation(_searchTerm)
          .then((result: LocationResponse[]) => {
            if (result && Object.keys(result).length > 0) {
              const results = this._appUtility.buildLocationResponse(result);
              this.searchParams.coordinates.latitude = results[Object.keys(results)[0]].lat;
              this.searchParams.coordinates.longitude = results[Object.keys(results)[0]].long;
              if (this.searchParams.coordinates.latitude && this.searchParams.coordinates.longitude) {
                this.updateSearchParam(results, isInit);
              }
            } else {
              this.locationError = true;
              this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
            }
            this.locationApiStatus = false;
          })
          .catch((err) => {
            if (err) {
              this.locationApiStatus = false;
              this.locationError = true;
              this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
            }
          });
      }
      this.locationTxt = _searchTerm;
    } else {
      if (_searchTerm.length >= 3) {
        if (_isCounty) {
          this.getCounties(isInit, _searchTerm);
        } else {
          this.locationApiStatus = true;
          this._providerUtilityService
            .getGeoLocation(_searchTerm)
            .then((result: LocationResponse[]) => {
              if (_searchTerm.length >= 3) {
                const results = this._appUtility.buildLocationResponse(result);
                if (isInit && results && Object.keys(results).length > 0) {
                  const coordinates: any = results[_searchTerm];
                  if (coordinates) {
                    this.setCoordinates(coordinates, false, isInit);
                  }
                } else {
                  if (isInit && results && Object.keys(results).length === 0) {
                    //No Location result
                    this.locationError = true;
                    this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
                  } else {
                    this.geoSuggestionArr = results;
                    let _geoLocationArr = [];
                    _geoLocationArr = this._keyValuePipe.transform(this.geoSuggestionArr);
                    if (_geoLocationArr && _geoLocationArr.length > 0) {
                      this.geoSuggestionMoreList = _geoLocationArr;
                      this.geoSuggestionTopList = _geoLocationArr.slice(0, this.appConfig?.features.lookUp.minCount);
                    }
                  }
                }
              } else {
                this.geoSuggestionArr = null;
                this.geoSuggestionTopList = [];
                this.geoSuggestionMoreList = [];
              }
              this.locationApiStatus = false;
            })
            .catch((err) => {
              if (err) {
                this.locationApiStatus = false;
                this.locationError = true;
                this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
              }
            });

          if (!isInit) {
            this.getCounties(isInit, _searchTerm);
          }
        }
      }
    }
  }
  updateSearchParam(result: any, isInit: boolean) {
    this.searchParams.stateCd = {
      code: result[Object.keys(result)[0]].stateCd
    };
    this._appSession.searchParams.stateCd = {
      code: result[Object.keys(result)[0]].stateCd
    };

    if (this._appSession.metaData && this._appSession.metaData.appContract) {
      const _county = this._appSession.metaData.appContract.memberCounty;
      if (!_county || _county === '') {
        this._appSession.metaData.appContract.memberCounty = result[Object.keys(result)[0]].county;
      }
    }
    this.locationError = false;
    this.getPrefixMetadata(isInit);
  }
  getCounties(isInit: boolean = false, searchTxt: string) {
    this.countyList = [];
    this.countyTopList = [];
    this._providerUtilityService.getCountyLookupList({ searchTerm: searchTxt, locale: this.locale }).then((result: any) => {
      if (result && result.counties && result.counties.length > 0) {
        if (isInit) {
          const _keywords = searchTxt.split(',').map((c) => c.trim());
          if (_keywords && _keywords.length > 1) {
            const _county = _keywords[0].toUpperCase().trim();
            const _state = _keywords[1].toUpperCase().trim();
            const _index = result.counties.findIndex((x) => x.countyName.toUpperCase().trim() === _county && x.stateCode.toUpperCase().trim() === _state);
            if (_index > -1) {
              this.setCoordinates(result.counties[_index], true, isInit);
            }
          }
        } else {
          this.countyList = this.locationWarningMessage ? [] : result.counties;
          this.countyTopList = this.locationWarningMessage ? [] : result.counties.slice(0, this.appConfig?.features.lookUp.minCount);
        }
      }
    });
  }

  setLocationError() {
    this.locationWarningMessage = false;
    this.locationError = false;
    this.errorText = '';
    if (
      !(
        this.searchParams.coordinates &&
        this.searchParams.coordinates.latitude &&
        this.searchParams.coordinates.longitude &&
        this.searchParams.coordinates.latitude !== '' &&
        this.searchParams.coordinates.longitude !== ''
      )
    ) {
      this.locationError = true;
      this.locationWarningMessage = false;
      this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
    }
    if (this.locationApiStatus) {
      this.locationError = false;
    }
  }

  showRedColorBorder(data) {
    if (!this._appSession?.customAddressSearch && (!this.locationTxt || this.locationTxt === '') && data) {
      if (!this.zipCodeProperties.disableZipcode) {
        this.locationWarningMessage = true;
      }
      this.locationError = false;
      this.countyList = [];
      this.countyTopList = [];
      setTimeout(() => {
        document.getElementById('zipCodeSrchID')?.focus();
      }, 100);
    } else {
      this.locationWarningMessage = false;
    }
  }

  setCoordinates(coordinates: any, isCounty: boolean = false, isInit: boolean = false) {
    this.searchParams.countyCode = null;
    this.searchParams.stateCd = null;
    this.searchParams.city = '';
    this.searchParams.coordinates = {
      latitude: '',
      longitude: ''
    };

    if (isCounty) {
      const _city = coordinates.countyName + ' County';
      this.searchParams.countyCode = coordinates.countyCode;
      this.searchParams.city = _city;
      this.searchParams.stateCd = {
        code: coordinates.stateCode
      };
      this.searchParams.coordinates.latitude = '0';
      this.searchParams.coordinates.longitude = '0';
      // Regional, Remote providers - Zoom map for county search.
      this.searchParams.coordinates.countyLatitude = coordinates.lat;
      this.searchParams.coordinates.countyLongitude = coordinates.long;
      this.locationTxt = this.searchParams.zip = _city + ', ' + coordinates.stateCode;
      this.clearAll();
    } else {
      this.searchParams.coordinates.latitude = coordinates.lat;
      this.searchParams.coordinates.longitude = coordinates.long;
      this.searchParams.coordinates.countyLatitude = '0';
      this.searchParams.coordinates.countyLongitude = '0';
      this.searchParams.city = coordinates.city;
      this.searchParams.stateCd = {
        code: coordinates.stateCd
      };
      this.locationTxt = this.searchParams.zip = coordinates.name;
      this.searchParams.countyCode = null;
      this.clearAll();
    }
    this.getPrefixMetadata(isInit);
  }

  //Method to handle summary page provider results reload on location change.
  reloadProviderSummary() {
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    this._appSession.serviceAreaValidated = false;
    if (this._appSession.isCaHmoDmhc && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      if ((!this._appSession.searchParams.countyCode && !this._appSession.searchParams.city) || this._appSession.customAddressSearch) {
        this._savNavigationService.getZipcodeBasedCounty().then((result) => {
          if (result && isEmpty(result)) {
            this.reloadSearch.emit(true);
          } else {
            this._navigationService.navigateByUrl(AppNavigations.SERVICE_AREA_VALIDATION_PATH);
          }
        });
      } else {
        this._savNavigationService.navigate();
      }
      this._appSession.pageNumber = undefined;
      this._appSession.searchParams.selectedSortCriteria = undefined;
      this._appSession.filterSearchParams = undefined;
      this._appSession.initialSearch = true;
    } else if (this._appSession.isCaHmoDmhc && _routeUrl === AppNavigations.SERVICE_AREA_VALIDATION_PATH) {
      this.reloadSAVPage.emit(true);
    } else if (this.searchParams.zip && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.reloadSearch.emit(true);
    } else if (this.searchParams.zip && _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH) {
      this.loadResult.emit(true);
    }
  }

  bindGeoLocation() {
    if (!this._appSession.isSecureState && (!this.searchParams.zip || this.searchParams.zip === '') && this._appSession.defaultLocation) {
      this.searchParams.coordinates = {
        latitude: this._appSession.defaultLocation.latitude,
        longitude: this._appSession.defaultLocation.longitude
      };
      this.locationTxt = this.searchParams.zip = this._appSession.defaultLocation.zip;
      this.searchParams.stateCd = this._appSession.defaultLocation.state;
    }
  }

  hasGeoLocation() {
    let _hasGeoLocation = false;
    let _geoLocationArr = [];

    if (!this.locationError && this.geoSuggestionArr) {
      _geoLocationArr = this._keyValuePipe.transform(this.geoSuggestionArr);
      _hasGeoLocation = _geoLocationArr && _geoLocationArr.length > 0;
    }

    if (!this.locationError && this.countyList && this.countyList.length > 0) {
      _hasGeoLocation = true;
    }
    return _hasGeoLocation;
  }

  showLocationMore() {
    this.locationMore = !this.locationMore;
    return false;
  }

  showCountyMore() {
    this.countyMore = !this.countyMore;
    return false;
  }

  clearAll() {
    this.geoSuggestionArr = null;
    this.geoSuggestionTopList = [];
    this.geoSuggestionMoreList = [];
    this.countyList = [];
    this.countyTopList = [];
    this.locationMore = false;
    this.countyMore = false;
    this.locationError = false;
  }

  private matchItem(keys: string[], name: string): string {
    //add single space after comma in city name.
    name = name.replace(/,\s*/g, ', ').toLocaleLowerCase();
    //find the matching city name in the geokeys
    const index = keys.findIndex((key) => key.toLocaleLowerCase() === name);
    if (index >= 0) {
      return keys[index];
    }
    return null;
  }

  getPrefixMetadata(isInit: boolean) {
    const _routeUrl = this._routeUtil.getResolvedUrl(this._route.snapshot);
    const prefix = CommonUtil.getPrefix(this.searchParams, this._appSession);
    const stateCode = this.searchParams.stateCd?.code ? this.searchParams.stateCd.code : '';
    if (
      this.showGATerminatingProviders &&
      this._appSession.planRule?.showGACareGapNotificationMsg &&
      !this._dataHelper.isEmptyString(stateCode) &&
      stateCode.toLocaleUpperCase() === STATE_GA &&
      _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH
    ) {
      this._locationService.setpinCode(true);
    } else {
      this._locationService.setpinCode(false);
      this._infoListSvc.removeMsgById(GA_TERMINATION_PROVIDERS);
      this._infoListSvc.update();
    }
    if (!this._appSession.isSecureState && !this._dataHelper.isEmptyString(prefix) && !this._dataHelper.isEmptyString(stateCode)) {
      const _prefixMetaDataReqObj: IPrefixMetadataRequest = {} as IPrefixMetadataRequest;
      _prefixMetaDataReqObj.prefix = prefix;
      _prefixMetaDataReqObj.planStateCode = this.searchParams.plan?.stateCd ? this.searchParams.plan.stateCd : '';
      _prefixMetaDataReqObj.typeOfCareNm = this.searchParams.typeOfCareNm ? this.searchParams.typeOfCareNm : '';
      _prefixMetaDataReqObj.planCategory = CommonUtil.getPrefixPlanCategoryValue(this.searchParams) ? CommonUtil.getPrefixPlanCategoryValue(this.searchParams) : '';
      _prefixMetaDataReqObj.stateCode = stateCode;
      _prefixMetaDataReqObj.countyCode = this.searchParams.countyCode ? this.searchParams.countyCode : '';

      this._providerUtilityService.getPrefixMetadata(_prefixMetaDataReqObj).then(
        (result: IPrefixMetadataResponse) => {
          this._appSession.alphaPrefixStateCode = result?.alphaPrefixStateCode ? result?.alphaPrefixStateCode : '';
          if (this.searchParams) {
            this.searchParams.eycPrefix = result?.eycPrefix ? result?.eycPrefix : '';
            this.searchParams.isPrefixStateEnabledForProcSearch = result?.isPrefixStateEnabledForProcSearch;
            this._appSession.hasColdStateEYC = this.searchParams.isPrefixStateEnabledForProcSearch;
            this._appSession.searchParams = this.searchParams;
          }
          if (result) {
            this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
          }
          if (!isInit) {
            this.reloadProviderSummary();
          }
        },
        (error: any) => {
          this._appSession.alphaPrefixStateCode = '';
          if (this.searchParams) {
            this.resetPrefixMetaDataParams();
          }
          if (!isInit) {
            this.reloadProviderSummary();
          }
        }
      );
    } else {
      this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
      this._appSession.alphaPrefixStateCode = '';
      if (this.searchParams) {
        this.resetPrefixMetaDataParams();
      }
      if (!isInit) {
        this.reloadProviderSummary();
      }
    }
  }

  private resetPrefixMetaDataParams(): void {
    this.searchParams.eycPrefix = '';
    this.searchParams.isPrefixStateEnabledForProcSearch = false;
    this._appSession.hasColdStateEYC = false;
    this._appSession.searchParams = this.searchParams;
  }
}
