import { Component, Inject, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { ResultsUtil } from './../../../fad/utilities/resultsUtil';
import { BRAND_CODE, CA_STATE, Locale } from './../../constants/app-constants';
import { IDisclaimerRequest } from './../../interfaces/iDisclaimerAttributes';
import { DisclaimerService } from './../../services/disclaimerService';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';

@Component({
  selector: 'app-fad-disclaimer',
  templateUrl: './pfDisclaimerCmp.html'
})
export class PFDisclaimerComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('minnesotaDisclaimer')
  minnesotaDisclaimer: TemplateRef<HTMLElement>;
  @Input() ruleList: any;
  @Input() isTopDisclaimerRequired: boolean;
  @Input() isVitalsRequired: boolean;
  @Input() isEycDetails: boolean = false;
  @Input() screenNo: any = 1;
  @Input() isMinnesotaDisclaimer: boolean = false;
  @Input() showDetailPharmacyDisclaimer: boolean = false;
  sidePanelRef: ModalRef<any, any>;
  costRuleList: any = null;
  disclaimers: any = [];
  modelDisclaimer: any = {};
  showDisclaimer: boolean = false;
  modelShow: boolean = false;
  startPage: number = 0;
  paginationLimit: number = 1;
  disclaimerCount: number = 0;
  showDisclaimerLoader: boolean = true;
  showDisclaimerError: boolean = false;
  importanttxt: string;
  adobeDisclaimerTag: string;
  constructor(
    private _disclaimer: DisclaimerService,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _sidePanel: SidePanel,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  /* ngAfterViewInit() {
    this.loadDisclaimers();
  } */

  ngOnChanges(args: any) {
    if (args['ruleList'] && JSON.stringify(args['ruleList'].currentValue) !== JSON.stringify(args['ruleList'].previousValue)) {
      this.loadDisclaimers();
    }
  }

  loadDisclaimers() {
    this.adobeDisclaimerTag = ResultsUtil.getAdobeDisclaimerTag(this.screenNo);
    this.importanttxt = this.content.disclaimerComponent.labels.important;
    const reqObj: IDisclaimerRequest = { language: '', rules: [] };
    reqObj.language = this._appSession.metaData?.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
    reqObj.isEyc = this._appSession.isEyc;
    reqObj.isCpt = this._appSession.isMedicalCodeSearch;
    reqObj.isTopRequired = this.isTopDisclaimerRequired ? this.isTopDisclaimerRequired : false;
    reqObj.isVitalRequired = this.isVitalsRequired ? this.isVitalsRequired : false;
    reqObj.rules = this.ruleList;
    this._disclaimer.loadDisclaimers(reqObj).then(
      (disclaimers: any) => {
        this.showDisclaimerLoader = false;
        if (this._appSession.isEyc || this._appSession.isMedicalCodeSearch) {
          this._appSession.costDisclaimers = this.isEycDetails ? this._appSession.costDisclaimers : Object.assign({}, disclaimers.costDisclaimers);
          this.costRuleList = Object.assign({}, this._appSession.costDisclaimers);
        }
        this.disclaimers = disclaimers && disclaimers.messages ? disclaimers.messages : null;
        this.disclaimerCount = disclaimers ? disclaimers.messages.length - 1 : 0;
        this.modelDisclaimer = (disclaimers && disclaimers.modelDisclaimers) || [];
        if (this.disclaimers && this.disclaimers.length > 0) {
          this.paginationLimit = this.disclaimers.findIndex((element) => {
            return element.match(this.importanttxt);
          });
          this.paginationLimit += this.paginationLimit === -1 ? 2 : 1;
          if ((this._appSession?.metaData?.brandCd === BRAND_CODE.ABC || this._appSession?.metaData?.brandCd === BRAND_CODE.ABCBS) && this._appSession?.searchParams?.stateCd?.code === CA_STATE) {
            this.paginationLimit = this.paginationLimit + 1;
          }
        }
      },
      (error: any) => {
        this.onError(error);
      }
    );
  }

  onError(error: any) {
    this.showDisclaimerLoader = false;
    this.showDisclaimerError = true;
  }

  showMoreItems() {
    this.paginationLimit = Number(this.paginationLimit) + this.disclaimerCount;
  }

  showLessItems() {
    this.paginationLimit = Number(this.paginationLimit) - this.disclaimerCount;
  }

  toggleModelDisclaimer() {
    this.modelShow = !this.modelShow;
  }

  embedBaseUrl(content: string) {
    return this.getDocumentURL(content);
  }

  onClose() {
    this.sidePanelRef.close();
  }

  private openSlider(direction: any) {
    this.sidePanelRef = this._sidePanel.open(direction, this.minnesotaDisclaimer);
  }
}
