import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CLIENTS } from '../constants/app-constants';
import { ResponseType } from '../enums/responseTypeEnum';
import { IHttpRequest } from '../interfaces/iHttpRequest';
import { IUrlParam } from '../interfaces/iUrlParam';
import { AppSession } from '../values/appSession';
import { DataHelper } from './dataHelper';
import { DataService } from './dataService';

@Injectable()
export class HttpClientService {
  private readonly API_PATH_STRING = ['/fad/api/', '/precare/api'];
  private dataModifiedOn = 'data-modifiedon';
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private http: HttpClient,
    private _dataHelper: DataHelper,
    private dataService: DataService) {
  }

  request(requestOptions: IHttpRequest): Promise<any> {
    const finalRequestUrl = this.getUrl(requestOptions.url, requestOptions.urlParams);
    const options2: any = {
      params: this.getHttpParams(requestOptions.urlParams),
      headers: this.getHttpHeaders(requestOptions),
      responseType: requestOptions.responseType || ResponseType.Json,
      withCredentials: requestOptions.withCredentials || false
    };

    const req2 = new HttpRequest(this.getHttpMethod(requestOptions),
      finalRequestUrl, this.getHttpBody(requestOptions), options2);

    if (requestOptions.method === 'GET' || this._appSession.appConfig?.environment.toLowerCase() === 'mock') {
      return new Promise((resolve, reject) => {
        this.get(req2.urlWithParams, req2.headers, req2.responseType).subscribe((result: any) => {
          resolve(result);
        },
          (error: any) => {
            reject(error);
          });
      });
    } else if (requestOptions.method === 'POST') {
      return new Promise((resolve, reject) => {
        this.post(req2.urlWithParams, req2.body, req2.headers, req2.responseType).subscribe((result: any) => {
          resolve(result);
        },
          (error: any) => {
            reject(error);
          });
      });
    }
  }

  private createCustomHeaders(headerParam?: HttpHeaders) {
    // Add custom headers
    if (headerParam) {
      return headerParam;
    } else {
      const headers = new HttpHeaders();
      return headers.set('Content-Type', 'application/json');
    }
  }

  private get(url, headerParam?: HttpHeaders, responseTypeParam?: any) {
    // Reset NG UI JavaScript Idle timeout for every HTTP GET API call
    this.dataService.resetIdleTimeoutClick(true);

    if (headerParam === undefined || headerParam == null) {
      if (responseTypeParam !== undefined && responseTypeParam != null) {
        return this.http.get(url, { responseType: responseTypeParam });
      } else {
        return this.http.get(url);
      }
    } else {
      if (responseTypeParam !== undefined && responseTypeParam != null) {
        return this.http.get(url, { responseType: responseTypeParam, headers: headerParam });
      } else {
        return this.http.get(url, { headers: headerParam });
      }
    }
  }

  private post(url, data, headerParam?: HttpHeaders, responseTypeParam?: any) {
    // Reset NG UI JavaScript Idle timeout for every HTTP GET API call
    this.dataService.resetIdleTimeoutClick(true);

    let header;
    if ((headerParam === undefined || headerParam == null)
      || (headerParam !== undefined && headerParam != null && headerParam instanceof HttpHeaders)) {
      header = this.createCustomHeaders(headerParam);
    }

    if ((typeof data !== 'string')) {
      data = JSON.stringify(data);
    }

    if (responseTypeParam !== undefined && responseTypeParam != null) {
      return this.http.post(url, data, { responseType: responseTypeParam, headers: header });
    } else {
      return this.http.post(url, data, { headers: header });
    }
  }

  private getUrl(url: string, urlParams: Array<IUrlParam> = []): string {
    for (const param of urlParams) {
      if (!param.isQueryParam
        && !new RegExp('^\\d+$').test(param.name)
        && param.value
        && new RegExp('(^|[^\\\\]):' + param.name + '(\\W|$)').test(url)) {
        const encVal = this.encodeUriSegment(param.value);
        url = url.replace(new RegExp(':' + param.name + '(\\W|$)', 'g'), (match, p1) => {
          return encVal + p1;
        });
      }
    }

    return url;
  }

  /**
   * Allow encoding chars on url.
   * @param val - url string
   */
  private encodeUriSegment(val: string) {
    return this.encodeUriQuery(val, true)
      .replace(/%26/gi, '&')
      .replace(/%3D/gi, '=')
      .replace(/%2B/gi, '+');
  }

  /**
   *  Allow encoding parameters in url
   *  @param val - url stirng
   *  @param pctEncodeSpaces - should encode spaces or not
   */
  private encodeUriQuery(val: string, pctEncodeSpaces: boolean) {
    return encodeURIComponent(val)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/g, '$')
      .replace(/%2C/gi, ',')
      .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
  }

  private getHttpParams(urlParams: Array<IUrlParam> = []): HttpParams {
    let params = new HttpParams();
    for (const param of urlParams) {
      if (param.isQueryParam && typeof param.value !== 'undefined') {
        params = params.set(param.name, param.value);
      }
    }

    return params;
  }

  private getHttpHeaders(requestOptions: IHttpRequest): HttpHeaders {
    if (this.isFindCareApiUrl(requestOptions.url)) {
      if (this._appSession.appConfig?.providerFinder.authToken === true &&
        (!requestOptions.headers || !requestOptions.headers.find((h) => h.name === 'Authorization')) && (this._appSession.metaData?.token ||this._appSession.metaData?.idToken)) {
          requestOptions.headers = requestOptions.headers || [];
        if (requestOptions.url.toLocaleLowerCase().indexOf(this.dataModifiedOn) !== -1 && this._appSession.isSecureState) {
          requestOptions.headers.push({
            name: 'Authorization',
            value: 'Bearer ' + this._appSession.metaData.idToken
          });
        }
        else {
          requestOptions.headers.push({
            name: 'Authorization',
            value: 'Bearer ' + this._appSession.metaData.token
          });
        }

      }
    }

    let headers = new HttpHeaders();

    (requestOptions.headers || []).forEach((header) => {
      if (typeof header.value !== 'undefined') {
        headers = headers.append(header.name, header.value);
      }
    });

    // below code ensures additional headers for tracking traffic and source
    if (this._appSession && this.isFindCareApiUrl(requestOptions.url)) {
      if (this._appSession.encryptedUserName) {
        headers = headers.set('x-smuniversalid', this._appSession.encryptedUserName);
      }

      if (this._appSession.isWebView) {
        let _webView = 'yes';
        if (this._appSession.mobileOS) {
          _webView = this._appSession.mobileOS;
        }
        // sets header with value yes, Andriod, iOS
        headers = headers.set('meta-webview', _webView);
      }

      if (this._appSession.isEmulation || (this._appSession.metaData && this._appSession.metaData.pfEmMode)) {
        // distinguish user with an emulator
        headers = headers.set('em_mode', 'yes');
      }
      if (this._appSession.metaData && this._appSession.metaData.brandCd) {
        headers = headers.set('meta-brandcd', this._appSession.metaData.brandCd);
      }
      if (this._appSession.deeplinkParams) {
        let _client = (this._appSession.deeplinkParams.client || '').toUpperCase();
        if (_client && this._appSession.isIntegratedMode) {
          switch (_client) {
            case CLIENTS.PEGAWGS:
              _client = CLIENTS.PEGAWGS;
              break;
            case CLIENTS.EMPLOYER:
              _client = CLIENTS.EMPLOYER;
              break;
            case CLIENTS.HIP:
              _client = CLIENTS.HIP;
              break;
            case CLIENTS.ASPEN:
              _client = CLIENTS.ASPEN;
              break;
            case CLIENTS.SYDNEYMEDICAID:
              _client = CLIENTS.SYDNEYMEDICAID;
              break
            default:
              break;
          }
        } else if (!_client && this._appSession.isIntegratedMode) {
          _client = CLIENTS.SYDNEYWEB;
        } else if (!_client) {
          _client = CLIENTS.FINDCARE;
        }

        if (_client) {
          // ensures identification of traffic from standalone,
          // rcp integration, employer integration or solution central integration
          headers = headers.set('meta-consumerapp', _client);

          if (this._appSession.metaData.metaSrcEnv) {
            headers = headers.set('meta-src-env', this._appSession.metaData.metaSrcEnv);
          }
        }
      }
      if (!this._dataHelper.isEmptyString(this._appSession.personalizedSessionId)) {
        headers = headers.set('x-correlationid', this._appSession.personalizedSessionId);
      }
    }

    return headers;
  }

  private getHttpMethod(requestOptions: IHttpRequest): string {
    if (typeof requestOptions.method === 'string') {
      return requestOptions.method;
    } else {
      switch (requestOptions.method) {
        case 1:
          return 'POST';
        case 2:
          return 'PUT';
        case 3:
          return 'DELETE';
        case 4:
          return 'OPTIONS';
        case 5:
          return 'HEAD';
        case 6:
          return 'PATCH';

        default:
          return 'GET';
      }
    }
  }

  private getHttpBody(requestOptions: IHttpRequest): any {
    if (requestOptions.data) {
      return requestOptions.isMultiPartRequest ? requestOptions.data : JSON.stringify(requestOptions.data);
    }

    return null;
  }

  /**
   * The method returns a true if the request is to a Find Care API endpoint
   * @param url The url which is getting invoked over Http
   */
  private isFindCareApiUrl(url: string) {
    if (!url) {
      return false;
    }
    if (!this.API_PATH_STRING.some((str)=> url.toLocaleLowerCase().indexOf(str) != -1)) {
      return false;
    } else {
      return true;
    }
  }

}
