import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PageAlertComponent } from './pageAlertCmp';
import { PageAlertsContainerComponent } from './pageAlertContainerCmp';
import { pageAlertDetailComponent } from './pageAlertDetailCmp';
import { ResultPageAlertComponent } from './resultPageAlertCmp';
import { PageAlertSvc } from './service/pageAlertSvc';

@NgModule({
  declarations: [PageAlertComponent, PageAlertsContainerComponent,pageAlertDetailComponent,ResultPageAlertComponent],
  exports: [PageAlertComponent, PageAlertsContainerComponent,pageAlertDetailComponent,ResultPageAlertComponent],
  imports: [CommonModule, UxSharedModule.forRoot(), FormsModule],
  providers: [PageAlertSvc]
})
export class PageAlertsModule {}
