import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { BaseComponent } from '../../base-component/baseCmp';
import { IMachineReadable, IUrl } from '../interfaces/iMachineReadable';
import { BRAND_CODE } from './../../../constants/app-constants';

@Component({
  moduleId: module.id,
  selector: 'app-machine-readable',
  templateUrl: '../views/pfMachineReadableCmp.html'
})
export class PFMachineReadableComponent extends BaseComponent {
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  get machineReadable(): IMachineReadable {
    return this.content?.machineReadable;
  }

  get brand(): IUrl {
    //This is a temperory fix for cobrandUrls.
    if (
      this._appSession.metaData.brandCd?.toLowerCase() === BRAND_CODE.HEALTHBLUE.toLowerCase() &&
      this._appSession?.planRule?.cobrandRule?.object?.cobrand?.toLowerCase() === BRAND_CODE.HEALTHBLUEKC.toLowerCase()
    ) {
      return this.content?.machineReadable?.cobrandUrls[BRAND_CODE.HEALTHBLUEKC.toLowerCase()];
    } else {
      return this.content?.machineReadable?.brandUrls[(this._appSession.metaData.brandCd || '').toLowerCase()];
    }
  }

  /**
   * This method will stop propagation so that HostListener's will not capture the click event
   * @param event
   * @returns
   */
  onMachineReadableClick(event: Event): void {
    event?.stopPropagation();
    return;
  }
}
