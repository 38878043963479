import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BILLING_TYPE, Locale, OFFICE_VISIT_MEDICALCODE } from '../../../../common/constants/app-constants';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { ICostInfo } from '../../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { OUTNETWORK } from '../../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { PROVIDER_TYPE } from '../../../common/constants/common';
import { RESULT_CONTENT_EN } from '../../../common/contents/result/resultContent';
import { RESULT_CONTENT_ES } from '../../../common/contents/result/resultContent.es';
import { IProvider } from '../../interfaces/iProvider';
import { CompareProvider } from '../../services/compareProvider';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { IOption } from './../../../common/interfaces/iOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-card-cmp',
  templateUrl: './providerCardCmp.html'
})
export class ProviderCardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() provider: IProvider;
  @Input() index: number;
  @Input() isActive!: boolean;
  @Input() mapVisible = true;
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _pfOfficeVisitCost: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST]);
  hasCostInfo = false;
  private pfOfficeVisitCostSubscription: Subscription;
  comapreProvider: IOption;
  comapreSelectedProviders: IProvider[] = [];

  private compareProviderSubscription: Subscription;
  providerMap = new Map<number, IProvider>();
  @Input() providerRemoved: IProvider = null;
  @Input() clearAll: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _compareProvider: CompareProvider
  ) {
    super(_route, _eventHandler, _appSession);
    this.pfOfficeVisitCostSubscription = this._pfOfficeVisitCost.subscribe((resp) => {
      const costDetails = resp.object;
      if (costDetails?.providerList?.length) {
        for (const provider of costDetails.providerList) {
          if (provider.providerKey === this.provider?.providerIdentifier && provider.addressKey === this.provider?.addressSummary?.addressIdentifier) {
            this.provider = { ...this.provider, costInfo: provider.costInfo };
            break;
          }
        }
      }
      this.hasCostInfo = this.provider && this.provider.costInfo ? true : false;
      if (this.provider?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(this.provider.costInfo?.copay)) {
        this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
        this._appSession.billingType = BILLING_TYPE.CPT;
        this._appSession.selectedPosCode = this._appSession.metaData?.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
        this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
        this._appSession.isSpecialtyOfficeVisitCost = true;
      }
    });
  }

  /**
   * Initializes the component and subscribes to necessary observables.
   */
  ngOnInit(): void {
    if (this.provider?.costInfo) {
      this.hasCostInfo = true;
    }
    this.comapreProvider = this.getOptionObject(this.provider, this.index);
    this.compareProviderSubscription = this._compareProvider.compareProvider.subscribe((data) => {
      this.comapreSelectedProviders = data || [];
    });
  }

  getEycCostInfo(provider: IProvider): ICostInfo {
    let eycCostInfo = {} as ICostInfo;
    if (provider?.eycAffliation?.affiliationList?.length) {
      const affFacility = { ...provider.eycAffliation.affiliationList[0] };
      if (affFacility?.eycCostInfo) {
        eycCostInfo = affFacility.eycCostInfo;
      }
    } else if (provider?.eycCostInfo) {
      eycCostInfo = provider.eycCostInfo;
    }
    return eycCostInfo;
  }

  ngOnDestroy(): void {
    if (this.pfOfficeVisitCostSubscription) {
      this.pfOfficeVisitCostSubscription.unsubscribe();
    }
    this.compareProviderSubscription?.unsubscribe();
  }

  getNetworkStatus(status: string): string {
    const statusLabels = {
      IN_NETWORK: this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent.inNetwork : RESULT_CONTENT_EN.summaryComponent.inNetwork,
      OUT_NETWORK: this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent.filter.labels.oonPlan : RESULT_CONTENT_EN.summaryComponent.filter.labels.oonPlan
    };
    return status === OUTNETWORK ? statusLabels.OUT_NETWORK : statusLabels.IN_NETWORK;
  }

  onSendCardStatus(status: boolean) {
    this.cardSelected.emit(status);
  }

  onCompare(providerObj: IProvider, index: number): void {
    if (this.providerMap.has(index)) {
      this.providerMap.delete(index);
      this._compareProvider.remove(providerObj);
    } else {
      this.providerMap.set(index, providerObj);
      this._compareProvider.add(providerObj);
    }
  }

  setCompareSelection(providerObj: IProvider, index: number): string {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}-${index}`;
      if (this.providerMap.has(index)) {
        return value;
      } else {
        return '';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['providerRemoved'] && this.providerRemoved) {
      const providerToRemove = this.providerRemoved;
      this.providerMap.forEach((provider, index) => {
        if (provider.providerIdentifier === providerToRemove.providerIdentifier) {
          this.providerMap.delete(index);
        }
      });
    }
    if (changes['clearAll']) {
      this.providerMap.clear();
    }
  }

  disableSelection(providerIdentifier: string): boolean {
    const provider = this.comapreSelectedProviders.find((option) => option.providerIdentifier === providerIdentifier);
    const isMobile = this.onScreenResize();
    const maxProviders = isMobile ? 2 : 3;

    return this.comapreSelectedProviders.length >= maxProviders && !provider;
  }

  private getOptionObject(providerObj: IProvider, index: number): IOption {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}-${index}`;
      return {
        value: value,
        id: `input-${value}`,
        name: providerObj.providerName,
        trueValue: value,
        falseValue: ''
      } as IOption;
    }
  }

  showVirtualOption() {
    return !this.mapVisible && this.provider?.providerFlags?.includes('virtualProvInd');
  }

  showOnlinePharmacy() {
    return this.provider?.providerTypeCodeList?.includes(PROVIDER_TYPE.PHARMACY) && !this.provider?.location?.address?.addressOne && !this.mapVisible;
  }
}
