import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppSession } from '../../../common/values/appSession';
import { BootstrapHandler } from '../classes/bootstrapHandler';
import { SearchExecutionMode } from '../constants/common';
import { IAppContract } from '../interfaces/iAppContract';
import { IAppState, IBootstrapResponse } from '../interfaces/iBootstrap';
import { IContract } from '../interfaces/iContract';
import { IMember } from '../interfaces/iMember';
import { FeatureManagerUtility } from '../utilities/featureManagerUtil';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  public members: IMember[] = [];
  public contracts: IContract[] = [];
  public appContract: IAppContract | undefined = undefined;
  private bootstrapSubject = new BehaviorSubject<IBootstrapResponse | undefined>(undefined);
  private appContractSubject = new BehaviorSubject<IAppContract | undefined>(this.appContract);
  private eligibleMemberSubject = new BehaviorSubject<string>(undefined);

  contract = this.appContractSubject.asObservable();
  array$ = this.eligibleMemberSubject.asObservable();

  constructor(
    private bootstrapHandler: BootstrapHandler,
    private appSession: AppSession
  ) {}

  /**
   * Initializes the bootstrap service by fetching and setting the bootstrap data.
   */
  async initialize(): Promise<void> {
    const bootstrap = await this.bootstrapHandler.getBootstrap();
    this.setBootstrap(bootstrap);
  }

  /**
   * Sets the selected member contract based on the provided member UID, contract UID, and status code.
   * If a matching contract is found, it updates the appContract with the contract.
   * If no matching contract is found, it does nothing.
   * @param mbrUid - The member UID to match.
   * @param contractUid - The contract UID to match (optional).
   * @param statusCd - The status code to match (optional).
   */
  setAppContract(mbrUid: string, contractUid?: string, statusCd?: string, hcid: string = undefined): void {
    const contracts = this.bootstrapSubject.getValue()?.contracts || [];
    let _selectedContract: IContract | undefined;

    if (!isEmpty(mbrUid)) {
      // Find the contract based on the provided parameters
      if (!isEmpty(contractUid) && !isEmpty(statusCd)) {
        _selectedContract = contracts.find((contract) => contract.mbrUid === mbrUid && contract.contractUid === contractUid && contract.statusCd === statusCd);
      } else if (!isEmpty(contractUid)) {
        _selectedContract = contracts.find((contract) => contract.mbrUid === mbrUid && contract.contractUid === contractUid);
      } else {
        _selectedContract = contracts.find((contract) => contract.mbrUid === mbrUid);
      }
    } else if (!isEmpty(hcid)) {
      _selectedContract = contracts.find((contract) => contract.hcId === hcid);
    } else {
      return;
    }

    if (!_selectedContract) {
      return;
    }

    // Extract prefixes from coverages and assign to _selectedContract.prefixes
    _selectedContract.prefixes = _selectedContract.coverages?.flatMap((coverage) => coverage.prefixes) || [];

    // Extract networks from coverages and assign to _selectedContract.networks
    _selectedContract.networks = _selectedContract.coverages?.flatMap((coverage) => coverage.networks) || [];

    // Create the app contract object
    const _appContract: IAppContract = {
      members: this.members,
      selectedContract: _selectedContract,
      contracts: this.contracts
    };

    // Update the app contract and notify subscribers
    this.appContract = _appContract;
    this.setAppStateInSession();

    this.appContractSubject.next(_appContract);
  }

  /**
   * Sets the bootstrap data and updates the app session.
   * @param bootstrap The bootstrap response object.
   */
  private setBootstrap(bootstrap: IBootstrapResponse | null): void {
    // ensuring initialization
    if (this.appSession.appState?.opsState) {
      this.appSession.appState.opsState.hasMemberContract = false;
    } else {
      this.appSession.appState = this.appSession.appState ?? { features: [], flags: [], opsState: { hasMemberContract: false } };
      this.appSession.appState.opsState = this.appSession.appState.opsState ?? { hasMemberContract: false };
    }

    if (bootstrap) {
      // Assign the bootstrap response to the app session
      this.appSession.bootstrap = bootstrap;
      this.bootstrapSubject.next(bootstrap);

      if (Array.isArray(bootstrap.contracts)) {
        const _appStateExecution = this.appSession?.appState?.executionMode || 'UNDEFINED';
        let mbrUid = '';
        let contractUid = '';
        if (_appStateExecution === 'SECURE') {
          this.appSession.appState.opsState.hasMemberContract = true;
          const _deepLinkContractUid = this.appSession?.deeplinkParams?.contractUid || '';
          const _contractWithDeeplinkContractUid = bootstrap.contracts.find((contract) => contract.contractUid === _deepLinkContractUid);
          if (_contractWithDeeplinkContractUid) {
            contractUid = _deepLinkContractUid;
            mbrUid = _contractWithDeeplinkContractUid.mbrUid;
          } else {
            mbrUid = bootstrap.contracts[0]?.mbrUid;
            contractUid = bootstrap.contracts[0]?.contractUid;
          }
          this.setMembers(bootstrap.contracts);
          this.setAppContract(mbrUid, contractUid);
        } else if (_appStateExecution === 'HCID_SEARCH') {
          const _hcId = bootstrap.contracts[0]?.hcId;
          this.setAppContract(undefined, undefined, undefined, _hcId);
        } else {
          console.info('The execution mode of the FC app is not appropriate!');
        }
      } else {
        this.setAppStateInSession();
      }
    }
  }

  /**
   * Updates the members list based on the provided contracts.
   * @param contracts The array of bootstrap contracts.
   */
  private setMembers(contracts: IContract[] | null): void {
    if (!contracts) {
      return;
    }

    const memberMap = new Map<string, IMember>();

    contracts.forEach((contract) => {
      this.addMemberToMap(memberMap, contract);
    });

    this.members = Array.from(memberMap.values());
    contracts.forEach((contract) => this.updateAssociatedMembers(contract));
    this.contracts = Array.from(new Map(contracts.map((contract) => [`${contract.contractUid}-${contract.statusCd}`, { ...contract }])).values());
  }

  /**
   * Updates the associated members for a given contract.
   * @param contract The contract to update associated members for.
   */
  private updateAssociatedMembers(contract: IContract): void {
    const memberMap = new Map<string, IMember>();
    const contracts = this.bootstrapSubject.getValue()?.contracts?.filter((c) => c.contractUid === contract.contractUid) || [];

    contracts.forEach((c) => {
      this.addMemberToMap(memberMap, c);
    });

    contract.associatedMembers = Array.from(memberMap.values());
  }

  /**
   * Enables eligible members only for the member chips
   * @param newArray
   */
  enableEligibleMembers(newArray: string) {
    this.eligibleMemberSubject.next(newArray);
  }

  /**
   * Adds a member to the member map if it doesn't already exist.
   * @param memberMap The map to add the member to.
   * @param contract The contract containing the member information.
   */
  private addMemberToMap(memberMap: Map<string, IMember>, contract: IContract): void {
    if (!memberMap.has(contract.mbrUid)) {
      memberMap.set(contract.mbrUid, {
        mbrUid: contract.mbrUid,
        firstNm: contract.firstNm,
        lastNm: contract.lastNm,
        dob: contract.dob,
        relationshipCd: contract.relationshipCd,
        genderCode: contract.genderCode,
        yearOfBirth: contract.yearOfBirth
      });
    }
  }

  private setAppStateInSession() {
    const _appState = {
      executionMode: this.appSession.appState?.executionMode || SearchExecutionMode.UNDEFINED,
      selectedEligibilityProduct: this.appContract || {},
      features: this.appSession.bootstrap?.features || [],
      flags: this.appSession.bootstrap?.flags || [],
      opsState: this.appSession.appState?.opsState || { hasMemberContract: false },
      opsIndicator: {}
    } as IAppState;

    const _sydneyFeatures = this.appSession.bootstrap?.sydneyFeatures || [];
    const _sydneyFeaturesForContract = _sydneyFeatures.find((feature) => feature.contractUid === this.appContract?.selectedContract?.contractUid);
    if (Array.isArray(_sydneyFeaturesForContract?.features) && _sydneyFeaturesForContract?.features?.length > 0) {
      _appState.features = Array.from(new Set(_appState.features?.concat(_sydneyFeaturesForContract.features)));
    }

    _appState.flags = Array.from(new Set(_appState.flags?.concat(this.appContract?.selectedContract?.productFlags || [])));

    this.appSession.appState = _appState;
    // set ops indicator only after features and flags set.
    this.appSession.appState.opsIndicator = FeatureManagerUtility.getOpsIndicator(this.appSession);
  }
}
