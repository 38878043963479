import { Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { HttpMethod } from '../enums/httpMethodEnum';
import { IPublicTccMedicalSearchRequest } from '../interfaces/iPublicTccMedicalRequest';
import { IPublicTccMedicalSearchResponse } from '../interfaces/iPublicTccMedicalSearchResponse';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';
@Injectable({ providedIn: 'root' })
export class PublicV1TccMedical extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(
    searchRequest: IPublicTccMedicalSearchRequest
  ): Promise<IPublicTccMedicalSearchResponse> {
    const apiUrl = apiNameList.restApi.publicV1TccMedical;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchRequest,
      headers: this.getHeaders()
    });
  }
}
