import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { IProviderList } from '../../../../../findcare/result/interfaces/iProvider';
import { FindCarePageType } from '../../../enums/findCarePages';
import { IAlertMessage } from '../../../interfaces/iPageAlert';
import { BaseComponent } from '../../core/baseCmp';
import { PageAlertHandler } from './service/pageAlertHandler';

@Component({
  moduleId: module.id,
  selector: 'app-fc-page-alert-detail-cmp',
  templateUrl: './pageAlertDetailCmp.html'
})
export class pageAlertDetailComponent extends BaseComponent implements OnInit {
  detailsPageAlerts: IAlertMessage[] = null;
  resultPageAlerts: IAlertMessage[] = null;
  @Output()
  pageTitle: string = null;
  @Input() providersInfo: IProviderList;
  @Input() pageName: FindCarePageType;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.pageTitle = this.content?.common?.pageAlertComponentLabels?.notice;
    this.detailsPageAlerts = this._appSession.pageAlerts[this.pageName];
  }

  getMessage(alertMessage: IAlertMessage) {
    return PageAlertHandler.replaceLiteralwithContent(alertMessage.id, this.providersInfo, this._appSession, this.content);
  }

  getIconStyle(type: string): string {
    return PageAlertHandler.getIconStyle(type);
  }
}
