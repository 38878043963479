import { isEmpty } from 'lodash';
import { ISearchParameters } from '../../../fad/search-providers/interfaces/iSearchParameters';
import { IRxFilter } from '../../result/interfaces/iRxFilter';

export class SearchRequestUtility {
  static buildRxFilterRequest(searchParams: ISearchParameters): IRxFilter {
    const rxFilter: IRxFilter = {};
    if ((searchParams?.dispenseTypeCode ?? []).length > 0) {
      rxFilter.dispenseTypeCode = searchParams?.dispenseTypeCode;
    }
    if ((searchParams?.languages ?? []).length > 0) {
      rxFilter.languageCodes = searchParams?.languages;
    }
    if (!isEmpty(searchParams?.pharmacyLevel)) {
      rxFilter.pharmacyLevel = searchParams?.pharmacyLevel;
    }
    if ((searchParams?.rxFeatures ?? []).length > 0) {
      rxFilter.features = searchParams?.rxFeatures; // rxFeatures values will be populated from filter selection - yet to implement
    }
    return rxFilter;
  }
}
