import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ISpecialty } from '../../../../fad/provider-details/models/iDetailsResponse';
import { OUTNETWORK } from '../../../../fad/search-results/values/providerSearchConstants';
import { FindCarePageType } from '../../../common/enums/findCarePages';
import { ProviderCard, ProviderCardNavigation } from '../../constants/result';
import { IProvider } from '../../interfaces/iProvider';
import { IAffiliationProvider } from '../../interfaces/iProviderAffiliation';
import { IDetail } from '../../interfaces/iProviderDetail';
import { BaseComponent } from './../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-overview-cmp',
  templateUrl: './providerOverviewCmp.html'
})
export class ProviderOverviewComponent extends BaseComponent {
  page = FindCarePageType.DetailOverview;
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() specialtySelected: EventEmitter<ISpecialty> = new EventEmitter<ISpecialty>();
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  @Input() providerDetails: IDetail;
  @Input() provider: IProvider;
  overviewContent = this.content?.result?.providerOverview;
  summaryContent = this.content?.result?.summaryComponent;
  showProviderLoader = true;
  @Input() affiliations: Map<string, IAffiliationProvider[]>;
  isMapView: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _sanitizer: DomSanitizer
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    // do something
  }

  isDetailPageAlert(): boolean {
    return this._appSession.pageAlerts[FindCarePageType.DetailOverview].length > 0;
  }

  /**
   * View PCP List Card Show Event.
   * Emits boolean value true for opening pcpList.
   */
  onViewPCPList() {
    this.cardSelected.emit(ProviderCard.PCP);
  }

  /**
   * View Speciality Card Show Event.
   * Emits boolean value true for opening speciality.
   */
  onShowSpeciality(speciality: ISpecialty) {
    this.cardSelected.emit(ProviderCard.SPECIALITY);
    this.specialtySelected.emit(speciality);
  }

  /**
   * View Recognitions Card Show Event.
   * Emits boolean value true for opening recognitions.
   */
  onShowRecognitions() {
    this.cardSelected.emit(ProviderCard.RECOGNITION);
  }

  /**
   * View Affiliations Card Show Event.
   * Emits boolean value true for opening affiliations.
   */
  onShowAffiliations() {
    this.cardSelected.emit(ProviderCard.AFFILIATION);
  }

  getGenderAndEthnicity(): string {
    const gender = this.providerDetails?.gender;
    const ethnicity = this.providerDetails?.ethnicityCodes || [];
    const ethnicityString = ethnicity?.length > 0 ? ethnicity.join(', ') : this.overviewContent.labels.noEthnicity;

    if (gender && ethnicity?.length > 0) {
      return `${this.titleCase(gender)}, ${this.titleCase(ethnicityString)}`;
    }
    if (gender && ethnicity?.length === 0) {
      return `${this.titleCase(gender)}, ${ethnicityString}`;
    }
    if (!gender && ethnicity?.length > 0) {
      return `${this.titleCase(ethnicityString)}, ${this.overviewContent.labels.noGender}`;
    }
    return this.overviewContent.labels.noGenderOrEthnicity;
  }

  getOfficeHours(): string[] {
    const officeHours = this.providerDetails?.officeHours || [];
    const daysPattern = /^(M|Tu|W|Th|F|Sa|Su)\s+/;
    const allDays = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];
    const officeHoursMap = new Map<string, string>();

    officeHours.forEach((hour) => {
      const day = hour.match(daysPattern)?.[1];
      if (day) {
        const hours = hour.replace(daysPattern, '').replace(' TO ', ' - ');
        if (hours === '00:00 AM - 00:00 AM') {
          officeHoursMap.set(day, 'Not Open');
        } else {
          officeHoursMap.set(day, hours);
        }
      }
    });

    return allDays.map((day) => officeHoursMap.get(day) || 'Not Open');
  }

  getNetworkStatus(status: string): string {
    const plan = this.providerDetails?.providerNetworkStatus?.coverages?.[0];
    const statusLabels = {
      IN_NETWORK: this.summaryContent.networkStatusSecured.TP_INNETWORK.replace('{PLANTYPE}', this.titleCase(plan || '')),
      OUT_NETWORK: this.summaryContent.networkStatusSecured.TP_OUTNETWORK
    };
    return status === OUTNETWORK ? statusLabels.OUT_NETWORK : statusLabels.IN_NETWORK;
  }

  checkNum(review: string): boolean {
    return !isNaN(Number(review));
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  isDiamondProvider(): boolean {
    if (!this.providerDetails?.performance?.recognition) {
      return false;
    }
    for (let recognition of this.providerDetails?.performance?.recognition) {
      if (recognition.code === this.overviewContent?.diamondCode) {
        return true;
      }
    }
    return false;
  }

  getReviewURL(): string {
    let reviewUrl;
    const pdtStrKey = this.providerDetails?.reviewRatings?.[0]?.pdtStrKey;
    if (pdtStrKey) {
      reviewUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + pdtStrKey + '/en');
    }
    return reviewUrl;
  }

  getMapEndpoint(): string {
    const latitude = this.providerDetails?.address?.latitude?.trim();
    const longitude = this.providerDetails?.address?.longitude?.trim();
    return `${this.overviewContent?.mapEndpoint}${latitude}+${longitude}`;
  }
}
