import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IBaseCode } from '../../../../common/interfaces/iBaseCode';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IOption } from '../../../common/interfaces/iOption';
import { FilterCategory } from '../../constants/result';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-checkbox-list-cmp',
  templateUrl: './filterCheckboxListCmp.html'
})
export class FilterCheckboxListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  options: Array<IBaseCode> = [];
  @Input()
  category: FilterCategory;

  optionDefinitions: Array<IOption> = [];
  appliedFilters: IAppliedSearchFilter[] = [];

  private appliedFilterRemove: Subscription;
  private appliedFiltersSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _appliedSearchFilter: AppliedSearchFilter
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.bindFilterOptions();
    this.appliedFilterRemove = this._appliedSearchFilter.appliedFilterRemove.subscribe((filter: IAppliedSearchFilter) => {
      if (filter && filter.category === this.category) {
        this.bindFilterOptions();
      }
    });

    this.appliedFiltersSubscription = this._appliedSearchFilter?.getAppliedFilters?.subscribe((data) => {
      this.bindFilterOptions();
    });
  }

  ngOnDestroy() {
    this.appliedFilterRemove?.unsubscribe();
    this.appliedFiltersSubscription?.unsubscribe();
  }

  onOptionChange(event: string, option: IOption): void {
    if (event !== '') {
      this._appliedSearchFilter.addFilterOption(this.category, option);
    } else {
      this._appliedSearchFilter.removeFilterOption(this.category, option);
    }
  }

  setSelection(option: IOption): string {
    if (option && this.appliedFilters?.some((filter) => filter.value === option.value)) {
      return option.value;
    } else {
      return '';
    }
  }

  private bindFilterOptions() {
    this.optionDefinitions = [];
    const appliedCategoryFilters = this._appliedSearchFilter.mapAppliedFilterItemsByCategory(this.category);
    this.appliedFilters = appliedCategoryFilters?.[this.category] || [];
    (this.options || []).forEach((option: IBaseCode) => {
      this.optionDefinitions.push(this.getOptionObject(option.code, option.name, false));
    });
  }

  private getOptionObject(value: string, name: string, isChecked: boolean = false): IOption {
    return {
      label: name,
      value: value,
      id: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      name: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      trueValue: value,
      falseValue: '',
      isChecked: isChecked
    } as IOption;
  }
}
