import { AfterViewInit, Component, ElementRef, EmbeddedViewRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BING_MAPS_CUSTOM_STYLE } from '../../constants/result';
import { IMapIcon, IMapInfoboxContext, IMapLocation } from '../../interfaces/iMap';
import { IProvider } from '../../interfaces/iProvider';
import { environment } from './../../../../../environments/environment';
import { BingMapsLoader } from './../../../../common/services/bingMapsLoader';
import { EventHandler } from './../../../../common/services/eventHandler';
import { AppSession } from './../../../../common/values/appSession';
import { BaseComponent } from './../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-map-cmp',
  templateUrl: './providerMapCmp.html'
})
export class ProviderMapComponent extends BaseComponent implements AfterViewInit, OnChanges {
  @Input() providers: IProvider[] = [];
  @Output() mapSearch: EventEmitter<IMapLocation> = new EventEmitter();
  @Output() openProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedPushpinProvider: EventEmitter<IProvider> = new EventEmitter();

  @ViewChild('mapView') mapView: ElementRef;
  @ViewChild('pushpinInfobox') pushpinInfobox: TemplateRef<IMapInfoboxContext>;
  @ViewChild('clusterInfobox') clusterInfobox: TemplateRef<IMapInfoboxContext>;
  @ViewChild('infoboxContainer', { read: ViewContainerRef }) infoboxContainer: ViewContainerRef;

  providerMap: Microsoft.Maps.Map = undefined;
  pinInfobox: Microsoft.Maps.Infobox = undefined;
  showSearchMapArea: boolean = false;
  activePushpin: Microsoft.Maps.Pushpin = undefined;
  pushpins: Microsoft.Maps.Pushpin[] = [];
  clusterLayer: Microsoft.Maps.ClusterLayer = undefined;
  mapLoaded: boolean = false;
  @Input() hideNavbar: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _loader: BingMapsLoader
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngAfterViewInit() {
    this.loadMap().then(() => {
      this.mapLoaded = true;
      this.checkAndSetMapData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providers']) {
      this.checkAndSetMapData();
    }
  }

  /**
   * Load the Bing Maps library and initialize the map
   */
  async loadMap() {
    await this._loader.loadMapsLibrary();

    this.providerMap = new Microsoft.Maps.Map(this.mapView.nativeElement, {
      mapTypeId: Microsoft.Maps.MapTypeId.road,
      credentials: environment.bingMaps.apiKey,
      showMapTypeSelector: false,
      showZoomButtons: false,
      showLocateMeButton: false,
      customMapStyle: BING_MAPS_CUSTOM_STYLE,
      center: new Microsoft.Maps.Location(34.197972948629776, -84.1564135)
    });
    this.setupPinInfobox();
  }

  /**
   * Check if the map is loaded and the providers data is available
   */
  checkAndSetMapData() {
    if (this.mapLoaded && this.providers?.length > 0) {
      this.setMapData();
    }
  }

  /**
   * Set the map data with the providers data
   */
  setMapData() {
    if (this.clusterLayer) {
      this.providerMap.layers.remove(this.clusterLayer);
    }

    // Filter out the virtual providers with invalid latitude and longitude
    this.pushpins = this.providers.filter((provider) => provider.addressSummary?.latitude !== '-1' && provider.addressSummary?.longitude !== '-1').map((provider) => this.createPushpin(provider));

    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      this.clusterLayer = new Microsoft.Maps.ClusterLayer(this.pushpins, {
        clusteredPinCallback: this.clusteredPinCallback.bind(this)
      });

      this.providerMap.layers.insert(this.clusterLayer);

      Microsoft.Maps.Events.addHandler(this.providerMap, 'viewchangeend', this.onMapPositionChange.bind(this));
      Microsoft.Maps.Events.addHandler(this.providerMap, 'click', this.onMapClick.bind(this));
    });

    if (!this.showSearchMapArea) {
      this.setMapBounds(this.pushpins);
    }
    this.showSearchMapArea = false;
  }

  /**
   * Create a pushpin for the provider
   * @param provider Provider data
   * @returns Microsoft.Maps.Pushpin instance
   */
  createPushpin(provider: IProvider): Microsoft.Maps.Pushpin {
    const { latitude, longitude } = provider?.addressSummary;
    const pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(latitude, longitude), this.getPushpinOptions(IMapIcon.DEFAULT_PUSHPIN));
    pushpin.metadata = provider;

    this.addPushpinEventHandlers(pushpin, provider);

    return pushpin;
  }

  /**
   * Callback function for clustered pushpin
   * @param cluster Clustered pushpin
   */
  clusteredPinCallback(cluster: Microsoft.Maps.ClusterPushpin, provider: IProvider) {
    cluster.setOptions(this.getPushpinOptions(IMapIcon.DEFAULT_CLUSTER));

    this.addPushpinEventHandlers(cluster, provider);
  }

  /**
   * Add event handlers for the pushpin
   * @param pushpin Pushpin instance
   */
  addPushpinEventHandlers(pushpin: Microsoft.Maps.Pushpin | Microsoft.Maps.ClusterPushpin, provider: IProvider) {
    Microsoft.Maps.Events.addHandler(pushpin, 'mouseover', () => {
      this.showInfobox(pushpin);
    });

    Microsoft.Maps.Events.addHandler(pushpin, 'mouseout', () => {
      this.hideInfobox(pushpin);
    });

    Microsoft.Maps.Events.addHandler(pushpin, 'click', () => {
      this.showInfobox(pushpin);
      this.onOpenProviderCard(provider);
    });
  }

  /**
   * Get pushpin options based on the icon type
   * @param iconType type of icon
   * @param iconText text to be displayed on the icon
   * @returns Microsoft.Maps.IPushpinOptions instance
   */
  getPushpinOptions(iconType: IMapIcon, iconText?: string): Microsoft.Maps.IPushpinOptions {
    const mapIconOptions = {
      DEFAULT_PUSHPIN: {
        icon: this.getCommonImageURL('map-pushpin.svg'),
        anchor: new Microsoft.Maps.Point(17, 48)
      },
      HOVER_PUSHPIN: {
        icon: this.getCommonImageURL('map-pushpin-hover.svg'),
        anchor: new Microsoft.Maps.Point(21, 59)
      },
      DEFAULT_CLUSTER: {
        icon: this.getCommonImageURL('map-pushpin.svg'),
        anchor: new Microsoft.Maps.Point(17, 48),
        color: '#286CE2'
      },
      HOVER_CLUSTER: {
        icon: this.createClusterHoverIcon(iconText),
        anchor: new Microsoft.Maps.Point(21, 59),
        color: '#FFFFFF'
      }
    };

    return mapIconOptions[iconType];
  }

  /**
   * Create a cluster hover icon with the text
   * @param text Text to be displayed on the cluster icon
   * @returns SVG string with text
   */
  createClusterHoverIcon(text: string): string {
    const textColor = '#286CE2';
    // To add text to the cluster icon, we need to create an SVG string with the text
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="59" viewBox="0 0 42 59" fill="none">
        <mask id="path-1-inside-1_4884_303327" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5216 32.4822C40.7198 29.1726 42 25.2039 42 20.9371C42 9.37388 32.598 0 21 0C9.40202 0 0 9.37388 0 20.9371C0 25.204 1.28018 29.1727 3.4785 32.4824L18.4477 56.7165C19.62 58.6144 22.38 58.6144 23.5523 56.7165L38.5216 32.4822Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5216 32.4822C40.7198 29.1726 42 25.2039 42 20.9371C42 9.37388 32.598 0 21 0C9.40202 0 0 9.37388 0 20.9371C0 25.204 1.28018 29.1727 3.4785 32.4824L18.4477 56.7165C19.62 58.6144 22.38 58.6144 23.5523 56.7165L38.5216 32.4822Z" fill="white"/>
        <path d="M38.5216 32.4822L34.3566 29.7158L34.311 29.7845L34.2677 29.8546L38.5216 32.4822ZM3.4785 32.4824L7.73241 29.8548L7.68908 29.7846L7.64346 29.7159L3.4785 32.4824ZM18.4477 56.7165L22.7016 54.0889L22.7016 54.0889L18.4477 56.7165ZM23.5523 56.7165L27.8063 59.3441L27.8063 59.3441L23.5523 56.7165ZM37 20.9371C37 24.1888 36.0277 27.1999 34.3566 29.7158L42.6866 35.2486C45.412 31.1453 47 26.2191 47 20.9371H37ZM21 5C29.8507 5 37 12.1494 37 20.9371H47C47 6.59833 35.3453 -5 21 -5V5ZM5 20.9371C5 12.1494 12.1493 5 21 5V-5C6.65474 -5 -5 6.59833 -5 20.9371H5ZM7.64346 29.7159C5.97234 27.2 5 24.1888 5 20.9371H-5C-5 26.2191 -3.41197 31.1454 -0.686462 35.2488L7.64346 29.7159ZM22.7016 54.0889L7.73241 29.8548L-0.77541 35.1099L14.1937 59.3441L22.7016 54.0889ZM19.2984 54.0889C20.08 52.8236 21.92 52.8236 22.7016 54.0889L14.1937 59.3441C17.32 64.4052 24.6801 64.4052 27.8063 59.3441L19.2984 54.0889ZM34.2677 29.8546L19.2984 54.0889L27.8063 59.3441L42.7755 35.1098L34.2677 29.8546Z" fill="#286CE2" mask="url(#path-1-inside-1_4884_303327)"/>
        <text x="50%" y="50%" font-size="16" font-family="Arial, sans-serif" text-anchor="middle" fill="${textColor}" font-weight="700">${text}</text>
      </svg>
    `;
  }

  /**
   * setup the pin infobox and add it to the map
   */
  setupPinInfobox() {
    const mapCenter: Microsoft.Maps.Location = this.providerMap.getCenter();

    this.pinInfobox = new Microsoft.Maps.Infobox(mapCenter, {
      visible: false,
      showPointer: true,
      showCloseButton: true,
      offset: new Microsoft.Maps.Point(16, 48),
      //@ts-ignore
      autoAlignment: true
    });

    this.pinInfobox.setMap(this.providerMap);
  }

  /**
   * Show the infobox for the pushpin
   * @param pushpin Pushpin instance
   */
  showInfobox(pushpin: Microsoft.Maps.Pushpin) {
    const iconType = pushpin instanceof Microsoft.Maps.ClusterPushpin ? IMapIcon.HOVER_CLUSTER : IMapIcon.HOVER_PUSHPIN;

    if (this.activePushpin && this.activePushpin !== pushpin) {
      this.hideInfobox(this.activePushpin);
    }
    this.activePushpin = pushpin;

    pushpin.setOptions(this.getPushpinOptions(iconType, pushpin.getText()));

    this.pinInfobox.setOptions({
      location: pushpin.getLocation(),
      htmlContent: this.getPinContent(pushpin),
      visible: true
    });
  }

  /**
   * Hide the infobox for the pushpin
   * @param pushpin Pushpin instance
   */
  hideInfobox(pushpin?: Microsoft.Maps.Pushpin) {
    const iconType = pushpin instanceof Microsoft.Maps.ClusterPushpin ? IMapIcon.DEFAULT_CLUSTER : IMapIcon.DEFAULT_PUSHPIN;

    pushpin.setOptions(this.getPushpinOptions(iconType));

    this.pinInfobox.setOptions({ visible: false });
  }

  /**
   * Get the content for the infobox based on the pushpin type
   * @param pin pushpin instance
   * @returns HTML content for the infobox
   */
  getPinContent(pin: Microsoft.Maps.Pushpin): string {
    this.infoboxContainer.clear();
    let infoboxViewRef: EmbeddedViewRef<IMapInfoboxContext>;

    if (pin instanceof Microsoft.Maps.ClusterPushpin) {
      const providers: IProvider[] = pin.containedPushpins.map((p) => p.metadata);
      infoboxViewRef = this.infoboxContainer.createEmbeddedView(this.clusterInfobox, { providers: providers });
    } else {
      infoboxViewRef = this.infoboxContainer.createEmbeddedView(this.pushpinInfobox, { provider: pin.metadata });
    }

    infoboxViewRef.detectChanges();
    return infoboxViewRef?.rootNodes[0]?.outerHTML || '';
  }

  /**
   * Set the map bounds based on the pushpins to make all the pushpins visible
   * @param pushpins pushpin instances
   */
  setMapBounds(pushpins: Microsoft.Maps.Pushpin[]) {
    if (pushpins.length === 0) {
      return;
    }

    const bounds = Microsoft.Maps.LocationRect.fromLocations(pushpins.map((pin) => pin.getLocation()));

    this.providerMap.setView({ bounds: bounds, padding: 24 });
  }

  /**
   * Search the map area based on the search criteria
   */
  onSearchMapArea() {
    const mapCenter = this.providerMap.getCenter();
    const center: IMapLocation = { latitude: mapCenter.latitude.toString(), longitude: mapCenter.longitude.toString() };

    this.mapSearch.emit(center);
  }

  /**
   * Show the search map area section on map position change
   */
  onMapPositionChange() {
    this.showSearchMapArea = true;
  }

  /**
   * Map click event handler
   * @param e Mouse event
   */
  onMapClick(e: Microsoft.Maps.IMouseEventArgs) {
    if (e.targetType !== 'pushpin' && e.targetType !== 'clusteredPushpin' && this.activePushpin) {
      this.hideInfobox(this.activePushpin);
    }
  }

  /**
   * Recenter the map to the user's current location
   * @returns void
   */
  onRecenter() {
    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const location = new Microsoft.Maps.Location(latitude, longitude);
        const userLocationPushpin = new Microsoft.Maps.Pushpin(location, {
          icon: this.getCommonImageURL('user-location.svg'),
          anchor: new Microsoft.Maps.Point(16, 16)
        });

        this.providerMap.entities.push(userLocationPushpin);
        this.providerMap.setView({ center: location });
      },
      (error) => {
        // TODO: Handle geolocation error scenario
      }
    );
  }

  /**
   * Set the zoom level of the map
   * @param n Zoom level to be set
   */
  setZoomLevel(n: number) {
    const curLevel = this.providerMap.getZoom();
    this.providerMap.setView({ zoom: curLevel + n });
  }

  /**
   * Toggle the infobox for the provider based on the show flag
   * @param provider provider data
   * @param show boolean flag to show/hide the infobox
   * @returns void
   */
  toggleProviderInfobox(provider: IProvider, show: boolean) {
    let pushpin = this.pushpins.find((pin) => pin.metadata.providerIdentifier === provider.providerIdentifier);
    if (!pushpin) {
      return;
    }

    show ? this.showInfobox(pushpin) : this.hideInfobox(pushpin);
  }

  onOpenProviderCard(provider: IProvider) {
    this.openProviderCard.emit(true);
    this.selectedPushpinProvider.emit(provider);
  }
}
