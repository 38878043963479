import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { HIGH_RANGE_AGE, LOW_RANGE_AGE } from '../../constants/compare';
import { ICompareProvider, IOfficeFeatureCodeList, IProviderDetails, IRecognitions } from '../../interfaces/iCompareProvider';
import { IProvider } from '../../interfaces/iProvider';

@Component({
	moduleId: module.id,
	selector: 'app-fc-provider-compare-cmp',
	templateUrl: './providerCompareCmp.html'
})
export class ProviderCompareComponent extends BaseComponent implements OnInit {
	compareProvidersContent = this.content?.result?.compareProvidersComponent;
	providerDetailsContent: IProviderDetails[];
	@Output() removeSelectedProvider: EventEmitter<IProvider> = new EventEmitter();
	@ViewChild('compareModal') compareModal: ModalComponent;
	expandedIndex: number = -1;
	showCompareResults: boolean = true;
	showRatings: boolean = false;
	showRecognitions: boolean = false;
	showTeleHealth: boolean = false;
	vitalsUrl: SafeResourceUrl;
	popupHeading: string;
	providerRecongnitionsData: IRecognitions;
	officeFeatureLabels = [];
	providerMapData = [];

	constructor(
		@Inject(AppSession)
		private _appSession: AppSession,
		private _eventHandler: EventHandler,
		route: ActivatedRoute,
		private _changeDetector: ChangeDetectorRef,
		private _sanitizer: DomSanitizer
	) {
		super(route, _eventHandler, _appSession);
	}

	ngOnInit() {
		if (this.waitUntilAppReload) {
			return;
		}
		this.popupHeading = this.compareProvidersContent?.labels?.compareResults;
	}

	getProviderMapData(providers) {
		this.providerMapData = [];
		providers.forEach((provider) => {
			const mapData = [{
				addressSummary: {
					latitude: provider?.locations[0]?.address?.latitude,
					longitude: provider?.locations[0]?.address?.longitude
				}
			}];

			this.providerMapData.push(mapData);
		});
	}

	gotoCompare() {
		this.popupHeading = this.compareProvidersContent?.labels?.compareResults;
		this.showRatings = false;
		this.showCompareResults = true;
		this.showRecognitions = false;
		this.showTeleHealth = false;
	}

	ngAfterViewInit() {
		this._changeDetector.detectChanges();
	}

	/** Function to open the modal */
	showModal(data): void {
		this.resetModalState();
		this.providerDetailsContent = data;
		this.getOfficeFeaturesData(data);
		this.getProviderMapData(data);
		this.compareModal.show();
	}

	/** Function to add another provider to the compare modal */
	addAnother(): void {
		this.closeModal();
	}

	/** Function to remove the provider from the modal */
	removeProvider(index: number, provider) {
		this.removeSelectedProvider.emit(provider);
		this.providerDetailsContent.splice(index, 1);
	}

	/** Function to reset the state of the modal */
	resetModalState(): void {
		this.showRatings = false;
		this.showCompareResults = true;
		this.showRecognitions = false;
		this.showTeleHealth = false;
	}

	/** Function to close the modal */
	closeModal(): void {
		this.compareModal.hide();
		this._changeDetector.detectChanges();
	}

	/** Function to print data in the modal */
	onPrint(): void {
		if (this.showCompareResults) {
			this.printTable();
		} else {
			this.printContent();
		}
	}

	/** Function to print content other than table */
	printContent(): void {
		this.removeExistingContainers();
		let printContainer = document.createElement('div');
		printContainer.classList.add('print-content-container');
		const contentToPrint = document.querySelector('.fcr-provider-rating-container') ||
			document.querySelector('.fcr-provider-rewards-scroll') ||
			document.querySelector('.fcr-provider-telehealth-container');
		if (contentToPrint) {
			printContainer.appendChild(contentToPrint.cloneNode(true));
			document.body.appendChild(printContainer);
			window.print();
			document.body.removeChild(printContainer);
		}
	}

	/** Function to print compare data (table content) */
	printTable(): void {
		this.removeExistingContainers();
		const printContainer = document.createElement('div');
		printContainer.classList.add('print-table-container');
		const tableToPrint = document.querySelector('.fcr-compare-provider-table');
		if (tableToPrint) {
			const clonedTable = tableToPrint.cloneNode(true) as HTMLElement;
			const tableHeader = clonedTable.querySelector('thead');
			const tableBody = clonedTable.querySelector('tbody');
			if (tableHeader) {
				const headerDiv = document.createElement('tr');
				headerDiv.innerHTML = tableHeader.innerHTML;
				headerDiv.classList.add('print-table-header');
				printContainer.appendChild(headerDiv);
			}
			if (tableBody) {
				printContainer.appendChild(tableBody.cloneNode(true));
			}
			document.body.appendChild(printContainer);
			window.print();
			document.body.removeChild(printContainer);
		}
	}

	/** Function to remove existing containers (if any) */
	removeExistingContainers(): void {
		const existingPrintContentContainer = document.querySelector('.print-content-container');
		if (existingPrintContentContainer) {
			document.body.removeChild(existingPrintContentContainer);
		}

		const existingPrintTableContainer = document.querySelector('.print-table-container');
		if (existingPrintTableContainer) {
			document.body.removeChild(existingPrintTableContainer);
		}
	}

	/** Function to get provider address text */
	getProviderAddress(address) {
		let addressText = '';
		let distance = '';
		addressText += address.addressOne + ' ' + address.addressTwo;
		distance = address.distance ? parseFloat(address.distance).toFixed(2) : '';
		if (distance) {
			return addressText + ' (' + distance + this.compareProvidersContent?.labels?.miles + ')';
		} else {
			return addressText;
		}
	}

	/** Functions to get provider name text */
	getProviderName(provider: ICompareProvider): string {
		return this.getProviderTitles(provider?.name?.titleList, provider?.networkRegisteredName, provider?.name?.providerName);
	}

	getProviderTitles(titleList: Array<string>, networkRegisteredName: string, providerName: string): string {
		const result = titleList?.slice(0, 4).join(', ');
		const remainingCount = titleList?.length - 4;
		return networkRegisteredName ? networkRegisteredName : providerName + (result ? `, ${result}${remainingCount > 0 ? `, +${remainingCount}` : ''}` : '');
	}

	/** Function to get gender preference text */
	getGenderPreference(genderPreferences: string): string {
		let genderPreference = '';
		if (typeof genderPreferences !== 'undefined' && genderPreferences !== null && genderPreferences.trim() !== '') {
			switch (genderPreferences.toUpperCase()) {
				case this.compareProvidersContent?.labels?.male:
				case this.compareProvidersContent?.labels?.maleTxt:
					genderPreference = this.compareProvidersContent?.labels?.maleOnlyText;
					break;
				case this.compareProvidersContent?.labels?.female:
				case this.compareProvidersContent?.labels?.femaleTxt:
					genderPreference = this.compareProvidersContent?.labels?.femaleOnlyText;
					break;
				case this.compareProvidersContent?.labels?.both:
				case this.compareProvidersContent?.labels?.bothTxt:
					genderPreference = this.compareProvidersContent?.labels?.bothText;
					break;
			}
		}
		return genderPreference;
	}

	/** Function to get IHSP text */
	getIhspPreference(ihsp: boolean): string {
		return ihsp ? this.compareProvidersContent?.labels?.yesText : this.compareProvidersContent?.labels?.noText;
	}

	/** Function to get Network status text */
	getNetworkStatusText(codes: Array<string>) {
		let networkStatusText = '';
		codes.forEach((code) => {
			switch (code) {
				case this.compareProvidersContent?.labels?.medical:
					networkStatusText += this.compareProvidersContent?.labels?.medicalNetwork + ', ';
					break;
				case this.compareProvidersContent?.labels?.dental:
					networkStatusText += this.compareProvidersContent?.labels?.dentalNetwork + ', ';
					break;
				case this.compareProvidersContent?.labels?.vision:
					networkStatusText += this.compareProvidersContent?.labels?.visionNetwork + ', ';
					break;
			}
		});
		networkStatusText = networkStatusText.slice(0, -2);
		return networkStatusText;
	}

	/** Function to get Age preference text */
	getAgePreference(lowRange: string, highRange: string): string {
		let agePreference = '';
		const lowRangeAge = lowRange !== undefined && lowRange !== '' ? Number(lowRange) : LOW_RANGE_AGE;
		const highRangeAge = highRange !== undefined && highRange !== '' ? Number(highRange) : LOW_RANGE_AGE;
		if (lowRangeAge > highRangeAge) {
			return agePreference;
		}
		if (lowRangeAge > LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
			agePreference = lowRangeAge.toString() + '-' + highRangeAge.toString() + this.compareProvidersContent?.labels?.yearsText;
		} else if (lowRangeAge > LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
			agePreference = lowRangeAge.toString() + ' ' + this.compareProvidersContent?.labels?.yearOlderText;
		} else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
			agePreference = this.compareProvidersContent?.labels?.birthToText + ' ' + highRangeAge.toString() + ' ' + this.compareProvidersContent?.labels?.yearsText;
		} else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
			agePreference = this.compareProvidersContent?.labels?.birthOlderText;
		}
		return agePreference;
	}

	/** Functiton toget speciality text */
	getSpecialtyText(specialty): string {
		let value = '';
		if (specialty && specialty.specialtyCodeName) {
			value = specialty.specialtyCodeName;
			if (specialty.boardCertificationCode) {
				switch (specialty.boardCertificationCode.toUpperCase()) {
					case this.compareProvidersContent?.labels?.boardCertified:
						value += this.compareProvidersContent?.labels?.boardCertifiedMsg;
						break;
					case this.compareProvidersContent?.labels?.notBoardCertified:
						value += this.compareProvidersContent?.labels?.notBoardCertifiedMsg;
						break;
					case this.compareProvidersContent?.labels?.naBoardCertified:
						value += this.compareProvidersContent?.labels?.naBoardCertifiedMsg;
						break;
				}
			}
		}
		return value;
	}

	/** Function to toggle expanded state for affiliations */
	onAffliateDropdownChanges(index: number) {
		this.expandedIndex = this.expandedIndex === index ? -1 : index;
	}

	checkNum(review: string): boolean {
		return !isNaN(Number(review));
	}

	gotoRating(provider) {
		const providerPdtStrKey = provider.reviewRatings?.[0]?.pdtStrKey;
		if (providerPdtStrKey) {
			this.vitalsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + providerPdtStrKey + '/en');
		}
		this.popupHeading = this.getProviderName(provider);
		this.showRatings = true;
		this.showCompareResults = false;
		this.showRecognitions = false;
		this.showTeleHealth = false;
	}

	getVitalsUrl() {
		return this.vitalsUrl;
	}

	showProviderRecognitions(provider) {
		this.providerRecongnitionsData = provider?.performance?.recognition;
		this.popupHeading = this.getProviderName(provider);
		this.showRatings = false;
		this.showCompareResults = false;
		this.showRecognitions = true;
		this.showTeleHealth = false;
	}

	gotoTelehealth(provider) {
		this.popupHeading = this.getProviderName(provider);
		this.showRatings = false;
		this.showCompareResults = false;
		this.showRecognitions = false;
		this.showTeleHealth = true;
	}

	getOfficeFeaturesData(data) {
		data.forEach((provider) => {
			const officeFeatures = provider?.locations[0]?.address?.officeFeatureCodeList || [];
			officeFeatures
				.filter((officeFeature) => officeFeature?.name)
				.forEach((officeFeature) => {
					if (!this.officeFeatureLabels.includes(officeFeature.name)) {
						this.officeFeatureLabels.push(officeFeature.name);
					}
				});
		});
	}

	getOfficeFeatureValue(featureList: Array<IOfficeFeatureCodeList>, featureName: string): string {
		let labelText = this.compareProvidersContent?.labels?.notAvailable;
		if (featureList && featureList.length > 0) {
			const filterdFeature = featureList.filter((x) => x.name === featureName);
			if (filterdFeature && filterdFeature.length > 0 && filterdFeature[0].value !== '') {
				labelText = filterdFeature[0].value;
			}
		}
		return labelText;
	}

	getAccessibilityNames(list) {
		return list.map((obj) => obj.name).join(', ');
	}

	isDiamondProvider(provider): boolean {
    if (!provider?.performance?.recognition) {
      return false;
    } else {
      for (let recognition of provider?.performance?.recognition) {
				if (recognition.code.name === this.compareProvidersContent?.labels?.diamondProvider) {
					return true;
				}
			}
			return false;
    }
	}
}
