import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { isEmpty } from 'lodash';
import { IWindow } from '../../../../../../common/interfaces/iWindow';
import { DataHelper } from '../../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../common/values/appSession';
import { TRANSPORTATION_PREFIX } from '../../../../../../fad/search-providers/values/providerSearchConstants';
import { IModalContent, IModalMetaInfo } from '../../../../interfaces/iModalContent';
import { CommonUtility } from '../../../../utilities/commonUtil';
import { BaseComponent } from '../../../core/baseCmp';
import { GenericModalService } from '../services/genericModalSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-generic-modal-cmp',
  templateUrl: './genericModalCmp.html'
})
export class GenericModalComponent extends BaseComponent {
  @ViewChild('genericModalCmp')
  genericModal: ModalComponent;
  modalMetaInfo: IModalMetaInfo = {} as IModalMetaInfo;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _genericModalService: GenericModalService,
    @Inject('Window') private _window: IWindow,
    @Inject(DataHelper) private _dataHelper: DataHelper
  ) {
    super(_route, _eventHandler, _appSession);
    this._genericModalService.modalListener().subscribe((modalInfo: IModalMetaInfo) => {
      this.modalMetaInfo = modalInfo;
      this.openModal();
    });
  }

  get genericModalTemplate(): IModalContent {
    let modalContent: IModalContent = {} as IModalContent;
    modalContent = this.content.common.modals[this.modalMetaInfo?.modalId];
    if (!isEmpty(modalContent)) {
      modalContent = CommonUtility.processModalContent(modalContent, this.currentBrand);
    }
    return modalContent;
  }

  get currentBrand() {
    return this._appSession.metaData?.brandName;
  }

  openModal() {
    this.genericModal?.show();
  }

  closeModal() {
    this.genericModal?.hide();
  }

  get isPrefixAvailable(): boolean {
    return !this._dataHelper.isEmptyString(this._appSession?.searchParams?.plan?.alphaPrefix) && TRANSPORTATION_PREFIX.includes(this._appSession.searchParams.plan.alphaPrefix);
  }

  printTransportation() {
    window.print();
    return false;
  }
}
