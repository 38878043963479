import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DenialOfServiceComponent } from './denialOfServiceCmp';

@NgModule({
  declarations: [DenialOfServiceComponent],
  exports: [DenialOfServiceComponent],
  imports: [CommonModule],
  providers: []
})
export class DenialOfServiceModule {
  static forRoot(): ModuleWithProviders<DenialOfServiceModule> {
    return {
      ngModule: DenialOfServiceModule,
      providers: []
    };
  }
}
