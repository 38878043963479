import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BenefitType, BenefitTypeKey, MemberPlanProgressLabels } from '../constants/memberPlanProgressConstants';
import { FormattedMbrPlanProgress, FormattedMbrPlanProgressList, IMemberPlanProgressResp } from '../interfaces/iMemberPlanProgressResponse';

@Pipe({
  name: 'transformMemberPlanProgress'
})
@Injectable()
export class TransformMemberPlanProgressPipe implements PipeTransform {
  transform(result: IMemberPlanProgressResp, content: any): FormattedMbrPlanProgressList {
    const formattedMbrPlanProgressList: FormattedMbrPlanProgressList = [];
    if (Object.keys(result)?.length > 0) {
      Object.keys(result).forEach((key) => {
        const benefitData = result[key];
        const label = MemberPlanProgressLabels[key];
        formattedMbrPlanProgressList.push({
          type: BenefitType.BENEFIT,
          label,
          spent: benefitData.spent,
          valueType: benefitData.valueType,
          ...(key.startsWith(BenefitTypeKey.SPENDING) ? { available: benefitData.remaining } : { limit: benefitData.limit, remaining: benefitData.remaining })
        } as FormattedMbrPlanProgress);
      });
    }

    if (!Object.keys(result).length) {
      return [];
    }

    if (!Object.keys(result).some((key) => key.startsWith(BenefitTypeKey.FAMILY) || key.startsWith(BenefitTypeKey.IND))) {
      formattedMbrPlanProgressList.push({ type: BenefitType.ERROR, message: content.result.memberPlanProgress.planProgressInfo });
    }
    return formattedMbrPlanProgressList;
  }
}
