import { AfterViewInit, Component, EventEmitter, Inject } from '@angular/core';
import { AppConstants } from '../../constants/app-constants';
import { AppSession } from '../../values/appSession';
import { AppEvents } from './../../../common/enums/appEvents';
import { EventHandler } from './../../services/eventHandler';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.html'
})

export class NavigationComponent implements AfterViewInit {

  brandLogo: string;
  brandCd: string;
  brandLogoClass: string;
  brandUrl: string;
  private _subscription: any;
  private _headerEvent: EventEmitter<string> = this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]);
  constructor(
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession) { }

  ngAfterViewInit() {
    this._subscription = this._headerEvent.subscribe((brand: string) => {
      setTimeout(() => {
        this.updateBrandLogo(brand);
      }, 125);
    });
  }

  updateBrandLogo(brand: string) {
    if (brand && brand.trim() === '') {
      return;
    }

    let _brandCd = brand.toUpperCase().trim();
    let cobrandCd = this._appSession?.planRule?.cobrandRule?.object?.cobrand;
    if (cobrandCd && cobrandCd.trim() != '') {
      _brandCd = cobrandCd.trim().toUpperCase();
    }

    const index = AppConstants.BRAND.findIndex((x) => x.brand === _brandCd);
    if (index >= 0) {
        this.brandLogo = AppConstants.BRAND[index].logo;
        this.brandCd = AppConstants.BRAND[index].brand;
        this.brandLogoClass = AppConstants.BRAND[index].logoClass;
    }
    this.brandUrl = brand;
  }
}
