import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppSession } from '../../../common/values/appSession';
import { PageAlertHandler } from '../../common/components/shareable/page-alert/service/pageAlertHandler';
import { PageAlertSvc } from '../../common/components/shareable/page-alert/service/pageAlertSvc';
import { FindCarePageType } from '../../common/enums/findCarePages';
import { ProviderDetailHandler } from '../classes/providerDetailHandler';
import { IProviderOverview } from '../interfaces/iProviderDetail';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetail {
  private providerDetailSubject = new BehaviorSubject<IProviderOverview | undefined>(undefined);

  details = this.providerDetailSubject.asObservable();
  private detailHistory: IProviderOverview[] = [];

  constructor(
    private detailHandler: ProviderDetailHandler,
    private _appSession: AppSession,
    private _pageAlertSvc: PageAlertSvc
  ) {}

  async initialize(providerIdentifier: string, addressIdentifier: string): Promise<void> {
    const currentProviderDetail = this.providerDetailSubject.getValue()?.providerDetail;
    if (currentProviderDetail?.providerIdentifier !== providerIdentifier || currentProviderDetail?.address.addressId !== addressIdentifier) {
      // call page alert with detail page for provider ddtail.
      this._pageAlertSvc.getPageAlerts(PageAlertHandler.buildPageAlertRequest(FindCarePageType.DetailOverview, this._appSession));
      const providerDetail = await this.detailHandler.getProviderDetail(providerIdentifier, addressIdentifier);
      this.setDetail(providerDetail);
    }
  }

  reset(): void {
    this.providerDetailSubject.next(undefined);
  }

  private setDetail(providerDetail: IProviderOverview): void {
    if (providerDetail) {
      this.providerDetailSubject.next(providerDetail);
      this.detailHistory.push(providerDetail);
    }
  }

  getParentDetail() {
    if (this.detailHistory.length > 1) {
      const previousDetail = this.detailHistory[this.detailHistory.length - 2];
      this.providerDetailSubject.next(previousDetail);
    }
  }
}
