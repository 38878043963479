import { Component, Input } from '@angular/core';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-virtual-connect-now-cmp',
  templateUrl: './virtualConnectNowCmp.html'
})
export class VirtualConnectNowComponent {
  @Input() provider: IProvider;

  navigateToUrl(data: string) {
    window.open(data);
  }
}
