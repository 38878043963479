import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from "../../../../../common/services/eventHandler";
import { AppSession } from "../../../../../common/values/appSession";
import { FindCarePageType } from '../../../enums/findCarePages';
import { IAlertMessage } from '../../../interfaces/iPageAlert';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-all-alerts-cmp',
  templateUrl: './pageAlertContainerCmp.html'
})
export class PageAlertsContainerComponent extends BaseComponent implements OnInit {

  pageAlerts: IAlertMessage[] = null;
  @Output()
  closeModal: EventEmitter<any> = new EventEmitter<any>();
  pageTitle: string = null;

  @Input() pageName: FindCarePageType;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.pageTitle = this.content?.common?.pageAlertComponentLabels?.notice;
  }

  onClose() {
    this.closeModal.emit();
  }
}
