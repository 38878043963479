export class AppNavigations {
  static readonly SEARCH_PROVIDERS_OUTLET = '/(pfoutlet:search-providers)';
  static readonly SEARCH_RESULTS_OUTLET = '/(pfoutlet:search-results)';
  static readonly SEARCH_RESULTS_NEXT_OUTLET = '/(pfoutlet:search-results-next)';
  static readonly SEARCH_RESULTS_PRINT_OUTLET = '/(pfoutlet:search-results)?print=true';
  static readonly PROVIDER_DETAILS_OUTLET = '/(pfoutlet:provider-details)';
  static readonly COST_SEARCH_OUTLET = '/(pfoutlet:cost-search)';
  static readonly EMULATE_SECURE_STATE_OUTLET = '/(pfoutlet:emulate-secure-state)';
  static readonly EMULATE_COST_OUTLET = '/(pfoutlet:emulate-cost)';
  static readonly DEFAULT_ROUTE_OUTLET = '/(pfoutlet:default-route)';
  static readonly ERROR_OUTLET = '/(pfoutlet:error)';
  static readonly MAINTENANCE_PAGE_ROUTE_OUTLET = '/(pfoutlet:app-maintenance)';
  static readonly CARE_TEAM_OUTLET = '/(pfoutlet:care-providers)';
  static readonly PROMOTED_PROCEDURES_OUTLET = '/(pfoutlet:promoted-procedures)';
  static readonly SERVICE_AREA_VALIDATION_PATH = '/service-area-validation';
  static readonly SERVICE_AREA_VALIDATION_OUTLET = '/(pfoutlet:service-area-validation)';
  static readonly VIRTUAL_CARE_PATH_OUTLET = '/(pfoutlet:virtual-care)';
  static readonly SEARCH_PROVIDERS_PATH = '/search-providers';
  static readonly SEARCH_RESULTS_PATH = '/search-results';
  static readonly SEARCH_RESULTS_NEXT = '/search-results-next';
  static readonly SEARCH_RESULTS_PRINT_PATH = '/search-results?print=true';
  static readonly PROVIDER_DETAILS_PATH = '/provider-details';
  static readonly COST_SEARCH_PATH = '/cost-search';
  static readonly EMULATE_SECURE_STATE_PATH = '/emulate-secure-state';
  static readonly EMULATE_COST_PATH = '/emulate-cost';
  static readonly DEFAULT_ROUTE_PATH = '/default-route';
  static readonly ERROR_PATH = '/error';
  static readonly MAINTENANCE_PAGE_PATH = '/app-maintenance';
  static readonly CARE_TEAM_PATH = '/care-providers';
  static readonly PROMOTED_PROCEDURES_PATH = '/promoted-procedures';
  static readonly VIRTUAL_CARE_PATH = '/virtual-care';
  static readonly ASSIGN_PCP_PATH = '/assign-pcp';
  static readonly ASSIGN_PCP_OUTLET = '/(pfoutlet:assign-pcp)';
  static readonly CARE_CIRCLE_OUTLET = '/(pfoutlet:care-circle)';
  static readonly CARE_CIRCLE_PATH = '/care-circle';
  static readonly FCR_HOME_PATH = '/fc-home';
  static readonly FCR_LANDING_PATH = '/fc-landing';
  static readonly FCR_RESULT_PATH = '/fc-result';
  static readonly FCR_HOME_OUTLET = '/(pfoutlet:fc-home)';
  static readonly FCR_LANDING_OUTLET = '/(pfoutlet:fc-landing)';
  static readonly FCR_RESULT_OUTLET = '/(pfoutlet:fc-result)';
  static readonly CARE_NOW = '/care-now';
  static readonly CARE_NOW_PATH_OUTLET = '/(pfoutlet:care-now)';
  static readonly DENIAL_OF_SERVICE_PATH = '/fc-dos';
  static readonly DENIAL_OF_SERVICE_PATH_OUTLET = '/(pfoutlet:fc-dos)';
}

export enum PfNavigationEvent {
  BACK = 'pf-back:navigation',
  BROWSER_BACK = 'pf-browser-back:navigation',
  NAVIGATE = 'pf-browser:navigation',
  PF_ROUTER_OUTLET_BACK = 'pf-router-outlet-back:navigation'
}
