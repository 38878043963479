import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { isEmpty } from 'lodash';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';
import { OONDetailsComponent } from '../../../../../../../common/components/common-alert/oonDetailsCmp';
import { GeoLocationService } from '../../../../../../../common/components/geoLocation/services/geoLocationService';
import { LocationComponent } from '../../../../../../../common/components/location/locationCmp';
import { AppExternalCommands, Locale, MODAL_TYPE, PLANTYPE_OON } from '../../../../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../../../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../../../../../common/enums/appEvents';
import { IAdobe, IAdobeSearchDetail } from '../../../../../../../common/interfaces/iAdobe';
import { IOptions } from '../../../../../../../common/interfaces/iAppMetadata';
import { IEventDetail } from '../../../../../../../common/interfaces/iEventDetail';
import { IGenericModalTemplate } from '../../../../../../../common/interfaces/iMessage';
import { IWindow } from '../../../../../../../common/interfaces/iWindow';
import { HighlightPipe } from '../../../../../../../common/pipes/highlightPipe';
import { DataHelper } from '../../../../../../../common/services/dataHelper';
import { DataService } from '../../../../../../../common/services/dataService';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { SearchSvc } from '../../../../../../../common/services/searchSvc';
import { AbstractSearchStrategy } from '../../../../../../../common/services/strategySvc';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../../../common/values/contentHelper';
import { ISelectedProvider } from '../../../../../../../fad/provider-details/models/iSelectedProvider';
import { ProviderDetailsNavigationService } from '../../../../../../../fad/provider-details/services/providerDetailsNavigationSvc';
import { IRulesResponse } from '../../../../../../../fad/rules/interfaces/iRulesResponse';
import { RulesService } from '../../../../../../../fad/rules/services/RulesSvc';
import { IZipCode } from '../../../../../../../fad/search-providers/interfaces/iAddressForm';
import { IAreaOfExpertise } from '../../../../../../../fad/search-providers/interfaces/iAreaOfExpertise';
import { ICptCode } from '../../../../../../../fad/search-providers/interfaces/iCptLookup';
import { IOfficeService } from '../../../../../../../fad/search-providers/interfaces/iOfficeService';
import { IProcedureLookup } from '../../../../../../../fad/search-providers/interfaces/iProcedureLookup';
import { IProviderMetadataRequest } from '../../../../../../../fad/search-providers/interfaces/iProviderMetadataRequest';
import { IProviderMetadataResponse } from '../../../../../../../fad/search-providers/interfaces/iProviderMetadataResponse';
import { ISearchParameters } from '../../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { ISmartSearchLookupRequest } from '../../../../../../../fad/search-providers/interfaces/iSmartSearchLookupRequest';
import { ISpecialtyLookup } from '../../../../../../../fad/search-providers/interfaces/iSpecialtyLookup';
import { IntegratedSearchService } from '../../../../../../../fad/search-providers/services/integratedSearchSvc';
import { ProviderUtilityService } from '../../../../../../../fad/search-providers/services/providerUtilitySvc';
import { SAVNavigationService } from '../../../../../../../fad/search-providers/services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, PROVIDER_TYPE_OF_CARE, THREE, TRANSPORTATION_PREFIX } from '../../../../../../../fad/search-providers/values/providerSearchConstants';
import { EycProcedureCriteria, EycSearchParam } from '../../../../../../../fad/search-results/interfaces/iSearchRequest';
import { FEATURE_SEARCH_TYPE, OON_ADOBE_TAGS } from '../../../../../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../../../../../fad/utilities/commonUtil';
import { WcsUtil } from '../../../../../../../fad/utilities/wcsUtil';
import { LOOKUP_TYPE, LookupType, MAX_MOB_RESPONSIVE_WIDTH_L, VIEW_COUNT_LIMIT } from '../../../../../constants/common';
import { ActionService } from '../../../../../services/actionSvc';
import { CommonUtility } from '../../../../../utilities/commonUtil';
import { BaseComponent } from '../../../../core/baseCmp';
import { GenericModalService } from '../../../modal/services/genericModalSvc';
import { SearchBoxHandler } from '../../handlers/searchBoxHandler';
import { LocationWrapperComponent } from '../location-wrapper/locationWrapperCmp';
import { ILookUpItem, ISearchBoxResponse } from './../../../../../interfaces/iSearchBox';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fc-integrated-search-cmp',
  templateUrl: './integratedSearchCmp.html',
  providers: []
})
export class IntegratedSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() searchParams: ISearchParameters;
  @Input() showChangeLocation: boolean;
  @ViewChild('openAddressSelection') changePlanSidePanel: TemplateRef<HTMLElement>;
  @Output() search: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openSwitchLocation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadCahmoPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadSAV: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('transportationModal') transportationModal: ModalComponent;
  @ViewChild('homeHealthCareModal') homeHealthCareModal: ModalComponent;
  @ViewChild('medicareSupplementModal') medicareSupplementModal: ModalComponent;
  @ViewChild('searchTermIdentifier') searchTermInput: ElementRef;
  @ViewChild('genericModalCmp') genericModal: ModalComponent;
  @ViewChild('location') locationCmp: LocationComponent;
  @ViewChild('locationV1') locationCmpV1: LocationWrapperComponent;
  @ViewChild('oonLearnMoreSearch') oonLearnMoreSlider: OONDetailsComponent;

  zipcodeProperties: IZipCode = {
    disableZipcode: false,
    showMarker: true,
    disabledClass: false,
    showError: true
  };

  lookupResultTypeListOrder = environment.lookupResultSortOrder;
  locale: Locale;
  searchWatermark = '';
  planUnknownError = false;
  modalType: any = MODAL_TYPE;
  searchCriteria: ISearchParameters;
  showAoeLoader = true;
  showOfcSrvcLoader = true;
  showSmartSearchLoader = true;
  showProviderLoader = true;
  showNpiProviderLoader = true;
  showLicenseProviderLoader = true;
  showFlyOut = false;
  specialtyMore = false;
  aoeMore = false;
  ofcSrvcMore = false;
  procedureMore = false;
  providerMore = false;
  providerNpiMore = false;
  providerLicenseMore = false;
  myNexusHeader: string;
  myNexusContent: string;
  myNexusClose: string;
  searchTerm = '';
  searchStrategy: AbstractSearchStrategy | undefined;
  procedureList: any[] = [];
  cptList: any[] = [];
  specialtyList: any[] = [];
  aoeList: any[] = [];
  ofcSrvcList: any[] = [];
  providerList: any[] = [];
  providerNpiList: any[] = [];
  providerLicenseList: any[] = [];
  procedureTop5List: any[] = [];
  specialtyTop5List: any[] = [];
  aoeTop5List: any[] = [];
  ofcSrvcTop5List: any[] = [];
  providerTop5List: any[] = [];
  providerNpiTop5List: any[] = [];
  providerLicenseTop5List: any[] = [];
  hasEYC = false;
  selected = '';
  inputMinLength = environment.features.lookUp.inputLength;
  inputMaxLength = environment.features.lookUp.inputMaxLength;
  isRedBorderShow: boolean;
  hasCostTransparency: boolean;
  showMedicalCode = false;
  showMedicalCodeMoreLess = false;
  visibleMedicalCodeCount: number = 3;
  executionCount: number = 0;
  enableOONlink = false;
  genericModalTemplate: IGenericModalTemplate = { message: '' };
  smartSearchRequestId = '';
  maxCategoryLimit: number = VIEW_COUNT_LIMIT;
  responsiveWidthMob: number = MAX_MOB_RESPONSIVE_WIDTH_L;

  findOtherOption = {
    label: this.content?.common?.pageHeader?.carePlanWrapper?.customZip?.label,
    value: this.content?.common?.pageHeader?.carePlanWrapper?.customZip?.label,
    id: 'rdb-mbr-id-zipcode'
  };

  sidePanelRef: ModalRef<any, any>;
  medicalCodeCriteria: {
    billingType: string;
    medicalCode: string;
    medicalModifierOptionCode?: string;
    medicalPosCode?: string;
  } = {
    billingType: '',
    medicalCode: ''
  };

  adobeTags = OON_ADOBE_TAGS;
  private _ruleChangeSubscription: Subscription;
  private _transportationLocation: any;
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  private reloadSAVPageObservable: Subscription;
  private loadResultObservable: Subscription;
  private reloadSearchObservable: Subscription;

  constructor(
    private searchService: SearchSvc,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    @Inject(ContentHelper) private _contentHelper: ContentHelper,
    @Inject(DataHelper) private _dataHelper: DataHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _highlightPipe: HighlightPipe,
    private _ruleService: RulesService,
    private _dataService: DataService,
    private _elementRef: ElementRef,
    @Inject('Window') private _window: IWindow,
    private _integratedSearchService: IntegratedSearchService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _sidePanel: SidePanel,
    private _savNavigationService: SAVNavigationService,
    private _geoLocationService: GeoLocationService,
    private _changeDetector: ChangeDetectorRef,
    private _searchBoxHandler: SearchBoxHandler,
    private _actionService: ActionService,
    private _genericModalService: GenericModalService
  ) {
    super(_route, _eventHandler, _appSession);

    this._ruleChangeSubscription = this._integratedSearchService.onRuleChange.subscribe((rules: IRulesResponse) => {
      const isActionBlockedByModal = this._genericModalService.isActionBlockedByModal(rules);
      if (!isActionBlockedByModal) {
        this.invokeActions(rules);
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale : Locale.ENGLISH;

    if (this._appSession) {
      this._appSession.integratedSearch = true;
      this.searchTerm = this._appSession.searchTerm ? this._appSession.searchTerm : '';
      if (this._appSession.searchParams && this._appSession.searchParams.zip) {
        this.searchCriteria.zip = this._appSession.searchParams.zip;
      }
    }
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_SEARCH_BAR_LOAD];
    eventDetail.message = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.MSG;
    eventDetail.target = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.CMD;
    //set the deeplink error modal
    this.setDeeplinkErrorModal();
    this._pfAppCommandEvent.emit(eventDetail);
    this.enableOONlink = this._appSession.planRule?.isOONProviderEnabled;
    this.reloadSAVPageObservable = this._geoLocationService.getReloadSAVPageObservable().subscribe((data) => {
      this.reloadSAVPage();
    });
    this.loadResultObservable = this._geoLocationService.getLoadResultObservable().subscribe((data) => {
      this.loadProviderSummary();
    });
    this.reloadSearchObservable = this._geoLocationService.getReloadSearchObservable().subscribe((data) => {
      this.reloadProviderSummary();
    });
  }

  ngAfterViewInit() {
    this._changeDetector.detectChanges();
    fromEvent(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        // Time in milliseconds between key events
        debounceTime(300),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // get value
        map((event) => {
          return event;
        })
      )
      .subscribe((event: KeyboardEvent) => {
        this.onKeyUpEvent(event);
      });

    if (this._appSession.isDeeplinkErrorMsg && !this._dataHelper.isEmptyString(this.genericModalTemplate.message)) {
      this.openModal(MODAL_TYPE.GENERIC_MODAL);
      this._appSession.isDeeplinkErrorMsg = false;
    }
  }

  onKeyUpEvent(event: KeyboardEvent) {
    this.showAoeLoader = true;
    this.showOfcSrvcLoader = true;
    this.showSmartSearchLoader = true;
    this.showProviderLoader = true;
    this.showNpiProviderLoader = true;
    this.showLicenseProviderLoader = true;
    this.isRedBorderShow = false;
    const _searchTerm = this.searchTerm.trim();

    if ((event.key !== 'Enter' || this.isAllListEmpty()) && _searchTerm.length > 0 && _searchTerm.length < this.inputMaxLength && this.isLocationValid()) {
      if (this.searchTerm && _searchTerm !== this.searchTerm.trim()) {
        return;
      } else {
        this.initateSearchTermActions(_searchTerm);
      }
    } else if (event.key === 'Enter') {
      for (let lookupResultType of this.lookupResultTypeListOrder) {
        if (lookupResultType === 'specialtyList' && this.specialtyList && this.specialtyList.length > 0) {
          this.onSpecialtySelect(this.specialtyList[0]);
          break;
        } else if (lookupResultType === 'procedureList' && this.procedureList && this.procedureList.length > 0) {
          this.onProcedureSelect(this.procedureList[0]);
          break;
        } else if (lookupResultType === 'providerList' && this.providerList && this.providerList.length > 0) {
          this.showProvidersMore(FEATURE_SEARCH_TYPE.NAME);
          break;
        } else if (lookupResultType === 'providerLicenseList' && this.providerLicenseList && this.providerLicenseList.length > 0) {
          this.onProviderSelect(this.providerLicenseList[0]);
          break;
        } else if (lookupResultType === 'providerNpiList' && this.providerNpiList && this.providerNpiList.length > 0) {
          this.onProviderSelect(this.providerNpiList[0]);
          break;
        } else if (lookupResultType === 'aoeList' && this.aoeList && this.aoeList.length > 0) {
          this.onAOESelect(this.aoeList[0]);
          break;
        } else if (lookupResultType === 'ofcSrvcList' && this.ofcSrvcList && this.ofcSrvcList.length > 0) {
          this.onOfcSrvcSelect(this.ofcSrvcList[0]);
          break;
        } else if (lookupResultType === 'cptList' && this.cptList && this.cptList.length > 0) {
          this.onMedicalCodeSelect(this.cptList[0]);
          break;
        } else {
          if (!this._appSession.isEyc) {
            const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
            if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
              this.resetSearchCriteria();
              this.resetAppSessionParams();
              this.reloadProviderSummary();
            }
          }
        }
      }
    }
    if (!this.locationCmpV1?.locationTxt) {
      this.isRedBorderShow = true;
    }
    this.locationCmpV1?.showRedColorBorder(this.isRedBorderShow);
  }

  /** Method to handle data-bound property changes */
  ngOnChanges(args: any) {
    if (this.showChangeLocation) {
      this.zipcodeProperties.disableZipcode = false;
      this.zipcodeProperties.disabledClass = false;
      this.zipcodeProperties.showMarker = true;
      this.zipcodeProperties.showError = true;
    }

    this.hasEYC = this.isMemberSecure && (this._appSession.hasEYC || this._appSession.hasDentalEYC);
    this.hasCostTransparency = this._appSession.metaData.appContract?.hasCostTransparency && this.isMedicalCodeSearchEnabled;
    this.selected = this.selectedOption();

    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.setSearchParams(args['searchParams'].currentValue);
    }
  }

  ngOnDestroy() {
    if (typeof this._ruleChangeSubscription !== 'undefined' && this._ruleChangeSubscription !== null) {
      this._ruleChangeSubscription.unsubscribe();
    }
    if (this.reloadSAVPageObservable) {
      this.reloadSAVPageObservable.unsubscribe();
    }
    if (this.loadResultObservable) {
      this.loadResultObservable.unsubscribe();
    }
    if (this.reloadSearchObservable) {
      this.reloadSearchObservable.unsubscribe();
    }

    if (this.isFtsFeatureActive) {
      this.clearAll();
      this.resetSearchCriteria();
      this.resetAppSessionParams();
    }
  }

  /** Set search criteria */
  setSearchParams(params: ISearchParameters) {
    this.searchCriteria = params;
  }

  initateSearchTermActions(_searchTerm: string) {
    this._transportationLocation = null;
    this.clearAll();

    if (_searchTerm.length < this.inputMinLength) {
      return;
    }

    const _isNumericSearch: boolean = this.isNumber(_searchTerm);
    const searchTypes: Array<LookupType> = [];

    // Check if the search term is not numeric and the location is valid
    if (!_isNumericSearch && this.isLocationValid()) {
      // Add SMART_SEARCH to the search types
      searchTypes.push(LookupType.SMART_SEARCH);
    }

    // Check if the member is in a secure state and has cost transparency
    if (this.isMemberSecure && this.hasCostTransparency) {
      // Add MEDICAL_CODE to the search types
      searchTypes.push(LookupType.MEDICAL_CODE);
    }

    // Check if the search term is not numeric
    if (!_isNumericSearch) {
      // Add NAME, AOE, and OFFICE_SERVICES to the search types
      searchTypes.push(LookupType.NAME);
      searchTypes.push(LookupType.AOE);
      searchTypes.push(LookupType.OFFICE_SERVICES);
    }

    // Check if the search term is numeric
    if (_isNumericSearch) {
      // Add NPI to the search types
      searchTypes.push(LookupType.NPI);
    }

    // Check if the search term is a valid license number (contains at least 2 numeric characters)
    if (this.validateLicenseNumber(_searchTerm)) {
      // Add LICENSE_NUMBER to the search types
      searchTypes.push(LookupType.LICENSE_NUMBER);
    }

    // Perform the lookup search with the determined search types
    this.performLookupSearch(searchTypes);
  }

  /** Method to reset the search */
  resetSearchCriteria() {
    const _providerTypeCode = this.searchCriteria.typeSelectNm;
    const _providerTypeCodeList = this.searchCriteria.providerTypeCodeList;
    const _eycSearchParam = this.searchCriteria.eycSearchParam;
    this.clearSearchCriteria();
    this.searchCriteria.typeSelectNm = _providerTypeCode;
    this.searchCriteria.providerTypeCodeList = _providerTypeCodeList;
    //Procedure search
    this.searchCriteria.eycSearchParam = _eycSearchParam;
    //Pharmacy search
    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }
  }

  /** Method to validate is all list empty */
  isAllListEmpty() {
    return (
      this.procedureList.length === 0 &&
      this.specialtyList.length === 0 &&
      this.aoeList.length === 0 &&
      this.ofcSrvcList.length === 0 &&
      this.providerList.length === 0 &&
      this.providerLicenseList.length === 0 &&
      this.providerNpiList.length === 0 &&
      this.cptList.length === 0
    );
  }

  /**
   * Checks if any of the lists have a length greater than zero.
   * @returns {boolean} True if any list has a length greater than zero, otherwise false.
   */
  isAnyListNotEmpty(): boolean {
    return (
      this.procedureList.length > 0 ||
      this.specialtyList.length > 0 ||
      this.aoeList.length > 0 ||
      this.ofcSrvcList.length > 0 ||
      this.providerList.length > 0 ||
      this.providerLicenseList.length > 0 ||
      this.providerNpiList.length > 0 ||
      this.cptList.length > 0
    );
  }

  /**
   * Retrieves feature lookup providers based on the current search criteria and search term.
   */
  getLookup(searchTypes: Array<LookupType>) {
    let searchCriteria: ISearchParameters = { ...this.searchCriteria };
    searchCriteria = this.bindProviderType(searchCriteria, this._appSession);

    if (searchCriteria && searchTypes.length > 0) {
      const session: AppSession = Object.assign({}, this._appSession);
      session.searchParams = searchCriteria;
      session.searchParams.searchTypes = searchTypes;

      const searchTerm = this.searchTerm.trim();

      this._searchBoxHandler.search(session, searchTerm).then(
        (result: ISearchBoxResponse) => {
          this.showNpiProviderLoader = false;
          this.showLicenseProviderLoader = false;
          this.showProviderLoader = false;
          this.showSmartSearchLoader = false;
          this.showAoeLoader = false;
          this.showOfcSrvcLoader = false;

          this.providerNpiList = result?.npiProviderList || [];
          this.providerNpiTop5List = this.providerNpiList.slice(0, environment.features.lookUp.minCount);

          this.providerLicenseList = result?.lnProviderList || [];
          this.providerLicenseTop5List = this.providerLicenseList.slice(0, environment.features.lookUp.minCount);

          this.providerList = result?.nmProviderList || [];
          this.providerTop5List = this.providerList.slice(0, environment.features.lookUp.minCount);

          this.cptList = result?.cptCodeList || [];

          this.procedureList = result?.procedureList || [];
          this.procedureTop5List = this.procedureList.slice(0, environment.features.lookUp.minCount);

          this.specialtyList = result?.specialtyList || [];
          this.specialtyTop5List = this.specialtyList.slice(0, environment.features.lookUp.minCount);

          this.aoeList = result?.areaOfExpertises || [];
          this.aoeTop5List = this.aoeList.slice(0, environment.features.lookUp.minCount);

          this.ofcSrvcList = result?.officeServices || [];
          this.ofcSrvcTop5List = this.ofcSrvcList.slice(0, environment.features.lookUp.minCount);

          //TODO: Need to handle transportation and smartSearchRequestId
          /*
          if (result?.transportationLocation) {
            this._transportationLocation = result.transportationLocation;
          }
          this.smartSearchRequestId = result?.smartSearchRequestId ?? undefined;
          */
        },
        (error: any) => {
          this.showNpiProviderLoader = false;
          this.showLicenseProviderLoader = false;
          this.showProviderLoader = false;
          this.showSmartSearchLoader = false;
          this.smartSearchRequestId = undefined;
          this.showAoeLoader = false;
          this.showOfcSrvcLoader = false;
          this.onCategoryError('PROVIDER_FEATURE_LOOKUP', error);
        }
      );
    }
  }

  /** Toggle finctionality to show more speciality */
  showSpecialtyMore() {
    this.specialtyMore = !this.specialtyMore;
    return false;
  }

  /** Toggle finctionality to show more AOE */
  showAoeMore() {
    this.aoeMore = !this.aoeMore;
    return false;
  }

  /** Toggle finctionality to show more Office Service */
  showOfcSrvcMore() {
    this.ofcSrvcMore = !this.ofcSrvcMore;
    return false;
  }

  /** Toggle finctionality to show more License */
  showLicenseMore() {
    this.providerLicenseMore = !this.providerLicenseMore;
    return false;
  }

  /** Toggle finctionality to show more procedure */
  showProcedureMore() {
    this.procedureMore = !this.procedureMore;
    return false;
  }

  /** Toggle finctionality to show more provider */
  showProvidersMore(searchType, oonOnly = false) {
    this.clearAll();
    this.clearSearchCriteria();
    const _searchTerm = this.searchTerm.trim();
    if (searchType === FEATURE_SEARCH_TYPE.NPI) {
      this.searchCriteria.provNm = '';
      this.searchCriteria.nationalProviderIdentifier = _searchTerm;
      this.searchCriteria.npi = _searchTerm;
    } else {
      this.searchCriteria.provNm = _searchTerm;
      this.searchCriteria.nationalProviderIdentifier = '';
      this.searchCriteria.npi = '';
    }
    // if the Link to show OON providers only is clicked
    if (oonOnly) {
      this.searchCriteria.planTypeList?.length ? this.searchCriteria.planTypeList.push(PLANTYPE_OON) : (this.searchCriteria.planTypeList = [PLANTYPE_OON]);
    }
    this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this.searchCriteria = this.bindProviderType(this.searchCriteria, this._appSession);

    //Adobe Analytics
    const _searchDetails = {} as IAdobeSearchDetail;
    _searchDetails.provName = _searchTerm;
    _searchDetails.zip = this.searchCriteria.zip;
    this.setAnalyticsQuery(_searchDetails);

    //Pharmacy search
    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }

    this.resetAppSessionParams();
    this.reloadProviderSummary();
  }

  /** Method to reset app session params */
  resetAppSessionParams(eyc: boolean = false) {
    this._appSession.pageNumber = undefined;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
    this._appSession.billingType = this.medicalCodeCriteria.billingType;
    this._appSession.medicalCode = this.medicalCodeCriteria.medicalCode;
    this._appSession.medicalModifierOptionCode = this.medicalCodeCriteria.medicalModifierOptionCode;
    this._appSession.medicalPosCode = this.medicalCodeCriteria.medicalPosCode;
  }

  //Method to handle summary page navigation and provider summary page update event.
  reloadProviderSummary() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (
      this._appSession &&
      _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH &&
      this._appSession.feature &&
      this._appSession.feature.showBCP &&
      this._appSession.feature.showPreFilterBCP &&
      this._appSession.searchParams
    ) {
      this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
    }

    if (this._contentHelper.isSearchResultRoute(_routeUrl) && this._appSession.isCaHmoDmhc && !this._appSession.serviceAreaValidated) {
      this._savNavigationService.navigate();
    } else if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.search.emit(true);
    } else {
      this._savNavigationService.navigate();
    }
    this._actionService.setSearchClick(true);
  }

  /** Method to change data on search change */
  onDataChanged(data: any) {
    const _searchTerm = data.trim();
    if (_searchTerm.length === 0) {
      this.clearAll();
      this.clearSearchCriteria();
    }
    if (this._appSession) {
      this._appSession.searchTerm = _searchTerm;
    }
    this._appSession.isSearchTerm = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (_searchTerm.length === 0 && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.resetSearchCriteria();
      this._appSession.searchTerm = this.searchTerm;
      this._appSession.searchParams = this.searchCriteria;
      this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this._appSession.isSearchTerm = false;
    }
  }

  highlightText(value: string) {
    return this._highlightPipe.transform(value, this.searchTerm, 'Multi-Word-Match');
  }

  /** Method to perform action on procedure select */
  onProcedureSelect(procedure: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isEyc = true;

    if (procedure) {
      this.searchTerm = procedure.procedureCode.name;
      const eycCriteria = new EycProcedureCriteria();
      const eycSearchParam = new EycSearchParam();

      this.searchCriteria.providerTypeCodeList = [];
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureProcedureScenario(procedure.providerCategoryCode.code) : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;
      eycCriteria.procedureCode = procedure.procedureCode && procedure.procedureCode.code ? procedure.procedureCode.code : '';
      eycCriteria.providerCategoryCode = procedure.providerCategoryCode.code;
      eycCriteria.procedureName = procedure.procedureCode.name;
      eycSearchParam.procedureDescription = procedure.procedureCode && procedure.procedureCode.description ? procedure.procedureCode.description : '';
      eycSearchParam.costDescription = procedure.costExplainationText;
      eycSearchParam.eycProcedureCriteria = eycCriteria;

      this.searchCriteria.eycSearchParam = eycSearchParam;
      this.searchCriteria.brandCode = this._appSession.metaData.brandCd;
      this.searchCriteria.smartSearchRequestId = this.smartSearchRequestId;

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
        const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
        if (_index > -1) {
          const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
          this.searchCriteria.selectedMem = _member.firstName + ' ' + _member.lastName;
        }
        this.searchCriteria.memberSeqNum = this._appSession.metaData.appContract.memberSeqNum;
      }

      if (this.isColdStateTccSearchEnabled) {
        if (this._appSession.searchParams.eycPrefix) {
          this.searchCriteria.eycPrefix = this._appSession.searchParams.eycPrefix;
        }
        if (this._appSession.searchParams.isPrefixStateEnabledForProcSearch) {
          this.searchCriteria.isPrefixStateEnabledForProcSearch = this._appSession.searchParams.isPrefixStateEnabledForProcSearch;
        }
      }

      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  /** Method to perform action on AOE select */
  onAOESelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isAoeSearch = true;
    let _providerTypes = [];
    this.searchTerm = obj.name;

    this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this.searchCriteria.aoeSelectNm.push(obj.code);
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  /** Method to perform action on Specialty select */
  onSpecialtySelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isSpecialitySearch = true;
    let _providerTypes = [];
    this.searchTerm = obj.name;
    if (!this.planUnknownError) {
      if (obj.providerTypes && obj.providerTypes.length > 0) {
        _providerTypes = obj.providerTypes;
        this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(_providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
        if (obj.providerTypes.length === 1) {
          this.searchCriteria.typeSelectNm = obj.providerTypes[0].toUpperCase().trim();
        }
      }

      // Specialty & Sub-specialty search params setting.
      if (obj.categories?.length > 0) {
        this.searchCriteria.specialtySelectNm = obj.categories;
        this.searchCriteria.taxonomySelectNm.push(obj.code);
      } else {
        this.searchCriteria.specialtySelectNm.push(obj.code);
      }

      this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
      this.searchCriteria.smartSearchRequestId = this.smartSearchRequestId;
      if (this._dataHelper.isEmptyString(this.searchCriteria.coverageTypeCode)) {
        if (_providerTypes && _providerTypes.length > 0) {
          this.searchCriteria.providerTypeCodeList = _providerTypes;
        }
      }

      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  /** Method to perform action on Provider select */
  onProviderSelect(item: ILookUpItem) {
    this.clearAll();
    this.clearSearchCriteria();

    let _providerTypes = [];
    // Medsupp scenario
    if (this._appSession.planRule && this._appSession?.planRule?.isMedicareGov) {
      _providerTypes = item.typeCodes.filter((x) => x.toUpperCase().trim() === PROVIDER_TYPE.VISION);
      this.searchCriteria.standAloneVision = true;
    } else {
      _providerTypes = item.typeCodes;
    }
    this.searchTerm = item.providerName.trim();
    const pageFadObj = {
      header: this.content.common?.pageHeader?.fadPageTitle,
      title: this.content.common?.pageTitle?.details
    };
    this._dataService.setPageTitle(pageFadObj);
    const selectedProvider = {
      providerIdentifier: item.id,
      providerTypeCodeList: _providerTypes,
      providerName: item.providerName,
      networkRegisteredName: item.networkRegisteredName,
      titleList: item.titleList,
      location: {
        address: item.address
      }
    } as unknown as ISelectedProvider;
    this.searchCriteria.provNm = this.searchTerm;
    if (_providerTypes && _providerTypes.length > 0) {
      if (_providerTypes.length === 1) {
        this.searchCriteria.typeSelectNm = _providerTypes[0].toUpperCase().trim();
      }
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(_providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    }

    this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
    if (this._dataHelper.isEmptyString(this.searchCriteria.coverageTypeCode)) {
      if (_providerTypes && _providerTypes.length > 0) {
        this.searchCriteria.providerTypeCodeList = _providerTypes;
      }
    }

    //Adobe Analytics
    const _searchDetails = {} as IAdobeSearchDetail;
    _searchDetails.provName = this.searchTerm;
    _searchDetails.zip = this.searchCriteria.zip;
    this.setAnalyticsQuery(_searchDetails);

    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      const _numberPattrn = /\#\d+(?:(\d+))?/gi;
      const _match = _numberPattrn.exec(this.searchTerm);
      const _hasMatch = _match && _match.length > 0 ? true : false;
      if (_hasMatch) {
        this.searchCriteria.provNm = this.searchTerm = this.searchTerm.replace(_numberPattrn, '').trim();
      }
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);

      this.resetAppSessionParams();
      if (this.isMemberSecure) {
        this.reloadProviderSummary();
      } else {
        this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
      }
    } else {
      this.resetAppSessionParams();
      this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
    }
  }

  /** Method to perform action on office service select */
  onOfcSrvcSelect(obj: any) {
    this.clearAll();
    this.clearSearchCriteria();
    this._appSession.isOfcSrvcSearch = true;
    let _providerTypes = [];
    this.searchTerm = obj.name;

    this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this.searchCriteria.ofcSrvcSelectNm.push(obj.code);
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }
  onSearchAllClick() {
    this.showFlyOut = !this.showFlyOut;
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  clearAll() {
    this.specialtyMore = false;
    this.specialtyList = [];
    this.specialtyTop5List = [];

    this.aoeMore = false;
    this.aoeList = [];
    this.aoeTop5List = [];

    this.ofcSrvcMore = false;
    this.ofcSrvcList = [];
    this.ofcSrvcTop5List = [];

    this.procedureMore = false;
    this.procedureList = [];
    this.procedureTop5List = [];

    this.providerMore = false;
    this.providerList = [];
    this.providerTop5List = [];

    this.providerNpiMore = false;
    this.providerNpiList = [];
    this.providerNpiTop5List = [];

    this.providerLicenseMore = false;
    this.providerLicenseList = [];
    this.providerLicenseTop5List = [];

    this.showMedicalCode = false;
    this.cptList = [];
    this.showMedicalCodeMoreLess = false;
    this.visibleMedicalCodeCount = THREE;
  }

  clearSearchCriteria() {
    const _searchCriteria: ISearchParameters = {} as ISearchParameters;
    _searchCriteria.plan = this.searchCriteria.plan;
    _searchCriteria.zip = this.searchCriteria.zip;
    _searchCriteria.stateCd = this.searchCriteria.stateCd;
    _searchCriteria.city = this.searchCriteria.city;
    if (this.searchCriteria.countyCode) {
      _searchCriteria.countyCode = this.searchCriteria.countyCode;
    }
    _searchCriteria.coordinates = this.searchCriteria.coordinates;
    _searchCriteria.typeOfCareNm = this.searchCriteria.typeOfCareNm;
    _searchCriteria.stateDdlValues = this.searchCriteria.stateDdlValues;
    _searchCriteria.planCategoryDdlValues = this.searchCriteria.planCategoryDdlValues;
    _searchCriteria.planDdlValues = this.searchCriteria.planDdlValues;
    _searchCriteria.coverageTypeCode = this.searchCriteria.coverageTypeCode;
    if (this.searchCriteria.identificationNumber) {
      _searchCriteria.identificationNumber = this.searchCriteria.identificationNumber;
    }
    _searchCriteria.typeSelectNm = '';
    _searchCriteria.specialtySelectNm = [];
    _searchCriteria.taxonomySelectNm = [];
    _searchCriteria.providerTypeCodeList = [];
    _searchCriteria.blueDistinctionCenter = [];
    _searchCriteria.categoryDescription = {};
    _searchCriteria.visionSrvcAvailableList = [];
    _searchCriteria.provNm = '';
    _searchCriteria.aoeSelectNm = [];
    _searchCriteria.ofcSrvcSelectNm = [];
    _searchCriteria.nationalProviderIdentifier = '';
    this.searchCriteria.npi = '';
    if (this._appSession.deeplinkParams && this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      _searchCriteria.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }
    _searchCriteria.includePrimeGroupSearch = true;
    _searchCriteria.eycPrefix = this._appSession?.searchParams?.eycPrefix;
    _searchCriteria.isPrefixStateEnabledForProcSearch = this._appSession?.searchParams?.isPrefixStateEnabledForProcSearch;
    this.searchCriteria = _searchCriteria;
    this._appSession.isSpecialitySearch = false;
    this._appSession.isAoeSearch = false;
    this._appSession.isOfcSrvcSearch = false;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.isEyc = false;
  }

  formatDistance(distance: string) {
    return distance ? parseFloat(distance).toFixed(2) : '';
  }

  onCancel() {
    this.showFlyOut = false;
  }

  reloadSAVPage() {
    this.reloadSAV.emit(true);
  }

  onApply() {
    this.selected = this.selectedOption();
    this.clearAll();
    this.searchTerm = '';
    this.showFlyOut = false;
  }

  selectedOption() {
    let _selectedItem = this.content?.common?.pageHeader?.searchCriteria?.labels?.searchAll;
    this.searchWatermark = this.hasEYC
      ? this.content.common.pageHeader?.searchCriteria?.labels?.searchSpecialtyProcedureName
      : this.content?.common?.pageHeader?.searchCriteria?.labels?.searchSpecialtyName;

    //Handle search text box watermark for mobile screen.
    if (document.documentElement && document.documentElement.clientWidth && document.documentElement.clientWidth < 900) {
      this.searchWatermark = this.content.common.pageHeader?.searchCriteria?.labels?.search;
    }

    return _selectedItem;
  }

  searchCtrlFocusOut() {
    this.showAoeLoader = true;
    this.showOfcSrvcLoader = true;
    this.showSmartSearchLoader = true;
    this.showProviderLoader = true;
    this.clearAll();
    this.getScroll();
  }

  getScroll() {
    if (this.isMemberSecure && !this._dataHelper.isEmptyString(this.locationCmpV1?.locationTxt) && this.executionCount === 0) {
      document.getElementById('txt-search-field').focus();
      this.executionCount++;
    }
  }

  searchCtrlFocus() {
    this.isRedBorderShow = false;
    if (!this.locationCmpV1?.locationTxt && this.executionCount !== 0) {
      this.isRedBorderShow = true;
    }
    this.executionCount++;
    this.locationCmpV1?.showRedColorBorder(this.isRedBorderShow);
    this.showFlyOut = false;
  }

  searchTypeCtrlFocus() {
    this.searchCtrlFocusOut();
  }

  locationCtrlFocus() {
    this.searchCtrlFocusOut();
    this.searchCtrlFocus();
  }

  /** Method to check if location is valid */
  isLocationValid() {
    this.isRedBorderShow = false;
    let _isValid = true;
    if (!(this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude !== '' && this.searchCriteria.coordinates.longitude !== '')) {
      _isValid = false;
      if (!this.locationCmpV1?.locationTxt) {
        this.isRedBorderShow = true;
        this.locationCmpV1.showRedColorBorder(this.isRedBorderShow);
      } else if (this.locationCmpV1?.locationTxt) {
        this.locationCmpV1.setLocationError();
      }
    }
    return _isValid;
  }

  /** Method to open modal */
  openModal(modal: string) {
    switch (modal) {
      case MODAL_TYPE.TRANSPORTATION: {
        this.transportationModal.show();
        break;
      }
      case MODAL_TYPE.HOMEHEALTHCARE: {
        this.homeHealthCareModal.show();
        break;
      }
      case MODAL_TYPE.MEDICARE_SUPPLEMENT: {
        this.medicareSupplementModal.show();
        break;
      }
      case MODAL_TYPE.GENERIC_MODAL: {
        this.genericModal.show();
        break;
      }
    }
  }

  closeModal(modal: string) {
    switch (modal) {
      case MODAL_TYPE.TRANSPORTATION: {
        this.transportationModal.hide();
        break;
      }
      case MODAL_TYPE.HOMEHEALTHCARE: {
        this.homeHealthCareModal.hide();
        break;
      }
      case MODAL_TYPE.MEDICARE_SUPPLEMENT: {
        this.medicareSupplementModal.hide();
        break;
      }
      case MODAL_TYPE.GENERIC_MODAL: {
        this.genericModal.hide();
        break;
      }
    }
  }

  redirectModal(modal: string) {
    let _url = null;

    switch (modal) {
      case MODAL_TYPE.HOMEHEALTHCARE: {
        this.closeModal(MODAL_TYPE.HOMEHEALTHCARE);
        _url = this.content.common.pageHeader.searchCriteria.homeHealthCareContent.url;
        break;
      }
      case MODAL_TYPE.MEDICARE_SUPPLEMENT: {
        this.closeModal(MODAL_TYPE.MEDICARE_SUPPLEMENT);
        _url = this.content.common.medicareSupplementContent.url;
        break;
      }
    }

    if (_url) {
      const winArgs = [_url];
      this._window.open.apply(this._window, winArgs);
    }
  }

  /**
   * Binds the provider type to the search parameters based on the application session state.
   * @param searchParams The current search parameters.
   * @param appSession The application session containing state information.
   * @returns {ISearchParameters} The updated search parameters with provider type information.
   */
  bindProviderType(searchParams: ISearchParameters, appSession: AppSession): ISearchParameters {
    const updatedSearchParams: ISearchParameters = { ...searchParams };

    const { opsIndicator } = appSession.appState;

    updatedSearchParams.standAloneDental = opsIndicator.isDentalStandAlone;
    updatedSearchParams.standAloneVision = opsIndicator.isVisionStandAlone;
    updatedSearchParams.coverageTypeCode = '';
    updatedSearchParams.typeSelectNm = '';
    updatedSearchParams.providerTypeCodeList = CommonUtility.getProviderTypes(appSession);

    return updatedSearchParams;
  }

  /** Method to load provider summary */
  loadProviderSummary() {
    if (this.searchTerm && this.searchCriteria) {
      this._appSession.providerTypeCodes = this.searchCriteria.providerTypeCodeList;
      if (this.searchCriteria.specialtySelectNm && this.searchCriteria.specialtySelectNm.length) {
        //Adobe Analytics
        const _searchDetails = {} as IAdobeSearchDetail;
        _searchDetails.specialityName = this.searchTerm;
        _searchDetails.zip = this.searchCriteria.zip;
        this.setAnalyticsQuery(_searchDetails);
        this._actionService.setSearchClick(true);
        this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
          (rules: IRulesResponse) => {
            const isActionBlockedByModal = this._genericModalService.isActionBlockedByModal(rules);
            if (!isActionBlockedByModal) {
              this._actionService.setSearchClick(true);
              this.invokeActions(rules);
            }
          },
          (error: any) => {
            try {
              this.onCategoryError('RULE', error);
            } catch (e) {}
          }
        );
      } else if (this.searchCriteria?.aoeSelectNm?.length || this.searchCriteria?.ofcSrvcSelectNm?.length) {
        this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
          (rules: IRulesResponse) => {
            const isActionBlockedByModal = this._genericModalService.isActionBlockedByModal(rules);
            if (!isActionBlockedByModal) {
              this.invokeActions(rules);
            }
          },
          (error: any) => {
            try {
              this.onCategoryError('RULE', error);
            } catch (e) {}
          }
        );
      } else if (this.searchCriteria.eycSearchParam) {
        //Adobe Analytics
        const _searchDetails = {} as IAdobeSearchDetail;
        _searchDetails.procedureName = this.searchTerm;
        _searchDetails.zip = this.searchCriteria.zip;
        this.setAnalyticsQuery(_searchDetails);

        this.resetAppSessionParams(true);
        this.reloadProviderSummary();
      } else if (CommonUtil.isCptSearch(this._appSession)) {
        this.resetAppSessionParams(false);
        this.reloadProviderSummary();
      }
    }
  }

  printTransportation() {
    window.print();
    return false;
  }

  //Sets the Analytics query for the search criteria - Only on RCP integrated page
  setAnalyticsQuery(data: IAdobeSearchDetail) {
    if (data && window.digitalData && window.digitalData.page && window.digitalData.page.search) {
      // Clear Adobe data query path
      if (window.digitalData.page.search.specialtySearchQuery) {
        window.digitalData.page.search.specialtySearchQuery = '';
      }
      if (window.digitalData.page.search.procedureSearchQuery) {
        window.digitalData.page.search.procedureSearchQuery = '';
      }
      if (window.digitalData.page.search.providerSearchQuery) {
        window.digitalData.page.search.providerSearchQuery = '';
      }

      const _adobePlan = {} as IAdobe;
      _adobePlan.searchDetail = data;
      const _query = CommonUtil.getAnalyticsQuery(_adobePlan);
      if (data.specialityName) {
        window.digitalData.page.search.specialtySearchQuery = _query;
      } else if (data.procedureName) {
        window.digitalData.page.search.procedureSearchQuery = _query;
      } else {
        window.digitalData.page.search.providerSearchQuery = _query;
      }
    }
  }

  resetforHPN() {
    this.searchTerm = this._appSession.searchTerm;
    this.selected = this.selectedOption();
  }

  get transportationLocation() {
    this.getScroll();
    if ((typeof this._transportationLocation === 'undefined' || this._transportationLocation === null) && this._appSession.planRule && this._appSession.planRule.transportationLocation) {
      this._transportationLocation = this._appSession.planRule.transportationLocation;
    }
    return this._transportationLocation;
  }

  showError() {
    return !this.showLoading && this.searchTerm && this.searchTerm.length >= this.inputMinLength;
  }

  navigateToLiveChat() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_LIVE_CHAT.MSG;
    eventDetail.target = AppExternalCommands.RCP_LIVE_CHAT.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  get leavingDisclaimerContent(): string {
    if (this.content && this.content.common.leavingDisclaimerComponent && this.content.common.leavingDisclaimerComponent.content1) {
      return this.content.common.leavingDisclaimerComponent.content1.replace(/{BRAND}/gi, this._appSession.metaData.brandName);
    }
    return '';
  }

  get leavingDisclaimerTermsOfUse(): string {
    if (this.content && this.content.common.leavingDisclaimerComponent && this.content.common.leavingDisclaimerComponent.content2) {
      return this.content.common.leavingDisclaimerComponent.content2.replace(/{BRAND}/gi, this._appSession.metaData.brandName);
    }
    return '';
  }

  get noResultsText() {
    return this.content.common.pageHeader?.searchCriteria?.labels?.noResultsMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  get defaultSearchText() {
    return this.content.common.pageHeader?.searchCriteria?.labels?.defaultSearchMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  // Logic to display defaultSearchText info. msg in search bar when there is no match in provider, procedure or specialty against search term
  isProviderMatchFound(): boolean {
    // If any provider is found against search term, returning false
    if (
      (this.providerList && this.providerList.length > 0) ||
      (this.providerLicenseList && this.providerLicenseList.length > 0) ||
      (this.providerNpiList && this.providerNpiList.length > 0) ||
      this.cptList?.length
    ) {
      return false;
    } else {
      let _procedureMatchFound: IProcedureLookup[] = [];
      let _specialtyMatchFound: ISpecialtyLookup[] = [];
      let _aoeMatchFound: IAreaOfExpertise[] = [];
      let _ofcSrvcMatchFound: IOfficeService[] = [];
      // pupulating _procedureMatchFound if procedures in procedureList has a match with search term
      if (this.procedureList.length > 0) {
        _procedureMatchFound = this.procedureList.filter((procedure) => {
          if (procedure.alias.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return procedure;
          }
        });
      }
      // populating _specialtyMatchFound if specialtyList has a match with search term
      if (this.specialtyList.length > 0) {
        _specialtyMatchFound = this.specialtyList.filter((specialty) => {
          if (specialty.alias.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return specialty;
          }
        });
      }
      // populating _aoe if aoeList has a match with search term
      if (this.aoeList.length > 0) {
        _aoeMatchFound = this.aoeList.filter((aoe) => {
          if (aoe.name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return aoe;
          }
        });
      }
      // populating _ofcSrvc if ofcSrvcList has a match with search term
      if (this.ofcSrvcList.length) {
        _ofcSrvcMatchFound = this.ofcSrvcList.filter((ofcSrvc) => {
          if (ofcSrvc.name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            return ofcSrvc;
          }
        });
      }
      // If procedure or specialty has matches with search term, return false
      return !(_procedureMatchFound.length > 0 || _specialtyMatchFound.length > 0 || _aoeMatchFound.length > 0 || _ofcSrvcMatchFound.length);
    }
  }

  /** Method to show loader */
  get showLoading() {
    if (this._appSession.searchTerm && this._appSession.searchTerm.length >= this.inputMinLength) {
      return this.showProviderLoader || this.showSmartSearchLoader || this.showNpiProviderLoader || this.showLicenseProviderLoader || this.showAoeLoader || this.showOfcSrvcLoader;
    }
    return false;
  }

  get suppressAoeAndOfcSrvcLookup(): boolean {
    const careType = !this._dataHelper.isEmptyString(this._appSession?.searchParams?.plan?.careType) ? this._appSession.searchParams.plan.careType : '';
    if (
      [PROVIDER_TYPE_OF_CARE.VISION.toString(), PROVIDER_TYPE_OF_CARE.DENTAL.toString(), PROVIDER_TYPE_OF_CARE.PHARMACY.toString()].includes(careType.toUpperCase()) ||
      this._appSession.isDentalStandAlone ||
      this._appSession.isVisionStandAlone ||
      this._appSession.isPharmacyStandAlone
    ) {
      return true;
    }
    return false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (
      typeof this._elementRef.nativeElement !== 'undefined' &&
      this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' &&
      event !== null &&
      typeof event.target !== 'undefined' &&
      event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      event.target.className !== 'fly-out look-up-container' &&
      !this._appSession.isCaHmoDmhc
    ) {
      this.searchCtrlFocusOut();
      this.searchCtrlFocus();
      if (event.target.className !== 'btn btn-primary' && !this.isLocationValid() && event.target.type !== 'radio') {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
  }

  isNumber(userInput: any): boolean {
    return userInput.match(/^[0-9]+$/) !== null ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target && event.target.screen && event.target.screen.availWidth) {
      this.selectedOption();
      //Handle search text box watermark for mobile screen.
      if (event.target.screen.availWidth < this.responsiveWidthMob) {
        this.searchWatermark = this.content.common.pageHeader?.searchCriteria?.labels?.search;
      }
    }
  }

  //Allow if icense number has 2 digit and some text or full of digits
  validateLicenseNumber(input: any) {
    return input.match(/\d{2,}/) !== null ? true : false;
  }

  private invokeActions(rules: IRulesResponse) {
    if (rules) {
      this.resetAppSessionParams();
      this._appSession.feature = rules;
      this.reloadProviderSummary();
    }
  }

  private createAoeOfceSrvcFwdLookupReq(): IProviderMetadataRequest {
    const zipCode = isEmpty(this.searchCriteria.countyCode) && isEmpty(this.searchCriteria.city) ? this.searchCriteria.zip : undefined;
    const city = isEmpty(this.searchCriteria.countyCode) ? this.searchCriteria.city : undefined;
    const countycode = !isEmpty(this.searchCriteria.countyCode) ? this.searchCriteria.countyCode : undefined;
    const req: IProviderMetadataRequest = {
      searchTerm: this.searchTerm.trim(),
      locale: this.locale,
      location: {
        city: city,
        countyCode: countycode,
        postalCode: zipCode,
        state: this.searchCriteria.stateCd?.code
      }
    };
    return req;
  }

  aoeOfceSrvcFwdLookup(_searchTerm: string) {
    this._providerUtilityService.getProviderMetaDataLookupList(this.createAoeOfceSrvcFwdLookupReq()).then(
      (result: IProviderMetadataResponse) => {
        if (result) {
        }
      },
      (error: any) => {
        try {
          this.showOfcSrvcLoader = false;
          this.showAoeLoader = false;
          this.onCategoryError('PROVIDER_METADATA_LOOKUP', error);
        } catch (e) {}
      }
    );
  }

  isCaHmoDmhc() {
    return this._appSession.isCaHmoDmhc;
  }
  openAddressSelectionPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.changePlanSidePanel);
  }

  onClose() {
    this.sidePanelRef.close();
  }

  loadCaHmo() {
    this.loadCahmoPage.emit(true);
  }

  medicalCodeDropDownClick() {
    this.showMedicalCode = !this.showMedicalCode;
  }

  medicalCodeShowMoreLessClick() {
    this.showMedicalCodeMoreLess = !this.showMedicalCodeMoreLess;
    this.visibleMedicalCodeCount = !this.showMedicalCodeMoreLess ? THREE : this.cptList.length;
  }

  updateZipcode(zipcode: string) {
    this.searchParams.postalCode = zipcode;
    this.searchParams.zip = zipcode;
    this.locationCmp.locationTxt = zipcode;
    this.locationCmp.getGeoCoordinates(false);
  }

  //Updating the transportation popup content based on prefix
  get isPrefixAvailable(): boolean {
    return !this._dataHelper.isEmptyString(this._appSession?.searchParams?.plan?.alphaPrefix) && TRANSPORTATION_PREFIX.includes(this._appSession.searchParams.plan.alphaPrefix);
  }

  getContactUsText(): string {
    return CommonUtil.isMemberSecure(this._appSession)
      ? this.content?.common?.pageHeader?.searchCriteria?.transportationContent?.contactUsSecure
      : this.content?.common?.pageHeader?.searchCriteria?.transportationContent?.contactUsPublic;
  }

  onMedicalCodeSelect(selectedMedicalCode: ICptCode) {
    this.clearAll();
    this.clearSearchCriteria();
    const { medicalTypeCd, medicalCd } = selectedMedicalCode;
    this.medicalCodeCriteria = {
      billingType: medicalTypeCd,
      medicalCode: medicalCd
    };
    this.searchTerm = selectedMedicalCode.searchDesc?.slice(0, this.inputMaxLength);
    this._appSession.isMedicalCodeSearch = true;
    this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.billingType = medicalTypeCd;
    this._appSession.medicalCode = medicalCd;
    this.searchCriteria.providerTypeCodeList = [];
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  bindSmartSearchRequest(_searchTerm: string): ISmartSearchLookupRequest {
    let _productType = '';
    if (this._appSession.metaData?.appContract) {
      if (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.productType && !this._appSession.isPlanUnknown) {
        _productType = this._appSession.metaData.appContract.coverageDetails.productType;
      }
    }
    let _searchCriteria: ISearchParameters = Object.assign({}, this.searchCriteria);
    _searchCriteria = this.bindProviderType(_searchCriteria, this._appSession);
    const _ruleRequest: ISmartSearchLookupRequest = {
      brand: this._appSession.metaData?.brandCd,
      state: this.searchCriteria.plan ? this.searchCriteria.plan.stateCd : '',
      planCategory: this.searchCriteria?.plan?.category?.catCode ? this.searchCriteria.plan.category.catCode : '',
      productType: _productType,
      identification: this.searchCriteria.identificationNumber ? this.searchCriteria.identificationNumber : '',
      locale: this.locale,
      network: this.searchCriteria.plan?.networkList && this.searchCriteria.plan?.networkList?.length > 0 ? this.searchCriteria.plan.networkList : [],
      prefix: CommonUtil.getPrefix(this.searchCriteria, this._appSession),
      contractUid: this._appSession.metaData?.appContract && !this._appSession.isPlanUnknown ? this._appSession.metaData.appContract.contractUid : undefined,
      mbrUid: this._appSession.metaData?.appContract?.mbrUid,
      providerTypeList: _searchCriteria?.providerTypeCodeList,
      searchTerm: encodeURI(_searchTerm),
      isPrefixProcSearchEnabled: this.isColdStateTccSearchEnabled
    };
    return _ruleRequest;
  }

  setDeeplinkErrorModal(): void {
    if (
      this._appSession.isDeeplinkErrorMsg &&
      this.content.common.pageHeader?.searchCriteria?.precareSearchErrorMessages &&
      !this._dataHelper.isEmptyObject(this.content.common.pageHeader?.searchCriteria?.precareSearchErrorMessages)
    ) {
      this.genericModalTemplate = this.genericModalTemplate ?? ({} as IGenericModalTemplate);
      const genericMessage = this.content.common.pageHeader.searchCriteria.precareSearchErrorMessages.genericMessage;
      if (genericMessage) {
        this.genericModalTemplate.header = !this._dataHelper.isEmptyString(genericMessage.header) ? genericMessage.header : undefined;
        this.genericModalTemplate.close = !this._dataHelper.isEmptyString(genericMessage.close) ? genericMessage.close : undefined;
        this.genericModalTemplate.closeAriaLabel = !this._dataHelper.isEmptyString(genericMessage.closeAriaLabel) ? genericMessage.closeAriaLabel : undefined;
        this.genericModalTemplate.message = WcsUtil.getInfoListContent(genericMessage.message);
      }
    }
  }

  getProviderName(provider: ILookUpItem): string {
    return CommonUtil.getProviderTitles(provider?.titleList, provider?.networkRegisteredName, provider?.providerName);
  }

  getOutOfNetworkProvidersLinkText(): string {
    if (this.content?.common?.pageHeader?.searchCriteria?.labels?.showNotInNtwrkProvsLink) {
      return this.content?.common?.pageHeader?.searchCriteria?.labels?.showNotInNtwrkProvsLink.replace(/{searchTerm}/gi, this.searchTerm);
    } else {
      return undefined;
    }
  }

  openOONProvidersLearnMore(): void {
    this.oonLearnMoreSlider.openSidePanel();
  }

  /**
   * Method to get the updated image URL based on condition
   */
  getUpdatedImage(type: string) {
    let imgUrl = '';
    if (type == LOOKUP_TYPE.SPECIALITY) {
      imgUrl = this.specialtyList.length > 5 && !this.specialtyMore ? 'fcr_plus.svg' : 'fcr_minus.svg';
    } else if (type == LOOKUP_TYPE.PROCEDURE) {
      imgUrl = this.procedureList.length > 5 && !this.procedureMore ? 'fcr_plus.svg' : 'fcr_minus.svg';
    } else if (type == LOOKUP_TYPE.PROCEDURE_LICENSE) {
      imgUrl = this.providerLicenseList.length > 5 && !this.providerLicenseMore ? 'fcr_plus.svg' : 'fcr_minus.svg';
    } else if (type == LOOKUP_TYPE.AOE) {
      imgUrl = this.aoeList.length > 5 && !this.aoeMore ? 'fcr_plus.svg' : 'fcr_minus.svg';
    } else if (type == LOOKUP_TYPE.OFC_SERVICE) {
      imgUrl = this.ofcSrvcList.length > 5 && !this.ofcSrvcMore ? 'fcr_plus.svg' : 'fcr_minus.svg';
    }
    return this.getCommonImageURL(imgUrl);
  }

  /**
   * Performs a lookup search.
   */
  private performLookupSearch(searchTypes: Array<LookupType>) {
    if (searchTypes?.length && this.searchCriteria?.coordinates?.latitude && this.searchCriteria?.coordinates?.longitude) {
      this.getLookup(searchTypes);
    } else {
      this.providerList = [];
      this.providerTop5List = [];
      this.providerNpiList = [];
      this.providerNpiTop5List = [];
      this.providerLicenseList = [];
      this.providerLicenseTop5List = [];
      this.procedureList = [];
      this.procedureTop5List = [];
      this.specialtyList = [];
      this.specialtyTop5List = [];
      this.cptList = [];
      this.aoeList = [];
      this.aoeTop5List = [];
      this.ofcSrvcList = [];
      this.ofcSrvcTop5List = [];

      this.showProviderLoader = false;
      this.showNpiProviderLoader = false;
      this.showLicenseProviderLoader = false;
      this.showSmartSearchLoader = false;
      this.smartSearchRequestId = undefined;
      this.showAoeLoader = false;
      this.showOfcSrvcLoader = false;
    }
  }
}
