import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InfoListService } from '../../../../../common/components/info-list/services/infoListSvc';
import { IMessage } from '../../../../../common/interfaces/iMessage';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppUtility } from '../../../../../common/utilities/appUtil';
import { RouteUtil } from '../../../../../common/utilities/routeUtil';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { GREEN_MEDICAL_BH, WLP_MEDICAL_BH } from '../../../../../fad/search-providers/values/providerSearchConstants';
import { BaseComponent } from './../../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-info-list',
  templateUrl: './infoListCmp.html',
  providers: []
})
export class InfoListComponent extends BaseComponent implements OnInit, OnDestroy {
  infoList: Array<IMessage>;
  private infoListChangeSubscription: Subscription;
  GREEN_MEDICAL_BH = GREEN_MEDICAL_BH;
  WLP_MEDICAL_BH = WLP_MEDICAL_BH;
  sanitizedHtml: SafeHtml;
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    _eventHandler: EventHandler,
    private _elementRef: ElementRef,
    public _infoListSvc: InfoListService,
    private _routeUtil: RouteUtil,
    private _appUtility: AppUtility,
    private sanitizer: DomSanitizer
  ) {
    super(_route, _eventHandler, _appSession);
    this.infoListChangeSubscription = this._infoListSvc.onInfoListChange.subscribe((list) => {
      this.infoList = list;
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.infoList[0]?.content);
      this.setFocus();
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.infoListChangeSubscription) {
      this.infoListChangeSubscription.unsubscribe();
    }
  }

  closeInfo(info: IMessage) {
    this._infoListSvc.removeMsgById(info.contentId);
  }

  setFocus() {
    if (this.infoList && this.infoList.length > 0) {
      const _index = this.infoList.findIndex((item) => item.focus === true);
      if (_index > -1) {
        this.setCtrlFocus(this._elementRef, `#lbl-info-${_index}`);
      }
    }
  }

  // Set Ctrl Focus
  setCtrlFocus(element: ElementRef, querySelector: string, delay: number = 1500) {
    setTimeout(() => {
      let _ele;
      if (element && element.nativeElement && querySelector) {
        _ele = element.nativeElement.querySelector(querySelector);
      } else if (element && element.nativeElement) {
        _ele = element.nativeElement;
      }
      if (_ele) {
        this._appUtility.scrollToTop();
        _ele.focus();
      }
    }, delay);
  }
}
