export enum ALERT_TYPE {
  INFORMATION = 'information',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}
export enum COVERAGE_TYPE {
  MEDICAL = 'MEDICAL',
  DENTAL = 'DENTAL',
  VISION = 'VISION',
  PHARMACY = 'RX'
}

export const CARDS_CHARLIMIT = {
  TITLE: 34,
  DESC: 50
};

export const NOT_ACCEPTING_NEW_PATIENTS_CODES = ['1937', '1938', '1939', '1943'];

export const SPANISH_PROVIDER_SEARCH_DISTANCE_FILTER = {
  title: 'Distance',
  options: [
    {
      label: '2 Millas',
      value: '2',
      id: 'cB_Distance_0',
      name: 'cB_Distance_0',
      trueValue: '2',
      falseValue: ''
    },
    {
      label: '5 Millas',
      value: '5',
      id: 'cB_Distance_1',
      name: 'cB_Distance_1',
      trueValue: '5',
      falseValue: ''
    },
    {
      label: '10 Millas',
      value: '10',
      id: 'cB_Distance_2',
      name: 'cB_Distance_2',
      trueValue: '10',
      falseValue: ''
    },
    {
      label: '20 Millas',
      value: '20',
      id: 'cB_Distance_3',
      name: 'cB_Distance_3',
      trueValue: '20',
      falseValue: ''
    },
    {
      label: '30 Millas',
      value: '30',
      id: 'cB_Distance_5',
      name: 'cB_Distance_5',
      trueValue: '30',
      falseValue: ''
    },
    {
      label: '50 Millas',
      value: '50',
      id: 'cB_Distance_6',
      name: 'cB_Distance_6',
      trueValue: '50',
      falseValue: ''
    },
    {
      label: '75 Millas',
      value: '75',
      id: 'cB_Distance_7',
      name: 'cB_Distance_7',
      trueValue: '75',
      falseValue: ''
    },
    {
      label: '100 Millas',
      value: '100',
      id: 'cB_Distance_8',
      name: 'cB_Distance_8',
      trueValue: '100',
      falseValue: ''
    }
  ]
};

export const PROVIDER_SEARCH_DISTANCE_FILTER = {
  title: 'Distance',
  options: [
    {
      label: '2 Miles',
      value: '2',
      id: 'cB_Distance_0',
      name: 'cB_Distance_0',
      trueValue: '2',
      falseValue: ''
    },
    {
      label: '5 Miles',
      value: '5',
      id: 'cB_Distance_1',
      name: 'cB_Distance_1',
      trueValue: '5',
      falseValue: ''
    },
    {
      label: '10 Miles',
      value: '10',
      id: 'cB_Distance_2',
      name: 'cB_Distance_2',
      trueValue: '10',
      falseValue: ''
    },
    {
      label: '20 Miles',
      value: '20',
      id: 'cB_Distance_3',
      name: 'cB_Distance_3',
      trueValue: '20',
      falseValue: ''
    },
    {
      label: '30 Miles',
      value: '30',
      id: 'cB_Distance_5',
      name: 'cB_Distance_5',
      trueValue: '30',
      falseValue: ''
    },
    {
      label: '50 Miles',
      value: '50',
      id: 'cB_Distance_6',
      name: 'cB_Distance_6',
      trueValue: '50',
      falseValue: ''
    },
    {
      label: '75 Miles',
      value: '75',
      id: 'cB_Distance_7',
      name: 'cB_Distance_7',
      trueValue: '75',
      falseValue: ''
    },
    {
      label: '100 Miles',
      value: '100',
      id: 'cB_Distance_8',
      name: 'cB_Distance_8',
      trueValue: '100',
      falseValue: ''
    }
  ]
};

export const PROVIDER_SEARCH_DISTANCE_FILTER_EYC = {
  title: 'Distance',
  options: [
    {
      label: '2 Miles',
      value: '2',
      id: 'cB_Distance_0',
      name: 'cB_Distance_0',
      trueValue: '2',
      falseValue: '',
      analytics: '2milesEycSearch'
    },
    {
      label: '5 Miles',
      value: '5',
      id: 'cB_Distance_1',
      name: 'cB_Distance_1',
      trueValue: '5',
      falseValue: '',
      analytics: '5milesEycSearch'
    },
    {
      label: '10 Miles',
      value: '10',
      id: 'cB_Distance_2',
      name: 'cB_Distance_2',
      trueValue: '10',
      falseValue: '',
      analytics: '10milesEycSearch'
    },
    {
      label: '20 Miles',
      value: '20',
      id: 'cB_Distance_3',
      name: 'cB_Distance_3',
      trueValue: '20',
      falseValue: '',
      analytics: '20milesEycSearch'
    },
    {
      label: '30 Miles',
      value: '30',
      id: 'cB_Distance_5',
      name: 'cB_Distance_5',
      trueValue: '30',
      falseValue: '',
      analytics: '30milesEycSearch'
    },
    {
      label: '50 Miles',
      value: '50',
      id: 'cB_Distance_6',
      name: 'cB_Distance_6',
      trueValue: '50',
      falseValue: '',
      analytics: '50milesEycSearch'
    },
    {
      label: '75 Miles',
      value: '75',
      id: 'cB_Distance_7',
      name: 'cB_Distance_7',
      trueValue: '75',
      falseValue: '',
      analytics: '75milesEycSearch'
    },
    {
      label: '100 Miles',
      value: '100',
      id: 'cB_Distance_8',
      name: 'cB_Distance_8',
      trueValue: '100',
      falseValue: '',
      analytics: '100milesEycSearch'
    }
  ]
};

export const PROVIDER_SEARCH_GENDER_FILTER = {
  title: 'Gender',
  options: [
    {
      id: 'cB_Gender_0',
      name: 'cB_Gender_0',
      label: 'Male',
      trueValue: 'M',
      falseValue: '',
      value: 'M'
    },
    {
      id: 'cB_Gender_1',
      name: 'cB_Gender_1',
      label: 'Female',
      trueValue: 'F',
      falseValue: '',
      value: 'F'
    }
  ]
};

export const PROVIDER_SEARCH_MEDICAID_CHECKBOX = {
  id: 'acceptMedicaid',
  name: 'acceptMedicaid',
  label: 'Accepts Medicaid',
  trueValue: true,
  falseValue: false
};

export const PROVIDER_SEARCH_NEWPATIENT_CHECKBOX = {
  id: 'acceptAcceptingNewPatient',
  name: 'acceptAcceptingNewPatient',
  label: 'Accepting new patients',
  trueValue: true,
  falseValue: false
};

export const PROVIDER_SEARCH_SERVEASPCP_CHECKBOX = {
  id: 'serveAsPcp',
  name: 'serveAsPcp',
  label: 'Able to serve as Primary Care Physician (PCP)',
  trueValue: true,
  falseValue: false
};
export const BLUE_PRECISION = {
  id: 'bluePrecisionInd',
  name: 'bluePrecisionInd',
  label: 'Blue Precision',
  trueValue: true,
  falseValue: false
};

export const BLUE_DISTINCT_TOTAL_CARE = {
  id: 'blueDistinctionInd',
  name: 'blueDistinctionInd',
  label: 'Blue Distinction Total Care',
  trueValue: true,
  falseValue: false
};
export const BLUE_DISTINCT_TOTAL_CARE_PLUS = {
  id: 'blueDistinctionPlusInd',
  name: 'blueDistinctionPlusInd',
  label: 'Blue Distinction Total Care+',
  trueValue: true,
  falseValue: false
};

export const ENHANCED_PERSONAL_CARE = {
  id: 'patCenteredCareInd',
  name: 'patCenteredCareInd',
  label: 'Enhanced Personal Health Care',
  trueValue: true,
  falseValue: false
};

export const LOWER_COPAY_PCP = {
  id: 'lowerCopayPCPInd',
  name: 'lowerCopayPCPInd',
  label: 'Lower Copay PCP',
  trueValue: true,
  falseValue: false
};

export const SORT_RESULT = {
  title: 'Specialty',
  options: [
    {
      label: '5',
      value: 5,
      id: 'cb_5',
      name: 'cb_5',
      trueValue: 5,
      falseValue: '',
      isChecked: false
    },
    {
      label: '10',
      value: 10,
      id: 'cb_10',
      name: 'cb_10',
      trueValue: 10,
      falseValue: '',
      isChecked: false
    },
    {
      label: '20',
      value: 20,
      id: 'cb_20',
      name: 'cb_20',
      trueValue: 20,
      falseValue: '',
      isChecked: false
    },
    {
      label: 'All',
      value: 'All',
      id: 'cb_All',
      name: 'cb_All',
      trueValue: 'All',
      falseValue: '',
      isChecked: false
    }
  ]
};

export const PROVIDER_OPTIONS = [
  {
    label: 'Professional',
    value: 'P',
    id: 'rb_P',
    name: 'rb_P',
    trueValue: 'P',
    falseValue: '',
    disable: false,
    isChecked: false
  },
  {
    label: 'Facility',
    value: 'F',
    id: 'rb_F',
    name: 'rb_F',
    trueValue: 'F',
    falseValue: '',
    disable: false,
    isChecked: false
  }
];

export const BLUEPRECISION_TYPE_BOTH = 'BOTH';
export const PROVIDER_SEARCH_DISTANCE = '20';
export const PROVIDER_SEARCH_PAGE_SIZE = 60;

export const PROVIDER_CATEGORY_CODE = {
  Individual: '01',
  Group: '02',
  Organization: '03',
  Hospital: '04',
  Ancillary: '05'
};

export enum FREQUENT_PROCEDURE_COUNT {
  NINE = 9,
  SIX = 6
}

export const FREQUENT_PROCEDURE_DESC_CHARLIMIT = 50;

export enum PROVIDER_TYPE {
  HEARING = 'A',
  VISION = 'V',
  RETAIL_CLINIC = 'C',
  DOCTOR_PROFESSIONAL = 'P',
  MEDICAL_GROUP = 'G',
  HOSPITAL = 'H',
  BEHAVIORAL_HEALTH = 'B',
  DENTAL = 'D',
  URGENT_CARE = 'U',
  OTHER_MEDICAL = 'O',
  PHARMACY = 'R',
  MEDICAL_EQUIPMENT = 'M',
  OTHER_SERVICES_PROVIDER = 'CA-OSP',
  LAB = 'L'
}

export const ALL_PROV_TYPE_CODES = Object.values(PROVIDER_TYPE);

export enum EYC_RETURN_PROVIDER_TYPE {
  DOCTOR_PROFESSIONAL = 'PROFESSIONAL',
  HOSPITAL = 'FACILITY',
  BOTH = 'PROF_FAC'
}

export enum EYC_PROVIDER_CATEGORY_CODE {
  FACILITY = 'F',
  PROFESSIONAL = 'P',
  BOTH = 'B'
}

export enum PROVIDER_TYPE_OF_CARE {
  MEDICAL = 'MEDICAL',
  DENTAL = 'DENTAL',
  VISION = 'VISION',
  PHARMACY = 'PHARMACY'
}

export const OUT_OF_NETWORK = 'OON';

export enum DISCLAIMERS {
  DENTAQUEST = 'DENTAQUEST',
  LIBERTY = 'LIBERTY',
  VSP = 'VSP',
  EYEQUEST = 'EYEQUEST',
  SUPERIOR = 'SUPERIOR',
  EYEMED = 'EYEMED',
  MARCH = 'MARCH',
  AVESIS = 'AVESIS',
  MARYLAND = 'MARYLAND',
  HCS = 'HCS',
  ADDCARETEAM = 'ADDCARETEAM',
  PRECONFIGSEARCH = 'PRECONFIGUREDSEARCH',
  PROMOTEDPROCEDURE = 'PROMOTEDPROCEDURES',
  DELTADENTAL = 'DELTADENTAL',
  NAVITUSPDF = 'NAVITUSPDF',
  KYPHARMACYPORTAL = 'KYPORTAL',
  OHEYEMED = 'OHEYEMED'
}

export enum MESSAGE_CATEGORIES {
  PROMOTED_PROCEDURES = 'promotedProcedures',
  CARE_GAP = 'careGapDetails',
  PENDING_APPTS = 'pendingAppointments'
}

export enum CLINICAL_DISCLAIMERS {
  TP_ATSM_SPCTRM_DSRD = 'TP_ATSM_SPCTRM_DSRD',
  TP_BH_HLTH_RSRC_CNTR = 'TP_BH_HLTH_RSRC_CNTR',
  TP_CSMNGT = 'TP_CSMNGT',
  TP_CNDCR = 'TP_CNDCR',
  TP_FTRMM = 'TP_FTRMM',
  TP_LHO_LC = 'TP_LHO_LC',
  TP_247NRSLN = 'TP_247NRSLN',
  TP_WBC = 'TP_WBC',
  TP_DPP = 'TP_DPP',
  TP_SSPR_SSO = 'TP_SSPR_SSO',
  TP_BPG = 'TP_BPG'
}

export enum INFOLIST_DISCLAIMERS {
  TP_DENTAQUEST = 'TP_DENTAQUEST',
  TP_OHDENTAQUEST = 'TP_OHDENTAQUEST',
  TP_LIBERTY = 'TP_LIBERTY',
  TP_MAGELLAN_PHARMACY = 'TP_MAGELLAN_PHARMACY',
  TP_VSP = 'TP_VSP',
  TP_EYEQUEST = 'TP_EYEQUEST',
  TP_SUPERIOR = 'TP_SUPERIOR',
  TP_HCS = 'TP_HCS',
  TP_GA_Termination_Providers = 'TP_GA_Termination_Providers',
  TP_32BJ_Member_Alert = 'TP_32BJ_Member_Alert',
  TP_TN_DENTAQUEST = 'TP_TN_DENTAQUEST',
  TP_TN_LIBERTY = 'TP_TN_LIBERTY',
  TP_PrimeGroupSearchMsg = 'TP_PrimeGroupSearchMsg',
  TP_SKYGEN = 'TP_SKYGEN',
  TP_HEALTHY_BLUE_EYEMED = 'TP_HEALTHY_BLUE_EYEMED',
  TP_COUPE_HEALTH = 'TP_COUPE_HEALTH'
}

export enum CLINICAL_ADOBE_TRACKS {
  TP_ATSM_SPCTRM_DSRD = 'autismProgramAlertFindCare',
  TP_BH_HLTH_RSRC_CNTR = 'behavioralProgramAlertFindCare',
  TP_CSMNGT = 'caseProgramAlertFindCare',
  TP_CNDCR = 'conditionProgramAlertFindCare',
  TP_FTRMM = 'futureProgramAlertFindCare',
  TP_LHO_LC = 'lactationProgramAlertFindCare',
  TP_247NRSLN = 'nurselineProgramAlertFindCare',
  TP_WBC = 'wellBeingProgramAlertFindCare',
  ONSITE_CLINICS = 'onsiteClinicProgramAlertFindCare',
  TP_DPP = 'diabetesPreventProgramAlertFindCare',
  TP_SSPR_SSO = 'smartShopperProgramAlertFindCare',
  TP_BPG = 'bpgProgramAlertFindCare'
}

export enum PCP_INFOLIST {
  PCP_EFFECTIVE_DATE = 'update-pcp-effectiveDate-text'
}

export const EMPTY_SPACE = '\xa0';

export const PROVIDER_TYPE_CARE = {
  options: [
    {
      label: 'Medical Plan or Network (may also include dental, vision, or pharmacy benefits)',
      value: 'medical'
    },
    {
      label: 'Dental Plan or Network',
      value: 'dental'
    },
    {
      label: 'Vision Plan or Network',
      value: 'vision'
    },
    {
      label: 'Pharmacy Plan or Network',
      value: 'pharmacy'
    }
  ]
};
export const SPANISH_PROVIDER_TYPE_CARE = {
  options: [
    {
      label: 'Plan médico o red (también puede incluir beneficios dentales, de visión o de farmacia)',
      value: 'medical'
    },
    {
      label: 'Plan o Red dental',
      value: 'dental'
    },
    {
      label: 'Plan o Red de visión',
      value: 'vision'
    },
    {
      label: 'Plan o red de farmacia',
      value: 'pharmacy'
    }
  ]
};

export const PROVIDER_MESSAGES = ['TP_PUBLICPHARMACY'];
export const DENTAL_ROUTINE_COVERAGETYPE = 'D';
export const VISION_ROUTINE_COVERAGETYPE = 'V';
export const PHARMACY_ROUTINE_COVERAGETYPE = 'R';
export const HEARING_ROUTINE_COVERAGETYPE = 'A';
export const MEDICAL_COVERAGETYPE = 'M';
export const AUDIOLOGIST_SPECIALTY = '10';
export const AUDIOLOGY_SUBSPECIALTY = 'CX';
export const TRANSPORTATION_SPECIALTY = '252';
export const LACTATION_COUNSELING = '257';
export const HOME_HEALTH_CARE = '33';
export const DME_MEDICAL_SURGICAL = '47';
export const BEHAVIOURAL_HEALTH_FACILITY_SPECIALTY = '6';
export const HCS_URL = 'https://hearingcaresolutions.com/anthem/';
export const LIBERTY_DENTAL_URL = 'https://client.libertydentalplan.com/anthem/FindADentist';
export const DENTAL_QUEST_URL = 'http://www.dentaquest.com/members/';
export const DENTAL_VSP_URL = 'https://www.vsp.com/find-eye-doctors.html?id=medicaid';
export const VEP_BEH_HEALTH = 'http://www.mbh-eap.com/pd/';
export const VEP = 'VEP';
export const AEP = 'AEP';
export const SUBSCRIBER = 'SUBSCRIBER';
export const PERSONALIZEDMATCH_MESSAGE = 'TP_PersonalizedMatch_Message';
export const NCCT_FAILOVER_MESSAGE = 'TP_NCCTFailoverMsg';
export const ADDCARETEAM_MESSAGE = 'TP_AddCareTeam';
export const TP_SUPPRESSED_MEDSUPP = 'TP_SUPPRESSED_MEDSUPP';
export const ADDCARETEAM_SUCCESS_MESSAGE = 'TP_AddCareTeamSuccessMsg';
export const ADDCARETEAM_FAILURE_MESSAGE = 'TP_AddCareTeamFailureMsg';
export const REMOVECARETEAM_SUCCESS_MESSAGE = 'TP_RemoveCareTeamSuccessMsg';
export const REMOVECARETEAM_FAILURE_MESSAGE = 'TP_RemoveCareTeamFailureMsg';
export const BCP_MESSAGE = 'TP_BCPMsg';
export const DENTALPROCEDURE_MESSAGE = 'TP_DentalProcMsg';
export const PRIME_GROUP_SEARCH_MESSAGE = 'TP_PrimeGroupSearchMsg';
export const ARIALABEL_MESSAGE = '_AriaLabel';
export const EYCMANITENANCE_ALERT_MESSAGE = 'TP_EycAlert';
export const REPORT_INVAID_EMAIL_SUCCESS = 'TP_ReportInvalidEmailSuccess';
export const INITIAL_VISIT_COST_MESSAGE = 'TP_InitialVisitCostMsg';
export const MORE_THAN_ONE_VISIT_COST_MESSAGE = 'TP_MoreThanOneVisitCostMsg';
export const GA_TERMINATION_PROVIDERS = 'TP_GA_Termination_Providers';
export const STATE_GA = 'GA';
export const SERVICE_AREA_VALIDATION_MESSAGE = 'TP_SavAlert';
export const SERVICE_AREA_VALIDATION_ARIA_MESSAGE = 'TP_SavAlert_AriaLabel';
export enum VISITS {
  INITIAL = 'INITIAL',
  SUBSEQUENT = 'MORETHANONE'
}
export const PROVIDER_TYPE_PHARMACY_VISIT = {
  title: 'The reason for my visit is:',
  options: [
    {
      id: 'cB_Retail_Pharmacy',
      name: 'cB_Pharmacy',
      label: 'I need to find a retail pharmacy',
      trueValue: 'Retail',
      falseValue: '',
      value: 'Retail',
      tooltip: ''
    },
    {
      id: 'cB_Medical_Pharmacy',
      name: 'cB_Pharmacy',
      label: 'I need to find a medical specialty pharmacy',
      trueValue: 'Medical',
      falseValue: '',
      value: 'Medical',
      tooltip: ''
    }
  ]
};

export const SPANISH_PROVIDER_TYPE_PHARMACY_VISIT = {
  title: 'The reason for my visit is:',
  options: [
    {
      id: 'cB_Retail_Pharmacy',
      name: 'cB_Pharmacy',
      label: 'Necesito buscar una farmacia minorista',
      trueValue: 'Retail',
      falseValue: '',
      value: 'Retail',
      tooltip: ''
    },
    {
      id: 'cB_Medical_Pharmacy',
      name: 'cB_Pharmacy',
      label: 'Necesito buscar una farmacia de especialidad',
      trueValue: 'Medical',
      falseValue: '',
      value: 'Medical',
      tooltip: ''
    }
  ]
};
export const PHARMACY_SUBSPECIALTY = 'RX';
export const MEDICAL_PHARMACY = 'Medical';
export const RETAIL_PHARMACY = 'Retail';
export const MEDICARE_PART_D = 'MEDICARE_PART_D';
export const DENTALNETWORK_URL = 'https://secure.mydentalapps.com/sub/dirServices/applications/beninq_privacy_notice.jsp?brandid=10';

export enum PF_APP_NAME {
  PAGE_EYC = 'PF_EYC',
  PAGE_FAD = 'PF_FAD'
}

export enum SPECIALTIES {
  GENERAL_ACUTE_CARE_HOSPITAL = '28'
}

export enum SUB_SPECIALTIES {
  ACUTE_CARE_HOSPITAL = 'GL'
}

export const LEVEL1 = 'LEVEL1';
export const MEDICAID_CODE = 'MDCFLG';
export const ENHNCMNT_TXT_LNGTH = 1180;
export const INDICATORS: string = 'INDICATORS';
export const NCCT_FAIL_OVER: string = 'NCCT_FAIL_OVER';
export const TRUE: string = 'true';
export const BCBSASOURCE = 'BCBSA';
export const NONBCBSASOURCE = 'HCAHPS';
export enum SORT_DATA_ANALYTICS {
  FAD_PERSONALIZED_MATCH = 'sortProvPersonalResultsFindCare',
  FAD_DISTANCE = 'sortProvDistanceResultsFindCare',
  FAD_ATOZ = 'sortProvAzResultsFindCare',
  FAD_ZTOA = 'sortProvZaResultsFindCare',
  FAD_TIER = 'sortProvBenefitTierResultsFindCare',
  FAD_QUALITY = 'sortProvQualityResultsFindCare',
  EYC_PERSONALIZED_MATCH = 'sortProcPersonalResultsFindCare',
  EYC_DISTANCE = 'sortProcDistanceResultsFindCare',
  EYC_ATOZ = 'sortProcAzResultsFindCare',
  EYC_ZTOA = 'sortProcZaResultsFindCare',
  EYC_LOWTOHIGH = 'sortProcLowHighResultsFindCare',
  EYC_HIGHTOLOW = 'sortProcHighLowResultsFindCare',
  EYC_TIER = 'sortProcBenefitTierResultsFindCare'
}

export enum RECOGNITION_FILTERS {
  SOS = 'siteOFService',
  COOPERATIVE_CARE = 'cooperativeCare',
  MEDICAL_SPECIALTY_PHARMACY = 'medicalSpecialtyPharmacy',
  VALUE_BASED_PROVIDERS = 'valueBasedProviders',
  OFC_OF_MENTAL_HEALTH = 'officeOfMentalHealth',
  OFC_OF_ALCOHOLISM_AND_SUBSTANCE = 'officeOfAlcoholismAndSubstance',
  CENTER_OF_EXCELLENCE = 'centerOfExcellence',
  PROVIDER_OF_DISTINCTION = 'providerOfDistinction',
  CCARE = 'ccare',
  EPHC = 'enhancedPersonalHealthCare',
  UPSWING = 'upswing',
  BLUE_PRECISION = 'bluePrecision',
  TOTAL_CARE = 'totalCare',
  PREFERRED_SPECIALISTS = 'preferredSpecialists',
  LGBTQ = 'lgbtq',
  ECP = 'ecpProvider',
  GREEN_RIBBON = 'greenRibbon',
  LOWER_COPAY_PCP_RCG = 'lowerCopayPCP',
  SNYDENTAL = 'snyDental'
}

export enum ACCOUNT_TYPE {
  HIA = 'HIA',
  HRA = 'HRA',
  HSA = 'HSA'
}

export enum PHARMACY_TYPE {
  AHD = 'AHD'
}

export const GREEN_MEDICAL_BH = 'TP_GreenMedicalBH';
export const WLP_MEDICAL_BH = 'TP_WlpMedicalBH';

export const UNIMASS_BRAND = 'unimass';
export const PERSONALIZED_MATCH = 'PersonalizedMatch';
export const VALID = 'VALID';
export const INDEX_ZERO = 0;
export const PREFIX_TP = 'TP_';
export const THREE = 3;
export const INDEX_FIFTY = 50;

export const TRANSPORTATION_PREFIX = ['1179', '1180', '1182', '2520'];

export const VIEW_MORE_PROCEDURE_COUNT = 6;
export const VACCINE = 'VACCINE';
export const PROVIDESFLUSHOTS = 'PROVIDESFLUSHOTS';
export const ADP_RECOGNITIONCODES = ['HPP50-LG', 'HPP50-SG', 'HPP50-NAT'];
export const COUPE_HEALTH_ALERT = 'TP_COUPE_HEALTH';
export const HAS_COUPE_HEALTH = 'HAS_COUPE_HEALTH';

export enum DOS {
  COUPE_HEALTH = 'COUPE_HEALTH',
  APP_MAINTENANCE = 'APP_MAINTENANCE'
}
