export const BING_MAPS_CUSTOM_STYLE: Microsoft.Maps.ICustomMapStyle = {
  elements: {
    area: { fillColor: '#a7dab2' },
    water: { fillColor: '#8bb1f7' },
    highway: { fillColor: '#fdde81', strokeColor: '#f5a111' }
  },
  settings: {
    landColor: '#e2e5e9',
    shadedReliefVisible: false
  },
  version: '1.0'
};

export enum FilterCategory {
  GENDER = 'gender',
  SPECIALTY = 'specialty',
  TAXONOMY = 'taxonomy',
  PROVIDER_TYPE = 'type',
  LANGUAGE = 'language',
  ADAACCSIBILE = 'adaAccessible',
  AREAOFEXPERTISE = 'areaOfExpertise',
  BILLINGCODE = 'billingCode',
  ETHNICITY = 'ethnicities',
  LEVELOFCARE = 'levelOfCares',
  OFFICESERVICES = 'officeServices',
  OFFICEFEATURES = 'officeFeatures'
}

export const ADOBE_TAGS_DISCLAIMER = {
  discResults: 'showDisclaimerProviderResults',
  discEycResults: 'showDisclaimerEycProviderResults'
};

export enum ProviderTabs {
  OVERVIEW = 'OVERVIEW',
  MORE_DETAILS = 'MORE_DETAILS',
  COST_ESTIMATE = 'COST_ESTIMATE'
}
export enum PDF_FILE_NAME {
  MEDICAL_PROCEDURE = 'medical_procedure',
  DENTAL_PROCEDURE = 'dental_procedure',
  MEDICAL_CODE = 'medical_code',
  SEARCH_ON = 'search_on'
}

export const UNDERSCORE = '_';
export const HYPHEN = '-';
export const INDEX_ZERO = 0;
export const INDEX_FIFTY = 50;
export enum ProviderCard {
  INSURANCE = 'INSURANCE',
  SPECIALITY = 'SPECIALITY',
  PCP = 'PCP',
  RECOGNITION = 'RECOGNITION',
  SERVICE = 'SERVICE',
  OVERVIEW = 'OVERVIEW',
  AFFILIATION = 'AFFILIATION',
  LICENSE = 'LICENSE',
  MORE_DETAILS = 'MORE_DETAILS',
  CPT = 'CPT'
}

export enum ProviderCardNavigation {
  AFFILIATION = 'AFFILIATION',
  LOCATION = 'LOCATION'
}
