import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { CommonUtil } from '../../fad/utilities/commonUtil';
import { BaseComponent } from '../components/base-component/baseCmp';
import { RIGHT } from '../constants/app-constants';
import { IFinalProvider } from '../interfaces/iCommonSearchResponse';
import { IReviewResponse } from '../interfaces/iSearchStrategyResponse';
import { EventHandler } from '../services/eventHandler';
import { SliderService } from '../services/sliderSvc';
import { AppSession } from '../values/appSession';
import { ContentHelper } from '../values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-ratings-slide-out-cmp',
  templateUrl: './ratingsSlideOutCmp.html'
})
export class RatingsSlideOutComponent extends BaseComponent implements OnInit {
  @ViewChild('viewRatingsSlider')
  ratingsSlider: TemplateRef<HTMLElement>;
  @Input()
  provider: IFinalProvider;
  @Input()
  index: number;
  @Input()
  showRatingOnSubscription: boolean;
  review: IReviewResponse;
  sidePanelRef: ModalRef<HTMLElement>;
  stars: string[] = [];
  totalReviews: string;
  vitalDetails: SafeResourceUrl;
  private count = 5;
  private filledStar = 'motif-icon motif-filled-star';
  private halfEmptyStar = 'motif-icon motif-filled-star motif-star-half-empty';
  private emptyStar = 'motif-icon motif-star';
  private _sliderSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _sanitizer: DomSanitizer,
    private _sliderService: SliderService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit(): void {
    if (this.waitUntilAppReload) {
      return;
    }

    this.review = this.provider?.reviewRatings?.length ? this.provider.reviewRatings[0] : undefined;
    this.totalReviews = !isNaN(Number(this.review?.totalReviews)) ? Number(this.review.totalReviews).toFixed(0) : undefined;
    let rating = !isNaN(Number(this.review?.starRating)) ? Number(this.review.starRating) : undefined;

    for (let i = 0; i < this.count; i++) {
      if (rating === .5) {
        this.stars.push(this.halfEmptyStar);
        rating--;
      } else if (rating > 0) {
        this.stars.push(this.filledStar);
        rating--;
      } else {
        this.stars.push(this.emptyStar);
      }
    }
    if (this.review?.pdtStrKey) {
      this.vitalDetails = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + this.review?.pdtStrKey + '/en');
    }
    if (this.showRatingOnSubscription) {
      this._sliderSubscription = this._sliderService.ratingSliderObs.subscribe((data) => {
        this.openSlider();
      });
    }
  }

  ngOnDestroy(): void {
    if (this._sliderSubscription) {
      this._sliderSubscription.unsubscribe();
    }
  }

  get tooltipContent(): string {
    if (this.review?.toolTip) {
      return this.review.toolTip.replace(/{DATE}/gi, this.review.lastReviewTime)
    }
    return '';
  }

  openSlider(): void {
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.ratingsSlider);
  }

  closeSlider(): void {
    this.sidePanelRef?.close();
  }

  showReviewRating(): boolean {
    if (this.provider?.reviewRatings?.length) {
      return !this.isGreyedOut() && CommonUtil.isReviewRating(this.provider?.reviewRatings[0], this.provider?.providerCategoryCode?.code, this.provider?.providerTypeCodeList);
    }
    return false;
  }

  isGreyedOut(): boolean {
    return !!(this.provider?.eycCostInfo?.isGreyedout || this.provider?.isFutureEffective);
  }
}
