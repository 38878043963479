import { Injectable } from '@angular/core';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { apiNameList } from './../../../environments/api-name-list';
import { HttpMethod } from './../enums/httpMethodEnum';
import { PublisherRequest } from './../models/publisherReq';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';
@Injectable({
  providedIn: 'root'
})
export class MessagingService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  postMessage(reqObj: PublisherRequest): Promise<any> {
    const apiUrl = apiNameList.restApi.messaging;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
