// Define the English content for the common components

import { PAGE_ALERTS_EN } from '../pageAlerts/pageAlert_en';

export const COMMON_CONTENT_EN = {
  alerts: {
    emailAddress: 'Please enter an email address.',
    invalidemailAddress: 'Please enter an valid email address.',
    emailSent: 'Email sent!',
    emailSending: 'Email Sending',
    emailFailed: 'There was a problem and the email was not sent.  Please try again later.'
  },
  labels: {
    btnCancel: 'Cancel',
    btnClose: 'Close',
    btnSent: 'Send',
    btnOk: 'OK',
    printPdf: 'Print page details',
    emailpdf: 'Email page details',
    emaillbl: "Enter Recipient's e-mail Address:",
    defaultEmailText: 'Enter email address',
    closeModal: ' button, select to close the modal',
    sendEmailModal: ' button, select to send the email',
    myPlan: 'My Plan'
  },
  pageHeader: {
    fadPageTitle: 'Find Care',
    viewAllLabel: 'View All',
    closeModal: 'button, select to close the modal',
    btnCancel: 'Cancel',
    btnContinue: 'Continue',
    btnSave: 'Save',
    lookUpInfo: 'Enter 3 or more characters to start a search. Use the up and down arrow keys to view search suggestions. ',
    btnClose: 'Close',
    btnOk: 'OK',
    tooltipInfo: "These family members aren't covered by the selected plan. Choose “Change Plan” to edit your plan selection.",
    freeTextSearch: {
      wishToTryBeta: 'Try Search Beta',
      returnToClassicSearch: 'Switch Back',
      searchTextHint: 'What kind of care are you looking for?',
      suggestionFailure: 'There was a problem. Please try again.',
      noSuggestionCriteriaFound: 'Please add more detail to your search.',
      searchExample: '<strong>Search Example</strong>: "I\'m looking for a female doctor to treat my migraines" ',
      moreExamples: {
        moreExamplesLink: 'More Examples',
        heading: 'Search Examples',
        examplesList: [
          {
            heading: 'Get as detailed as you want:',
            examples: [
              "I'm looking for a doctor.",
              "I'm looking for a female doctor.",
              "I'm looking for a female doctor to treat my migraines.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish and is accepting new patients.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish, is accepting new patients, and is able to serve as a PCP.",
              "I'm looking for a female doctor to treat my migraines who speaks Spanish, is accepting new patients, and is able to serve as a PCP, and is in Richmond, Virginia"
            ]
          },
          {
            heading: 'Other search examples:',
            examples: ['Orthopedic', 'Doctor John W.Smith', 'PCP', '<i>(Enter a 10-digit NPI number)</i> 1234567890', 'Doctor Miller in Los Angeles, CA']
          },
          {
            heading: 'Add a search location:',
            examples: ['Richmond, VA', 'Henrico County', 'Virginia', '23173']
          }
        ]
      },
      assistanceHelpText: 'Select to add a suggestion to your search. Use Enter/Return to search.',

      search: 'Search',
      accessibilityLabelforSearch: 'Use keyboard down arrow to navigate through suggestions and use enter key or space bar to select a suggestion. Or, press enter key now to search'
    },
    searchCriteria: {
      labels: {
        lookUpInfo: 'Enter 3 or more characters to start a search. Use the up and down arrow keys to view search suggestions. ',
        planUnknown: 'Plan/Network Unknown',
        searchAll: 'Search All',
        searchSpecialtyName: 'Search by doctor (name or specialty), hospital, procedure, and more',
        careTeamFor: 'Care Team for {NAME}',
        selectedMemberPlan: '{PLAN}',
        selectedPlan: '{PLAN}',
        findingCarePrefix: 'Finding Care in ID Number Prefix {{PREFIX}}',
        noResultsMsg: 'Nothing was found for "<span class="ant-font-b">{TERM}</span>". Please check your spelling or try a different search location.',
        defaultSearchMsg: 'We couldn\'t find an exact match for "<span class="ant-font-b">{TERM}</span>", Here are the closest matches.',
        search: 'Search by doctor (name or specialty),',
        specialties: 'Specialties',
        providerName: 'Provider Name',
        aoe: 'Area Of Expertise',
        procedures: 'By Procedure',
        officeService: 'Office Service',
        searchBy: 'Search by',
        searchSpecialtyProcedureName: 'Search by doctor (name or specialty), hospital, procedure, and more',
        searchLookUpBy: 'Search for care by {CATEGORY}',
        procedure: 'Procedure',
        specialty: 'specialty',
        county: 'County',
        serving: 'Serving',
        cities: 'Cities',
        counties: 'Counties',

        nationalProviderIdentifier: 'National Provider Identifier (NPI)',
        licenseNumber: 'License Number',
        name: 'name',
        showNotInNtwrkProvsLink: 'Search out-of-network care providers for',
        showMore: 'Show More',
        showLess: 'Show Less',
        seeAll: 'View All',
        showMoreInfo: 'show more link will list all the matches ',
        showLessInfo: 'show less link will list the top 5 ',
        ariaLabelForViewAllProviders: 'Click on View All for more Provider Names',
        cantFind: "Can't find what you're looking for?",
        needHelp: 'Need Help?',
        liveChat: 'Chat with a Live Agent',
        noResultsAlert: '<span class="ant-font-b">Nothing found for "{TERM}</span>". Need help?',
        ariaLabelForProviderSearch: 'select enter for procedure based provider search',
        ariaLabelForSpecialtySearch: 'select enter for specialty based provider search',
        ariaLabelForAreaOfExpertiseSearch: 'select enter for area of expertise based provider search',
        ariaLabelForOfficeServiceSearch: 'select enter for office service based provider search',
        ariaLabelForProviderDetails: 'select enter for provider details',
        miles: 'miles away',
        medicalCode: 'Medical Code',
        medicalCodeToolTipDesc:
          'Medical codes are used to help determine claim and payment amounts. Keep in mind that a medical procedure or service may include multiple codes. Searching by procedure instead of medical code will give you the best overall cost estimate.',
        planSelected: 'I want to search this plan/network:'
      },
      cptDataAnalytics: {
        medDropdownMenuSearch: 'medCodeHomeFindCare',
        expandMedCodeHomeFindCare: 'expandMedCodeHomeFindCare',
        collapseMedCodeHomeFindCare: 'collapseMedCodeHomeFindCare',
        showAllMedCodeHomeFindCare: 'showAllMedCodeHomeFindCare',
        showMoreMedCodeHomeFindCare: 'showMoreDropSearchHomeFindCare',
        showLessMedCodeHomeFindCare: 'showLessMedCodeHomeFindCare',
        infoIconMedCodeHomeFindCare: 'infoIconMedCodeHomeFindCare'
      },
      alerts: {
        zipcodeEmptyError: 'Please enter a location.',
        zipcodeError: 'We did not find that location. Please try again.',
        stateZipCodeError: 'State And Zip Code MissMatch',
        invalidError: 'Please enter a valid name.',
        networkUnknown:
          'Sorry, we are not able to retrieve information for one or more of your plan coverages. Please select from one of the available plan coverages being shown in order to complete your search.',
        networkUnknownMsg: "We're having a problem loading your plan/network information.",
        planSelectionMsg: 'Please search by selecting your plan.',
        memberPlanError: "We're having a problem loading your plan/network information. You can either try again later or ",
        memberPlanErrorLink: 'search by selecting your plan',
        primeGroupSearchMsg:
          'These search results are showing care providers in your medical group. If you want to search outside of your medical group, clear the "Medical Group or IPA" filter. You\'ll need a referral from your Primary Care Physician (PCP) before receiving non-emergency services.',
        clinicalPrograms: {
          TP_ATSM_SPCTRM_DSRD: {
            id: 'lnk-atsm-spctrm-dsrd',
            dataAnalytics: 'autismProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Receive support and resources for your family.',
            programName: 'Autism Spectrum Disorder Program'
          },
          TP_BH_HLTH_RSRC_CNTR: {
            id: 'lnk-bh-hlth-rsrc-cntr',
            dataAnalytics: 'behavioralProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: '24/7 support for emotional health issues.',
            programName: 'Behavioral Health Resource Management'
          },
          TP_CSMNGT: {
            id: 'lnk-csmngt',
            dataAnalytics: 'caseProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Care coordination and support after an illness.',
            programName: 'Case Management'
          },
          TP_CNDCR: {
            id: 'lnk-cndcr',
            dataAnalytics: 'conditionProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Support and tools to help manage your condition.',
            programName: 'ConditionCare'
          },
          TP_FTRMM: {
            id: 'lnk-ftrmm',
            dataAnalytics: 'futureProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Resources to help you have a healthy pregnancy.',
            programName: 'Future Moms'
          },
          TP_LHO_LC: {
            id: 'lnk-lho-lc',
            dataAnalytics: 'lactationProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Breastfeeding support from the comfort of home.',
            programName: 'Online Lactation Consultation'
          },
          TP_247NRSLN: {
            id: 'lnk-nrsln',
            dataAnalytics: 'nurselineProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Consult with a registered nurse around the clock.',
            programName: '24/7 Nurseline'
          },
          TP_SSPR_SSO: {
            id: 'lnk-smrtshopper',
            dataAnalytics: 'smartShopperProgramAlertFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'It pays to shop around for your care.',
            programName: 'Smart Shopper'
          },
          TP_BPG: {
            id: 'lnk-bpg',
            dataAnalytics: 'bpgProgramAlertFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Online program to help reduce back and joint pain.',
            programName: 'Back and Joint Pain Guide'
          },
          TP_WBC: {
            id: 'lnk-wbc',
            dataAnalytics: 'wellBeingProgramLinkFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: 'Your coach is here to guide, support, and inspire.',
            programName: 'Well Being Coach'
          },
          TP_DPP: {
            id: 'lnk-dpp',
            dataAnalytics: 'diabetesPreventLinkResultsFindCare',
            ariaLabel: 'Click here to navigate to {PROGRAM_NAME}',
            msgText: "Lark's virtual coaching greatly reduces your risk.",
            programName: 'Diabetes Prevention Program'
          }
        },
        infoListDisclaimers: {
          TP_HCS: [
            {
              id: 'hcs-label-text',
              displayText: 'Hearing Care Solutions manages some parts of your hearing benefit.',
              orderSequence: '1',
              ariaLabel: 'Hearing Care Solutions manages some parts of your hearing benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'hcs-link-text',
              displayText: 'Go to the {linkLabel} to find a hearing professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the Hearing Care Solutions website to find a hearing professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://hearingcaresolutions.com/anthem/',
              linkLabel: 'Hearing Care Solutions website'
            }
          ],
          TP_DENTAQUEST: [
            {
              id: 'dentaquest-label-text',
              displayText: 'DentaQuest manages some parts of your dental benefit.',
              orderSequence: '1',
              ariaLabel: 'DentaQuest manages some parts of your dental benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'dentaquest-link-text',
              displayText: 'Go to the {linkLabel} to find a dental professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the DentaQuest website to find a dental professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'http://www.dentaquest.com/members/',
              linkLabel: 'DentaQuest website'
            }
          ],
          TP_OHDENTAQUEST: [
            {
              id: 'ohdentaquest-label-text',
              displayText: 'DentaQuest manages some parts of your dental benefit.',
              orderSequence: '1',
              ariaLabel: 'DentaQuest manages some parts of your dental benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'ohdentaquest-link-text',
              displayText: 'Go to the {linkLabel} to find a dental professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the DentaQuest website to find a dental professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://dentaquest.com/state-plans/regions/ohio/member-page/find-a-dentist/',
              linkLabel: 'DentaQuest website'
            }
          ],
          TP_LIBERTY: [
            {
              id: 'liberty-label-text',
              displayText: 'Liberty Dental manages some parts of your dental benefit.',
              orderSequence: '1',
              ariaLabel: 'Liberty Dental manages some parts of your dental benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'liberty-link-text',
              displayText: 'Go to the {linkLabel} to find a dental professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the Liberty Dental website to find a dental professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://client.libertydentalplan.com/anthem/FindADentist',
              linkLabel: 'Liberty Dental website'
            }
          ],
          TP_MAGELLAN_PHARMACY: [
            {
              id: 'pharmacy-label-text',
              displayText: 'Magellan manages some parts of your Pharmacy benefit.',
              orderSequence: '1',
              ariaLabel: 'Magellan manages some parts of your Pharmacy benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'pharmacy-link-text',
              displayText: 'Go to the {linkLabel} to find a pharmacy in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the pharmcy website to find a pharmacy in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.lamcopbmpharmacy.com',
              linkLabel: 'Magellan website'
            }
          ],
          TP_TN_DENTAQUEST: [
            {
              id: 'tndentaquest-label-text',
              displayText: 'Some of your 2022 dental benefits are managed by DentaQuest. ',
              orderSequence: '1',
              ariaLabel: 'Some of your 2022 dental benefits are managed by DentaQuest.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'tndentaquest-link-text',
              displayText: 'To find a dental professional in your 2022 dental plan, go to the {linkLabel}',
              orderSequence: '2',
              ariaLabel: 'To find a dental professional in your 2022 dental plan, go to the DentaQuest website',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'http://www.dentaquest.com/members/',
              linkLabel: 'DentaQuest website'
            }
          ],
          TP_TN_LIBERTY: [
            {
              id: 'tnliberty-label-text',
              displayText: 'Some of your 2023 dental benefits will be managed by Liberty Dental. ',
              orderSequence: '1',
              ariaLabel: 'Some of your 2023 dental benefits will be managed by Liberty Dental.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'tnliberty-link-text',
              displayText: 'To find a dental professional in your 2023 dental plan, go to the {linkLabel}.',
              orderSequence: '2',
              ariaLabel: 'To find a dental professional in your 2023 dental plan, go to the Liberty Dental website.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://client.libertydentalplan.com/anthem/FindADentist',
              linkLabel: 'Liberty Dental website'
            }
          ],
          TP_VSP: [
            {
              id: 'vsp-label-text',
              displayText: 'VSP manages some parts of your vision benefit.',
              orderSequence: '1',
              ariaLabel: 'VSP manages some parts of your vision benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'vsp-link-text',
              displayText: 'Go to the {linkLabel} to find a vision professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the VSP website to find a vision professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.vsp.com/find-eye-doctors.html?id=medicaid',
              linkLabel: 'VSP website'
            }
          ],
          TP_EYEQUEST: [
            {
              id: 'eyequest-label-text',
              displayText: 'EyeQuest manages some parts of your vision benefit.',
              orderSequence: '1',
              ariaLabel: 'EyeQuest manages some parts of your vision benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'eyequest-link-text',
              displayText: 'Go to the {linkLabel} to find a vision professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the EyeQuest website to find a vision professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://govservices10.dentaquest.com/Router.jsp?component=Main&source=Logon&action=ProviderDirectory',
              linkLabel: 'EyeQuest website'
            }
          ],
          TP_SUPERIOR: [
            {
              id: 'superior-label-text',
              displayText: 'Superior manages some parts of your vision benefit.',
              orderSequence: '1',
              ariaLabel: 'Superior manages some parts of your vision benefit.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'superior-link-text',
              displayText: 'Go to the {linkLabel} to find a vision professional in your network.',
              orderSequence: '2',
              ariaLabel: 'Go to the Superior website to find a vision professional in your network.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://superiorvision.com/',
              linkLabel: 'Superior website'
            }
          ],
          TP_GA_Termination_Providers: [
            {
              id: 'termination-Providers-link-text',
              displayText: 'Please review this list of {linkLabel} during the upcoming plan year.',
              orderSequence: '1',
              ariaLabel: 'Please review this list of care providers who may leave your plan network during the upcoming plan year.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: '/assets/documents/gaterminatingproviders.pdf',
              linkLabel: 'care providers who may leave your plan network',
              linkDataAnalyticsTag: 'providersLeftNetworkAlertFindCare',
              mobileLinkDataAnalyticsTag: 'PROVIDERS_LEFT_NETWORK_ALERT_FIND_CARE'
            }
          ],
          TP_SUPPRESSED_MEDSUPP: [
            {
              id: 'suppressed-medsupp-alert-label-text',
              displayText: 'To find a medical care provider that accepts Medicare Assignment (one that accepts the Medicare-approved amount as full payment) go to {linkLabel}.',
              orderSequence: '1',
              ariaLabel: 'To find a medical care provider that accepts Medicare Assignment (one that accepts the Medicare-approved amount as full payment) go to medicare.gov/care-compare.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.medicare.gov/care-compare/',
              linkLabel: 'medicare.gov/care-compare'
            }
          ],
          TP_PrimeGroupSearchMsg: [
            {
              id: 'PrimeGroupSearchMsg-label-text',
              displayText:
                "These search results show care providers in your medical group. If you want to search outside your medical group, select &quot;No Preference&quot; in the Your Medical Group filter. You'll need a referral from your Primary Care Physician before receiving non-emergency services."
            }
          ]
        },
        costForInitialVisit: {
          msgText: "The estimated costs we're showing are for an initial visit. To view detailed benefit information for this procedure, including any service limits, go to {BENEFITS_LINK}.",
          linkText: 'Find Benefits',
          linkUrl: '/member/benefits?covtype=med',
          ariaLabel: 'Click here to navigate to Find Benefits'
        },
        costForMoreThanOneVisit: {
          msgText: "The estimated costs we're showing are for visits 1-{VISITS_VALUE}.  To view coverage information for this procedure, including service limits, go to {BENEFITS_LINK}.",
          linkText: 'Find Benefits',
          linkUrl: '/member/benefits?covtype=med',
          ariaLabel: 'Click here to navigate to Find Benefits'
        },

        yourMedicalGroupSearchMsg:
          'These search results show care providers in your medical group.  If you want to search outside your medical group, select "No Preference" in the Your Medical Group filter. You\'ll need a referral from your Primary Care Physician before receiving non-emergency services.',
        yourMedicalHealthSystemMsg:
          'These search results show care providers in your medical group. To search outside your medical group, choose another option in the "Your Health System" filter. However, if you seek non-emergency care outside your medical group, you will need a referral from your PCP.'
      },
      homeHealthCareContent: {
        url: 'https://www.mynexuscare.com/providerdirectory',
        publicHeader: 'Sorry, we can’t complete your search',
        publicContent: 'For immediate assistance finding Home Health Care, please call the Member Services number on your member ID card.',
        secureHeader: 'Home Health Care Services',
        secureContent: 'We partner with myNEXUS to help manage your Home Health Care services. To find a Home Health Care service provider, use the myNEXUS Provider Finder.',
        Ok: 'Ok',
        ariaLabel: 'Ok button, click here to close the carelon modal'
      },
      homeHealthCareSecureContentInfo: {
        MY_NEXUS_SECURE_CONTENT: [
          {
            id: 'carelon-label-text',
            orderSequence: '1',
            displayText: 'We partner with Carelon Post Acute Solutions to help manage home healthcare services. ',
            ariaLabel: 'We partner with Carelon Post Acute Solutions to help manage home healthcare services.',
            isAnchorText: false,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: '',
            linkLabel: '',
            targetAttribute: ''
          },
          {
            id: 'carelon-care-link-text',
            orderSequence: '2',
            displayText: 'Learn more by {linkLabel} or calling 844-411-9622. ',
            ariaLabel: 'Learn more by visiting their website or calling 844-411-9622.',
            isAnchorText: true,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: 'https://mynexuscare.com',
            linkLabel: 'visiting their website',
            targetAttribute: '_blank'
          },
          {
            id: 'carelon-provider-link-text',
            orderSequence: '3',
            displayText: '{linkLabel}.',
            ariaLabel: 'Find home healthcare services.',
            isAnchorText: true,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: 'https://mynexuscare.com/providerdirectory',
            linkLabel: 'Find home healthcare services',
            targetAttribute: '_blank'
          }
        ]
      },
      transportationContent: {
        header: 'Transportation',
        prefixBasedHeader: 'Transportation (Non-Emergency)',
        evidenceCoverageText: "Please refer to your plan's Evidence of Coverage for detailed benefit information.",
        medicalAppointmentText: 'Please call the following number(s) to schedule your transportation for medical appointments:',
        prefixBasedMedicalAppointmentText: 'Call the following number to schedule transportation for medical appointments:',
        countiesText: 'Counties of service: ',
        reservationsText: 'Reservations: ',
        rideAssistText: 'Ride Assist: ',
        ttyText: 'TTY: ',
        contactUsSecure: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/member/contact-us">Member Services.</a>',
        contactUsPublic: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/contact-us">Member Services.</a>',
        close: 'Close',
        print: 'Print',
        tryAgain: 'Try Again',
        goBack: 'Go Back',
        ariaClose: 'Close button, click here to close the transportation modal',
        ariaPrint: 'Print, click here to print the transportation details'
      },
      precareSearchErrorMessages: {
        genericMessage: {
          header: 'Unable to Complete Your Search',
          message: [
            {
              id: 'deeplink_negative_msg',
              orderSequence: '1',
              displayText: "For some reason, we're unable to complete your search. Please {linkLabel} if you have questions about your benefits and coverage.",
              isAnchorText: true,
              inAppNavigation: true,
              mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
              navigationUrl: '/member/contact-us',
              linkLabel: 'contact us',
              targetAttribute: '_self',
              ariaLabel: "For some reason, we're unable to complete your search. Please contact us if you have questions about your benefits and coverage."
            }
          ],
          close: 'Close',
          closeAriaLabel: 'Close button, select to close the modal'
        }
      }
    },
    updateLocationWrapper: {
      locationButton: 'Update Location',
      searchLocation: 'Search Location',
      currentLocation: {
        formHeader: 'Current Location',
        alertMsg: "Note: If you're using a VPN, your current location will be the same as the VPN you're connected to.",
        formFields: {
          city: {
            label: 'City, State',
            placeholder: 'Enter City'
          },
          zipcode: {
            label: 'Zip Code',
            placeholder: 'Enter ZIP code'
          }
        }
      },
      otherAddress: {
        formHeader: 'Other Address',
        formFields: {
          streetAddress: {
            label: 'Street Address',
            placeholder: 'Enter street address'
          },
          streetAddress2: {
            label: 'Street Address Line 2 (Optional)',
            placeholder: 'Enter street address'
          },
          zipcodeOnly: {
            label: 'Zip Code',
            placeholder: 'Enter ZIP code'
          },
          cityOrCounty: {
            label: 'City, State',
            placeholder: 'Enter City'
          }
        }
      },
      homeAddress: {
        label: 'Home Address'
      },
      zipcode: {
        label: 'Zip Code, City or County',
        placeholder: 'City, County, or ZIP'
      },
      continue: 'Continue to Find Care',
      cancel: 'Cancel',
      errorMessage: {
        zipCodeError: 'We didn’t find Zip code. Please try again.',
        emptyZipError: 'Please enter a zip code, city, or county.',
        emptyZipOnlyError: 'Please enter a ZIP code.',
        addressError: 'Please enter the valid address line.',
        pcpError: 'This plan is not available for the address entered.',
        currentLocationError: "Geo Code search can't be done for this location.",
        currentLocationNotEnabled: 'To continue this search Geo location has to been enabled.',
        addessMismatch: "That ZIP code isn't located in that state.Please check the state and ZIP code and try again.",
        emptyCityOrCountyError: 'Please enter city, or state.',
        cityOrCountyError: 'Please enter a valid city or state.'
      },
      addressTypes: {
        homeAddressDisclaimer: 'Search based on your home address',
        workAddressDisclaimer: 'Search based on your work address',
        zipCodeDisclaimer: 'Search based on zipcode',
        changeLocation: 'Search by address',
        hintText: '.',
        addressSection: {
          home: {
            code: 'HOME',
            label: 'Home Address'
          },
          work: {
            code: 'WORK',
            label: 'Work Address'
          },
          enter: {
            code: 'enter',
            label: 'enter Address'
          },
          update: {
            code: 'update',
            label: 'Update Home or Work Address'
          }
        },
        customSection: {
          heading: 'Find Other Types of Care',
          subHeading: 'For all other types of searches, enter  ZIP.',
          customZip: {
            label: 'Enter Address',
            code: 'CUSTOMZIP'
          }
        }
      }
    },
    carePlanWrapper: {
      planNetworks: 'Plan Networks',
      changePlanPublic: 'Change Plan',
      planStart: ' (Starting {DATE})',
      changePlanSecure: 'Change Plan or Member',
      changePlanPublicMsg: 'Change Plan Networks',
      changeMember: 'Change Member',
      selectedMemberPlans: "Finding Care in All of <span>{NAME}</span>'s",
      careTeamPlans: 'Viewing Care Team for <span>{NAME}</span> in',
      selectedMemberPlan: '{PLAN}',
      selectedPlan: '{PLAN}',
      customZip: {
        label: 'Enter Address',
        code: 'CUSTOMZIP'
      },
      planNetworksToutHeader: 'The following plan networks are displayed in the search results.',
      closeModal: 'button, select to close the modal',
      planLabelMsg: 'Which plan Network?',
      careLabel: 'What type of care are you searching for?',
      memberLabel: 'Who is this care for?',
      planNetworkLabel: 'Your {{NETWORK}} Plan Network',
      alerts: {
        coverageDetailErrorMsg: 'Selected member dosent have any coverage. So please select another member'
      },
      changePlanLabel: {
        choosePlan: 'Choose a Plan',
        choosePlanTitle: 'Some family members may have different plans.Selecting a different plan will change which members you can search for.',
        includes: 'Includes:',
        eligibleMembers: 'Eligible Members:'
      }
    }
  },
  searchHeader: {
    headerTitle: 'Find Care',
    headerTitleSuffix: 'for',
    changePlan: 'Change Plan',
    selectFamilyMember: 'Select a Family Member',
    contractChangeSuccess: 'Success! Your plan selection has changed.',
    startOver: 'Start Over'
  },
  pageTitle: {
    searchCriteria: 'Find Care Criteria',
    searchResults: 'Find Care Results',
    details: 'Find Care Provider Details'
  },
  actionItems: {
    actionItemButton: 'select to {ACTION}'
  },
  hideQuickLinkShopForProcedure: {
    iAmInterested: {
      name: "I'm Interested",
      header: 'Thanks For Letting Us Know',
      description: "We'll use your feedback to improve our system and show you more of what might be helpful. Please let us know why.",
      dataAnalytics: {
        sendFeedbackIntModalFindCare: 'sendFeedbackIntModalFindCare',
        cancelFeedbackIntModalFindCare: 'cancelFeedbackIntModalFindCare',
        backFeedbackIntModalFindCare: 'backFeedbackIntModalFindCare',
        interestedOptionFindCare: 'interestedOptionFindCare',
        interestedOptionShopProcFindCare: 'interestedOptionShopProcFindCare'
      }
    },
    notInterested: {
      name: 'Not Interested',
      header: "Tell Us Why You're Not Interested",
      description:
        "We'll use your feedback to improve our system and show you more of what might be helpful. Depending on your feedback, we'll remove this button from your Find Care experience for a period of time.  If you need to search for something that's been removed, please use the Find Care search bar.",
      dataAnalytics: {
        sendFeedbackNotIntModalFindCare: 'sendFeedbackNotIntModalFindCare',
        cancelFeedbackNotIntModalFindCare: 'cancelFeedbackNotIntModalFindCare',
        backFeedbackNotIntModalFindCare: 'backFeedbackNotIntModalFindCare',
        notInterestedOptionFindCare: 'notInterestedOptionFindCare',
        notInterestedOptionShopProcFindCare: 'notInterestedOptionShopProcFindCare'
      }
    },
    cancel: 'Cancel',
    sendFeedback: 'Send Feedback',
    other: 'Other',
    closeModal: ' button, select to close the modal',
    otherTextBoxPlaceholder: 'Enter reason'
  },
  newLandingComponent: {
    labels: {
      TP_PROMOTED_PROCEDURE: "{MESSAGE}<br><a href='javascript:void(0);' target='{TARGET}' aria-label='{ARIA-LABEL}' data-analytics='{DATA-ANALYTICS}' class='{CATEGORY}'>{LABEL}</a>"
    }
  },
  locationFormComponent: {
    formHeader: 'Address for Primary Care Search',
    formHeaderMessage:
      "When searching for a primary care provider, we'll need to use your home or work address as a starting point. (You'll be able to search for other types of care based on any location.)",
    homeAddress: 'Home Address',
    workAddress: 'Work Address (optional)',
    streetAddress: { label: 'Street Address', placeholder: 'Enter street address' },
    streetAddress2: { label: 'Street Address Line 2 (optional)', placeholder: 'Enter street address' },
    city: { label: 'City', placeholder: 'Enter city' },
    state: { label: 'State', placeholder: 'Select state' },
    zipcode: { label: 'ZIP Code', placeholder: 'Enter ZIP code' },
    continue: 'Continue to Find Care',
    cancel: 'Cancel',
    errorMessage: { zipCodeError: 'Please Check the Address', pcpError: 'This plan is not available for the address entered.' }
  },
  appErrorMsg: {
    sessionExpired: 'Sorry, your session might have expired. Please refresh the page and try again.',
    defaultErrorMsg: "Sorry, we're experiencing technical difficulties at this time. Please try again later.",
    ErrorMsg: "We're having trouble finding that address. Please recheck the address you entered and try again."
  },
  leavingDisclaimerComponent: {
    closeBtnText: 'Close',
    continueBtnText: 'Continue',
    header: 'You are leaving {BRAND}.com',
    content1:
      "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
    content2:
      'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.',
    btnClose: 'Return to {BRAND}.com',
    btnContinue: 'Continue',
    externalSite: 'button, select to navigate to external site',
    closeModal: 'button, select to close the modal',
    VEP: {
      header: 'Looking for a doctor or hospital in the Anthem Alliance EPO Network ?',
      content1: 'Click the medical providers button to  search for medical providers.',
      content2: 'Click the behavioral health providers button to search for behavioral health providers.',
      content3:
        'For behavioral health services, your product is supported by the Mercy Managed Behavioral Health (MMBH) network. By selecting “behavioral health”, you will be leaving Anthem’s site and linking to MMBH’s website; and you acknowledge that Anthem does not control, guarantee, or approve the information available on the external site or the security or transmission between you and the external site',
      btnClose: 'Medical Providers',
      btnContinue: 'Behavioral Health Providers'
    }
  },
  medicareSupplementContent: {
    heading: 'Important Information for Medicare Supplement Insurance customers',
    description:
      'To find providers that accept Medicare Assignment (those providers that accept the Medicare-approved amount as full payment), you will need to visit the Physician Compare tool at Medicare.gov.',
    btnCancel: 'Cancel',
    btnContinue: 'Continue',
    redirect: 'Go to medicare Provider Finder',
    url: 'https://www.medicare.gov/'
  },
  pageAlerts: {
    ...PAGE_ALERTS_EN
  },
  pageAlertLinks: {
    eyeQuestVision: 'EyeQuest manages some parts of your vision benefit. Use the Search button to go to the EyeQuest website to find a vision professional in your network.',
    eyeQuestVisionUrl: 'https://govservices10.dentaquest.com/Router.jsp?component=Main&source=Logon&action=ProviderDirectory',
    superiorVision: 'Superior manages some parts of your vision benefit. Use the Search button to go to the Superior website to find a vision professional in your network.',
    superiorVisionUrl: 'https://www.superiorvision.com',
    eyeMedVision: 'EyeMed manages some parts of your vision benefit. Go to the EyeMed website to find a vision professional in your network.',
    eyeMedVisionUrl: 'https://eyedoclocator.eyemedvisioncare.com/ahkplus/?networkDDDisabled=true',
    oheyeMedVisionUrl: 'https://eyedoclocator.eyemedvisioncare.com/anthemohmd',
    marchVision: 'March Vision Care manages some parts of your vision benefit. Go to the March Vision Care website to find a vision professional in your network.',
    marchVisionUrl: 'https://www.marchvisioncare.com/',
    avesisUrl: 'https://www.fap.avesis.com/amerigroupga/provider/search',
    marylandUrl: 'https://mdmwp.sciondental.com/MWP/Landing',
    navitusPdfUrl: 'https://www.navitus.com/getmedia/25f6cef8-46f9-4d0b-9bd5-7ae8a4a13150/Navitus-Pharmacy-Network-Listing-National-and-Chains.pdf',
    kyPortalUrl: 'http://kyportal.medimpact.com/',
    HCS_URL: 'https://hearingcaresolutions.com/anthem/',
    LIBERTY_DENTAL_URL: 'https://client.libertydentalplan.com/anthem/FindADentist',
    DENTAL_QUEST_URL: 'http://www.dentaquest.com/members/',
    DENTAL_VSP_URL: 'https://www.vsp.com/find-eye-doctors.html?id:medicaid',
    VEP_BEH_HEALTH: 'http://www.mbh-eap.com/pd/'
  },
  pageAlertComponentLabels: {
    notice: 'Notices',
    view: 'View',
    importantNotices: 'Important Notices',
    close: 'Close'
  },
  modals: {
    MD_TRANSPORTATION: {
      title: 'Transportation',
      content: {
        header: 'Transportation',
        prefixBasedHeader: 'Transportation (Non-Emergency)',
        evidenceCoverageText: "Please refer to your plan's Evidence of Coverage for detailed benefit information.",
        medicalAppointmentText: 'Please call the following number(s) to schedule your transportation for medical appointments:',
        prefixBasedMedicalAppointmentText: 'Call the following number to schedule transportation for medical appointments:',
        countiesText: 'Counties of service: ',
        reservationsText: 'Reservations: ',
        rideAssistText: 'Ride Assist: ',
        ttyText: 'TTY: ',
        contactUsSecure: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/member/contact-us">Member Services.</a>',
        contactUsPublic: 'Additional transportation benefits are available to you through Medi-Cal. To learn more, contact <a target="_blank" href="/contact-us">Member Services.</a>',
        close: 'Close',
        print: 'Print',
        tryAgain: 'Try Again',
        goBack: 'Go Back',
        ariaClose: 'Close button, click here to close the transportation modal',
        ariaPrint: 'Print, click here to print the transportation details'
      }
    },
    MD_MY_NEXUS_SECURE: {
      title: 'Home Health Care Services',
      content: {
        body: '<span id="carelon-label-text" class="pf-pre-wrap pf-inline-span"><span id="carelon-label-text" aria-label="We partner with Carelon Post Acute Solutions to help manage home healthcare services.">We partner with Carelon Post Acute Solutions to help manage home healthcare services. </span><span id="carelon-care-link-text" aria-label="Learn more by visiting their website or calling 844-411-9622.">Learn more by <a target="_blank" aria-label=" visiting their website" id="carelon-care-link-text-anchor" href="https://www.careloninsights.com/medical-benefits-management/post-acute-care">visiting their website</a> or calling 844-411-9622. </span><span id="carelon-provider-link-text" aria-label="Find home healthcare services."><a target="_blank" aria-label=" Find home healthcare services" id="carelon-provider-link-text-anchor" href="https://providers.carelonmedicalbenefitsmanagement.com/postacute/finder/">Find home healthcare services</a>.</span></span>',
        Ok: 'Ok',
        ariaLabel: 'Ok button, click here to close the carelon modal'
      }
    },
    MD_MY_NEXUS_PUBLIC: {
      title: 'Sorry, we can’t complete your search',
      content: {
        body: '<span>For immediate assistance finding Home Health Care, please call the Member Services number on your member ID card.</span>',
        Ok: 'Ok',
        ariaLabel: 'Ok button, click here to close the carelon modal'
      }
    },
    MD_MEDICARE_SUPPLEMENT: {
      title: 'Important Information for Medicare Supplement Insurance customers',
      content: {
        closeBtnText: 'button, click here to close the modal',
        redirectBtnText: 'button, click here will redirect to',
        description:
          'To find providers that accept Medicare Assignment (those providers that accept the Medicare-approved amount as full payment), you will need to visit the Physician Compare tool at Medicare.gov.',
        btnCancel: 'Cancel',
        btnContinue: 'Continue',
        redirect: 'Go to medicare Provider Finder',
        url: 'https://www.medicare.gov/',
        disclaimer1:
          "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
        disclaimer2:
          'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.'
      }
    },
    MD_LEAVING_SITE: {
      title: 'You are leaving {BRAND}.com',
      content: {
        closeBtnText: 'Close',
        continueBtnText: 'Continue',
        disclaimer1:
          "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
        disclaimer2:
          'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.',
        btnClose: 'Return to {BRAND}.com',
        btnContinue: 'Continue',
        externalSite: 'button, select to navigate to external site',
        closeModal: 'button, select to close the modal'
      }
    },
    MD_LEAVING_SITE_VEP: {
      title: 'Looking for a doctor or hospital in the Anthem Alliance EPO Network ?',
      content: {
        discalimer1: 'Click the medical providers button to  search for medical providers.',
        disclaimer2: 'Click the behavioral health providers button to search for behavioral health providers.',
        disclaimer3:
          'For behavioral health services, your product is supported by the Mercy Managed Behavioral Health (MMBH) network. By selecting “behavioral health”, you will be leaving Anthem’s site and linking to MMBH’s website; and you acknowledge that Anthem does not control, guarantee, or approve the information available on the external site or the security or transmission between you and the external site',
        closeBtnText: 'Medical Providers',
        continueBtnText: 'Behavioral Health Providers'
      }
    }
  },
  sidePanels: {
    MD_MEDICARE_SUPPLEMENT: {
      title: 'Important Information for Medicare Supplement Insurance customers',
      content: {
        closeBtnText: 'button, click here to close the modal',
        redirectBtnText: 'button, click here will redirect to',
        description:
          'To find providers that accept Medicare Assignment (those providers that accept the Medicare-approved amount as full payment), you will need to visit the Physician Compare tool at Medicare.gov.',
        btnCancel: 'Cancel',
        btnContinue: 'Continue',
        redirect: 'Go to medicare Provider Finder',
        url: 'https://www.medicare.gov/',
        disclaimer1:
          "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
        disclaimer2:
          'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.'
      }
    },
    MD_LEAVING_SITE: {
      title: 'You are leaving {BRAND}.com',
      content: {
        closeBtnText: 'Close',
        continueBtnText: 'Continue',
        disclaimer1:
          "By selecting the 'Continue' button below, you will be leaving {BRAND}'s site and linking to a site created and/or maintained by another entity ('External Site') and you acknowledge that {BRAND} does not control, guarantee, endorse or approve the information, products or services available at the External Site or the security of transmissions between you and the External Site.",
        disclaimer2:
          'Upon linking you are subject to the terms of use, privacy, copyright and security policies of the External Site. {BRAND} provides these links solely for your information and convenience. Members: Your health plan may not cover all the services or products described on the External Site; please refer to your benefit booklet. The information contained on the External Site should not be interpreted as medical advice or treatment.',
        btnClose: 'Return to {BRAND}.com',
        btnContinue: 'Continue',
        externalSite: 'button, select to navigate to external site',
        closeModal: 'button, select to close the modal'
      }
    },
    MD_LEAVING_SITE_VEP: {
      title: 'Looking for a doctor or hospital in the Anthem Alliance EPO Network ?',
      content: {
        discalimer1: 'Click the medical providers button to  search for medical providers.',
        disclaimer2: 'Click the behavioral health providers button to search for behavioral health providers.',
        disclaimer3:
          'For behavioral health services, your product is supported by the Mercy Managed Behavioral Health (MMBH) network. By selecting “behavioral health”, you will be leaving Anthem’s site and linking to MMBH’s website; and you acknowledge that Anthem does not control, guarantee, or approve the information available on the external site or the security or transmission between you and the external site',
        closeBtnText: 'Medical Providers',
        continueBtnText: 'Behavioral Health Providers'
      }
    }
  }
};

//  Define a type based on the structure of the English content
export type CommonContent = typeof COMMON_CONTENT_EN;
