import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { IDetailsParameters, IOutputFlags } from '../../provider-details/models/iDetailsParameters';
import { IPlanList } from '../../provider-details/models/iPlanList';
import { IMapRequest } from '../../search-results/interfaces/iMapRequest';
import { ICompareProvider, ICostInfo, IEycAffliation, IProvider } from '../../search-results/interfaces/iSummaryResp';
import { providerOutputFlags } from '../../search-results/values/providerSearchConstants';
import { ICompareRequest } from '../models/iCompareRequest';
import { ProviderCompareService } from '../services/ProviderCompareSvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { PFMapComponent } from './../../../common/components/map/pfMapModule';
import { NETWORK_TYPECODE } from './../../../common/constants/app-constants';
import { PhonePatternPipe } from './../../../common/pipes/phonePatternPipe';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ProviderDetailsNavigationService } from './../../provider-details/services/providerDetailsNavigationSvc';
import { PROVIDER_TYPE } from './../../search-providers/values/providerSearchConstants';

@Component({
  moduleId: module.id,
  selector: 'app-fad-compare-providers-cmp',
  templateUrl: '../views/pfCompareCmp.html'
})
export class PFCompareComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input()
  compareProviders: any;
  @Output()
  openDrivingDirectionModal: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openReview: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openDomain: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('mapComponent')
  mapComponent: PFMapComponent;
  @ViewChild('screenreaderText')
  screenreaderText: ElementRef;
  @Output()
  openAward: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openTelehealth: EventEmitter<object> = new EventEmitter<object>();
  showEyc: boolean = false;
  showCompareError: boolean;
  showCompareLoader: boolean = true;
  showPlansLoader: boolean = true;
  showAffiliationLoader: boolean = true;
  compareResponse: any;
  compareAffilation: Array<IProvider>;
  compareVisibility: any;
  officeFeatures: any;
  toggleMapBool: boolean = false;
  showMapCmp: boolean = false;
  isShowDistance: boolean = true;
  mapData: IMapRequest[] = [];
  showBeFirstToReview: boolean = true;
  vitalsSsoConfig: any;
  networkTypeConst: any = NETWORK_TYPECODE;
  showMoreLink: boolean = false;
  officeFeatureLabels = [];
  constructor(
    public providerCompareService: ProviderCompareService,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _appUtility: AppUtility,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appUtility.scrollToTop();
    this.showBeFirstToReview = CommonUtil.isMemberSecure(this._appSession);
    if (this._appSession && this._appSession.searchParams) {
      this.isShowDistance =
        this._appSession &&
        this._appSession.searchParams &&
        this._appSession.searchParams.coordinates &&
        ((this._appSession.searchParams.coordinates.latitude === '0' && this._appSession.searchParams.coordinates.longitude === '0') ||
          (this._appSession &&
            this._appSession.searchParams &&
            typeof this._appSession.searchParams.coordinates.latitude === 'undefined' &&
            typeof this._appSession.searchParams.coordinates.longitude === 'undefined'))
          ? false
          : true;
    }
    this.compareAffilation = [...this.compareProviders];
    this.getCompareDetails();
  }

  ngAfterViewInit() {
    if (this.screenreaderText && this.screenreaderText.nativeElement) {
      this.screenreaderText.nativeElement.focus();
    }
  }

  get isDentalSearch(): boolean {
    return (
      this._appSession.hasDentalEYC &&
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
    );
  }

  getCompareDetails() {
    this.providerCompareService.getCompare(this.buildCompareRequest()).then(
      (result: any) => {
        this.onCompareResponseSuccess(result);
      },
      (error: any) => {
        try {
          this.onCompareResponseError(error);
        } catch (e) {}
      }
    );
  }

  buildCompareRequest() {
    const req = {} as ICompareRequest;
    req.providerDetail = [];
    const plan: IPlanList = {
      identifier: this._appSession.searchParams.plan.identifier,
      isNationalPlan: this._appSession.searchParams.plan.isNationalPlan
    };
    const outputFlags: IOutputFlags = {
      includeTaxonomy: true,
      includeVisibilityRules: providerOutputFlags.visibilityRulesFlag,
      includeReviewRatings: providerOutputFlags.reviewRatingsFlag,
      includePrimeGroupSearch: providerOutputFlags.includePrimeGroupSearch,
      imposeAddressConsolidation: providerOutputFlags.imposeAddressConsolidation,
      improveSearchCriteria: providerOutputFlags.improveSearchCriteria,
      includeSpfInfo: providerOutputFlags.includeSpfInfo,
      includeProviderNetworks: providerOutputFlags.includeProviderNetworks
    };

    if (!this._appSession.isEyc) {
      outputFlags.includeAllNetworks = (this._appSession.hasStandaloneDentalContract || this._appSession.hasStandaloneVisionContract) && this._appSession.hasMedicalContracts;
    }

    const netwrkList: any[] = [];
    for (const network of this._appSession.searchParams.plan.networkList || []) {
      const net = {
        code: network
      };
      netwrkList.push(net);
    }
    req.outputFlags = outputFlags;
    (this.compareProviders || []).forEach((provider) => {
      const request: IDetailsParameters = {
        providerIdentifier: provider.providerIdentifier,
        addressIdentifier: provider.location && provider.location.address ? provider.location.address.addressId : '',
        brandCode: this._appSession.metaData.brandCd,
        state: undefined,
        distance: this._appSession.searchParams.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
        planStateCode: this._appSession.searchParams.plan.stateCd,
        rxChoiceNtwkIndicator: '',
        specialtyCategoryList:
          this._appSession.searchParams.specialtySelectNm && this._appSession.searchParams.specialtySelectNm.length > 0
            ? this._appSession.searchParams.specialtySelectNm.filter((x) => x !== '00')
            : null,
        networkList: netwrkList,
        alphaPrefix: CommonUtil.getAlphaPrefix(this._appSession),
        location: {
          latitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.latitude : '',
          longitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.longitude : ''
        },
        planList: new Array(plan),
        locale: (this._appSession.metaData && this._appSession.metaData.locale && this._appSession.metaData.locale.toString()) || ''
      };
      if (provider.providerTypeCodeList) {
        request.providerTypeCodeList = provider.providerTypeCodeList;
      }

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid && !this._appSession.isPlanUnknown) {
        request.mbrUid = this._appSession.metaData.appContract.mbrUid;
      }

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid && !this._appSession.isPlanUnknown) {
        request.contractUid = this._appSession.metaData.appContract.contractUid;
      }

      // Add aditional parameters for CVS Pharmacy API.
      if ((request.providerTypeCodeList || []).includes(PROVIDER_TYPE.PHARMACY)) {
        request.pharmacyName = provider.providerName;
        request.pharmacyNumber = provider.providerIdentifier;
        request.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;

        if (this._appSession.searchParams.stateCd && this._appSession.searchParams.stateCd.code) {
          request.state = this._appSession.searchParams.stateCd.code;
        }
        if (this._appSession.searchParams.zip) {
          request.zipCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
        }
        if (this._appSession.searchParams.city) {
          request.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
        }
      }
      // Assigning isFutureCoverage for details request when a future plan is selected
      const hasHcid = this._appSession && this._appSession.searchParams && this._appSession.searchParams.identificationNumber && this._appSession.searchParams.identificationNumber !== '';
      if ((CommonUtil.isMemberSecure(this._appSession) || hasHcid) && this._appSession.isFutureCoverage) {
        request.isFutureCoverage = this._appSession.isFutureCoverage;
      }
      request.isShipDirectToDoor = provider?.isShipDirectToDoor;
      request.pdtKey = provider.pdtKey;
      req.providerDetail.push(request);
    });
    return req;
  }

  onCompareResponseSuccess(result: any) {
    this.showCompareLoader = false;
    if (result == null) {
      this.showCompareError = true;
    } else {
      this.compareResponse = result.providerDetail;
      if (this._appSession.isEyc || CommonUtil.isMemberSecure(this._appSession)) {
        (this.compareResponse || []).forEach((provDtl, i) => {
          const matchedProv = this.compareProviders.find((c) => {
            return c.providerIdentifier === provDtl.providerIdentifier;
          });
          provDtl.eycAffliation = this.getAffliation(i);
          if (!matchedProv) {
            return;
          }
          provDtl.eycCostInfo = matchedProv.eycCostInfo;
          provDtl.pdtKey = matchedProv.pdtKey;
          provDtl.sequenceNumber = matchedProv.sequenceNumber;
          provDtl.isRecordFromSPFI = matchedProv.isRecordFromSPFI;
        });
      }
      if (CommonUtil.isMemberSecure(this._appSession) && (this.compareResponse || []).some((provDtl) => provDtl.eycCostInfo !== undefined)) {
        this.showEyc = true;
      }
      this.compareVisibility = result.visibilityRule;
      this.officeFeatures = result.officeFeatures;
      if (this.compareResponse) {
        this.compareResponse.forEach((provider) => {
          const officeFeatures = provider?.locations[0]?.address?.officeFeatureCodeList || [];

          officeFeatures
            .filter((officeFeature) => officeFeature?.name)
            .forEach((officeFeature) => {
              if (!this.officeFeatureLabels.includes(officeFeature.name)) {
                this.officeFeatureLabels.push(officeFeature.name);
              }
            });
        });
      }
      this.setMapRequest();
    }
    this._appUtility.scrollToTop();
  }

  onCompareResponseError(error: any) {
    this.showCompareLoader = false;
    this.showCompareError = true;
  }

  getAffliation(index: number): IEycAffliation {
    let affliation = {} as IEycAffliation;
    if (index > -1) {
      affliation = this.compareAffilation.filter((c) => {
        return c.providerIdentifier === this.compareResponse[index].providerIdentifier;
      })[0]?.eycAffliation;
      this.compareAffilation = this.compareAffilation.filter((c) => {
        return c.eycAffliation !== affliation;
      });
    }
    return affliation;
  }

  getProvider(provider: any) {
    let selectedProvider: any;
    if (provider) {
      selectedProvider = this.compareProviders.filter(
        (x) =>
          x.providerIdentifier === provider.providerIdentifier &&
          provider.locations &&
          provider.locations.length > 0 &&
          provider.locations[0].address &&
          x.location &&
          x.location.address &&
          provider.locations[0].address.addressId === x.location.address.addressId
      );
    }
    return selectedProvider && selectedProvider.length > 0 ? selectedProvider[0] : selectedProvider;
  }
  getGenderText(genderCode: any) {
    return this.WcsUtility.getGenderText(genderCode);
  }
  getImageURL(filename: string) {
    return this.getQualityImageURL(filename);
  }

  getSpecialtyMsg(specialty: any) {
    return this.WcsUtility.getSpecialtyMsg(specialty);
  }

  getMedicaid(provider: any) {
    if (provider && provider.performance && provider.performance.acceptMedicaid) {
      return this.WcsUtility.getAcceptsMedicaidMsg(provider.performance.acceptMedicaid);
    } else {
      return this.WcsUtility.getAcceptsMedicaidMsg('');
    }
  }

  showAffiliation(professional: IProvider) {
    return this._appSession.isEyc && this.showSPFI && professional && professional.eycAffliation && professional.eycAffliation.affiliationList[0] && professional.eycAffliation.isVisibleAffiliation;
  }

  getAddress(professional: IProvider) {
    return CommonUtil.swapOfficeLocation(this.showSPFI, professional);
  }

  toggleMap() {
    this.toggleMapBool = !this.toggleMapBool;
    if (!this.showMapCmp) {
      this.showMapCmp = true;
    }
    if (this.toggleMapBool && typeof this.mapComponent !== 'undefined') {
      this.mapComponent.focusMap();
    }
  }

  setMapRequest() {
    if (this.compareProviders && this.compareProviders.length > 0) {
      this.mapData = CommonUtil.setMapRequest(this.compareProviders, null, this.showSPFI);
      if (typeof this.mapComponent !== 'undefined') {
        this.mapComponent.setMap(this.mapData);
      }
    }
  }

  openDrivingDirection(provider: any) {
    const selectedProvider = this.getProvider(provider);
    this.openDrivingDirectionModal.emit(selectedProvider);
  }

  mapPinClickEvent(event: any) {
    // TO DO:
  }

  getReviewRatingImage(rating: string) {
    let imagePath = '';
    if (rating) {
      imagePath = this.getQualityImageURL(rating.toString().replace(/\./, '-') + 'stars_sm.png');
    }
    return imagePath;
  }

  vitalsSsoConfigValue(professional: any) {
    this.vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional);
    return this.vitalsSsoConfig;
  }

  openReviewModal(provider: any) {
    this.openReview.emit(provider);
  }

  openAwardModal(performance: any) {
    this.openAward.emit(performance);
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  formatDistance(distance: string): string {
    return distance ? parseFloat(distance).toFixed(2) : '';
  }
  showProviderDetails(professional: any) {
    professional.providerName = professional && professional.name && professional.name.providerName ? professional.name.providerName : professional.providerName ? professional.providerName : '';
    professional.titleList = professional.name.titleList;
    professional.location =
      professional && professional.locations && professional.locations.length > 0 ? professional.locations[0] : professional && professional.location ? professional.location : '';
    if (this._appSession.isEyc) {
      const selectedProv = this.compareProviders.filter((c) => {
        return c.providerIdentifier === professional.providerIdentifier;
      });
      if (selectedProv && selectedProv.length > 0) {
        professional.eycCostInfo = selectedProv[0].eycCostInfo;
      } else {
        professional.eycCostInfo = professional.eycCostInfo ? professional.eycCostInfo : '';
      }
    }

    this._providerDetailsNavigationService.navigateToProviderDetails(professional);
  }
  openDomainDisclaimerModal(url: any) {
    this.openDomain.emit(url);
  }
  administrativeOffice(activeFlagList: Array<string>): boolean {
    return this.WcsUtility.isAdministrativeOffice(activeFlagList);
  }

  decodeHTML(html) {
    return this.WcsUtility.decodeHTML(html);
  }

  private getMoreRating(event: Event) {
    this.showMoreLink = !this.showMoreLink;
    event.preventDefault();
  }

  private showInfoNA(prov) {
    return (
      (prov && prov.reviewRatings && prov.reviewRatings[0] && prov.reviewRatings[0].starRating && prov.reviewRatings[0].starRating !== 'No Rating Available') ||
      (prov && prov.reviewRatings && prov.reviewRatings[0] && prov.reviewRatings[0].pdtStrKey && this.showBeFirstToReview)
    );
  }

  openTelehealthModal() {
    this.openTelehealth.emit();
  }

  getEycCostInfo(professional: IProvider): ICostInfo {
    return CommonUtil.swapEycCostInfo(this.showSPFI, professional);
  }

  getProviderName(provider: ICompareProvider): string {
    return CommonUtil.getProviderTitles(provider?.name?.titleList, provider?.networkRegisteredName, provider?.name?.providerName);
  }
}
