import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GenericModalComponent } from './components/genericModalCmp';
import { GenericModalService } from './services/genericModalSvc';
@NgModule({
  declarations: [GenericModalComponent],
  providers:[GenericModalService],
  exports: [GenericModalComponent],
  imports: [CommonModule, UxSharedModule]
})
export class GenericModalModule { }
