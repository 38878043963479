import { Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnInit, Output } from '@angular/core';
import { IWindow } from '../interfaces/iWindow';
import { AppSession } from '../values/appSession';

export interface IRecaptchaConfig {
  'expired-callback': Function;
  callback: Function;
  sitekey: string;
  size?: 'compact' | 'normal';
  tabindex?: number;
  type: 'image';
}

export interface IGreCaptcha {
  execute: Function;
  getPageId: Function;
  getResponse: Function;
  ready: Function;
  render: (element: HTMLElement, config: IRecaptchaConfig) => void;
  reset: Function;
}

@Directive({
  selector: '[appRecaptcha]',
  exportAs: 'recaptcha'
})

export class RecaptchaDirective implements OnInit {

  @Input() config: Pick<IRecaptchaConfig, 'tabindex' | 'type' | 'size'> = {
    type: 'image'
  };
  @Input() key!: string;
  @Input() language!: string;

  @Output() captchaResponse = new EventEmitter<string>();
  @Output() captchaExpired = new EventEmitter<void>();

  ;
  constructor(private element: ElementRef, private ngZone: NgZone, @Inject('Window') public window: IWindow, @Inject(AppSession) private _appSession: AppSession) {
  }

  ngOnInit() {
    // Do not change the order
    this.registerCallback();
    this.appendScript();
  }

  onExpired() {
    this.ngZone.run(() => {
      this.captchaExpired.emit();
    });
  }

  onSuccess(token: string) {
    this.ngZone.run(() => {
      this.captchaResponse.emit(token);
    });
  }

  registerCallback() {
    this.window.onReCaptchaLoad = () => {
      const config: IRecaptchaConfig = {
        ...this.config,
        'sitekey': this.key,
        'callback': this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this)
      };
      this.renderCaptcha(this.element.nativeElement, config);
    };
  }

  appendScript() {
    const script = document.createElement('script');
    const language = this.language ? `&hl=${this.language}`: '';
    script.src = `${this._appSession.appConfig?.recaptcha.baseUrl}?onload=onReCaptchaLoad&render=explicit${language}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  private renderCaptcha(element: HTMLElement, config: IRecaptchaConfig) {
    this.window.grecaptcha.render(element, config);
  }

}
