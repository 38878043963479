export const PROVIDER_SEARCH_DISTIANCE_FILTER = {
  title: 'Distance',
  options: [
    {
      label: '2 Miles',
      value: '2',
      id: 'cB_Distance_0',
      name: 'dropdownDistance',
      trueValue: '2',
      falseValue: ''
    },
    {
      label: '5 Miles',
      value: '5',
      id: 'cB_Distance_1',
      name: 'dropdownDistance',
      trueValue: '5',
      falseValue: ''
    },
    {
      label: '10 Miles',
      value: '10',
      id: 'cB_Distance_2',
      name: 'dropdownDistance',
      trueValue: '10',
      falseValue: ''
    },
    {
      label: '20 Miles',
      value: '20',
      id: 'cB_Distance_3',
      name: 'dropdownDistance',
      trueValue: '20',
      falseValue: ''
    },
    {
      label: '30 Miles',
      value: '30',
      id: 'cB_Distance_5',
      name: 'dropdownDistance',
      trueValue: '30',
      falseValue: ''
    },
    {
      label: '50 Miles',
      value: '50',
      id: 'cB_Distance_6',
      name: 'dropdownDistance',
      trueValue: '50',
      falseValue: ''
    },
    {
      label: '75 Miles',
      value: '75',
      id: 'cB_Distance_7',
      name: 'dropdownDistance',
      trueValue: '75',
      falseValue: ''
    },
    {
      label: '100 Miles',
      value: '100',
      id: 'cB_Distance_8',
      name: 'dropdownDistance',
      trueValue: '100',
      falseValue: ''
    }
  ]
};

export const SPANISH_PROVIDER_SEARCH_DISTANCE_FILTER = {
  title: 'Distance',
  options: [
    {
      label: '2 Millas',
      value: '2',
      id: 'cB_Distance_0',
      name: 'dropdownDistance',
      trueValue: '2',
      falseValue: ''
    },
    {
      label: '5 Millas',
      value: '5',
      id: 'cB_Distance_1',
      name: 'dropdownDistance',
      trueValue: '5',
      falseValue: ''
    },
    {
      label: '10 Millas',
      value: '10',
      id: 'cB_Distance_2',
      name: 'dropdownDistance',
      trueValue: '10',
      falseValue: ''
    },
    {
      label: '20 Millas',
      value: '20',
      id: 'cB_Distance_3',
      name: 'dropdownDistance',
      trueValue: '20',
      falseValue: ''
    },
    {
      label: '30 Millas',
      value: '30',
      id: 'cB_Distance_5',
      name: 'dropdownDistance',
      trueValue: '30',
      falseValue: ''
    },
    {
      label: '50 Millas',
      value: '50',
      id: 'cB_Distance_6',
      name: 'dropdownDistance',
      trueValue: '50',
      falseValue: ''
    },
    {
      label: '75 Millas',
      value: '75',
      id: 'cB_Distance_7',
      name: 'dropdownDistance',
      trueValue: '75',
      falseValue: ''
    },
    {
      label: '100 Millas',
      value: '100',
      id: 'cB_Distance_8',
      name: 'dropdownDistance',
      trueValue: '100',
      falseValue: ''
    }
  ]
};

export const PROVIDER_SEARCH_GENDER_FILTER = {
  title: 'Gender',
  options: [
    {
      id: 'cB_Gender_1',
      name: 'checkBoxGender',
      label: 'Male',
      trueValue: 'M',
      falseValue: '',
      value: 'M',
      analyticsText: 'selectMaleOptionProviderResults',
      showFilterCount: true
    },
    {
      id: 'cB_Gender_2',
      name: 'checkBoxGender',
      label: 'Female',
      trueValue: 'F',
      falseValue: '',
      value: 'F',
      analyticsText: 'selectFemaleOptionProviderResults',
      showFilterCount: true
    }
  ]
};

export const SPANISH_PROVIDER_SEARCH_GENDER_FILTER = {
  title: 'Gender',
  options: [
    {
      id: 'cB_Gender_1',
      name: 'radioGender',
      label: 'Masculino',
      trueValue: 'M',
      falseValue: '',
      value: 'M',
      analyticsText: 'selectMaleOptionProviderResults',
      showFilterCount: true
    },
    {
      id: 'cB_Gender_2',
      name: 'radioGender',
      label: 'Femenino',
      trueValue: 'F',
      falseValue: '',
      value: 'F',
      analyticsText: 'selectFemaleOptionProviderResults',
      showFilterCount: true
    }
  ]
};

export const PROVIDER_SEARCH_MEDICAID_CHECKBOX = {
  id: 'acceptMedicaid',
  name: 'acceptMedicaid',
  label: '',
  trueValue: true,
  falseValue: false
};

export const PROVIDER_SEARCH_ECP_CHECKBOX = {
  id: 'ecp',
  name: 'ecp',
  label: '',
  trueValue: true,
  falseValue: false
};

export const PROVIDER_SEARCH_BOARD_CERTIFIED_CHECKBOX = {
  id: 'boardCertified',
  name: 'boardCertified',
  label: '',
  trueValue: true,
  falseValue: false
};

export const PROVIDER_SEARCH_NEWPATIENT_CHECKBOX = {
  id: 'acceptAcceptingNewPatient',
  name: 'acceptAcceptingNewPatient',
  label: '',
  trueValue: true,
  falseValue: false,
  analyticsText: 'acceptNewPatientsProviderResults'
};

export const ADP_FILTER_CHECKBOX = {
  id: 'lbl-filter-hpp',
  name: 'lbl-filter-hpp',
  label: '',
  trueValue: true,
  falseValue: false,
  analyticsText: 'hppfilterResults'
};

export const PROVIDER_SEARCH_SERVEASPCP_CHECKBOX = {
  id: 'serveAsPcp',
  name: 'serveAsPcp',
  label: '',
  trueValue: true,
  falseValue: false,
  analyticsText: 'serveAsPcpProviderResults'
};

export const PROVIDER_SEARCH_SCHEDULEAPPOINTMENT_CHECKBOX = {
  id: 'scheduleAppointment',
  name: 'scheduleAppointment',
  label: '',
  trueValue: true,
  falseValue: false,
  analyticsText: 'scheduleAppointmentProviderResults'
};
export const BLUE_PRECISION = {
  id: 'bluePrecisionInd',
  name: 'bluePrecisionInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const COOPERATIVE_CARE = {
  id: 'cooperativeCareInd',
  name: 'cooperativeCareInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const MEDICAL_PLAN = {
  id: 'medicalPlanInd',
  name: 'planFilterOptions',
  label: '',
  trueValue: true,
  falseValue: false,
  value: ''
};
export const DENTAL_PLAN = {
  id: 'dentalPlanInd',
  name: 'planFilterOptions',
  label: '',
  trueValue: true,
  falseValue: false,
  value: ''
};
export const VISION_PLAN = {
  id: 'visionPlanInd',
  name: 'planFilterOptions',
  label: '',
  trueValue: true,
  falseValue: false,
  value: ''
};
export const ALL_PLAN_TYPES = {
  id: 'allTypesInd',
  name: 'planFilterOptions',
  label: '',
  trueValue: true,
  falseValue: false,
  value: ''
};

export const OON_PLAN = {
  id: 'oonPlanInd',
  name: 'planFilterOptions',
  label: '',
  trueValue: true,
  falseValue: false,
  value: ''
};

export const PHARMACY_LEVEL = {
  id: 'pharmacyLevelInd',
  name: 'pharmacyLevelInd',
  label: '',
  trueValue: 'Level1',
  falseValue: '',
  value: 'Level1'
};
export const PHARMACY_PREFERRED = {
  id: 'pharmacyPreferredInd',
  name: 'pharmacyPreferredInd',
  label: '',
  trueValue: 'Level1',
  falseValue: '',
  value: 'Level1'
};
export const ALL_PHARMACY_FEATURES = {
  id: 'allPharmacyFeaturesInd',
  name: 'allPharmacyFeaturesInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const OPEN_HOURS = {
  id: 'open24HoursADayInd',
  name: 'open24HoursADayInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const RXM90_PHARMACY = {
  id: 'rxM90PharmacyInd',
  name: 'rxM90PharmacyInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const DRIVE_THROUGH = {
  id: 'drivethroughInd',
  name: 'drivethroughInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const ONSITE_MEDICAL = {
  id: 'onsiteMedicalInd',
  name: 'onsiteMedicalInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const BLOOD_PRESSURE = {
  id: 'bloodPressureInd',
  name: 'bloodPressureInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const FLUSHOTS = {
  id: 'fluShotsInd',
  name: 'fluShotsInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const HOMEDELIVERY = {
  id: 'homeDeliveryInd',
  name: 'homeDeliveryInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const VACCINATIONS = {
  id: 'vaccinationsInd',
  name: 'vaccinationsInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const COUMPOUND_MEDICATIONS = {
  id: 'coumpoundMedicationsInd',
  name: 'coumpoundMedicationsInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const DURABLE_MEDICAL = {
  id: 'durableMedicalInd',
  name: 'durableMedicalInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const SOS_TIER = {
  id: 'sosTierInd',
  name: 'sosTierInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const LGBTQ_ALLY = {
  id: 'lgbtqAllyInd',
  name: 'lgbtqAllyInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const CENTER_OF_EXCELLENCE = {
  id: 'centerOfExcellenceInd',
  name: 'centerOfExcellenceInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const PROVIDER_OF_DISTINCTION = {
  id: 'providerOfDistinctionInd',
  name: 'providerOfDistinctionInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const MSP_RECOGNITION = {
  id: 'mspInd',
  name: 'mspInd',
  label: '',
  trueValue: true,
  falseValue: false
};
export const VALUE_BASED_PROVIDER = {
  id: 'valueBasedProviderInd',
  name: 'valueBasedProviderInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const BLUE_DISTINCT_TOTAL_CARE = {
  id: 'blueDistinctionInd',
  name: 'blueDistinctionInd',
  label: '',
  trueValue: 'BDTC',
  falseValue: ''
};
export const BLUE_DISTINCT_TOTAL_CARE_PLUS = {
  id: 'blueDistinctionPlusInd',
  name: 'blueDistinctionPlusInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const ENHANCED_PERSONAL_CARE = {
  id: 'patCenteredCareInd',
  name: 'patCenteredCareInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const LOWER_COPAY_PCP = {
  id: 'lowerCopayPCPInd',
  name: 'lowerCopayPCPInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const OFFICE_MENTAL_HEALTH = {
  id: 'patOMHInd',
  name: 'patOMHInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const OFFICE_ALCOHOL_SUBSTANCE = {
  id: 'patOASASInd',
  name: 'patOASASInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const BLUE_CARE_PRIME_PREFERRED = {
  id: 'patBCPInd',
  name: 'patBCPInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const GREEN_RIBBON = {
  id: 'greenRibbonRcg',
  name: 'greenRibbonRcg',
  label: '',
  trueValue: true,
  falseValue: false
};

export const SPANISH_EYCSEARCH_PROVIDERTYPE_FILTER = {
  title: 'Hospital / Doctor',
  options: [
    {
      id: 'rB_HosDoc_0',
      name: 'radioProviderType',
      label: 'Hospitales',
      trueValue: 'H',
      falseValue: 'P',
      value: 'H'
    },
    {
      id: 'rB_HosDoc_1',
      name: 'radioProviderType',
      label: 'Médicos',
      trueValue: 'P',
      falseValue: 'H',
      value: 'P'
    }
  ]
};
export const EYCSEARCH_PROVIDERTYPE_FILTER = {
  title: 'Hospital / Doctor',
  options: [
    {
      id: 'rB_HosDoc_0',
      name: 'radioProviderType',
      label: 'Hospitals',
      trueValue: 'H',
      falseValue: 'P',
      value: 'H'
    },
    {
      id: 'rB_HosDoc_1',
      name: 'radioProviderType',
      label: 'Doctors',
      trueValue: 'P',
      falseValue: 'H',
      value: 'P'
    }
  ]
};

export const SPANISH_PROVIDER_SEARCH_BOARDCERTIFICATION_FILTER = {
  title: 'BoardCertification',
  options: [
    {
      id: 'cB_BoardCertification_0',
      name: 'radioBoardCertification',
      label: 'Sin Preferencia',
      trueValue: false,
      falseValue: '',
      value: false
    },
    {
      id: 'cB_BoardCertification_1',
      name: 'radioBoardCertification',
      label: ' Sí',
      trueValue: true,
      falseValue: '',
      value: true
    }
  ]
};
export const PROVIDER_SEARCH_BOARDCERTIFICATION_FILTER = {
  title: 'BoardCertification',
  options: [
    {
      id: 'cB_BoardCertification_0',
      name: 'radioBoardCertification',
      label: 'No Preference',
      trueValue: false,
      falseValue: '',
      value: false
    },
    {
      id: 'cB_BoardCertification_1',
      name: 'radioBoardCertification',
      label: ' Yes',
      trueValue: true,
      falseValue: '',
      value: true
    }
  ]
};

export const SPANISH_PROVIDER_SEARCH_TIER_FILTER_NEW = {
  title: 'Tier',
  options: [
    {
      label: '',
      id: 'cB_tier_1',
      name: 'radioTier',
      trueValue: 'Tier1',
      falseValue: ''
    }
  ]
};

export const PROVIDER_SEARCH_TIER_FILTER_NEW = {
  title: 'Tier',
  options: [
    {
      label: '',
      id: 'cB_keyfilter_tier_1',
      name: 'radioTier',
      trueValue: 'Tier1',
      falseValue: ''
    },
    {
      label: '',
      id: 'cB_recognition_tier_1',
      name: 'radioTier',
      trueValue: 'Tier1',
      falseValue: ''
    }
  ]
};

export const SPANISH_PROVIDER_SEARCH_SERVICEAVAILABLE_FILTER = {
  title: 'ServiceAvailable',
  options: [
    {
      label: 'Exámenes',
      value: 'EX',
      id: 'ch_exams',
      name: 'ch_exams',
      trueValue: 'EX',
      falseValue: '',
      showTooltip: false
    },
    {
      label: 'Marcos, lentes, contactos',
      value: 'M',
      id: 'ch_materials',
      name: 'ch_materials',
      trueValue: 'M',
      falseValue: '',
      showTooltip: false
    },
    {
      label: 'Descuentos no cubiertos',
      value: 'VD',
      id: 'ch-discounts_on_non-covered_services',
      name: 'ch-examsandmaterials',
      trueValue: 'VD',
      falseValue: '',
      showTooltip: true
    }
  ]
};

export const PROVIDER_SEARCH_SERVICEAVAILABLE_FILTER = {
  title: 'ServiceAvailable',
  options: [
    {
      label: 'Exams',
      value: 'EX',
      id: 'ch_exams',
      name: 'ch_exams',
      trueValue: 'EX',
      falseValue: '',
      showTooltip: false
    },
    {
      label: 'Frames, Lenses, Contacts',
      value: 'M',
      id: 'ch_materials',
      name: 'ch_materials',
      trueValue: 'M',
      falseValue: '',
      showTooltip: false
    },
    {
      label: 'Non-Covered Discounts',
      value: 'VD',
      id: 'ch-discounts_on_non-covered_services',
      name: 'ch-examsandmaterials',
      trueValue: 'VD',
      falseValue: '',
      showTooltip: true
    }
  ]
};

/* export const PROVIDER_SEARCH_BDC_FILTER = [{
  type: 'P',
  options: ['BDFC']
}, {
  type: 'H',
  options: ['BDBAR', 'BDCANC', 'BDCAR', 'BDCIT', 'BDFC', 'BDGT', 'BDKAH', 'BDMAT', 'BDSPS', 'BDTPT', 'BDSTR']
}
]; */

export const BLUE_DISTINCTION = {
  BD: 'BD',
  BD_PLUS: 'PLUS'
};

export const PROVIDER_SEARCH_BDC_FILTER = [
  {
    type: 'P',
    options: [
      {
        value: 'BDFC',
        bd: {
          id: 'BDFCInd',
          name: 'BDFCInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdfc'
        },
        bdcPlus: {
          id: 'BDFCPlusInd',
          name: 'BDFCPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdfc_plus'
        }
      }
    ]
  },
  {
    type: 'H',
    options: [
      {
        value: 'BDBAR',
        bd: {
          id: 'BDBARInd',
          name: 'BDBARInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdbarcc'
        },
        bdcPlus: {
          id: 'BDBARPlusInd',
          name: 'BDBARPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdbarcc_plus'
        }
      },
      {
        value: 'BDCANC',
        bd: {
          id: 'BDCANCInd',
          name: 'BDCANCInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdcanc'
        },
        bdcPlus: {
          id: 'BDCANCPlusInd',
          name: 'BDCANCPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdcanc_plus'
        }
      },
      {
        value: 'BDCAR',
        bd: {
          id: 'BDCARInd',
          name: 'BDCARInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdcar'
        },
        bdcPlus: {
          id: 'BDCARPlusInd',
          name: 'BDCARPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdcar_plus'
        }
      },
      {
        value: 'BDCIT',
        bd: {
          id: 'BDCITInd',
          name: 'BDCITInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdcit'
        },
        bdcPlus: {
          id: 'BDCITPlusInd',
          name: 'BDCITPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdcit_plus'
        }
      },
      {
        value: 'BDFC',
        bd: {
          id: 'BDFCInd',
          name: 'BDFCInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdfc'
        },
        bdcPlus: {
          id: 'BDFCPlusInd',
          name: 'BDFCPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdfc_plus'
        }
      },
      {
        value: 'BDGT',
        bd: {
          id: 'BDGTInd',
          name: 'BDGTInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdgt'
        },
        bdcPlus: {
          id: 'BDGTPlusInd',
          name: 'BDGTPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdgt_plus'
        }
      },
      {
        value: 'BDKAH',
        bd: {
          id: 'BDKAHInd',
          name: 'BDKAHInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdkah'
        },
        bdcPlus: {
          id: 'BDKAHPlusInd',
          name: 'BDKAHPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdkah_plus'
        }
      },
      {
        value: 'BDMAT',
        bd: {
          id: 'BDMATInd',
          name: 'BDMATInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdmat'
        },
        bdcPlus: {
          id: 'BDMATPlusInd',
          name: 'BDMATPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdmat_plus'
        }
      },
      {
        value: 'BDSPS',
        bd: {
          id: 'BDSPSInd',
          name: 'BDSPSInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdsps'
        },
        bdcPlus: {
          id: 'BDSPSPlusInd',
          name: 'BDSPSPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdsps_plus'
        }
      },
      {
        value: 'BDTPT',
        bd: {
          id: 'BDTPTInd',
          name: 'BDTPTInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdtpt'
        },
        bdcPlus: {
          id: 'BDTPTPlusInd',
          name: 'BDTPTPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdtpt_plus'
        }
      },
      {
        value: 'BDSTR',
        bd: {
          id: 'BDSTRInd',
          name: 'BDSTRInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdstr'
        },
        bdcPlus: {
          id: 'BDSTRPlusInd',
          name: 'BDSTRPlusInd',
          label: 'Blue Distinction Centers+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'mp'
        }
      }
    ]
  }
];

export const SPANISH_PROVIDER_SEARCH_BDC_FILTER = [
  {
    type: 'P',
    options: [
      {
        value: 'BDFC',
        bd: {
          id: 'BDFCInd',
          name: 'BDFCInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdfc'
        },
        bdcPlus: {
          id: 'BDFCPlusInd',
          name: 'BDFCPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdfc_plus'
        }
      }
    ]
  },
  {
    type: 'H',
    options: [
      {
        value: 'BDBAR',
        bd: {
          id: 'BDBARInd',
          name: 'BDBARInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdbarcc'
        },
        bdcPlus: {
          id: 'BDBARPlusInd',
          name: 'BDBARPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdbarcc_plus'
        }
      },
      {
        value: 'BDCANC',
        bd: {
          id: 'BDCANCInd',
          name: 'BDCANCInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdcanc'
        },
        bdcPlus: {
          id: 'BDCANCPlusInd',
          name: 'BDCANCPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdcanc_plus'
        }
      },
      {
        value: 'BDCAR',
        bd: {
          id: 'BDCARInd',
          name: 'BDCARInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdcar'
        },
        bdcPlus: {
          id: 'BDCARPlusInd',
          name: 'BDCARPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdcar_plus'
        }
      },
      {
        value: 'BDCIT',
        bd: {
          id: 'BDCITInd',
          name: 'BDCITInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdcit'
        },
        bdcPlus: {
          id: 'BDCITPlusInd',
          name: 'BDCITPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdcit_plus'
        }
      },
      {
        value: 'BDFC',
        bd: {
          id: 'BDFCInd',
          name: 'BDFCInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdfc'
        },
        bdcPlus: {
          id: 'BDFCPlusInd',
          name: 'BDFCPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdfc_plus'
        }
      },
      {
        value: 'BDGT',
        bd: {
          id: 'BDGTInd',
          name: 'BDGTInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdgt'
        },
        bdcPlus: {
          id: 'BDGTPlusInd',
          name: 'BDGTPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdgt_plus'
        }
      },
      {
        value: 'BDKAH',
        bd: {
          id: 'BDKAHInd',
          name: 'BDKAHInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdkah'
        },
        bdcPlus: {
          id: 'BDKAHPlusInd',
          name: 'BDKAHPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdkah_plus'
        }
      },
      {
        value: 'BDMAT',
        bd: {
          id: 'BDMATInd',
          name: 'BDMATInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdmat'
        },
        bdcPlus: {
          id: 'BDMATPlusInd',
          name: 'BDMATPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdmat_plus'
        }
      },
      {
        value: 'BDSPS',
        bd: {
          id: 'BDSPSInd',
          name: 'BDSPSInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdsps'
        },
        bdcPlus: {
          id: 'BDSPSPlusInd',
          name: 'BDSPSPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdsps_plus'
        }
      },
      {
        value: 'BDTPT',
        bd: {
          id: 'BDTPTInd',
          name: 'BDTPTInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdtpt'
        },
        bdcPlus: {
          id: 'BDTPTPlusInd',
          name: 'BDTPTPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'bdtpt_plus'
        }
      },
      {
        value: 'BDSTR',
        bd: {
          id: 'BDSTRInd',
          name: 'BDSTRInd',
          label: 'Blue Distinction',
          trueValue: 'BD',
          falseValue: '',
          value: 'bdstr'
        },
        bdcPlus: {
          id: 'BDSTRPlusInd',
          name: 'BDSTRPlusInd',
          label: 'Centros Blue Distinction+',
          trueValue: 'BDPlus',
          falseValue: '',
          value: 'mp'
        }
      }
    ]
  }
];

export const PHARMACY_FEATURES = {
  IS24HOURIND: 'open24HoursADay',
  PREFERRED_PHARMACY: 'preferred',
  DME: 'dme',
  HOME_DELIVERY: 'homeDelivery',
  COMPOUNDING: 'preparesCompounds',
  NINETY_DAYS: 'ninetyDayRetail',
  DISPTYPE: 'pharmacytypedesc',
  DISPTYPE_CODE: '1',
  ADA: 'ADA',
  ADANUMBER: '1084',
  WEB_ADDRESS: 'web_address',
  LICENSE: 'license',
  ONSITEMEDICALCLINIC: 'onsiteMedicalClinic',
  VACCINATIONINDICATOR: 'vaccinationIndicator',
  OPEN7DAYSAWEEK: 'open7DaysAWeek',
  PROVIDESFLUSHOTS: 'providesFluShots',
  HOMEDELIVERY: 'homeDelivery',
  DRIVETHROUGH: 'driveThru',
  RXMAINTENANCE90: 'rxM90Pharmacy',
  BLOODPRESSURESCREENING: 'bloodPressureScreening'
};

export const BLUE_DISTINCTINON_CENTER = {
  id: 'blueDistinctionCenterInd',
  name: 'blueDistinctionCenterInd',
  label: '',
  trueValue: 'BD',
  falseValue: '',
  value: ''
};

export const BLUE_DISTINCTINON_CENTER_PLUS = {
  id: 'blueDistinctionCenterPlusInd',
  name: 'blueDistinctionCenterPlusInd',
  label: '',
  trueValue: 'BDPlus',
  falseValue: '',
  value: ''
};

export const PROVIDER_SEARCH_TELEHEALTH_CHECKBOX = {
  id: 'teleHealthInd',
  name: 'teleHealthInd',
  label: '',
  trueValue: true,
  falseValue: false,
  value: 'y',
  showFilterCount: true
};

export const SPANISH_CME_DESIGNATION = {
  title: 'Centros de Excelencia Médica',
  options: [
    {
      id: 'Inmunoterapia Celular',
      name: 'Inmunoterapia Celular',
      label: 'Inmunoterapia Celular',
      trueValue: 'CME-CLRIMTPY',
      falseValue: '',
      value: 'CME-CLRIMTPY'
    },
    {
      id: 'Trasplantes',
      name: 'Trasplantes',
      label: 'Trasplantes',
      trueValue: 'CME-TNSPLT',
      falseValue: '',
      value: 'CME-TNSPLT'
    },
    {
      id: 'Dispositivos de asistencia ventricular',
      name: 'Dispositivos de asistencia ventricular',
      label: 'Dispositivos de asistencia ventricular',
      trueValue: 'CME-WAD',
      falseValue: '',
      value: 'CME-WAD'
    }
  ]
};

export const CME_DESIGNATION = {
  title: 'Centers Of Medical Excellence',
  options: [
    {
      id: 'Cellular Immunotherapy',
      name: 'Cellular Immunotherapy',
      label: 'Cellular Immunotherapy',
      trueValue: 'CME-CLRIMTPY',
      falseValue: '',
      value: 'CME-CLRIMTPY'
    },
    {
      id: 'Transplants',
      name: 'Transplants',
      label: 'Transplants',
      trueValue: 'CME-TNSPLT',
      falseValue: '',
      value: 'CME-TNSPLT'
    },
    {
      id: 'Ventricular Assist Devices',
      name: 'Ventricular Assist Devices',
      label: 'Ventricular Assist Devices',
      trueValue: 'CME-WAD',
      falseValue: '',
      value: 'CME-WAD'
    }
  ]
};

export const PROVIDER_SEARCH_EXTENDED_HOURS_CHECKBOX = {
  id: 'extendedHours',
  name: 'extendedHours',
  label: '',
  trueValue: true,
  falseValue: false,
  value: 'y'
};

export const UPSWING = {
  id: 'upswingInd',
  name: 'upswingInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const SNYDENTAL = {
  id: 'snyDentalInd',
  name: 'snyDentalInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const CCARE = {
  id: 'ccareInd',
  name: 'ccareInd',
  label: '',
  trueValue: true,
  falseValue: false
};

export const SPANISH_FILTER = {
  distanceFilter: SPANISH_PROVIDER_SEARCH_DISTANCE_FILTER,
  genderFilter: SPANISH_PROVIDER_SEARCH_GENDER_FILTER,
  medicaidCheckbox: PROVIDER_SEARCH_MEDICAID_CHECKBOX,
  ecpCheckbox: PROVIDER_SEARCH_ECP_CHECKBOX,
  boardCertified: PROVIDER_SEARCH_BOARD_CERTIFIED_CHECKBOX,
  serveAsPCPCheckBox: PROVIDER_SEARCH_SERVEASPCP_CHECKBOX,
  scheduleAppointmentCheckbox: PROVIDER_SEARCH_SCHEDULEAPPOINTMENT_CHECKBOX,
  newPatientCheckBox: PROVIDER_SEARCH_NEWPATIENT_CHECKBOX,
  adpCheckBox: ADP_FILTER_CHECKBOX,
  pharmacyLevelCheckBox: PHARMACY_LEVEL,
  pharmacyPreferredCheckBox: PHARMACY_PREFERRED,
  allPharmacyFeaturesCheckBox: ALL_PHARMACY_FEATURES,
  openHoursCheckBox: OPEN_HOURS,
  rxM90PharmacyCheckBox: RXM90_PHARMACY,
  drivethroughCheckBox: DRIVE_THROUGH,
  onsiteMedicalCheckBox: ONSITE_MEDICAL,
  bloodPressureCheckbox: BLOOD_PRESSURE,
  fluShotsCheckBox: FLUSHOTS,
  homeDeliveryCheckBox: HOMEDELIVERY,
  vaccinationsCheckBox: VACCINATIONS,
  coumpoundMedicationsCheckBox: COUMPOUND_MEDICATIONS,
  durableMedicalCheckBox: DURABLE_MEDICAL,
  medicalPlanCheckBox: MEDICAL_PLAN,
  dentalPlanCheckBox: DENTAL_PLAN,
  visionPlanCheckBox: VISION_PLAN,
  allPlanTypes: ALL_PLAN_TYPES,
  oonPlanCheckBox: OON_PLAN,
  cooperativeCareCheckBox: COOPERATIVE_CARE,
  lgbtqAlly: LGBTQ_ALLY,
  sosTier: SOS_TIER,
  centerOfExcellence: CENTER_OF_EXCELLENCE,
  providerOfDistinction: PROVIDER_OF_DISTINCTION,
  bluePrecisionCheckBox: BLUE_PRECISION,
  blueDistinctCheckBox: BLUE_DISTINCT_TOTAL_CARE,
  blueDistinctPlusCheckBox: BLUE_DISTINCT_TOTAL_CARE_PLUS,
  enhancedPersonalCheckBox: ENHANCED_PERSONAL_CARE,
  eycProviderTypeFilter: SPANISH_EYCSEARCH_PROVIDERTYPE_FILTER,
  boardCertification: SPANISH_PROVIDER_SEARCH_BOARDCERTIFICATION_FILTER,
  tier: SPANISH_PROVIDER_SEARCH_TIER_FILTER_NEW,
  serviceAvailable: SPANISH_PROVIDER_SEARCH_SERVICEAVAILABLE_FILTER,
  bdcFilter: SPANISH_PROVIDER_SEARCH_BDC_FILTER,
  blueDistictionCenter: BLUE_DISTINCTINON_CENTER,
  blueDistictionCenterPlus: BLUE_DISTINCTINON_CENTER_PLUS,
  omhCheckBox: OFFICE_MENTAL_HEALTH,
  oasasCheckBox: OFFICE_ALCOHOL_SUBSTANCE,
  bcpCheckBox: BLUE_CARE_PRIME_PREFERRED,
  teleHealthCheckbox: PROVIDER_SEARCH_TELEHEALTH_CHECKBOX,
  upswing: UPSWING,
  snyDental: SNYDENTAL,
  ccare: CCARE,
  msp: MSP_RECOGNITION,
  valueBasedProvider: VALUE_BASED_PROVIDER,
  extendedHoursCheckbox: PROVIDER_SEARCH_EXTENDED_HOURS_CHECKBOX,
  cmeDesignation: SPANISH_CME_DESIGNATION,
  greenRibbon: GREEN_RIBBON,
  lowerCopayPCPCheckBox: LOWER_COPAY_PCP
};
export const FILTER = {
  distanceFilter: PROVIDER_SEARCH_DISTIANCE_FILTER,
  genderFilter: PROVIDER_SEARCH_GENDER_FILTER,
  medicaidCheckbox: PROVIDER_SEARCH_MEDICAID_CHECKBOX,
  ecpCheckbox: PROVIDER_SEARCH_ECP_CHECKBOX,
  boardCertified: PROVIDER_SEARCH_BOARD_CERTIFIED_CHECKBOX,
  serveAsPCPCheckBox: PROVIDER_SEARCH_SERVEASPCP_CHECKBOX,
  scheduleAppointmentCheckbox: PROVIDER_SEARCH_SCHEDULEAPPOINTMENT_CHECKBOX,
  newPatientCheckBox: PROVIDER_SEARCH_NEWPATIENT_CHECKBOX,
  adpCheckBox: ADP_FILTER_CHECKBOX,
  pharmacyLevelCheckBox: PHARMACY_LEVEL,
  pharmacyPreferredCheckBox: PHARMACY_PREFERRED,
  allPharmacyFeaturesCheckBox: ALL_PHARMACY_FEATURES,
  openHoursCheckBox: OPEN_HOURS,
  rxM90PharmacyCheckBox: RXM90_PHARMACY,
  drivethroughCheckBox: DRIVE_THROUGH,
  onsiteMedicalCheckBox: ONSITE_MEDICAL,
  bloodPressureCheckbox: BLOOD_PRESSURE,
  fluShotsCheckBox: FLUSHOTS,
  homeDeliveryCheckBox: HOMEDELIVERY,
  vaccinationsCheckBox: VACCINATIONS,
  coumpoundMedicationsCheckBox: COUMPOUND_MEDICATIONS,
  durableMedicalCheckBox: DURABLE_MEDICAL,
  medicalPlanCheckBox: MEDICAL_PLAN,
  dentalPlanCheckBox: DENTAL_PLAN,
  visionPlanCheckBox: VISION_PLAN,
  allPlanTypes: ALL_PLAN_TYPES,
  oonPlanCheckBox: OON_PLAN,
  cooperativeCareCheckBox: COOPERATIVE_CARE,
  lgbtqAlly: LGBTQ_ALLY,
  sosTier: SOS_TIER,
  centerOfExcellence: CENTER_OF_EXCELLENCE,
  providerOfDistinction: PROVIDER_OF_DISTINCTION,
  bluePrecisionCheckBox: BLUE_PRECISION,
  blueDistinctCheckBox: BLUE_DISTINCT_TOTAL_CARE,
  blueDistinctPlusCheckBox: BLUE_DISTINCT_TOTAL_CARE_PLUS,
  enhancedPersonalCheckBox: ENHANCED_PERSONAL_CARE,
  eycProviderTypeFilter: EYCSEARCH_PROVIDERTYPE_FILTER,
  boardCertification: PROVIDER_SEARCH_BOARDCERTIFICATION_FILTER,
  tier: PROVIDER_SEARCH_TIER_FILTER_NEW,
  serviceAvailable: PROVIDER_SEARCH_SERVICEAVAILABLE_FILTER,
  bdcFilter: PROVIDER_SEARCH_BDC_FILTER,
  blueDistictionCenter: BLUE_DISTINCTINON_CENTER,
  blueDistictionCenterPlus: BLUE_DISTINCTINON_CENTER_PLUS,
  omhCheckBox: OFFICE_MENTAL_HEALTH,
  oasasCheckBox: OFFICE_ALCOHOL_SUBSTANCE,
  bcpCheckBox: BLUE_CARE_PRIME_PREFERRED,
  teleHealthCheckbox: PROVIDER_SEARCH_TELEHEALTH_CHECKBOX,
  upswing: UPSWING,
  snyDental: SNYDENTAL,
  ccare: CCARE,
  msp: MSP_RECOGNITION,
  valueBasedProvider: VALUE_BASED_PROVIDER,
  extendedHoursCheckbox: PROVIDER_SEARCH_EXTENDED_HOURS_CHECKBOX,
  cmeDesignation: CME_DESIGNATION,
  greenRibbon: GREEN_RIBBON,
  lowerCopayPCPCheckBox: LOWER_COPAY_PCP
};

export const PROVIDER_SEARCH_PAGE_SIZE = 60;
export const CASTLIGHT_TRANSPARENCY = {
  id: 'castLightTransparencyInd',
  name: 'castLightTransparencyInd',
  label: 'Castlight Transparency',
  trueValue: true,
  falseValue: false
};

export const WEB_VIEW = {
  id: 'webViewInd',
  name: 'webViewInd',
  label: 'Web View',
  trueValue: true,
  falseValue: false
};

export const CHANGE_PCP = {
  id: 'changePcpInd',
  name: 'changePcpInd',
  label: 'Change PCP',
  trueValue: true,
  falseValue: false
};

export const EMULATE_FLAG = {
  id: 'emulateFlagInd',
  name: 'emulateFlagInd',
  label: 'Emulate Flag',
  trueValue: true,
  falseValue: false
};

export const SECURE_STATE = {
  id: 'secureStateInd',
  name: 'secureStateInd',
  label: 'Secure State',
  trueValue: true,
  falseValue: false
};

export const CARE_TEAM = {
  id: 'careTeamInd',
  name: 'careTeamInd',
  label: 'Care Team',
  trueValue: true,
  falseValue: false
};

export const SECUREFLAGS = {
  emulateFlag: EMULATE_FLAG,
  secureState: SECURE_STATE,
  castLightTransparency: CASTLIGHT_TRANSPARENCY,
  webView: WEB_VIEW,
  changePCP: CHANGE_PCP,
  careTeam: CARE_TEAM
};

export enum DEFAULT_TEXT_LIST {
  SPECIALTY = 'S',
  SUBSPECIALTY = 'SS',
  LEVELOFCARE = 'LOC',
  NOPREFERENCE = 'NP',
  ALL = 'ALL'
}

export enum TAB_VIEW {
  LIST_VIEW = 'LISTVIEW',
  TILE_VIEW = 'TILEVIEW',
  MAP_VIEW = 'MAPVIEW'
}

export enum LANDING_TAB_VIEW {
  MEMBER_VIEW = 0,
  GUEST_VIEW = 1
}

export enum DETAILS_TAB_VIEW {
  PROFILE_VIEW,
  RECOGNITIONS_VIEW,
  INSURANCE_VIEW,
  PRICING_VIEW
}
export enum SOURCE_SYSTEM_NAME {
  BCBSA = 'bcbsa'
}
export enum FEATURE_SEARCH_TYPE {
  NPI = 'NPI',
  LICENSE = 'LICENSE',
  NAME = 'NAME'
}

export enum FOCUS_TYPE {
  GUEST = 'GUEST',
  MEMBER = 'MEMBER'
}

export const INNETWORK = 'TP_INNETWORK';
export const OUTNETWORK = 'TP_OUTNETWORK';

export enum SEARCH_IMPROVEMENTS {
  IMPROVED_SEARCH_CRITERIA = 'IMPROVED_SEARCH_CRITERIA',
  REMOVED_SUBSPECIALTIES = 'REMOVED_SUBSPECIALTIES',
  RESULTS_FOR_SPECIALTIES = 'RESULTS_FOR_SPECIALTIES',
  REMOVED_PRIMEGROUP = 'REMOVED_PRIMEGROUP',
  INCREASED_RADIUS = 'INCREASED_RADIUS'
}

export const NORATING = 'No Rating Available';

export const providerOutputFlags = {
  visibilityRulesFlag: true,
  reviewRatingsFlag: true,
  imposeAddressConsolidation: true,
  improveSearchCriteria: true,
  includeSpfInfo: false,
  includeProviderNetworks: false,
  includePrimeGroupSearch: false,
  includeDisclaimerRules: true
};

export const PRIME_GROUP_SEARCH_CONSTANTS = {
  STATE: 'CA',
  PRODUCTTYPE: 'HMO'
};

export const PAGENUMBER = '1';

export const TIER_ONE_FILTER = 'tier_one';

export const SPECIAL_FILTER_CATEGORIES = ['able_to_serve_pcp', 'medicaid', 'acceptingnewpatient', 'ecp_providers'];

export const DEEP_LINK_ID = 'sso.dpl.rcp.find-care';

export const DEEP_LINK_CMP = 'cmp=EM-HCR-01-LOG-ABS';

export const ZIPCODE = 'zipcode';

export const EQUAL_OPERAND = '=';

export const AMPERSAND = '&';

export const CITY = 'city';

export const STATE = 'state';

export const PROCCODE = 'proccode';

export const DOT_OPERAND = '.';

export const COMMA = ',';

export const CULTURE = 'culture';

export const CULTURE_VALUE = 'mx';

export const TAXONOMIES = 'taxonomies';

export const PILL_ID = {
  hospitalaffiliation: {
    label: 'lbl-hospitalaffiliation-heading',
    adobeTag: 'hospitalaffiliation'
  },
  groupaffiliation: {
    label: 'lbl-groupaffiliation-heading',
    adobeTag: 'groupaffiliation'
  },
  medicalgrpAffiliation: {
    label: 'lbl-medicalGrpAfflitn-heading',
    adobeTag: 'medicalgrpAffiliation'
  },
  recognition: {
    label: 'lbl-quality-heading',
    adobeTag: 'recognition'
  },
  language: {
    label: 'lbl-language-heading',
    adobeTag: 'language'
  },
  gender: {
    label: 'lbl-gender-heading',
    adobeTag: 'gender'
  },
  ethnicity: {
    label: 'lbl-ethnicity-heading',
    adobeTag: 'ethnicity'
  },
  patientPopulation: {
    label: 'lbl-patientpreference-heading',
    adobeTag: 'patientPopulation'
  },
  aoe: {
    label: 'lbl-aoe-heading',
    adobeTag: 'aoe'
  },
  ada: {
    label: 'lbl-ada-heading',
    adobeTag: 'ada'
  },
  levelOfCare: {
    label: 'lbl-levelofcare-heading',
    adobeTag: 'levelOfCare'
  },
  boardCertified: {
    label: 'lbl-boardcertification-heading',
    adobeTag: 'boardCertified'
  },
  distance: {
    label: 'lbl-distance-heading',
    adobeTag: 'distance'
  },
  speciality: {
    label: 'lbl-specialty-heading',
    adobeTag: 'speciality'
  },
  subSpeciality: {
    label: 'lbl-subspecialty-heading',
    adobeTag: 'subSpeciality'
  },
  eycProviderType: {
    label: 'lbl-eyc-hospdoctext-heading',
    adobeTag: 'eycProviderType'
  },
  fadProviderType: {
    label: 'lbl-providerType-heading',
    adobeTag: 'fadProviderType'
  },
  pharmacyLevel: {
    label: 'lbl-level-heading',
    adobeTag: 'pharmacyLevel'
  },
  pharmacyPreferred: {
    label: 'lbl-preferred-heading',
    adobeTag: 'pharmacyPreferred'
  },
  pharmacyFeatures: {
    label: 'lbl-features-heading',
    adobeTag: 'pharmacyFeatures'
  },
  pharmacyType: {
    label: 'lbl-type-heading',
    adobeTag: 'pharmacyType'
  },
  bdc: {
    label: 'lbl-bdc-heading',
    adobeTag: 'bdc'
  },
  serviceAvailable: {
    label: 'lbl-services-available-heading',
    adobeTag: 'serviceAvailable'
  },
  licenseType: {
    label: 'lbl-licensetype-heading',
    adobeTag: 'licenseType'
  },
  phone: {
    label: 'lbl-phone-heading',
    adobeTag: 'phone'
  },
  email: {
    label: 'lbl-email-heading',
    adobeTag: 'email'
  },
  profile: {
    label: 'lbl-profile-heading',
    adobeTag: 'profile'
  },
  pcp: {
    label: 'lbl-pcp-heading',
    adobeTag: 'pcp'
  },
  plan: {
    label: 'lbl-plan-heading',
    adobeTag: 'plan'
  },
  officeService: {
    label: 'lbl-office-service-heading',
    adobeTag: 'officeService'
  },
  medicalCodes: {
    label: 'lbl-medical-codes-heading',
    adobeTag: 'medicalCodes'
  },
  cme: {
    label: 'lbl-cme-heading',
    adobeTag: 'cme'
  },
  adp: {
    label: 'lbl-adp-heading',
    adobeTag: 'recognition'
  }
};
export const HEALTH_SYSTEM_ID = 'healthSystem_01';

export const EMPTY_STRING = '';

export const Y_STR = 'Y';
export const DOLLAR_SYMBOL = '$';
export const NO = 'N';
export const YES = 'Y';

export const TP_ACCREDITED = 'tp_accredited';
export const TP_NOTACCREDITED = 'tp_notaccredited';

export enum API_NAME {
  TCC_MEDICAL = 'TCC_MEDICAL',
  TCC_DENTAL = 'TCC_DENTAL',
  SECURE_SPECIALTY = 'SECURE_SPECIALTY',
  SECURE_RX_SPECIALTY = 'SECURE_RX_SPECIALTY',
  PUBLIC_SPECIALTY = 'PUBLIC_SPECIALTY',
  PUBLIC_TCC_MEDICAL = 'PUBLIC_TCC_MEDICAL',
  SECURE_CPT_MEDICAL = 'SECURE_CPT_MEDICAL',
  SECURE_SPECIALTY_V2 = 'SECURE_SPECIALTY_V2',
  PUBLIC_SPECIALITY_V2 = 'PUBLIC_SPECIALITY_V2'
}

export enum PDF_FILE_NAME {
  MEDICAL_PROCEDURE = 'medical_procedure',
  DENTAL_PROCEDURE = 'dental_procedure',
  MEDICAL_CODE = 'medical_code',
  SEARCH_ON = 'search_on'
}

export const UNDERSCORE = '_';
export const HYPHEN = '-';

export const FEEDBACK_INTENDED_TASK_OPTIONS = {
  options: [
    {
      id: 'rb_intendedTask_yes',
      name: 'intendedTask',
      value: 'Y',
      label: 'Yes'
    },
    {
      id: 'rb_intendedTask_no',
      name: 'intendedTask',
      value: 'N',
      label: 'No'
    }
  ]
};

export const OON_ADOBE_TAGS = {
  searchOutNetDropdownFindCare: 'searchOutNetDropdownFindCare',
  outNetDefinitionDropdownFindCare: 'outNetDefinitionDropdownFindCare',
  outNetDefinitionResultsFindCare: 'outNetDefinitionResultsFindCare',
  outNetDefinitionDetailsFindCare: 'outNetDefinitionDetailsFindCare',
  outNetModalViewedFindCare: 'outNetModalViewedFindCare',
  closeBtnOutNetFindCare: 'closeBtnOutNetFindCare',
  backArrowOutNetFindCare: 'backArrowOutNetFindCare'
};
