import { Injectable } from '@angular/core';
import { CommonUtil } from '../../../app/fad/utilities/commonUtil';
import { Locale } from '../constants/app-constants';
import { IPcpInfoRequest, IPcpInfoResponse } from '../interfaces/iPCPInfo';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { apiNameList } from './../../../environments/api-name-list';
import { HttpMethod } from './../../common/enums/httpMethodEnum';
import { IPCPResponse, IPCPValidateResponse } from './../../common/interfaces/iPcpDetails';
import { IPcpRequest } from './../../common/interfaces/iPcpRequest';
import { BaseService } from './../../common/services/baseService';
import { IReasonCodesRequest, IReasonCodesResponse } from './../../fad/assign-pcp/models/iReasonCodeList';
import { HttpClientService } from './httpClientService';
@Injectable()
export class PCPService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  validateModifyPCP(reqObj: IPcpRequest): Promise<IPCPValidateResponse> {
    reqObj.validate = true;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.assignPcpValidate,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getProviderPcpInfo(reqObj: IPcpInfoRequest): Promise<IPcpInfoResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerPcpInfo,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  /**
   * Add or Update PCP of a member
   * @param reqObj
   */
  modifyPCP(reqObj: IPcpRequest): Promise<any> {
    if (CommonUtil.isValidString(this.appSession?.deeplinkParams?.pcpid)) {
      reqObj.fromSeed = true;
    }
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.modifyPCP,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  /**
   * Remove PCP assigned for a member
   * Using HttpMethod Post due to server configurations not allowing DELETE method calls to include a request body
   * RCP PCP Portal Api is set up to call SOA Add or Delete depending on the contents of the request body
   */
  removePCP(reqObj: IPcpRequest): Promise<IPCPResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.removePCP,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  /**
   * Get pcp member details
   */
  getPcpMemberInfo(reqObj: IPcpRequest): Promise<IPCPResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.pcpMemberInfo,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getHeaders() {
    let headers = super.getHeaders();
    if (this.appSession && this.appSession.mobileOS && this.appSession.mobileOS !== '') {
      headers.push({
        name: 'meta-origin',
        value: this.appSession.mobileOS
      });
    }
    const locale = this.appSession && this.appSession.metaData && this.appSession.metaData.locale ? this.appSession.metaData.locale.toString() : Locale.ENGLISH;
    headers.push({
      name: 'meta-locale',
      value: locale
    });

    if (this.appSession && this.appSession.metaData && this.appSession.metaData.brandCd) {
      headers.push({
        name: 'meta-brandcd',
        value: this.appSession.metaData.brandCd
      });
    }
    return headers;
  }

  getPcpReasonCode(request: IReasonCodesRequest): Promise<IReasonCodesResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.pcpReasonCodes,
      data: request,
      headers: this.getHeaders()
    });
  }
}
