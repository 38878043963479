import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { AssignPcpService } from '../../../common/components/pcp/pfAssignPcpSvc';
import { PillItemLookupService } from '../../../common/components/pill-filter/services/pillItemLookupService';
import { Locale, TIER1 } from '../../../common/constants/app-constants';
import { ControlType } from '../../../common/enums/controlType';
import { IAdobe, IAdobeFilterDetail } from '../../../common/interfaces/iAdobe';
import { IBaseCode } from '../../../common/interfaces/iBaseCode';
import { IRecognition } from '../../../common/interfaces/iCommonSearchResponse';
import { IPlaceOfServices } from '../../../common/interfaces/iCptMedicalResponse';
import { IMessage } from '../../../common/interfaces/iMessage';
import { IWindow } from '../../../common/interfaces/iWindow';
import { DataHelper } from '../../../common/services/dataHelper';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppConfig } from '../../../common/values/appConfig';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IPillFilterOrder, IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { IAdaListResponse } from '../../search-providers/interfaces/iAdaListResp';
import { IBaseOptions, IOptions } from '../../search-providers/interfaces/iOptions';
import { IProviderSearchDdlValues } from '../../search-providers/interfaces/iProviderSearchDdlValues';
import { ISearchParameters } from '../../search-providers/interfaces/iSearchParameters';
import { IntegratedSearchService } from '../../search-providers/services/integratedSearchSvc';
import { ProviderUtilityService } from '../../search-providers/services/providerUtilitySvc';
import {
  ADP_RECOGNITIONCODES,
  ALERT_TYPE,
  CLINICAL_ADOBE_TRACKS,
  HOME_HEALTH_CARE,
  INDEX_FIFTY,
  INDEX_ZERO,
  LEVEL1,
  MEDICAID_CODE,
  OUT_OF_NETWORK,
  PRIME_GROUP_SEARCH_MESSAGE,
  PROVIDER_SEARCH_DISTANCE,
  PROVIDER_TYPE,
  PROVIDER_TYPE_OF_CARE,
  RETAIL_PHARMACY,
  SORT_DATA_ANALYTICS,
  TRANSPORTATION_SPECIALTY
} from '../../search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../utilities/commonUtil';
import { ResultsUtil } from '../../utilities/resultsUtil';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { IFilter } from '../interfaces/iAppliedFilters';
import { IDynamicFilters } from '../interfaces/iDynamicFilters';
import { IGroupAffiliations } from '../interfaces/iGroupAffiliations';
import { ISortOption } from '../interfaces/iSummaryResp';
import { CptSummaryModel } from '../models/cptSummaryModel';
import { SortModel } from '../models/sortModel';
import { SummaryModel } from '../models/summaryModel';
import { AppliedFilterService } from '../services/appliedFilterSvc';
import { FilterCategoryService } from '../services/filterCategorySvc';
import { ProviderSummaryService } from '../services/providerSummarySvc';
import { SearchResultsFilterService } from '../services/searchResultsfilterSvc';
import {
  BLUE_DISTINCTINON_CENTER,
  BLUE_DISTINCTINON_CENTER_PLUS,
  BLUE_DISTINCTION,
  DEFAULT_TEXT_LIST,
  EMPTY_STRING,
  FILTER,
  HEALTH_SYSTEM_ID,
  OON_PLAN,
  PAGENUMBER,
  PILL_ID,
  SPANISH_FILTER
} from '../values/providerSearchConstants';
import { IAffiliationOption } from './../../../common/components/control-list/interfaces/iAffiliationOption';
import { IRecognitionFilter, IRecognitionFilterV2 } from './../../../common/interfaces/iCommonSearchRequest';
import { IProvTypeSpecialtyUtilityResponse } from './../../search-providers/interfaces/iProvTypeSpecialtyUtility';
import { IPillID, IProviderType } from './../../search-providers/interfaces/iProviderType';
import { PROVIDESFLUSHOTS, RECOGNITION_FILTERS, VACCINE } from './../../search-providers/values/providerSearchConstants';
import { ALL_PLAN_TYPES, DENTAL_PLAN, MEDICAL_PLAN, PHARMACY_FEATURES, VISION_PLAN } from './../values/providerSearchConstants';

declare let window: any;
declare let _satellite: any;
@Component({
  moduleId: module.id,
  selector: 'app-fad-search-result-filter-cmp2',
  templateUrl: '../views/pfSearchResultsFilterCmp2.html',
  providers: []
})
export class PFSearchResultsFilterComponent2 extends BaseComponent implements OnInit, OnDestroy {
  filteredAdaAccessibility: IOptions[];
  adpFilterInfo: IRecognition = { code: '' };

  get isSecureState(): boolean {
    return this._appSession?.isSecureState;
  }

  distanceNm: string;
  @Input()
  set distance(distance: string) {
    this.distanceNm = distance;
  }
  @Input()
  pcpProviderCount: any;
  @Input()
  ecpProviderCount: any;
  @Input()
  medicaidProviderCount: any;
  @Input()
  anpProviderCount: any;
  @Input()
  filterGroupList: any;
  @Input()
  forwardfilterRule: any;
  @Input()
  oonProviders: boolean;
  @Output()
  applyFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  bindDynamicFilter: EventEmitter<ISearchParameters> = new EventEmitter<ISearchParameters>();
  @Output()
  closeFaqsContent = new EventEmitter();
  searchCriteria: ISearchParameters;
  specialtyExpand: boolean = false;
  specialties: IProviderSearchDdlValues = {};
  selectedSpecialty: string = '';
  subSpecialtyExpand: boolean = false;
  subSpecialties: IProviderSearchDdlValues;
  selectedSubSpecialty: string = '';

  providertypeExpand: boolean = false;
  providerTypes: IProviderSearchDdlValues;
  selectedProviderType: string = '';

  levelOfCareExpand: boolean = false;
  levelOfCares: IProviderSearchDdlValues;
  patientPreferenceExpand: boolean = false;
  patientPreferenceList: IProviderSearchDdlValues;
  pharmacyTypeList: IProviderSearchDdlValues;
  pharmacyFeatureList: IProviderSearchDdlValues;
  aoeExpand: boolean = false;
  aoeList: IProviderSearchDdlValues;
  adaList: IProviderSearchDdlValues;
  ofcSrvcList: IProviderSearchDdlValues;
  selectedAoe: string = '';
  selectedPatientPopulation: string = '';
  ethnicityExpand: boolean = false;
  ethnicityList: IProviderSearchDdlValues;
  selectedEthnicity: string = '';
  languageExpand: boolean = false;
  languageList: IProviderSearchDdlValues;
  selectedLanguage: string = '';
  hospitalAffiliationExpand: boolean = false;
  hospitalAffiliationList: IProviderSearchDdlValues;
  groupAffiliationExpand: boolean = false;
  groupAffiliationList: IProviderSearchDdlValues;
  medicalGrpAffiliationList: IProviderSearchDdlValues;
  licenseTypeExpand: boolean = false;
  licenseTypeList: IProviderSearchDdlValues;
  distanceExpand: boolean = false;
  isHealthSystemFormatDone: boolean = false;
  hasHealthSystem: boolean = false;
  genderExpand: boolean = false;
  optionsExpand: boolean = false;
  eycProviderTypeExpand: boolean = false;
  qualityExpand: boolean = false;
  levelExpand: boolean = false;
  featuresExpand: boolean = false;
  typeExpand: boolean = false;
  showMoreExpand: boolean = false;
  boardCertificationExpand: boolean = false;
  tierExpand: boolean = false;
  phoneExpand: boolean = false;
  emailExpand: boolean = false;
  serviceAvailableExpand: boolean = false;
  feature: IRulesResponse;
  pillFilterOrder: IPillFilterOrder;
  serviceAvailableValue: any;
  licenseData: any;
  showFilter: boolean = false;
  boolHideFilter: boolean = false;
  eycShow: boolean = false;
  bdcFilterList: any;
  bdcExpand: boolean = false;
  bdcCode: Array<string> = [];
  bdcSelectedNm: string = '';
  filter: any = FILTER;
  noPreference: string = this.WcsUtility.getDefaultText(DEFAULT_TEXT_LIST.NOPREFERENCE);
  invalidPhone: boolean = false;
  invalidEmail: boolean = false;
  locale: string;
  selectedText: string = '';
  showHospitalAffLoader: boolean = false;
  showGroupAffLoader: boolean = false;
  bindFilter: boolean = true;
  isInitalLoad: boolean = true;
  ctrlType = ControlType;
  hasPrimeGroupAffiliations = false;
  isPrimeGroupRemoved = false;
  memberMedicalGrpAffltn?: string;
  allText: string = DEFAULT_TEXT_LIST.ALL.toLowerCase();
  isDefaultCheckboxSelected: boolean;
  @Input()
  showResultInMobileView: boolean;

  pillID = PILL_ID;
  keyFilterTier = FILTER.tier?.options?.[0];
  recognitionTier = FILTER.tier?.options?.[1];

  providerTypeList: IProviderType[] = [];
  providerTypeSelection: any = {} as any;
  bdcFilter: IProviderSearchDdlValues;
  selectedPlanType: string = '';
  showMoreGeneralLink: boolean = false;
  hospitalSearch: string = '';
  medicalGroupSearch: string = '';
  isGroupAffiliationSearch = false;
  isMedicalGrpAffiltnSearch = false;
  showMemberMedicalGrpAffiliation = false;
  distanceFilter5 = [];
  selectedDistance: string = '';
  selectedPosCode: string = '';
  selectedModifierCode: string = '';
  posList: IPlaceOfServices[] = [];
  posCodes: IBaseOptions[] = [];
  modifierCodes: IBaseOptions[] = [];
  showCmeFilter = false;
  appConfig: AppConfig;
  accordionExpandCount: number;
  primeGroupLoaded: boolean = true;
  dynamicFilterLoaded: boolean = false;
  dynamicFilterOptions: IDynamicFilters = undefined;
  showOMH: boolean = true;
  showOAS: boolean = true;
  showCptFilters = false;
  recognitionFilter: IRecognitionFilter = {};
  recognitionFilterList: IRecognitionFilterV2[] = [];
  private _promises: any[] = [];
  private primeGroupResultSubscription: Subscription;
  private sortInputSubscription: Subscription;
  private searchAffiliation: Subscription;
  private groupAffiliations: IGroupAffiliations;
  private primeGroupSearchResult: Subscription;
  private removeFilter: Subscription;
  tierLabel = '';
  tierTooltip = '';
  private medicalCodeFilter: Subscription;
  private dynamicFilters: Subscription;
  private anpFilterSvc: Subscription;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerUtilityService: ProviderUtilityService,
    private _providerSummaryService: ProviderSummaryService,
    private _ruleService: RulesService,
    private _cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _dataService: DataService,
    private _searchResultsFilterService: SearchResultsFilterService,
    private _pillItemLookupService: PillItemLookupService,
    private _infoListSvc: InfoListService,
    public sumModel: SummaryModel,
    public sortModel: SortModel,
    private _integratedSearchService: IntegratedSearchService,
    private _appliedFilterService: AppliedFilterService,
    private _filterCategoryService: FilterCategoryService,
    public cptSummaryModel: CptSummaryModel,
    private _assignPcpService: AssignPcpService,
    @Inject('Window')
    private _window: IWindow
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');

    this.anpFilterSvc = this._assignPcpService.anpSubject.subscribe((data) => {
      let anpFilter: IPillID = { label: 'lbl-profile-heading', adobeTag: 'profile' };
      this.searchCriteria.acceptingNewPatients = true;
      this.onFilterChange(event, anpFilter);
    });
    this.searchAffiliation = this._searchResultsFilterService.searchInput.subscribe((data) => {
      if (data) {
        this.loadAffiliations(data);
      }
    });
    this.primeGroupSearchResult = this._searchResultsFilterService.onPrimeGroupSearchResponse.subscribe((isPrimeGroupSearchResponse: boolean) => {
      this.isPrimeGroupRemoved = false;
      this._appSession.isMemberMedicalGrpSearch = isPrimeGroupSearchResponse;
      this.showMemberMedicalGrpAffiliation = isPrimeGroupSearchResponse;
      if (isPrimeGroupSearchResponse) {
        this.populatePrimeGroupAffiliations();
      } else {
        this.primeGroupLoaded = true;
        this.handleYourMedicalGroupFilterVisibility();
        this.resetPrimeGroupAffiliation();
      }
    });
    this._searchResultsFilterService.onRemovedPrimeGroupRule().subscribe((isPrimeGroupRemoved: boolean) => {
      this.isPrimeGroupRemoved = false;
      if (this.isOnlyUrgentCareProviderType()) {
        this.isPrimeGroupRemoved = isPrimeGroupRemoved;
      }
    });

    this.removeFilter = this._appliedFilterService.removeAppliedFilter.subscribe((data) => {
      if (data) {
        this.removeAppliedFilter(data);
      }
    });

    this.medicalCodeFilter = this.cptSummaryModel.cptSummaryResponse.subscribe((data) => {
      if (data) {
        this.posList = data.posInfos || [];
        this.posCodes = this.posList.map((posCd) => {
          const posCode = {} as IBaseOptions;
          posCode.value = posCode.label = posCd?.pos?.name?.slice(INDEX_ZERO, INDEX_FIFTY) ?? '';
          posCode.id = posCd.pos.code;
          return posCode;
        });
        this.modifierCodes = this.getModifierCodes(data.pos?.code);
        this.selectedPosCode = data.pos?.name;
        this._appSession.selectedPosCode = this.selectedPosCode;
        this.selectedModifierCode = data.modifier?.name;
        this._appSession.selectedModifierCode = this.selectedModifierCode;
      }
    });

    this.dynamicFilters = this._filterCategoryService.getDynamicFilters.subscribe((data) => {
      if (data) {
        this.loadDynamicFilters(data);
      }
    });
  }

  showMedicalCodeOptions() {
    return CommonUtil.isCptSearch(this._appSession);
  }

  fluShotTranslater() {
    if (!this.isSecureState) {
      this.content.summaryComponent.filter.labels.fluShots = this.content.summaryComponent.filter.vaccines;
      this.content.pharmacyContent.pharmacyFeatures.providesFluShots = this.content.summaryComponent.filter.vaccines;
    }
  }

  private handleYourMedicalGroupFilterVisibility(): void {
    if (this.feature?.showMedicalGroupAffiliation && !this.searchCriteria.ableToServePcp && this._appSession.isSecureState && this._appSession.primeGroupAffiliations?.affiliationList?.length > 0) {
      if (!(this.medicalGrpAffiliationList && this.medicalGrpAffiliationList?.options?.length > 0)) {
        this.medicalGrpAffiliationList = CommonUtil.buildDropDownValues(
          this._appSession.primeGroupAffiliations?.affiliationList,
          'cb_result_filter_membermedicalGrp_',
          this.noPreference,
          '00',
          'provider'
        );
      }
      this.showMemberMedicalGrpAffiliation = true;
      this.memberMedicalGrpAffltn = '00';
    }
  }

  private isOnlyUrgentCareProviderType(): boolean {
    return this.searchCriteria.providerTypeCodeList && this.searchCriteria.providerTypeCodeList.length === 1 && this.searchCriteria.providerTypeCodeList[0] === PROVIDER_TYPE.URGENT_CARE;
  }
  get getPromises(): any[] {
    return this._promises;
  }

  set resetPromises(object: any[]) {
    this._promises = object;
  }

  get tierIndicator() {
    return this.sumModel?.summaryResponse?.sortIndicator;
  }

  get showRecognitionFilter() {
    return !this._dataHelper.isEmptyString(this.tierIndicator?.name) || this.recognitionFilterList?.length > 0;
  }

  ngOnInit() {
    this.fluShotTranslater();
    this.appConfig = this._appSession.appConfig;
    this.accordionExpandCount = this.appConfig?.features?.pillFilter?.accordionDefaultExpand;

    if (this._appSession.feature?.showBVVFilter) {
      this.tierLabel = this.content.summaryComponent.filter.labels.bvvProvider;
      this.tierTooltip = this.content.summaryComponent.filter.tooltips.bvvProvider;
    } else if (this._appSession.feature?.showHPPFilter) {
      this.tierLabel = this.content.summaryComponent.filter.labels.hppProvider;
      this.tierTooltip = this.content.summaryComponent.filter.tooltips.hppProvider;
    } else {
      this.tierLabel = this.content.summaryComponent.filter.labels.benefitTier1;
      this.tierTooltip = this.content.summaryComponent.filter.tooltips.integratedTier1;
    }

    if (this.waitUntilAppReload) {
      return;
    }
    this.isDefaultCheckboxSelected = true;
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
    this.setPlanTypeLabels();
    this.filter = this._appSession.metaData.locale === Locale.SPANISH ? cloneDeep(SPANISH_FILTER) : cloneDeep(FILTER);

    if (this.filter && this.filter.distanceFilter?.options) {
      this.distanceFilter5 = this.filter.distanceFilter;
    }
    if (this._appSession && this._appSession.searchParams) {
      this._appSession.searchParams.includePrimeGroupSearch = true;
      this.searchCriteria = JSON.parse(JSON.stringify(this._appSession.searchParams));
      this.selectedDistance = this.searchCriteria.distanceNm;
      if (typeof this._appSession.filterSearchParams === 'undefined') {
        const _searchParams = Object.assign({}, this._appSession.searchParams);
        this._appSession.filterSearchParams = JSON.parse(JSON.stringify(_searchParams));
      }
      this.feature = this._appSession.feature;
      this.pillFilterOrder = this._appSession.pillFilterOrder ? this._appSession.pillFilterOrder : ({} as IPillFilterOrder);
      this.pouplateDropDownCollection();
    }
    if (this._appSession.isEyc) {
      this.eycShow = true;
      if (
        this.searchCriteria &&
        this.searchCriteria.eycSearchParam &&
        this.searchCriteria.eycSearchParam.eycProcedureCriteria &&
        this.searchCriteria.eycSearchParam.eycProcedureCriteria.providerCategoryCode &&
        this.searchCriteria.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
      ) {
        this.eycShow = false;
      }
      this.eycProviderTypeExpand = true;
      this.eycShowGender();
    }
    this.hospitalSearch = this._appSession.hospSearchTerm ? this._appSession.hospSearchTerm : '';
    this.validateSearchTerm(this.pillID.hospitalaffiliation, this.hospitalSearch);
    this.medicalGroupSearch = this._appSession.medicalGrpSearchTerm ? this._appSession.medicalGrpSearchTerm : '';
    this.validateSearchTerm(this.pillID.groupaffiliation, this.medicalGroupSearch);
    if (this.searchCriteria) {
      this.setPlanType();
    }

    this.adpFilterInfo = this._appSession.adpPgmCode || { code: '' };

    this.hasHealthSystem = this._appSession?.hasHealthSystem;
    this.showCptFilters = CommonUtil.isCptSearch(this._appSession);

    if (this._appSession.isOnlyAnpProvider) {
      this._assignPcpService.isAnpProvider(true);
      this._appSession.isOnlyAnpProvider = false;
    }

    // Invoking bind filter deselection.
    Promise.all(this.getPromises).then(() => {
      this.bindInitalFilterDeselection();
    });
  }

  ngOnDestroy() {
    //this.assignPcpSubscripton.unsubscribe(); // unsubscribe the assignPcpSubscripton value
    if (this.primeGroupResultSubscription) {
      this.primeGroupResultSubscription.unsubscribe();
    }
    if (this.sortInputSubscription) {
      this.sortInputSubscription.unsubscribe();
    }
    if (this.searchAffiliation) {
      this.searchAffiliation.unsubscribe();
    }
    if (this.primeGroupSearchResult) {
      this.primeGroupSearchResult.unsubscribe();
    }
    if (this.removeFilter) {
      this.removeFilter.unsubscribe();
    }
    if (this.medicalCodeFilter) {
      this.medicalCodeFilter.unsubscribe();
    }
    if (this.dynamicFilters) {
      this.dynamicFilters.unsubscribe();
    }
    if (this.anpFilterSvc !== null) {
      this.anpFilterSvc.unsubscribe();
    }
    this._filterCategoryService.updateDynamicFilters(undefined);
    this._filterCategoryService.filterCategoryList = [];
  }

  loadDynamicFilters(filters: IDynamicFilters) {
    if (this.dynamicFilterEnabled()) {
      this.showOMH = true;
      this.showOAS = true;
      if (this._appSession?.feature) {
        this.feature = Object.assign({}, this._appSession.feature);
      }
      this.dynamicFilterLoaded = false;
      this.dynamicFilterOptions = filters;
      this.bindDynamicFilters(filters);
    }
  }

  removeBracesForFilterItems(value: any) {
    return value.toString().slice(0, value.length - 1) + ' ' + this.content.summaryComponent.filter.providers + ')';
  }

  bindDynamicFilters(currentFilters: IDynamicFilters) {
    if (this.filter && currentFilters) {
      //Provider Types
      this.bindDynamicProviderTypes(this.feature?.showProviderTypes, currentFilters?.providerType, this._filterCategoryService?.providerTypeOptions);

      // Specialty Filter
      if (this.feature?.showSpeciality && this.searchCriteria?.speicalityDdlValues?.options) {
        const _dynamicSpecialties = currentFilters.specialty;
        //Bind Specialty Filters
        const _specialtyOptions = this._filterCategoryService.getDynamicFilterOptions(_dynamicSpecialties, this.searchCriteria.speicalityDdlValues.options);
        if (_specialtyOptions && _specialtyOptions.length > 0) {
          this.specialties.options = _specialtyOptions;
          if (this.searchCriteria?.specialtySelectNm) {
            const specialtySelectNm = _specialtyOptions
              .filter((specialty) => this.searchCriteria.specialtySelectNm?.includes(specialty.value))
              .map((type) => {
                return type.value;
              });
            this.searchCriteria.specialtySelectNm = specialtySelectNm;
            this.bindFilterDeselection(this.pillID.speciality.label);
          }
        }
      }

      // Sub-Specialty Filter
      this.bindDynamicSubSpecialities(currentFilters);

      //Gender
      if (this.feature?.showGender && !currentFilters.gender?.length) {
        this.feature.showGender = false;
        this._filterCategoryService.removeFilterCategory(this.pillID.gender.label);
      }

      //Language
      if (this.feature?.showLanguage && this.languageList?.options) {
        const dynamicLanguages = currentFilters?.language || currentFilters?.staffLanguage;
        const languages = this.bindDynamicOptions(this.feature.showLanguage, dynamicLanguages, this._filterCategoryService?.languageOptions?.options);
        if (languages?.length) {
          this.languageList.options = languages;
        } else {
          this.feature.showLanguage = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.language.label);
        }
      }

      //Ethnicity
      if (this.feature?.showEthnicity && this.ethnicityList?.options) {
        const ethnicities = this.bindDynamicOptions(this.feature.showEthnicity, currentFilters?.ethnicity, this._filterCategoryService?.ethnicityOptions?.options);
        if (ethnicities?.length) {
          this.ethnicityList.options = ethnicities;
        } else {
          this.feature.showEthnicity = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.ethnicity.label);
        }
      }

      //Level of Care
      if (this.feature?.showLevelOfCare && this.levelOfCares?.options) {
        const levelOfCares = this.bindDynamicOptions(this.feature.showLevelOfCare, currentFilters?.levelOfCare, this._filterCategoryService?.levelOfCareOptions?.options);
        if (levelOfCares?.length) {
          this.levelOfCares.options = levelOfCares;
        } else {
          this.feature.showLevelOfCare = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.levelOfCare.label);
        }
      }

      //Area of Expertise
      if (this.feature?.showAreaofExpertise && this.aoeList?.options) {
        const areaOfExpertise = this.bindDynamicOptions(this.feature.showAreaofExpertise, currentFilters?.areaOfExpertise, this._filterCategoryService?.areaOfExpertiseOptions?.options);
        if (areaOfExpertise?.length) {
          this.aoeList.options = areaOfExpertise;
        } else {
          this.feature.showAreaofExpertise = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.aoe.label);
        }
      }

      //AdaAccessibility
      if (this.adaList?.options) {
        // there is no visibility rule required for this filter.
        this.filteredAdaAccessibility = this.bindDynamicOptions(true, currentFilters?.adaAccessibilityList, this._filterCategoryService?.adaAccessibilityOptions?.options);
        if (this.filteredAdaAccessibility?.length) {
          this.adaList.options = this.filteredAdaAccessibility;
        } else {
          this._filterCategoryService.removeFilterCategory(this.pillID.ada.label);
        }
      }

      //Office Service
      if (this.feature?.showOfficeService && this.ofcSrvcList?.options) {
        const officeService = this.bindDynamicOptions(this.feature.showOfficeService, currentFilters?.officeService, this._filterCategoryService?.officeServiceOptions?.options);
        if (officeService?.length) {
          this.ofcSrvcList.options = officeService;
        } else {
          this.feature.showOfficeService = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.officeService.label);
        }
      }

      //Patient preference
      if (this.feature?.showPatientPopulation && this.patientPreferenceList?.options) {
        const patientPopulation = this.bindDynamicOptions(this.feature.showPatientPopulation, currentFilters?.patientPopulation, this._filterCategoryService?.patientPopulationOptions?.options);
        if (patientPopulation?.length) {
          this.patientPreferenceList.options = patientPopulation;
        } else {
          this.feature.showPatientPopulation = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.patientPopulation.label);
        }
      }

      //Services available
      if (this.feature?.showServiceAvailable) {
        const servicesAvailable = this.bindDynamicOptions(this.feature.showServiceAvailable, currentFilters?.servicesAvailable, this._filterCategoryService?.serviceAvailableOptions?.options);
        if (servicesAvailable?.length) {
          this.filter.serviceAvailable.options = servicesAvailable;
        } else {
          this.feature.showServiceAvailable = false;
          this._filterCategoryService.removeFilterCategory(this.pillID.serviceAvailable.label);
        }
      }

      this.bindDynamicBooleanOptions(currentFilters);
      if (this.networkProgramReferenceV2) {
        this.bindDynamicRecognitionOptionsV2(currentFilters);
      } else {
        this.bindDynamicRecognitionOptions(currentFilters);
      }
      this.bindDynamicPharmacyFilters(currentFilters);
      this.bindDynamicBlueDistinctionFilters(currentFilters);
      this.bindCMEDesignationFilters(currentFilters);

      if (!this.showProfilePill) {
        this._filterCategoryService.removeFilterCategory(this.pillID.profile.label);
      }
      if (this.networkProgramReferenceV2) {
        if (!this.showRecognitionFilter) {
          this._filterCategoryService.removeFilterCategory(this.pillID.recognition.label);
        }
      } else if (!this.showRecognition()) {
        this._filterCategoryService.removeFilterCategory(this.pillID.recognition.label);
      }
    }
    this.dynamicFilterLoaded = true;
  }

  bindDynamicProviderTypes(showProviderTypes: boolean, dynamicProvTypes: Array<string>, providerTypes: Array<IProviderType>) {
    if (showProviderTypes) {
      let _providerTypes = providerTypes?.filter((option) => dynamicProvTypes?.includes(option?.code)) ?? [];
      if (_providerTypes?.length) {
        this.providerTypeList = _providerTypes;
        if (this.searchCriteria?.providerTypeCodeList) {
          const providerTypeCodeList = _providerTypes
            .filter((type) => this.searchCriteria.providerTypeCodeList.includes(type.code))
            .map((type) => {
              return type.code;
            });
          this.searchCriteria.providerTypeCodeList = providerTypeCodeList;
          (this.searchCriteria.providerTypeCodeList || []).forEach((provType: string) => {
            if (this.providerTypeSelection && this.providerTypeSelection[provType]) {
              this.providerTypeSelection[provType].selected = true;
            }
          });
          Object.keys(this.providerTypeSelection).forEach((typeCode: string) => {
            if (!this.searchCriteria.providerTypeCodeList.includes(typeCode) && this.providerTypeSelection[typeCode].selected) {
              this.searchCriteria.providerTypeCodeList.push(typeCode);
            }
          });
          this.bindFilterDeselection(this.pillID.fadProviderType.label);
        }
      }
    }
  }

  bindDynamicSubSpecialities(filters: IDynamicFilters) {
    // Sub-Specialty Filter
    if (this.feature?.showSubSpeciality && this.searchCriteria?.subSpeicalityDdlValues?.options?.length) {
      let _subSpecialtyOptions: IOptions[] = [];
      if (filters?.subSpecialty) {
        const _dynamicSubSpecialties = filters.subSpecialty;

        //Bind Sub-Specialty Filters
        _subSpecialtyOptions = this._filterCategoryService.getDynamicFilterOptions(_dynamicSubSpecialties, this.searchCriteria.subSpeicalityDdlValues.options);
        if (this.searchCriteria?.taxonomySelectNm) {
          const subSpecialtySelectNm = _subSpecialtyOptions
            .filter((subSpecialty) => this.searchCriteria.taxonomySelectNm.includes(subSpecialty.value))
            .map((type) => {
              return type.value;
            });
          this.searchCriteria.taxonomySelectNm = subSpecialtySelectNm;
          this.bindFilterDeselection(this.pillID.subSpeciality.label);
        }
      }
      if (this.subSpecialties) {
        this.subSpecialties.options = _subSpecialtyOptions;
      }
    }
  }

  bindDynamicOptions(featureVisible: boolean, dynamicFilterOptions: Array<string>, filterOptions: Array<IOptions>): Array<IOptions> {
    let dynamicOptions: Array<IOptions> = undefined;
    if (featureVisible && dynamicFilterOptions?.length && filterOptions?.length) {
      const _dynamicFilterOptions = dynamicFilterOptions;
      dynamicOptions = this._filterCategoryService.getDynamicFilterOptions(_dynamicFilterOptions, filterOptions);
    }
    return dynamicOptions;
  }

  bindDynamicBooleanOptions(filters: IDynamicFilters) {
    if (this.feature) {
      if (this.feature.showAbleToServePCP && !filters.servepcp) {
        this.feature.showAbleToServePCP = false;
      }
      if (this.feature.showAcceptNewPatient && !filters?.acceptsPatients) {
        this.feature.showAcceptNewPatient = false;
      }
      if (this.feature.showAcceptMedicaid && !filters?.acceptsMedicaid?.includes(MEDICAID_CODE)) {
        this.feature.showAcceptMedicaid = false;
      }
      if (this.feature.showBoardCertification && !filters?.specialtyBoardCertified) {
        this.feature.showBoardCertification = false;
      }
      if (this.feature.showScheduleAppointment && !filters?.appointmentAvailable) {
        this.feature.showScheduleAppointment = false;
      }
      if (this.feature.showTeleHealth && !filters?.telehealth) {
        this.feature.showTeleHealth = false;
      }
      this.feature.showExtendedHoursFilter = (this.feature.showExtendedHoursFilter && (filters?.eveningHours || filters?.weekendHours)) ?? false;
    }
  }

  bindDynamicRecognitionOptions(filters: IDynamicFilters) {
    if (this.feature) {
      if (this.feature.showOMH) {
        if (!filters?.recognition?.includes(RECOGNITION_FILTERS.OFC_OF_MENTAL_HEALTH)) {
          this.showOMH = false;
        }
        if (!filters?.recognition?.includes(RECOGNITION_FILTERS.OFC_OF_ALCOHOLISM_AND_SUBSTANCE)) {
          this.showOAS = false;
        }
        this.feature.showOMH = this.showOMH || this.showOAS;
      }
      if (this.feature.showCooperativeCare && !filters?.recognition?.includes(RECOGNITION_FILTERS.COOPERATIVE_CARE)) {
        this.feature.showCooperativeCare = false;
      }
      if (this.feature.showMSP && !filters?.recognition?.includes(RECOGNITION_FILTERS.MEDICAL_SPECIALTY_PHARMACY)) {
        this.feature.showMSP = false;
      }
      if (this.feature.showValueBasedProvider && !filters?.recognition?.includes(RECOGNITION_FILTERS.VALUE_BASED_PROVIDERS)) {
        this.feature.showValueBasedProvider = false;
      }
      if (this.feature.showCenterOfExcellence && !filters?.recognition?.includes(RECOGNITION_FILTERS.CENTER_OF_EXCELLENCE)) {
        this.feature.showCenterOfExcellence = false;
      }
      if (this.feature.showProviderOfDistinction && !filters?.recognition?.includes(RECOGNITION_FILTERS.PROVIDER_OF_DISTINCTION)) {
        this.feature.showProviderOfDistinction = false;
      }
      if (this.feature.showCCare && !filters?.recognition?.includes(RECOGNITION_FILTERS.CCARE)) {
        this.feature.showCCare = false;
      }
      if (this.feature.showUpswing && !filters?.recognition?.includes(RECOGNITION_FILTERS.UPSWING)) {
        this.feature.showUpswing = false;
      }
      if (this.feature.showSNYDental && !filters?.recognition?.includes(RECOGNITION_FILTERS.SNYDENTAL)) {
        this.feature.showSNYDental = false;
      }
      if (this.feature.showBluePrecision && !filters?.recognition?.includes(RECOGNITION_FILTERS.BLUE_PRECISION)) {
        this.feature.showBluePrecision = false;
      }
      if (this.feature.showBDTC && !filters?.recognition?.includes(RECOGNITION_FILTERS.TOTAL_CARE)) {
        this.feature.showBDTC = false;
      }
      if (this.feature.showBCP && !filters?.recognition?.includes(RECOGNITION_FILTERS.PREFERRED_SPECIALISTS)) {
        this.feature.showBCP = false;
      }
      if (this.feature.showSosTier && !filters?.recognition?.includes(RECOGNITION_FILTERS.SOS)) {
        this.feature.showSosTier = false;
      }
      if (this.feature.showLgbtqFilter && !filters?.recognition?.includes(RECOGNITION_FILTERS.LGBTQ)) {
        this.feature.showLgbtqFilter = false;
      }
      if (this.feature.showEPHC && !filters?.recognition?.includes(RECOGNITION_FILTERS.EPHC)) {
        this.feature.showEPHC = false;
      }
      if (this.feature.showECP && !filters?.recognition?.includes(RECOGNITION_FILTERS.ECP)) {
        this.feature.showECP = false;
      }
      if (filters?.recognition?.includes(RECOGNITION_FILTERS.GREEN_RIBBON)) {
        this.recognitionFilter.showGreenRibbonFilter = true;
      } else if (!filters?.recognition?.includes(RECOGNITION_FILTERS.GREEN_RIBBON)) {
        this.recognitionFilter.showGreenRibbonFilter = false;
      }
      if (this.feature.showLwrCpyPcpRcgnFltr && !filters?.recognition?.includes(RECOGNITION_FILTERS.LOWER_COPAY_PCP_RCG)) {
        this.feature.showLwrCpyPcpRcgnFltr = false;
      }
    }
  }

  bindDynamicRecognitionOptionsV2(filters: IDynamicFilters) {
    const selectedRecognitionCodes = this.searchCriteria?.recognitionCodes || [];
    if (filters?.recognitionV2?.length) {
      this.recognitionFilterList = filters.recognitionV2.map((recognition) => {
        return {
          code: recognition.code,
          name: recognition.name,
          hoverText: recognition.hoverText,
          definition: { id: recognition.code, name: recognition.code, label: '', trueValue: true, falseValue: false },
          isSelected: selectedRecognitionCodes.includes(recognition.code)
        };
      });
    }
    this.bindFilterDeselection(this.pillID.recognition.label);
  }

  onRecognitionFilterChange(code: string, event: any, pillId: IPillID) {
    const codes = this.searchCriteria?.recognitionCodes || [];
    const index = codes.indexOf(code);
    if (index === -1) {
      codes.push(code);
    } else {
      codes.splice(index, 1);
    }
    this.searchCriteria.recognitionCodes = codes;
    this.onFilterChange(event, pillId);
  }

  bindDynamicPharmacyFilters(filters: IDynamicFilters) {
    //Pharmacy Type
    if (this.feature.showPharmacyType) {
      const pharmacyTypeOptions = this.bindDynamicOptions(this.feature.showPharmacyType, filters?.pharmacyTypes, this._filterCategoryService?.pharmacyTypeOptions?.options);

      if (pharmacyTypeOptions?.length) {
        this.pharmacyTypeList.options = pharmacyTypeOptions;
      } else {
        this.feature.showPharmacyType = false;
        this._filterCategoryService.removeFilterCategory(this.pillID.pharmacyType.label);
      }
    }

    //Pharmacy Features
    if (this.feature.showPharmacyFeatures) {
      const rxFeatures = (filters?.features || []).map((feature) => {
        let featureName = feature.toUpperCase();
        if (!this.isSecureState && featureName === VACCINE) {
          featureName = PROVIDESFLUSHOTS;
        }
        return featureName;
      });
      const features = [];
      rxFeatures.forEach((feature) => {
        if (PHARMACY_FEATURES[feature]) {
          features.push(PHARMACY_FEATURES[feature].toUpperCase());
        }
      });
      const pharmacyFeatureOptions = this.bindDynamicOptions(this.feature.showPharmacyFeatures, features, this._filterCategoryService?.pharmacyFeatureOptions?.options);
      if (pharmacyFeatureOptions?.length) {
        this.pharmacyFeatureList.options = pharmacyFeatureOptions;
      } else {
        this.feature.showPharmacyFeatures = false;
        this._filterCategoryService.removeFilterCategory(this.pillID.pharmacyFeatures.label);
      }
    }

    //Preferred Pharmacy
    if (this.feature.showPharmPrefered && !filters?.preferredPharmacy) {
      this.feature.showPharmPrefered = false;
      this._filterCategoryService.removeFilterCategory(this.pillID.pharmacyPreferred.label);
    }

    //Level
    if (this.feature.showPharmacyLevel && !filters?.pharmacyLevel?.includes(LEVEL1)) {
      this.feature.showPharmacyLevel = false;
      this._filterCategoryService.removeFilterCategory(this.pillID.pharmacyLevel.label);
    }
  }

  bindCMEDesignationFilters(filters: IDynamicFilters) {
    if (this.feature?.showCMEDesignation) {
      const cmeFilter = this.bindDynamicOptions(true, filters?.cmeDesignations, this._filterCategoryService?.cmeDesignationOptions?.options);
      if (cmeFilter?.length) {
        this.filter.cmeDesignation.options = cmeFilter;
        this.showCmeFilter = true;
      } else {
        this.showCmeFilter = false;
      }
    }
  }

  bindDynamicBlueDistinctionFilters(filters: IDynamicFilters) {
    if (this.feature?.showBDC && this.bdcFilterList?.options) {
      let blueDistinction: Array<IOptions> = [];
      const blueDistinctionOptions = Object.assign([], this._filterCategoryService?.blueDistinctionOptions?.options);
      const dynamicBDOptions = filters.blueDistinction;
      if (dynamicBDOptions?.length && blueDistinctionOptions?.length) {
        blueDistinctionOptions?.forEach((option: IOptions) => {
          if (option?.value) {
            const value = option.value;
            const bdValue = value.concat('_', BLUE_DISTINCTION.BD).toUpperCase();
            const bdPlusValue = value.concat('_', BLUE_DISTINCTION.BD_PLUS).toUpperCase();
            option.bd.hidden = !dynamicBDOptions.includes(bdValue) ?? true;
            option.bdcPlus.hidden = !dynamicBDOptions.includes(bdPlusValue) ?? true;
            if (!option.bd.hidden || !option.bdcPlus.hidden) {
              blueDistinction.push(option);
            }
          }
        });
      }
      if (blueDistinction?.length) {
        this.bdcFilterList.options = blueDistinction;
      } else {
        this.feature.showBDC = false;
        this._filterCategoryService.removeFilterCategory(this.pillID.bdc.label);
      }
    }
  }

  //Pharmacy Feature dynamic filter
  showPharmacyFeature(pharmacyFeature: string): boolean {
    if (this.isDynamicFilterEnabled) {
      const hasFeature = (pharmacyFeature && this.dynamicFilterOptions?.features?.includes(pharmacyFeature.toUpperCase())) ?? false;
      return hasFeature;
    }
    return true;
  }

  onCMEDesignationChange(event: string, cme: IOptions) {
    if (!this.searchCriteria.cmeValue) {
      this.searchCriteria.cmeValue = [];
    }

    if (event !== '' && cme.value) {
      this.searchCriteria.cmeValue.push(cme.value);
      this.setAdobeTagOnFilterchange(cme, this.pillID.cme.adobeTag);
    } else {
      this.searchCriteria.cmeValue = this.searchCriteria.cmeValue.filter((x) => x !== cme.value);
    }
    this.bindForwardFilter();

    //Bind ProviderType applied filters.
    this.bindFilterDeselection(PILL_ID.cme.label);
  }

  selectedCMEDesignation(cme: IOptions) {
    if (this.searchCriteria && this.searchCriteria.cmeValue && this.searchCriteria.cmeValue.length && cme?.value && this.searchCriteria.cmeValue.indexOf(cme.value) > -1) {
      return cme.value;
    } else {
      return '';
    }
  }

  //Pharmacy Type dynamic filter
  showPharmacyType(pharmacyType: string): boolean {
    if (this.isDynamicFilterEnabled) {
      const hasType = (pharmacyType && this.dynamicFilterOptions?.pharmacyTypes?.includes(pharmacyType.toUpperCase())) ?? false;
      return hasType;
    }
    return true;
  }

  onPillItemFocus() {
    this.closeFaqsContent.emit();
  }

  getCategoryFilterOptions(categoryCode: string, filterGroup: any, options: any, selectedItem: string) {
    // Remove options that doesn't need to show provider count
    let _options = options.filter((opt) => (typeof opt.showFilterCount !== 'undefined' ? opt.showFilterCount === true : true));
    if (filterGroup && categoryCode && options) {
      const _fitlerGroupList = filterGroup.filter((x: any) => {
        return categoryCode === x.groupingCriteria?.toUpperCase();
      });
      _options = this.getFilterOptions(_fitlerGroupList, _options, selectedItem);
    }
    return _options;
  }

  getFilterOptions(fitlerGroupList: any, filterOptions: any, selectedItem: string) {
    let _fitlerGroupCountList = [];
    const _filterOptions = [];
    this.selectedText = '';
    if (selectedItem && selectedItem.length > 0) {
      this.selectedText = selectedItem;
    }

    for (const option of filterOptions) {
      let _optionValue = '';
      if (option) {
        const _optionItem = option.label;

        if (option.code) {
          _optionValue = option.code.toString();
        } else if (option.value) {
          _optionValue = option.value.toString();
        }

        let _count = 0;

        if (fitlerGroupList && fitlerGroupList[0] && fitlerGroupList[0].groupCountDetailsList && fitlerGroupList[0].groupCountDetailsList.length > 0) {
          _fitlerGroupCountList = fitlerGroupList[0].groupCountDetailsList;
          let _index = -1;
          if (_optionValue !== '') {
            _index = _fitlerGroupCountList.findIndex((x) => x.value.toString()?.toUpperCase() === _optionValue?.toUpperCase().trim());
          } else {
            _index = _fitlerGroupCountList.findIndex((x) => !x.value || x.value === '');
          }

          if (_index > -1) {
            _count = _fitlerGroupCountList[_index].count;
          }
        }
        if (option?.ariaLabel) {
          option.ariaLabel = option.label + ' (' + _count + ' ' + this.content.summaryComponent.filter.providers + ')';
        }
        //option.label = option.label + ' (' + _count + ')';
        if (selectedItem !== '' && _optionItem === selectedItem) {
          this.selectedText = option.label;
        }
        _filterOptions.push(option);
      }
    }
    return _filterOptions;
  }

  onBlueDistinctionTypeClick(event: any, typePlus: boolean, bdcValue?: string) {
    let bdcLabel: string = '';
    this.isInitalLoad = false;
    if (bdcValue) {
      this.pushBDC(bdcValue, event, typePlus);
    } else {
      if (!typePlus) {
        this.searchCriteria.blueDistinctionType = event;
        bdcLabel = this.content.summaryComponent.filter.bdc;
      } else {
        this.searchCriteria.blueDistinctionTypePlus = event;
        bdcLabel = this.content.summaryComponent.filter.bdcPlus;
      }
      this.setAdobeTagOnFilterchange(bdcLabel, this.pillID.bdc.adobeTag);
    }
    const bindForwardFilter: boolean = true;
    this.getRuleResponse(bindForwardFilter);
    //this.bindForwardFilter();

    //Bind Blue Distinction applied filters.
    this.bindFilterDeselection(this.pillID.bdc.label);
  }

  pushBDC(code: string, event?: any, typePlus?: boolean) {
    let index: number;
    let bdcLabel: string = '';
    if (!this.searchCriteria.blueDistinctionCenter.some((x) => x.code === code)) {
      const bdc: any = {
        code: code,
        bd: '',
        bdcPlus: ''
      };
      this.searchCriteria.blueDistinctionCenter.push(bdc);
    }
    this.searchCriteria.blueDistinctionCenter.forEach((x, i) => {
      if (x.code === code) {
        index = i;
      }
    });
    if (index >= 0 && event !== null && typeof event !== 'undefined') {
      if (typePlus) {
        this.searchCriteria.blueDistinctionCenter[index].bdcPlus = event;
        this.searchCriteria.blueDistinctionCenter[index].bd = this.searchCriteria.blueDistinctionCenter[index].bd !== '' ? this.searchCriteria.blueDistinctionCenter[index].bd : '';
        bdcLabel = this.content.summaryComponent.filter.bdcPlus;
      } else {
        this.searchCriteria.blueDistinctionCenter[index].bd = event;
        this.searchCriteria.blueDistinctionCenter[index].bdcPlus = this.searchCriteria.blueDistinctionCenter[index].bdcPlus !== '' ? this.searchCriteria.blueDistinctionCenter[index].bdcPlus : '';
        bdcLabel = this.content.summaryComponent.filter.bdc;
      }
      if (this.searchCriteria.blueDistinctionCenter[index].bdcPlus === '' && this.searchCriteria.blueDistinctionCenter[index].bd === '') {
        this.searchCriteria.blueDistinctionCenter.splice(index, 1);
      }
      this.setAdobeTagOnFilterchange(this.content.summaryComponent.filter.bdcValue[code] + ' ' + bdcLabel, this.pillID.bdc.adobeTag);
    } else {
      const bdc: any = {
        code: code,
        bd: '',
        bdcPlus: ''
      };
      this.searchCriteria.blueDistinctionCenter.push(bdc);
    }
  }

  selectedBlueDistinctionPlus(bdcValue) {
    let index: number;
    this.searchCriteria.blueDistinctionCenter.forEach((x, i) => {
      if (x.code === bdcValue) {
        index = i;
      }
    });
    if (index > -1) {
      return this.searchCriteria.blueDistinctionCenter[index].bdcPlus;
    } else {
      return null;
    }
  }

  selectedBlueDistinction(bdcValue) {
    let index: number;
    this.searchCriteria.blueDistinctionCenter.forEach((x, i) => {
      if (x.code === bdcValue) {
        index = i;
      }
    });
    if (index > -1) {
      return this.searchCriteria.blueDistinctionCenter[index].bd;
    } else {
      return null;
    }
  }

  clearPcpIdInRequest() {
    if (this.searchCriteria.pcpId) {
      this.searchCriteria.pcpId = undefined;
      this._appSession.searchParams.pcpId = undefined;
    }
  }

  onFilterChange(event: any, pillID?: IPillID) {
    this.isInitalLoad = false;
    this.clearPcpIdInRequest();
    if (this._dataHelper.areEqualStrings(pillID?.label, this.pillID?.pcp?.label)) {
      this.getRuleResponse();
      if (this.searchCriteria.ableToServePcp) {
        this.resetPrimeGroupAffiliation(true);
      }
    }

    this.bindForwardFilter();

    if (pillID && pillID.adobeTag) {
      this.setAdobeTagOnFilterchange(event, pillID.adobeTag);
    }
    if (this.searchCriteria?.recognitionCodes?.length) {
      this.searchCriteria.adpFilter = this.searchCriteria.recognitionCodes.some(code => ADP_RECOGNITIONCODES.includes(code));
    } else {
      this.searchCriteria.adpFilter = false;
    }
    //Bind filter applied filters.
    this.bindFilterDeselection(pillID?.label);
  }

  private changeProviderTypeBasedOnPlanType(providerTypeCode: string, providerTypeName: string) {
    if (this._filterCategoryService?.providerTypeOptions?.length) {
      const providerTypeList: IProviderType[] = this._filterCategoryService.providerTypeOptions.filter((provtype: IProviderType) => provtype.code === providerTypeCode);
      if (providerTypeList && providerTypeList.length > 0 && this.searchCriteria && this.searchCriteria.providerTypeCodeList) {
        this.searchCriteria.providerTypeCodeList.forEach((provType: string) => {
          if (this.providerTypeSelection && this.providerTypeSelection[provType] && this.providerTypeSelection[provType].selected) {
            this.providerTypeSelection[provType].selected = false;
          }
        });
        this.searchCriteria.providerTypeCodeList = [];
        this.onProviderTypeChange(providerTypeName, providerTypeList[0], true);
      }
    }
  }

  onPlanTypeChange(event: string, isPlanTypeChange: boolean = false) {
    if (!(isPlanTypeChange && this.isSelectedPlanType(event))) {
      this.searchCriteria.standAloneDental = false;
      this.searchCriteria.standAloneVision = false;
      this.searchCriteria.planTypeList = [];
      if (isPlanTypeChange) {
        if (this.searchCriteria.specialtySelectNm) {
          this.searchCriteria.specialtySelectNm = [];
        }
        if (this.searchCriteria.taxonomySelectNm) {
          this.searchCriteria.taxonomySelectNm = [];
        }
      }
      if (event === this.allText) {
        this.selectedPlanType = this.allText;
        this.setAdobeTagOnFilterchange(PROVIDER_TYPE_OF_CARE.MEDICAL, this.pillID.plan.adobeTag);
        if (this.feature.showDentalPlan) {
          this.setAdobeTagOnFilterchange(PROVIDER_TYPE_OF_CARE.DENTAL, this.pillID.plan.adobeTag);
        }
        if (this.feature.showVisionPlan) {
          this.setAdobeTagOnFilterchange(PROVIDER_TYPE_OF_CARE.VISION, this.pillID.plan.adobeTag);
        }
      }
      if (event === PROVIDER_TYPE_OF_CARE.MEDICAL) {
        this.selectedPlanType = PROVIDER_TYPE_OF_CARE.MEDICAL;
        this.searchCriteria.planTypeList.push(PROVIDER_TYPE_OF_CARE.MEDICAL);
      }
      if (event === PROVIDER_TYPE_OF_CARE.DENTAL) {
        this.selectedPlanType = PROVIDER_TYPE_OF_CARE.DENTAL;
        this.searchCriteria.planTypeList.push(PROVIDER_TYPE_OF_CARE.DENTAL);
        this.setAdobeTagOnFilterchange(PROVIDER_TYPE_OF_CARE.DENTAL, this.pillID.plan.adobeTag);
        if (isPlanTypeChange) {
          this.changeProviderTypeBasedOnPlanType(PROVIDER_TYPE.DENTAL, PROVIDER_TYPE_OF_CARE.DENTAL);
        }
      }
      if (event === PROVIDER_TYPE_OF_CARE.VISION) {
        this.selectedPlanType = PROVIDER_TYPE_OF_CARE.VISION;
        this.searchCriteria.planTypeList.push(PROVIDER_TYPE_OF_CARE.VISION);
        this.setAdobeTagOnFilterchange(PROVIDER_TYPE_OF_CARE.VISION, this.pillID.plan.adobeTag);
        if (isPlanTypeChange) {
          this.changeProviderTypeBasedOnPlanType(PROVIDER_TYPE.VISION, PROVIDER_TYPE_OF_CARE.VISION);
        }
      }
      if (event === OUT_OF_NETWORK) {
        this.selectedPlanType = OUT_OF_NETWORK;
        this.searchCriteria.planTypeList.push(OUT_OF_NETWORK);
      }
      this.onFilterChange(event);

      //Bind Pan network applied filters.
      this.bindFilterDeselection(this.pillID.plan.label);
    }
  }

  onDespenseTypeChange(event: string, pharmacyType: IOptions) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    this.searchCriteria.dispenseTypeCode = this.searchCriteria.dispenseTypeCode || [];

    if (event && pharmacyType?.value) {
      this.searchCriteria.dispenseTypeCode.push(pharmacyType.value);
      this.setAdobeTagOnFilterchange(pharmacyType.label, this.pillID.pharmacyType.adobeTag);
    } else if (pharmacyType?.value) {
      this.searchCriteria.dispenseTypeCode = this.searchCriteria.dispenseTypeCode.filter((x) => x !== pharmacyType.value);
    } else {
      this.searchCriteria.dispenseTypeCode = [];
    }

    this.bindForwardFilter();
    this.bindFilterDeselection(this.pillID.pharmacyType.label);
  }

  onPharmacyFeaturesChange(event: string, pharmacyFeature: IOptions) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    const propertyName = pharmacyFeature.value;

    if (this.searchCriteria[propertyName] === undefined) {
      this.setPharmacyPharmacyFeature(propertyName);
    }

    if (this.searchCriteria[propertyName] !== undefined) {
      if (event && propertyName) {
        this.searchCriteria[propertyName] = true;
        this.setAdobeTagOnFilterchange(pharmacyFeature.label, this.pillID.pharmacyFeatures.adobeTag);
      } else {
        this.searchCriteria[propertyName] = false;
      }

      this.bindForwardFilter();
      this.bindFilterDeselection(this.pillID.pharmacyFeatures.label);
    }
  }

  bindForwardFilter() {
    if (this.bindFilter || this._appSession.isEyc) {
      // emit dynamic filter event.
      if (this.dynamicFilterEnabled()) {
        this.bindDynamicFilter.emit(this.isInitalLoad ? this._appSession.searchParams : this.searchCriteria);
      }

      if (this.isInitalLoad === false) {
        this.applySearchFilter();
      }
      this.isInitalLoad = false;
    }
  }
  /* Forward filter - End */

  eycShowGender() {
    this.getRuleResponse();
  }

  clearAll() {
    // Clear all applied filters.
    this._appliedFilterService.clearFilters();
    this._appSession.medicalModifierOptionCode = '';
    this._appSession.medicalPosCode = '';
    this.primeGroupLoaded = false;
    this.resetFilterCategory();

    this.isDefaultCheckboxSelected = true;
    this.providerTypeList = this._filterCategoryService.providerTypeOptions;
    if (this._appSession?.filterSearchParams) {
      this._appSession.medicalGrpSearchTerm = '';
      this.selectedDistance = this._appSession.filterSearchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this.searchCriteria = JSON.parse(JSON.stringify(this._appSession.filterSearchParams));
    }
    if ((this._appSession?.primeGroupAffiliations?.affiliationList || []).length) {
      this.memberMedicalGrpAffltn = this._appSession.primeGroupAffiliations.affiliationList[0].providerIdentifier;
    }
    if (
      !this._dataHelper.isNonEmptyArray(this._appSession.searchParams.taxonomySelectNm) &&
      !this._dataHelper.isNonEmptyArray(this.searchCriteria.taxonomySelectNm) &&
      this._appSession.removedTaxonomies
    ) {
      this.searchCriteria.taxonomySelectNm.push(this._appSession.removedTaxonomies);
    }
    if (this.networkProgramReferenceV2) {
      this.clearRecognitionV2();
    } else {
      this.clearRecognition();
    }
    this._appSession.searchParams = this.searchCriteria;
    this.setPersonalizeMatch();
    this.clearDropDowns();
    this.setProviderTypeSelection(this.searchCriteria);
    this.getRuleResponse(false, true);
    this.invalidEmail = false;
    this.invalidPhone = false;
    this.resetGender();
    this.searchCriteria.providerTier = '';
    this.hospitalSearch = '';
    this.medicalGroupSearch = '';
    this.hospitalAffiliationList = {} as IProviderSearchDdlValues;
    this.groupAffiliationList = {} as IProviderSearchDdlValues;
    this.medicalGroupSearch = '';
    this.bindFilter = true;
    this.bdcCode = [];
    this.bindForwardFilter();
    this.resetPlanFilter();
    this._pillItemLookupService.destoryComponent();

    // Bind filter deselection.
    this.bindInitalFilterDeselection();
  }

  applySearchFilter(dataObj: any = null) {
    this.validatePhone();
    this.validateEmail();
    if (!this.invalidPhone && !this.invalidEmail) {
      this.bindAffiliation(dataObj);
      this.searchCriteria.selectedSortCriteria = null;
      this._appSession.searchParams = this.searchCriteria;
      this.applyFilter.emit(this.searchCriteria);
    }
  }

  validatePhone() {
    this.invalidPhone = false;
    if (this.searchCriteria.providerPhone && this.searchCriteria.providerPhone !== '') {
      if (this.searchCriteria.providerPhone.length < 10) {
        this.invalidPhone = true;
      } else {
        const pattern = /^[0-9]+$/;
        if (!pattern.test(this.searchCriteria.providerPhone)) {
          this.invalidPhone = true;
        }
      }
    }
  }

  validateEmail() {
    this.invalidEmail = false;
    if (this.searchCriteria.providerEmail && this.searchCriteria.providerEmail !== '') {
      const pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!pattern.test(this.searchCriteria.providerEmail)) {
        this.invalidEmail = true;
      }
    }
  }

  bindAffiliation(dataObj: any) {
    let _bindHospital = false;
    let _bindGroup = false;
    if (dataObj) {
      if (dataObj.hospitalAffiliation && this.groupAffiliationExpand) {
        _bindGroup = true;
      } else if (dataObj.groupAffiliation && this.hospitalAffiliationExpand) {
        _bindHospital = true;
      }
    } else {
      _bindHospital = this.hospitalAffiliationExpand;
      _bindGroup = this.groupAffiliationExpand;
    }
    if (_bindHospital) {
      this.showHospitalAffLoader = true;
      this.pouplateHospitalAffiliation();
    }
    if (_bindGroup && !this.hasPrimeGroupAffiliations) {
      this.showGroupAffLoader = true;
      this.pouplateGroupAffiliation();
    }
  }

  toggleFilter(filter: string) {
    switch (filter.toLocaleUpperCase()) {
      case 'Q':
        return (this.qualityExpand = !this.qualityExpand);
      case 'S':
      case 'SB':
        return (this.subSpecialtyExpand = !this.subSpecialtyExpand);
      case 'L':
        return (this.levelOfCareExpand = !this.levelOfCareExpand);
      case 'PL':
        return (this.levelExpand = !this.levelExpand);
      case 'PF':
        return (this.featuresExpand = !this.featuresExpand);
      case 'PT':
        return (this.typeExpand = !this.typeExpand);
      case 'P':
        return (this.patientPreferenceExpand = !this.patientPreferenceExpand);
      case 'A':
        return (this.aoeExpand = !this.aoeExpand);
      case 'EC':
        return (this.ethnicityExpand = !this.ethnicityExpand);
      case 'LG':
        return (this.languageExpand = !this.languageExpand);
      case 'D':
        return (this.distanceExpand = !this.distanceExpand);
      case 'G':
        return (this.genderExpand = !this.genderExpand);
      case 'O':
        return (this.optionsExpand = !this.optionsExpand);
      case 'E':
        return (this.eycProviderTypeExpand = !this.eycProviderTypeExpand);
      case 'SM':
        return (this.showMoreExpand = !this.showMoreExpand);
      case 'BC':
        return (this.boardCertificationExpand = !this.boardCertificationExpand);
      case 'TR':
        return (this.tierExpand = !this.tierExpand);
      case 'PH':
        return (this.phoneExpand = !this.phoneExpand);
      case 'EM':
        return (this.emailExpand = !this.emailExpand);
      case 'SA':
        return (this.serviceAvailableExpand = !this.serviceAvailableExpand);
      case 'HA':
        this.showHospitalAffLoader = !this.hospitalAffiliationExpand;
        this.pouplateHospitalAffiliation();
        return (this.hospitalAffiliationExpand = !this.hospitalAffiliationExpand);
      case 'GA':
        this.showGroupAffLoader = !this.groupAffiliationExpand;
        this.pouplateGroupAffiliation();
        return (this.groupAffiliationExpand = !this.groupAffiliationExpand);
      case 'LT':
        return (this.licenseTypeExpand = !this.licenseTypeExpand);
      case 'BDC':
        return (this.bdcExpand = !this.bdcExpand);
      default:
        return;
    }
  }

  getSpecialties() {
    if (!this._appSession.specialtyCategories?.length) {
      const promise = this._providerUtilityService
        .getSpecialityCodesList(CommonUtil.buildSpecialtiesRequest(this.searchCriteria, this._appSession))
        .then((result: IProvTypeSpecialtyUtilityResponse) => {
          this._appSession.specialtyCategories =
            this._appSession.planRule?.isTransportation === false ? result.specialtyCategoryList.filter((x) => x.code !== TRANSPORTATION_SPECIALTY) : result.specialtyCategoryList;
          this.bindSpecialties();
        })
        .catch((error: unknown) => {
          this.onError('Specialties', error);
        });

      this._promises.push(promise);
    } else {
      this.bindSpecialties();
    }
  }

  bindSpecialties() {
    if (this._appSession.specialtyCategories) {
      const allSpecialty = this.WcsUtility.getDefaultText(DEFAULT_TEXT_LIST.SPECIALTY);
      const specialtyCategoryList = this.searchCriteria.providerTypeCodeList?.length
        ? this._appSession.specialtyCategories.filter((specialty) => this.searchCriteria.providerTypeCodeList.some((provType) => specialty.providerTypes?.includes(provType)))
        : [...this._appSession.specialtyCategories];
      let specialties = CommonUtil.getSpecialty(specialtyCategoryList, allSpecialty, false);
      this.searchCriteria.speicalityDdlValues = { ...specialties };
      this.specialties = { ...specialties };
      this.bindSubSpecialties();
    }
  }

  // Function to preselect values in speciality checkbox
  setSelectedSpecialty(specialty: any) {
    if (
      this.searchCriteria &&
      this.searchCriteria.specialtySelectNm &&
      this.searchCriteria.specialtySelectNm.length > 0 &&
      specialty &&
      this.searchCriteria.specialtySelectNm.indexOf(specialty.value) > -1
    ) {
      return specialty.value;
    } else {
      return '';
    }
  }

  onSpecialtyChange($event: string, specialty: IOptions) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    if (!this.searchCriteria.specialtySelectNm) {
      this.searchCriteria.specialtySelectNm = [];
    }
    if ($event !== '') {
      this.searchCriteria.specialtySelectNm.push(specialty.value);
      this.setAdobeTagOnFilterchange(specialty.label, this.pillID.speciality.adobeTag);
    } else {
      this.searchCriteria.specialtySelectNm = this.searchCriteria.specialtySelectNm.filter((x) => x !== specialty.value);
      this.resetSubSpecialty(specialty);
    }

    this.setPersonalizeMatch();
    this.getRuleResponse(true);

    //Bind Speciality applied filters.
    this.bindFilterDeselection(this.pillID.speciality.label);
  }

  showSpecialityDropdown() {
    // Additional logic for hearing provider type to hide speciality.
    // This is added to avoid additional rule API call.
    // Removed the Additional logic to hide speciality filter for hearing provider type
    // to avoid the missing speciality dropdown in the search results page.
    return this.feature && this.feature.showSpeciality;
  }

  onProviderTypeClick(event: any, providerType: IProviderType) {
    const selectedProvTypeList = this.searchCriteria.providerTypeCodeList.filter((provTypeCode) => this.providerTypeList.map((provType) => provType.code).includes(provTypeCode));

    const isSingleSelection = selectedProvTypeList?.length === 1;

    // do not allow to uncheck if there is only one provider type selected
    if (!this.isProcedureFiltersEnabled() && this.providerTypeSelection[providerType.code].selected && isSingleSelection) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }

  onProviderTypeChange(event: any, providerType?: IProviderType, isPlanFilterChanged: boolean = false) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    if (isPlanFilterChanged && this.searchCriteria && this.searchCriteria.providerTypeCodeList && this.searchCriteria.providerTypeCodeList.length === 0) {
      this.searchCriteria.providerTypeCodeList.push(providerType.code);
      this.providerTypeSelection[providerType.code].selected = true;
      this.resetMedicalGroupFilter();
      this.resetYourMedicalGroupFilter();
    } else {
      if (event === true && this.searchCriteria && this.searchCriteria.providerTypeCodeList && this.searchCriteria.providerTypeCodeList.indexOf(providerType.code) === -1) {
        this.searchCriteria.providerTypeCodeList.push(providerType.code);
        this.providerTypeSelection[providerType.code].selected = true;
      } else {
        // do not allow to uncheck if there is only one provider type selected
        if (event === false && (this.isProcedureFiltersEnabled() || this.searchCriteria.providerTypeCodeList?.length > 1)) {
          this.searchCriteria.providerTypeCodeList = this.searchCriteria.providerTypeCodeList.filter((x) => x !== providerType.code);

          this.providerTypeSelection[providerType.code].selected = false;
        } else {
          this.providerTypeSelection[providerType.code].selected = true;
        }
      }
      if (
        !this.eycShow &&
        !isPlanFilterChanged &&
        this.isPlanFilterEnabled() &&
        this.searchCriteria &&
        this.searchCriteria.providerTypeCodeList &&
        this.searchCriteria.providerTypeCodeList.length > 0
      ) {
        let planToBeSelected = this.allText;
        if (this.searchCriteria.providerTypeCodeList.length > 1) {
          planToBeSelected = this.allText;
        } else if (this.searchCriteria.providerTypeCodeList[0] === PROVIDER_TYPE.DOCTOR_PROFESSIONAL) {
          planToBeSelected = this.allText;
        } else if (this.searchCriteria.providerTypeCodeList[0] === PROVIDER_TYPE.DENTAL && this.feature.showDentalPlan) {
          planToBeSelected = PROVIDER_TYPE_OF_CARE.DENTAL;
        } else if (this.searchCriteria.providerTypeCodeList[0] === PROVIDER_TYPE.VISION && this.feature.showVisionPlan) {
          planToBeSelected = PROVIDER_TYPE_OF_CARE.VISION;
        }
        if (SearchRequestUtil.isOutOfNetwork(this._appSession)) {
          planToBeSelected = OUT_OF_NETWORK;
        }
        this.onPlanTypeChange(planToBeSelected);
      }
      const textArray = this.providerTypeList.filter((x) => x.code === providerType.code);
      if (event === true && textArray.length > 0) {
        this.setAdobeTagOnFilterchange(textArray[0].label, this.pillID.fadProviderType.adobeTag);
      }
      this.resetMedicalGroupFilter();
      this.resetYourMedicalGroupFilter();

      //Bind ProviderType applied filters.
      this.bindFilterDeselection(PILL_ID.fadProviderType.label);
    }

    this.setPersonalizeMatch();
    const bindForwardFilter: boolean = true;
    const providerTypeChanged: boolean = true;
    this.getRuleResponse(bindForwardFilter, providerTypeChanged);

    //Bind ProviderType applied filters.
    this.bindFilterDeselection(PILL_ID.fadProviderType.label);
  }

  // Binding level of care in dropdown, resetting level of care to default value 00
  getLevelOfCare() {
    this._promises.push(
      this._providerUtilityService.getLOCList(this.locale).then(
        (result: any) => {
          this.onLOCSuccess(result);
        },
        (error: any) => {
          this.onError('LOC', error);
        }
      )
    );
  }

  onLOCSuccess(data: any) {
    const allLevelOfCareTxt = this.WcsUtility.getDefaultText(DEFAULT_TEXT_LIST.LEVELOFCARE);
    if (data) {
      this.levelOfCares = CommonUtil.buildDropDownValues(data.levelOfCareList, 'cb_result_filter_levelofcare_', '', allLevelOfCareTxt);
      this._filterCategoryService.levelOfCareOptions = Object.assign({}, this.levelOfCares);
      if (this.levelOfCares && this.levelOfCares.options && this.levelOfCares.options.length > 0) {
        this.onLevelOfCareChange('', false);
      }
    }
  }

  onLevelOfCareChange($event: any, applyFilter: boolean = true, levelofcare?: IOptions) {
    this.bindFilter = true;
    if (!this.searchCriteria.levelOfCareList) {
      this.searchCriteria.levelOfCareList = [];
    }
    if ($event !== '' && applyFilter) {
      this.searchCriteria.levelOfCareList.push(levelofcare.value);
    } else {
      this.searchCriteria.levelOfCareList = this.searchCriteria.levelOfCareList.filter((x) => x !== levelofcare?.value);
    }
    if (applyFilter) {
      this.bindForwardFilter();
      this.setAdobeTagOnFilterchange(this.getFilterLabel($event, this.levelOfCares.options), this.pillID.levelOfCare.adobeTag);
    }

    //Bind levelOfCare applied filters.
    this.bindFilterDeselection(this.pillID.levelOfCare.label);
  }

  getSubSpecialty() {
    const _subSpecialties = CommonUtil.getSubSpecialty(this._appSession.specialtyCategories, this.searchCriteria.specialtySelectNm);
    this.searchCriteria.subSpeicalityDdlValues = Object.assign({}, _subSpecialties);
    this.subSpecialties = Object.assign({}, _subSpecialties);
    if (this.dynamicFilterOptions?.subSpecialty) {
      this.bindDynamicSubSpecialities(this.dynamicFilterOptions);
    }

    if (!this.subSpecialties || this.subSpecialties?.options?.length <= 0) {
      this.searchCriteria.taxonomySelectNm = [];
    }
  }

  onSubSpecialtyChange($event: string, subspecialty: IOptions) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    this.searchCriteria.taxonomySelectNm = this.searchCriteria.taxonomySelectNm || [];

    // Check box uncheck scenario, $event will be empty, as false value for definition of checkbox binding
    // is empty string.
    if ($event !== '') {
      this.searchCriteria.taxonomySelectNm.push(subspecialty.value);
      this.setAdobeTagOnFilterchange(subspecialty.label, this.pillID.subSpeciality.adobeTag);
    } else {
      this.searchCriteria.taxonomySelectNm = this.searchCriteria.taxonomySelectNm.filter((x) => x !== subspecialty.value);
    }

    this.setPersonalizeMatch();
    this.getRuleResponse(true);

    //Bind Sub-Speciality applied filters.
    this.bindFilterDeselection(this.pillID.subSpeciality.label);
  }

  // Function to preselect values in sub speciality checkbox
  setSelectedSubSpecialty(subSpecialty: any) {
    return this.searchCriteria.taxonomySelectNm?.includes(subSpecialty?.value) ? subSpecialty.value : '';
  }

  getPharmacyTypes(): Array<IOptions> {
    const pharmacyDispenseTypes = this.content.pharmacyContent?.pharmacydispenseTypes as Record<string, { name: string }> | undefined;

    const options: Array<IBaseCode> = pharmacyDispenseTypes ? Object.entries(pharmacyDispenseTypes).map(([key, value]) => ({ name: value.name, code: key })) : [];

    options.sort((a, b) => a.name.localeCompare(b.name));

    this.pharmacyTypeList = CommonUtil.buildDropDownValues(options, 'cb_result_filter_pharmacytype_', '', '00');
    this._filterCategoryService.pharmacyTypeOptions = { ...this.pharmacyTypeList };

    return [];
  }

  getPharmacyFeatures(): Array<IOptions> {
    const pharmacyFeatures = this.content.pharmacyContent?.pharmacyFeatures as Record<string, string> | undefined;

    const options: Array<IBaseCode> = pharmacyFeatures ? Object.entries(pharmacyFeatures).map(([key, value]) => ({ name: value, code: key })) : [];

    options.sort((a, b) => a.name.localeCompare(b.name));

    this.pharmacyFeatureList = CommonUtil.buildDropDownValues(options, 'cb_result_filter_pharmacyfeature_', '', '00');
    this._filterCategoryService.pharmacyFeatureOptions = { ...this.pharmacyFeatureList };

    return [];
  }

  getPatientPreferences() {
    this._promises.push(
      this._providerUtilityService.getPatientPreferencesList(this.locale).then(
        (result: any) => {
          this.onPatientPreferencesSuccess(result);
        },
        (error: any) => {
          this.onError('PatientPreferences', error);
        }
      )
    );
  }

  onPatientPreferencesSuccess(data: any) {
    if (data) {
      this.patientPreferenceList = CommonUtil.buildDropDownValues(data.codeTypeList, 'cb_result_filter_patientpreference_', '', '00');
      this._filterCategoryService.patientPopulationOptions = Object.assign({}, this.patientPreferenceList);
    }
  }

  preSelectPatientPopulation(patient: any) {
    if (
      this.searchCriteria &&
      this.searchCriteria.patientPreferenceSelectNm &&
      this.searchCriteria.patientPreferenceSelectNm.length > 0 &&
      patient &&
      this.searchCriteria.patientPreferenceSelectNm.indexOf(patient.value) > -1
    ) {
      return patient.value;
    } else {
      return '';
    }
  }

  preSelectPharmacyType(pharmacyType: IOptions) {
    const { dispenseTypeCode } = this.searchCriteria || {};
    return dispenseTypeCode?.includes(pharmacyType?.value) ? pharmacyType.value : '';
  }

  preSelectPharmacyFeature(pharmacyFeature: IOptions): string {
    const featureValue = pharmacyFeature?.value || '';
    const isPharmacyFeatureSelected = this.searchCriteria[featureValue] || false;
    return isPharmacyFeatureSelected ? featureValue : '';
  }

  setPharmacyPharmacyFeature(propertyName: string) {
    if (propertyName && this.searchCriteria[propertyName] === undefined) {
      this.searchCriteria[propertyName] = false;
    }
  }

  /**
   * Handle dropdown change event and apply search to refresh results page
   * $event => object having selected value
   * applyFilter => default value true. Decides whether to call search API.
   */
  onPatientPreferenceChange($event: any, patient: IOptions) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    if (!this.searchCriteria.patientPreferenceSelectNm) {
      this.searchCriteria.patientPreferenceSelectNm = [];
    }
    if ($event !== '' && patient.value) {
      this.searchCriteria.patientPreferenceSelectNm.push(patient.value);
      this.setAdobeTagOnFilterchange(patient.label, this.pillID.patientPopulation.adobeTag);
    } else {
      this.searchCriteria.patientPreferenceSelectNm = patient && patient.value ? this.searchCriteria.patientPreferenceSelectNm.filter((x) => x !== patient.value) : [];
    }

    this.bindForwardFilter();

    //Bind Patient Population applied filters.
    this.bindFilterDeselection(this.pillID.patientPopulation.label);
  }

  getAoe() {
    this._promises.push(
      this._providerUtilityService.getAOEList(this.locale).then(
        (result: any) => {
          this.onAoeSuccess(result);
        },
        (error: any) => {
          this.onError('Aoe', error);
        }
      )
    );
  }

  getAda() {
    this._promises.push(
      this._providerUtilityService.getADAList(this.locale).then(
        (result: IAdaListResponse) => {
          this.onAdaSuccess(result);
        },
        (error: any) => {
          this.onError('Aoe', error);
        }
      )
    );
  }

  onAoeSuccess(data: any) {
    if (data) {
      this.aoeList = CommonUtil.buildDropDownValues(data.codeTypeList, 'cb_result_filter_aoe_', '', '00');
      this._filterCategoryService.areaOfExpertiseOptions = Object.assign({}, this.aoeList);
    }
  }

  onAdaSuccess(data: IAdaListResponse) {
    if (data) {
      this.adaList = CommonUtil.buildDropDownValues(data.codeTypeList, 'cb_result_filter_ada_', '', '00');
      this._filterCategoryService.adaAccessibilityOptions = Object.assign({}, this.adaList);
    }
  }

  // Function to preselect values in AOE checkbox
  seletedAoe(aoe: any) {
    if (this.searchCriteria && this.searchCriteria.aoeSelectNm && this.searchCriteria.aoeSelectNm.length > 0 && aoe && this.searchCriteria.aoeSelectNm.indexOf(aoe.value) > -1) {
      return aoe.value;
    } else {
      return '';
    }
  }

  seletedAda(ada: any) {
    if (this.searchCriteria && this.searchCriteria.adaSelectNm && this.searchCriteria.adaSelectNm.length > 0 && ada && this.searchCriteria.adaSelectNm.indexOf(ada.value) > -1) {
      return ada.value;
    } else {
      return '';
    }
  }
  /**
   * Handle dropdown change event and apply search to refresh results page
   * $event => object having selected value
   */
  onAoeChange($event: any, aoe: any) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    if (!this.searchCriteria.aoeSelectNm) {
      this.searchCriteria.aoeSelectNm = [];
    }

    if ($event !== '' && aoe.value) {
      this.searchCriteria.aoeSelectNm.push(aoe.value);
      this.setAdobeTagOnFilterchange(aoe.label, this.pillID.aoe.adobeTag);
    } else {
      this.searchCriteria.aoeSelectNm = this.searchCriteria.aoeSelectNm.filter((x) => x !== aoe.value);
    }

    this.bindForwardFilter();

    //Bind Area of Expertise applied filters.
    this.bindFilterDeselection(this.pillID.aoe.label);
  }

  onAdaChange($event: any, ada: any) {
    this.bindFilter = true;
    this.isDefaultCheckboxSelected = false;
    if (!this.searchCriteria.adaSelectNm) {
      this.searchCriteria.adaSelectNm = [];
    }

    if ($event !== '' && ada.value) {
      this.searchCriteria.adaSelectNm.push(ada.value);
      this.setAdobeTagOnFilterchange(ada.label, this.pillID.ada.adobeTag);
    } else {
      this.searchCriteria.adaSelectNm = this.searchCriteria.adaSelectNm.filter((x) => x !== ada.value);
    }
    this.bindForwardFilter();

    //Bind AdaAccessibility applied filters.
    this.bindFilterDeselection(this.pillID.ada.label);
  }

  getOfcSrvc() {
    this._promises.push(
      this._providerUtilityService.getOfcSrvcList(this.locale).then(
        (result: any) => {
          this.onOfcSrvcSuccess(result);
        },
        (error: any) => {
          this.onError('OfcSrvc', error);
        }
      )
    );
  }

  onOfcSrvcSuccess(data: any) {
    if (data) {
      this.ofcSrvcList = CommonUtil.buildDropDownValues(data.codeTypeList, 'cb_result_filter_ofcSrvc_', '', '00');
      this._filterCategoryService.officeServiceOptions = Object.assign({}, this.ofcSrvcList);
    }
  }

  seletedOfcSrvc(officeService: any) {
    if (
      this.searchCriteria &&
      this.searchCriteria.ofcSrvcSelectNm &&
      this.searchCriteria.ofcSrvcSelectNm.length > 0 &&
      officeService &&
      this.searchCriteria.ofcSrvcSelectNm.indexOf(officeService.value) > -1
    ) {
      return officeService.value;
    } else {
      return '';
    }
  }

  onOfcSrvcChange($event: any, officeService: any) {
    this.isDefaultCheckboxSelected = false;
    if (!this.searchCriteria.ofcSrvcSelectNm) {
      this.searchCriteria.ofcSrvcSelectNm = [];
    }
    if ($event !== '' && officeService.value) {
      this.searchCriteria.ofcSrvcSelectNm.push(officeService.value);
    } else {
      this.searchCriteria.ofcSrvcSelectNm = this.searchCriteria.ofcSrvcSelectNm.filter((x) => x !== officeService.value);
    }
    this.bindForwardFilter();

    //Bind Office Service applied filters.
    this.bindFilterDeselection(this.pillID.officeService.label);
  }

  onGenderChange($event: string, applyFilter: boolean = true, gender?: IOptions) {
    this.bindFilter = true;
    if (!this.searchCriteria.genderCodeList) {
      this.searchCriteria.genderCodeList = [];
    }
    if ($event !== '' && applyFilter) {
      this.searchCriteria.genderCodeList.push(gender.value);
    } else {
      this.searchCriteria.genderCodeList = this.searchCriteria.genderCodeList.filter((x) => x !== gender?.value);
    }

    if (applyFilter) {
      this.bindForwardFilter();
      this.setAdobeTagOnFilterchange(gender.label, this.pillID.gender.adobeTag);
    }

    //Bind Gender applied filters.
    this.bindFilterDeselection(this.pillID.gender.label);
  }

  setGenderValue(gender: IOptions) {
    if (this.searchCriteria && this.searchCriteria.genderCodeList && this.searchCriteria.genderCodeList.length > 0 && gender && this.searchCriteria.genderCodeList.indexOf(gender.value) > -1) {
      return gender.value;
    } else {
      return '';
    }
  }

  getEthnicity() {
    this._promises.push(
      this._providerUtilityService.getEthnicityList(this.locale).then(
        (result: any) => {
          this.onEthnicitySuccess(result);
        },
        (error: any) => {
          this.onError('Ethnicity', error);
        }
      )
    );
  }

  setSelectedEthnicity(ethnicity: IOptions) {
    if (this.searchCriteria && this.searchCriteria.ethnicityList && this.searchCriteria.ethnicityList.length > 0 && ethnicity && this.searchCriteria.ethnicityList.indexOf(ethnicity.value) > -1) {
      return ethnicity.value;
    } else {
      return '';
    }
  }

  onEthnicitySuccess(data: any) {
    if (data) {
      this.ethnicityList = CommonUtil.buildDropDownValues(data.codeTypeList, 'cb_result_filter_ethnicity_');
      this._filterCategoryService.ethnicityOptions = Object.assign({}, this.ethnicityList);
    }
  }

  onEthnicityChange($event: string, applyFilter: boolean = true, ethnicity?: IOptions) {
    this.bindFilter = true;
    if (!this.searchCriteria.ethnicityList) {
      this.searchCriteria.ethnicityList = [];
    }
    if ($event !== '' && applyFilter) {
      this.searchCriteria.ethnicityList.push(ethnicity.value);
    } else {
      this.searchCriteria.ethnicityList = this.searchCriteria.ethnicityList.filter((x) => x !== ethnicity?.value);
    }

    if (applyFilter) {
      this.bindForwardFilter();
      this.setAdobeTagOnFilterchange(ethnicity.label, this.pillID.ethnicity.adobeTag);
    }

    //Bind Ethnicity applied filters.
    this.bindFilterDeselection(this.pillID.ethnicity.label);
  }

  getLanguage() {
    if (
      (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY ||
        (this.searchCriteria.providerTypeCodeList && this.searchCriteria.providerTypeCodeList.length > 0 && this.searchCriteria.providerTypeCodeList.indexOf(PROVIDER_TYPE.PHARMACY) > -1)) &&
      this.searchCriteria.coverageTypeCode === RETAIL_PHARMACY &&
      this._appSession.isSecureState === true
    ) {
      this._promises.push(
        this._providerUtilityService.getPharmacyLanguageList(this.locale).then(
          (result: any) => {
            this.onLanguageSuccess(result);
          },
          (error: any) => {
            this.onError('Language', error);
          }
        )
      );
    } else {
      this._promises.push(
        this._providerUtilityService.getLanguageList(this.locale).then(
          (result: any) => {
            this.onLanguageSuccess(result);
          },
          (error: any) => {
            this.onError('Language', error);
          }
        )
      );
    }
  }

  onLanguageSuccess(data: any) {
    if (data) {
      this.languageList = CommonUtil.buildDropDownValues(data.codeTypeList, 'cb_result_filter_language_');
      this._filterCategoryService.languageOptions = Object.assign({}, this.languageList);
    }
  }

  /**
   * Handle dropdown change event and apply search to refresh results page
   * $event => object having selected value
   * applyFilter => default value true. Decides whether to call search API.
   */
  onLanguageChange($event: any, applyFilter: boolean = true, language?: IOptions) {
    this.bindFilter = true;
    if (!this.searchCriteria.languages) {
      this.searchCriteria.languages = [];
    }
    if ($event !== '' && applyFilter) {
      this.searchCriteria.languages.push(language?.value);
    } else {
      this.searchCriteria.languages = this.searchCriteria.languages.filter((x) => x !== language?.value);
    }

    if (applyFilter) {
      this.bindForwardFilter();
      this.setAdobeTagOnFilterchange(language.label, this.pillID.language.adobeTag);
    }

    //Bind Language applied filters.
    this.bindFilterDeselection(this.pillID.language.label);
  }

  getHospitalAffiliation() {
    let searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', this.appConfig?.paginationSize.hospitalAffiliationPage, 'H');
    searchRequest = this.removeSpecialty(searchRequest);
    this._providerSummaryService.getAffiliationOptions(searchRequest).then(
      (result: any) => {
        if (this.hospitalSearch) {
          this.onHospitalAffiliationSuccess(result);
        }
        this.showHospitalAffLoader = false;
      },
      (error: any) => {
        this.onError('HospitalAffiliation', error);
        this.showHospitalAffLoader = false;
      }
    );
  }

  onHospitalAffiliationSuccess(data: any) {
    if (data) {
      this.hospitalAffiliationList = CommonUtil.buildDropDownValues(data.affiliationList, 'rb_result_filter_hospitalaffiliation', this.noPreference, '00', 'provider', false);
      this._appSession.isHospAffSearch = false;

      if (this.searchCriteria.hospitalAffiliationSelectNm && this.searchCriteria.hospitalAffiliationSelectNm !== '00') {
        this.onHospitalAffiliationChange(this.searchCriteria.hospitalAffiliationSelectNm, false);
      } else {
        this.onHospitalAffiliationChange('00', false);
      }
      this._cdr.detectChanges();
    }
  }

  /**
   * Handle dropdown change event and apply search to refresh results page
   * $event => object having selected value
   * applyFilter => default value true. Decides whether to call search API.
   */
  onHospitalAffiliationChange($event: any, applyFilter: boolean = true) {
    if ($event !== '') {
      this.searchCriteria.hospitalAffiliationSelectNm = $event;

      if (applyFilter === true) {
        this.applySearchFilter({ hospitalAffiliation: true });
        this.setAdobeTagOnFilterchange(this.getFilterLabel($event, this.hospitalAffiliationList.options), this.pillID.hospitalaffiliation.adobeTag);
        this.bindForwardFilter();
      }
    }

    //Bind hospital affiliation applied filters.
    this.bindFilterDeselection(this.pillID.hospitalaffiliation.label);
  }

  getGroupAffiliation() {
    let searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', this.appConfig?.paginationSize.groupAffiliationPage, 'G');
    searchRequest = this.removeSpecialty(searchRequest);
    this._providerSummaryService.getAffiliationOptions(searchRequest).then(
      (result: any) => {
        if (this.medicalGroupSearch) {
          this.onGroupAffiliationSuccess(result);
          this._appSession.isMedicalAffSearch = false;
        }
        this.showGroupAffLoader = false;
      },
      (error: any) => {
        this.onError('GroupAffiliation', error);
        this.showGroupAffLoader = false;
      }
    );
  }

  onGroupAffiliationSuccess(groupAffiliations: IGroupAffiliations): void {
    if (groupAffiliations) {
      // Bind dropdowns
      if (this._appSession.isMedicalAffSearch === true) {
        this.groupAffiliationList = CommonUtil.buildDropDownValues(groupAffiliations.affiliationList, 'cb_result_filter_groupaffiliation_', this.noPreference, '00', 'provider');
      } else {
        const affiliationList = this.hasHealthSystem && !this.isHealthSystemFormatDone ? this.getHealthSystemProviderName(groupAffiliations.affiliationList) : groupAffiliations.affiliationList;
        this.medicalGrpAffiliationList = CommonUtil.buildDropDownValues(affiliationList, 'cb_result_filter_membermedicalGrp_', this.noPreference, '00', 'provider');
      }

      if (this.memberMedicalGrpAffltn && this.memberMedicalGrpAffltn !== '00') {
        this.onMemberMedicalGrpChange(this.memberMedicalGrpAffltn, false);
      } else {
        this.onMemberMedicalGrpChange('00', false);
      }
      if (this.searchCriteria.groupAffiliationSelectNm && this.searchCriteria.groupAffiliationSelectNm !== '00') {
        this.onGroupAffiliationChange(this.searchCriteria.groupAffiliationSelectNm, false, true);
      } else {
        this.onGroupAffiliationChange('00', false);
      }

      if (this.searchCriteria.groupAffiliationSelectNm && this.searchCriteria.groupAffiliationSelectNm !== '00') {
        this.memberMedicalGrpAffltn = '00';
      } else if (this.memberMedicalGrpAffltn && this.memberMedicalGrpAffltn !== '00') {
        this.searchCriteria.groupAffiliationSelectNm = '00';
      }
      // commenting below line for now as not sure why change detecting is explictly called
      // this line is causing error in unit test
      // this._cdr.detectChanges();
    }
  }

  /**
   * Handle dropdown change event and apply search to refresh results page
   * $event => object having selected value
   * applyFilter => default value true. Decides whether to call search API.
   */
  onGroupAffiliationChange($event: any, applyFilter: boolean = true, itemChanged: boolean = false): void {
    if ($event !== '') {
      if (applyFilter === true && itemChanged === true) {
        this.isGroupAffiliationSearch = true;
        this.searchCriteria.groupAffiliationSelectNm = $event;
        this.memberMedicalGrpAffltn = '00';
        this.isMedicalGrpAffiltnSearch = false;
      }
      this.searchCriteria.groupAffiliationSelectNm = $event;
      this.searchCriteria.groupAffiliationHasChanged = itemChanged;
      //set includePrimeGroupSearch false if No Preference is selected.
      if ((applyFilter && itemChanged && $event === '00') || (this.memberMedicalGrpAffltn === '00' && this.searchCriteria.groupAffiliationSelectNm === '00')) {
        this.searchCriteria.includePrimeGroupSearch = false;
      } else {
        this.searchCriteria.includePrimeGroupSearch = true;
      }

      if (applyFilter === true) {
        this.applySearchFilter({ groupAffiliation: true });
        this.setAdobeTagOnFilterchange(this.getFilterLabel($event, this.groupAffiliationList.options), this.pillID.groupaffiliation.adobeTag);
        this.bindForwardFilter();
      }
    }

    //Bind group affiliation applied filters.
    this.bindFilterDeselection(this.pillID.groupaffiliation.label);
  }

  onMemberMedicalGrpChange($event: any, applyFilter: boolean = true, itemChanged: boolean = false): void {
    if ($event !== '' && this.memberMedicalGrpAffltn !== $event) {
      if (applyFilter && itemChanged) {
        this.isMedicalGrpAffiltnSearch = true;
        this.isGroupAffiliationSearch = false;
        this.searchCriteria.groupAffiliationSelectNm = '00';
        this.memberMedicalGrpAffltn = $event;
        this._appSession.isMedicalAffSearch = false;
      }
      //set includePrimeGroupSearch false if No Preference is selected.
      if (applyFilter && itemChanged && $event === '00') {
        this.searchCriteria.includePrimeGroupSearch = false;
        this.searchCriteria.includeHealthSystemSearch = false;
      } else if (applyFilter && itemChanged && $event === HEALTH_SYSTEM_ID) {
        this.searchCriteria.includeHealthSystemSearch = true;
      } else {
        this.searchCriteria.includePrimeGroupSearch = true;
        this.searchCriteria.includeHealthSystemSearch = false;
      }

      if (applyFilter === true) {
        this.applySearchFilter({ groupAffiliation: true });
        this.setAdobeTagOnFilterchange(this.getFilterLabel($event, this.medicalGrpAffiliationList.options), this.pillID.groupaffiliation.adobeTag);
        this.bindForwardFilter();
      }
    }

    //Bind group affiliation applied filters.
    this.bindFilterDeselection(this.pillID.groupaffiliation.label);
  }

  getLicenseType() {
    const data = this._appSession.providerTypes.filter((x) => x.code === this.searchCriteria.typeSelectNm);
    if (data && data.length > 0) {
      this.licenseTypeList = CommonUtil.buildDropDownValues(data[0].licenseList, 'cb_result_filter_licensetype_', this.noPreference, '00');

      if (this.searchCriteria.providerLicenseType && this.searchCriteria.providerLicenseType !== '00') {
        this.onLicenseTypeChange(this.searchCriteria.providerLicenseType, false);
      } else {
        this.onLicenseTypeChange('00', false);
      }
    }
  }
  /**
   * Handle dropdown change event and apply search to refresh results page
   * $event => object having selected value
   * applyFilter => default value true. Decides whether to call search API.
   */
  onLicenseTypeChange($event: any, applyFilter: boolean = true) {
    if ($event !== '') {
      this.searchCriteria.providerLicenseType = $event;

      if (applyFilter === true) {
        this.applySearchFilter();
        this.setAdobeTagOnFilterchange(this.getFilterLabel($event, this.licenseTypeList.options), this.pillID.licenseType.adobeTag);
      }
    }

    //Bind License Type applied filters.
    this.bindFilterDeselection(this.pillID.licenseType.label);
  }

  getBDCfilter() {
    let _typeSelect = PROVIDER_TYPE.HOSPITAL;
    const _filter = this.filter.bdcFilter.filter((x) => x.type === _typeSelect);
    const bdcFilters = _filter?.length > 0 ? _filter[0] : [];
    this.bdcFilterList = Object.assign({}, bdcFilters);
    this._filterCategoryService.blueDistinctionOptions = Object.assign({}, bdcFilters);
    const selectedBDC = this.searchCriteria.blueDistinctionCenter;
    // validate if selected speciality exist after changing provider type. if not remove from selected list
    if (this.bdcFilterList && this.bdcFilterList.options && this.bdcFilterList.options.length > 0 && selectedBDC?.length > 0) {
      for (const bdc of selectedBDC) {
        let hasBDC = false;

        if (this.bdcFilterList.options.some((x) => x.value === bdc.code)) {
          hasBDC = true;
        }

        if (hasBDC === false) {
          this.searchCriteria.blueDistinctionCenter = this.searchCriteria.blueDistinctionCenter.filter((c) => c.code !== bdc.code);
          this.bdcCode = this.bdcCode.filter((x) => x !== bdc.code);
        }
      }
    }
  }

  onError(type: string, error: any) {
    // TODO : Need to log error
    throw error;
  }

  getRuleResponse(bindForwardFilter: boolean = false, providerTypeChanged: boolean = false) {
    this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
      (rules: IRulesResponse) => {
        this.onRuleSuccess(rules, bindForwardFilter, providerTypeChanged);
      },
      (error: any) => {
        // todo
        throw error;
      }
    );
  }

  onRuleSuccess(rules: IRulesResponse, bindForwardFilter: boolean, providerTypeChanged: boolean) {
    this.feature = rules;
    this._appSession.feature = Object.assign({}, rules);
    this.pillFilterOrder = this._appSession.pillFilterOrder = rules && rules.pillFilter ? rules.pillFilter : ({} as IPillFilterOrder);
    if (!this._infoListSvc.doNotShowInfoListDisclaimer) {
      this._infoListSvc.abolishMessages();

      if (rules.disclaimer.length) {
        for (const disclaimer of rules.disclaimer) {
          this._infoListSvc.addMsgById(disclaimer);
          if (disclaimer in CLINICAL_ADOBE_TRACKS && typeof _satellite !== 'undefined') {
            _satellite.track(CLINICAL_ADOBE_TRACKS[disclaimer]);
          }
        }
      }
      this._infoListSvc.update();
    }
    //Bind dynamic filters after rules reload.
    this.loadDynamicFilters(this.dynamicFilterOptions);

    // interrupt summary call and show modal popup
    if (rules.showTransportation || rules.isMyNexus || rules.showMedicareSupplement) {
      // de-select transportation selection from specialty filter
      if (this.searchCriteria && this.searchCriteria.specialtySelectNm && this.searchCriteria.specialtySelectNm.length > 0 && rules.showTransportation) {
        this.searchCriteria.specialtySelectNm = this.searchCriteria.specialtySelectNm.filter((specialtyCode) => specialtyCode !== TRANSPORTATION_SPECIALTY);
        // Transportation specialty applied filter de-dselection on Medicare scenario.
        this._appliedFilterService.removeFilter({
          category: this.pillID.speciality.label,
          value: TRANSPORTATION_SPECIALTY
        } as IFilter);
      }

      // de-select home health care from specialty filter
      if (this.searchCriteria && this.searchCriteria.specialtySelectNm && this.searchCriteria.specialtySelectNm.length > 0 && rules.isMyNexus) {
        this.searchCriteria.specialtySelectNm = this.searchCriteria.specialtySelectNm.filter((specialtyCode) => specialtyCode !== HOME_HEALTH_CARE);
        // Home Health Care specialty applied filter de-dselection on MyNexus scenario.
        this._appliedFilterService.removeFilter({
          category: this.pillID.speciality.label,
          value: HOME_HEALTH_CARE
        } as IFilter);
      }

      // de-select provider type other than vision and hearing if medicare supplement
      if (this.searchCriteria && this.searchCriteria.providerTypeCodeList && this.searchCriteria.providerTypeCodeList.length > 0 && rules.showMedicareSupplement) {
        this.searchCriteria.providerTypeCodeList = this.searchCriteria.providerTypeCodeList.filter((type) => type === PROVIDER_TYPE.VISION || type === PROVIDER_TYPE.HEARING);

        this.searchCriteria.typeSelectNm = '';
        this.setProviderTypeSelection(this.searchCriteria);
      }

      this._integratedSearchService.setRules(rules);

      return;
    }

    this.clearForwardFilterOptions();

    if (this.feature && this.feature.showBDC) {
      this.getBDCfilter();
    }
    if (providerTypeChanged === true) {
      this.getSpecialties();
      this.clearSearchCriteria();

      Promise.all(this._promises).then(() => {
        // Bind filter deselection on provider type change.
        this.bindInitalFilterDeselection();

        if (bindForwardFilter === true) {
          this.bindForwardFilter();
        }

        //Re-bind the filter deselection on provider type change.
        this.bindAllFilterDeselection();
      });
    } else {
      this.bindSubSpecialties();
      if (bindForwardFilter === true) {
        this.bindForwardFilter();
      }

      //Bind Sub-Speciality applied filters.
      this.bindFilterDeselection(this.pillID.subSpeciality.label);
    }
  }

  bindSubSpecialties() {
    if (this.feature?.showSubSpeciality) {
      this.getSubSpecialty();
    } else {
      this.searchCriteria.taxonomySelectNm = [];
    }
  }

  setSelectedServiceAvailable(serviceAvailable: any) {
    if (
      this.searchCriteria &&
      this.searchCriteria.visionSrvcAvailableList &&
      this.searchCriteria.visionSrvcAvailableList.length > 0 &&
      serviceAvailable &&
      this.searchCriteria.visionSrvcAvailableList.indexOf(serviceAvailable.value) > -1
    ) {
      return serviceAvailable.value;
    } else {
      return '';
    }
  }

  onServiceAvailableChange(event: any, serviceAvailable: any) {
    if (!this.searchCriteria.visionSrvcAvailableList) {
      this.searchCriteria.visionSrvcAvailableList = [];
    }

    if (event !== '' && serviceAvailable.value) {
      this.searchCriteria.visionSrvcAvailableList.push(serviceAvailable.value);
      this.setAdobeTagOnFilterchange(serviceAvailable.label, this.pillID.serviceAvailable.adobeTag);
    } else {
      this.searchCriteria.visionSrvcAvailableList = this.searchCriteria.visionSrvcAvailableList.filter((x) => x !== serviceAvailable.value);
    }

    this.bindForwardFilter();

    //Bind Service Available applied filters.
    this.bindFilterDeselection(this.pillID.serviceAvailable.label);
  }

  onBlueDistictionCenterChange(item: IOptions) {
    if (item) {
      item.isChecked = !Boolean(item.isChecked);
      const value = item.value;
      if (value) {
        if (!this.bdcCode.some((x) => x === value)) {
          this.bdcCode.push(value);
        } else {
          const bdcIndex = this.bdcCode.indexOf(value);
          this.bdcCode.splice(bdcIndex, 1);
        }
      }
    }
  }

  onBlueDistictionCenterClear() {
    this.searchCriteria.blueDistinctionCenter = [];
    this.bdcCode = [];
  }

  pouplateDropDownCollection() {
    this.primeGroupLoaded = false;
    this.resetFilterCategory();
    this.resetPromises = [];
    this._filterCategoryService.genderOptions = Object.assign({}, this.filter.genderFilter);
    this._filterCategoryService.cmeDesignationOptions = Object.assign({}, this.filter.cmeDesignation);
    this._filterCategoryService.serviceAvailableOptions = Object.assign({}, this.filter.serviceAvailable);
    this.getProviderTypes();

    if (this.feature?.showSpeciality) {
      this.getSpecialties();
    }
    if (this.feature && this.feature.showLanguage) {
      this.getLanguage();
    }
    if (this.feature && this.feature.showLicenseType) {
      this.getLicenseType();
    }
    if (this.feature && this.feature.showBDC) {
      this.getBDCfilter();
    }
    if (!this._appSession.isEyc || (this._appSession.hasDentalEYC && this.feature && this.feature.showEthnicity && this.showDentalDDS)) {
      this.getEthnicity();
    }

    // pre-load patient preference and aoe
    // this is needed for provider type filter and dynamic filter rules
    this.getLevelOfCare();
    this.getAoe();
    this.getAda();
    this.getOfcSrvc();
    this.getPatientPreferences();
    this.getPharmacyTypes();
    this.getPharmacyFeatures();

    // Invoking filter count on success of list of codes service.
    Promise.all(this.getPromises).then(() => {
      this.bindFilter = true;
      this.bindForwardFilter();
    });
  }

  pouplateHospitalAffiliation() {
    if (this.showHospitalAffLoader && this.feature && this.feature.showAffialition && this._appSession && this._appSession.searchParams) {
      this.getHospitalAffiliation();
    }
  }

  pouplateGroupAffiliation() {
    if (this.showGroupAffLoader && this.feature?.showMedicalGroupAffiliation && this._appSession && this._appSession.searchParams) {
      this.getGroupAffiliation();
    }
  }

  clearDropDowns() {
    if (this.feature && this.feature.showAffialition) {
      this.onHospitalAffiliationChange('00', false);
    }
    if (this.feature?.showMedicalGroupAffiliation) {
      this.onGroupAffiliationChange('00', false);
    }
    if (this.showMemberMedicalGrpAffiliation) {
      this.onMemberMedicalGrpChange('00', false);
    }
    if (this.feature && this.feature.showLicenseType) {
      this.onLicenseTypeChange('00', false);
    }
    if (this.feature && this.feature.showLanguage) {
      this.onLanguageChange('00', false);
    }
    this.onEthnicityChange('00', false);
  }

  get showProfilePill() {
    return this.feature?.isSNYMember ? false : this.showGeneralSection;
  }

  get showGeneralSection() {
    return (
      this.feature &&
      (this.feature.showAcceptMedicaid ||
        this.feature.showECP ||
        this.feature.showBoardCertification ||
        this.feature.showScheduleAppointment ||
        this.feature.showTeleHealth ||
        this.feature.showExtendedHoursFilter)
    );
  }
  get showEthnicitySection() {
    return this.feature?.showEthnicity && this.ethnicityList?.options?.length;
  }

  get showRecognitionGeneralSection() {
    return (
      this.feature &&
      (this.feature.showCooperativeCare ||
        this.feature.showBluePrecision ||
        this.feature.showBDTC ||
        this.feature.showEPHC ||
        this.feature.showBDC ||
        this.feature.showTier ||
        this.recognitionFilter.showGreenRibbonFilter ||
        this.feature.showLwrCpyPcpRcgnFltr)
    );
  }

  showAdditionalOption() {
    return this.feature && (this.feature.showAcceptNewPatient || this.feature.showAbleToServePCP || this.feature.showAcceptMedicaid || this.feature.showECP || this.feature.showBoardCertification);
  }

  showRecognition() {
    return this.feature && (this.showRecognitionGeneralSection || this.feature.showBDC);
  }

  displayMoreOption() {
    return (
      this.feature &&
      (this.feature.showLanguage ||
        this.feature.showEthnicity ||
        this.feature.showAreaofExpertise ||
        this.feature.showPatientPopulation ||
        this.feature.showBoardCertification ||
        this.feature.showLicenseType ||
        this.feature.showPhone ||
        this.feature.showEmail ||
        this.feature.showAffialition ||
        this.feature.showServiceAvailable)
    );
  }

  onPhoneKeyPress(event: any) {
    const pattern = /[0-9]+/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSortChange(sortOption: ISortOption) {
    //To track adobe analytics values for sort drop down
    if (typeof _satellite !== 'undefined') {
      _satellite.track(this.setSortingAdobeAnalytics(this.sortModel.selectedValue));
    }

    if (this._appSession.searchParams.selectedSortCriteria !== sortOption.value && !this.sortModel.ignoreOnchange) {
      this._appSession.searchParams.selectedSortCriteria = sortOption.value;
      this._searchResultsFilterService.setSortFilter(sortOption);

      // emit dynamic filter event.
      if (this.dynamicFilterEnabled()) {
        this.bindDynamicFilter.emit(this._appSession.searchParams);
      }
    }
  }

  validateSearchTerm(pill: IPillID, value: string): void {
    value = value.trim().toLocaleLowerCase();
    const pillValue = { pill, value };
    if (value.length > 2) {
      this.showGroupAffLoader = true;
      this._searchResultsFilterService.setSearchInput(pillValue);
    } else {
      if (pill.label === this.pillID.hospitalaffiliation.label) {
        this.hospitalAffiliationList = {} as IProviderSearchDdlValues;
      } else {
        // bring back prime group details to fiter if user clear the search keyword in filter
        if (this.groupAffiliations && (this.groupAffiliations.affiliationList || []).length > 0) {
          this.onGroupAffiliationSuccess(this.groupAffiliations);
          this._appSession.isMedicalAffSearch = false;
        } else {
          this.groupAffiliationList = {} as IProviderSearchDdlValues;
        }
      }
    }
  }

  setPlanType() {
    if (this.searchCriteria.planTypeList && this.searchCriteria.planTypeList.length > 0) {
      this.selectedPlanType = this.searchCriteria.planTypeList[0];
    } else {
      this.selectedPlanType = this.allText;
    }
  }

  /**
   * To set the Sorting dropdown for data analytics based on value
   * @param sortSelectedValue
   */
  setSortingAdobeAnalytics(sortSelectedValue: string) {
    if (!sortSelectedValue) {
      return;
    }
    switch (sortSelectedValue.toLocaleLowerCase()) {
      case 'personalizedmatch':
        return this.eycShow ? SORT_DATA_ANALYTICS.EYC_PERSONALIZED_MATCH : SORT_DATA_ANALYTICS.FAD_PERSONALIZED_MATCH;
      case 'distance':
        return this.eycShow ? SORT_DATA_ANALYTICS.EYC_DISTANCE : SORT_DATA_ANALYTICS.FAD_DISTANCE;
      case 'atoz':
        return SORT_DATA_ANALYTICS.FAD_ATOZ;
      case 'atoz_eyc':
        return SORT_DATA_ANALYTICS.EYC_ATOZ;
      case 'ztoa':
        return SORT_DATA_ANALYTICS.FAD_ZTOA;
      case 'ztoa_eyc':
        return SORT_DATA_ANALYTICS.EYC_ZTOA;
      case 'tier':
        return this.eycShow ? SORT_DATA_ANALYTICS.EYC_TIER : SORT_DATA_ANALYTICS.FAD_TIER;
      case 'least_expensive':
        return SORT_DATA_ANALYTICS.EYC_LOWTOHIGH;
      case 'most_expensive':
        return SORT_DATA_ANALYTICS.EYC_HIGHTOLOW;
      case 'quality':
        return SORT_DATA_ANALYTICS.FAD_QUALITY;
      default:
        return 'sort' + sortSelectedValue + 'ResultsFindCare';
    }
  }

  /**
   * This method will call affiliation-options api to load prime group affiliations
   */
  populatePrimeGroupAffiliations(): void {
    if (this.feature?.showMedicalGroupAffiliation && this._appSession && this._appSession.searchParams) {
      if ((this._appSession?.primeGroupAffiliations?.affiliationList || []).length > 0) {
        this.primeGroupLoaded = true;
        this.onPrimeGroupAffiliationSuccess(this._appSession.primeGroupAffiliations);
      } else {
        this.primeGroupLoaded = false;
        this.resetFilterCategory();
        this.showGroupAffLoader = true;
        this._appSession.isMedicalAffSearch = true;
        const searchRequest = ResultsUtil.buildProviderRequest(this._appSession, PAGENUMBER, this.appConfig?.paginationSize.groupAffiliationPage, PROVIDER_TYPE.MEDICAL_GROUP);
        if (searchRequest.searchCriteria) {
          delete searchRequest.searchCriteria.specialtyCategoryList;
          delete searchRequest.searchCriteria.subSpecialtyList;
          delete searchRequest.searchCriteria.taxonomyList;
        }
        this._providerSummaryService.getAffiliationOptions(searchRequest).then(
          (result: IGroupAffiliations) => {
            if ((result?.affiliationList || []).length > 0) {
              this._appSession.primeGroupAffiliations = result;
              this._appSession.isMedicalAffSearch = false;
              this.onPrimeGroupAffiliationSuccess(result);
              this.showGroupAffLoader = false;
            }
            this.primeGroupLoaded = true;
          },
          (error: any) => {
            this.primeGroupLoaded = true;
            this.showGroupAffLoader = false;
            this._appSession.isMedicalAffSearch = false;
            this.onError('GroupAffiliation', error);
          }
        );
      }
    }
  }

  /**
   * This method will populate affiliation dropdown and add prime group search message to info list component
   * if result contains affiliationList
   * @param groupAffiliations
   */
  onPrimeGroupAffiliationSuccess(groupAffiliations: IGroupAffiliations): void {
    this.hasPrimeGroupAffiliations = true;
    this.medicalGroupSearch = this._appSession.medicalGrpSearchTerm;
    if (!this.isGroupAffiliationSearch && groupAffiliations.affiliationList && groupAffiliations.affiliationList.length > 0) {
      this.memberMedicalGrpAffltn = this.searchCriteria?.includeHealthSystemSearch ? HEALTH_SYSTEM_ID : groupAffiliations.affiliationList[0].providerIdentifier;
    }
    if (!this.searchCriteria.ableToServePcp && this.showMemberMedicalGrpAffiliation && this.memberMedicalGrpAffltn && this.memberMedicalGrpAffltn !== '00' && !this._appSession.medicalGrpSearchTerm) {
      this.medicalGroupSearch = this.getMedicalGroupSearchName(groupAffiliations.affiliationList[0].providerName);
    }
    this.onGroupAffiliationSuccess(groupAffiliations);
    this.managePrimeGroupDisclaimer();
  }

  resetPrimeGroupAffiliation(clearFilter: boolean = false): void {
    if (clearFilter) {
      this.groupAffiliationList = {} as IProviderSearchDdlValues;
      this.searchCriteria.groupAffiliationSelectNm = '';
      this.memberMedicalGrpAffltn = '00';
      this.hasPrimeGroupAffiliations = false;

      // Clear Prime Group filter deselection.
      this._appliedFilterService.clearFilters(PILL_ID.groupaffiliation.label);
    }
    this.isGroupAffiliationSearch = false;
    this.medicalGroupSearch = this._appSession.medicalGrpSearchTerm ? this._appSession.medicalGrpSearchTerm : '';
    this.managePrimeGroupDisclaimer();
  }

  // Method to check Dynamic filter is enabled.
  dynamicFilterEnabled(): boolean {
    return this.isDynamicFilterEnabled && (!(this._appSession.isEyc || this.showCptFilters) || (this.isEYCDynamicFilterEnabled && (this._appSession.isEyc || this.showCptFilters)));
  }

  private clearSearchCriteria() {
    if (this.feature) {
      // Clear General filter section based on visibility rules
      if (typeof this.feature.showAbleToServePCP === 'undefined' || this.feature.showAbleToServePCP === false) {
        this.searchCriteria.ableToServePcp = false;
      }

      if (typeof this.feature.showScheduleAppointment === 'undefined' || this.feature.showScheduleAppointment === false) {
        this.searchCriteria.scheduleAptmntAvailable = false;
      }

      if (typeof this.feature.showAcceptNewPatient === 'undefined' || this.feature.showAcceptNewPatient === false) {
        this.searchCriteria.acceptingNewPatients = false;
      }

      if (typeof this.feature.showAcceptMedicaid === 'undefined' || this.feature.showAcceptMedicaid === false) {
        this.searchCriteria.medicaidInd = false;
      }

      if (typeof this.feature.showECP === 'undefined' || this.feature.showECP === false) {
        this.searchCriteria.ecpprovider = false;
      }

      if (typeof this.feature.showBoardCertification === 'undefined' || this.feature.showBoardCertification === false) {
        this.searchCriteria.boardCertification = false;
      }

      if (typeof this.feature.showTeleHealth === 'undefined' || this.feature.showTeleHealth === false) {
        this.searchCriteria.teleHealthInd = false;
      }

      // Clear Gender filter section based on visibility rules
      if (typeof this.feature.showGender === 'undefined' || this.feature.showGender === false) {
        this.resetGender();
      }

      if (typeof this.feature.showSpeciality === 'undefined' || this.feature.showSpeciality === false) {
        this.searchCriteria.specialtySelectNm = [];
        this.searchCriteria.taxonomySelectNm = [];
      }
    }
  }

  private clearForwardFilterOptions() {
    if (this.feature) {
      // Clear Gender filter options provider count.
      if (this.feature.showGender && this.filter.genderFilter && this.filter.genderFilter.options) {
        const _genderOptions = [];
        for (const option of this.filter.genderFilter.options) {
          _genderOptions.push(option);
        }
        this.filter.genderFilter.options = _genderOptions;
      }
    }
  }

  private clearRecognition() {
    if (typeof this.searchCriteria !== 'undefined' && this.searchCriteria !== null) {
      this.searchCriteria.cooperativeCareOnly = false;
      this.searchCriteria.sosOnly = false;
      this.searchCriteria.bluePrecision = false;
      this.searchCriteria.enhancedPersonalCare = false;
      this.searchCriteria.centerOfExcellence = false;
      this.searchCriteria.providerOfDistinction = false;
      this.searchCriteria.providerTier = '';
      this.searchCriteria.blueDistinct = '';
      this.searchCriteria.blueDistinctionType = '';
      this.searchCriteria.blueDistinctionTypePlus = '';
      this.searchCriteria.providerTier = '';
      this.searchCriteria.nyOMHOnly = false;
      this.searchCriteria.nyOASASOnly = false;
      this.searchCriteria.blueCarePrimePreferredSpecialistOnly = false;
      this.searchCriteria.upswingOnly = false;
      this.searchCriteria.snyDentalOnly = false;
      this.searchCriteria.hasCcare = false;
      this.searchCriteria.lowerCopayPCP = false;
    }
    this.onBlueDistictionCenterClear();
  }

  private clearRecognitionV2() {
    if (this.searchCriteria?.recognitionCodes?.length) {
      this.searchCriteria.recognitionCodes = [];
    }
    this.recognitionFilterList.forEach((rec) => (rec.isSelected = false));
    this.onBlueDistictionCenterClear();
  }

  private resetGender() {
    if (typeof this.searchCriteria !== 'undefined' && this.searchCriteria !== null) {
      this.searchCriteria.genderCodeList = [];
    }
  }

  private getProviderTypes() {
    this._promises.push(
      this._providerUtilityService.getProviderCodesList(CommonUtil.buildPrefixRuleRequest(this.searchCriteria, this._appSession)).then(
        (result: any) => {
          this.populateProviderTypes(result);
        },
        (error: any) => {
          this.onError('PROVIDER_TYPES', error);
        }
      )
    );
  }

  private populateProviderTypes(data: any) {
    this._appSession.providerTypes = CommonUtil.populateProviderTypes(data, this._appSession, false);

    const providerTypeNames = this.content.commonHealthCareContent.providers;
    let providerTypes = [];

    // remove pharmacy filter if types pill filter is displayed.
    if (this.feature && this.feature.showProviderTypes && this._appSession.providerTypes && this._appSession.providerTypes.length > 0) {
      providerTypes = this._appSession.providerTypes;
      this.feature.showProviderTypes = providerTypes?.length > 0;
    }

    this.providerTypeList = CommonUtil.getProviderTypes(providerTypes, providerTypeNames);
    this._filterCategoryService.providerTypeOptions = Object.assign([], this.providerTypeList);

    if (this.searchCriteria) {
      this.setProviderTypeSelection(this.searchCriteria);
    }
  }

  private setProviderTypeSelection(searchParams: ISearchParameters) {
    if (searchParams && this.providerTypeList) {
      for (let i = 0; i < this.providerTypeList.length; i++) {
        const providerType = this.providerTypeList[i];

        if (!(providerType.code in this.providerTypeSelection)) {
          this.providerTypeSelection[providerType.code] = { selected: false };
        } else if (this.providerTypeSelection && this.providerTypeSelection[providerType.code]) {
          this.providerTypeSelection[providerType.code].selected = false;
        }

        if (searchParams.providerTypeCodeList && searchParams.providerTypeCodeList.length > 0 && searchParams.providerTypeCodeList.indexOf(providerType.code) > -1) {
          this.providerTypeSelection[providerType.code].selected = true;
        }

        // set provider types after clear button event
        if (this.providerTypeSelection[providerType.code].selected === true && this.searchCriteria.providerTypeCodeList && this.searchCriteria.providerTypeCodeList.indexOf(providerType.code) === -1) {
          this.searchCriteria.providerTypeCodeList.push(providerType.code);
        }
      }
    }
  }

  private loadAffiliations(pillAction: any) {
    this.showHospitalAffLoader = false;
    this.showGroupAffLoader = false;
    switch (pillAction.pill.label) {
      case this.pillID.hospitalaffiliation.label:
        if (this.hospitalSearch.length > 2) {
          this.showHospitalAffLoader = true;
          this._appSession.hospSearchTerm = pillAction.value;
          this._appSession.isHospAffSearch = true;
          this.pouplateHospitalAffiliation();
        }
        break;
      case this.pillID.groupaffiliation.label:
        if (this.medicalGroupSearch.length > 2) {
          this.showGroupAffLoader = true;
          this._appSession.isMedicalAffSearch = true;
          this._appSession.medicalGrpSearchTerm = pillAction.value;
          this.pouplateGroupAffiliation();
        }
        break;
      default:
        break;
    }
  }

  private resetSubSpecialty(specialty: IOptions) {
    let taxonomyList = [];
    if (this.searchCriteria && this._appSession.specialtyCategories?.length) {
      const specialtyCategory = this._appSession.specialtyCategories.find((s) => s.code === specialty.value);

      if (this.searchCriteria.taxonomySelectNm?.length && specialtyCategory?.taxonomies?.length) {
        taxonomyList = this.searchCriteria.taxonomySelectNm.filter((code) => !specialtyCategory.taxonomies.some((subSpecialty) => subSpecialty.code === code));
      }
    }
    this.searchCriteria.taxonomySelectNm = taxonomyList;

    //Bind Sub-Speciality applied filters.
    this.bindFilterDeselection(this.pillID.subSpeciality.label);
  }

  private resetPlanFilter() {
    this.selectedPlanType = this.oonProviders ? OUT_OF_NETWORK : this.allText;

    if (typeof this.searchCriteria !== 'undefined' && this.searchCriteria !== null) {
      if (!this._appSession.isDentalStandAlone) {
        this.searchCriteria.standAloneDental = false;
      }
      if (!this._appSession.isVisionStandAlone) {
        this.searchCriteria.standAloneVision = false;
      }
      this.searchCriteria.planTypeList = this.oonProviders ? [OUT_OF_NETWORK] : [];
    }
  }

  setSelectedLevelOfCare(levelofcare: IOptions): string {
    if (
      this.searchCriteria &&
      this.searchCriteria.levelOfCareList &&
      this.searchCriteria.levelOfCareList.length > 0 &&
      levelofcare &&
      this.searchCriteria.levelOfCareList.indexOf(levelofcare.value) > -1
    ) {
      return levelofcare.value;
    } else {
      return '';
    }
  }

  setSelectedLanguage(language: IOptions): string {
    if (this.searchCriteria && this.searchCriteria.languages && this.searchCriteria.languages.length > 0 && language && this.searchCriteria.languages.indexOf(language.value) > -1) {
      return language.value;
    } else {
      return '';
    }
  }

  private setPersonalizeMatch() {
    if (typeof this.searchCriteria !== 'undefined' && this.searchCriteria !== null) {
      if (CommonUtil.isMemberSecure(this._appSession)) {
        this.searchCriteria.hasPersonalizedMatch = true;
      } else {
        this.searchCriteria.hasPersonalizedMatch = false;
      }
    }
  }

  private onLookupLoad(event) {
    if (event && document.getElementById('lnk-toggle-quality') !== null) {
      this.showMoreGeneralLink = document.getElementById('lnk-toggle-quality').childElementCount > 6 ? true : false;
    }
  }

  private setAdobeTagOnFilterchange(event: any, pillID: string) {
    let label = '';
    if (pillID !== '') {
      const id = '#' + pillID + ' span.pill-text';
      if (document.querySelectorAll(id)[0] as HTMLElement) {
        pillID = (document.querySelectorAll(id)[0] as HTMLElement).innerText;
      }
    }
    if (typeof event === 'string' && event !== '') {
      label = event.split('(')[0];
      this.setAdobeTag(label, pillID);
    } else if (event && event.value && event.value !== '') {
      label = event.label.split('(')[0];
      this.setAdobeTag(label, pillID);
    } else {
      if (event && event.isSelected && event.definition && event.definition.id) {
        if (document.getElementById(event.definition.id).parentElement.nextElementSibling.childNodes[0] as HTMLElement) {
          label = (document.getElementById(event.definition.id).parentElement.nextElementSibling.childNodes[0] as HTMLElement).innerText.split('(')[0];
        }
        this.setAdobeTag(label, pillID);
      }
    }
  }

  private getFilterLabel(value: string, list: Array<IOptions>) {
    if (value && list && list.length > 0) {
      const pillArray = list.filter((x) => x.value === value);
      if (pillArray.length > 0) {
        return pillArray[0].label;
      } else {
        return null;
      }
    }
  }

  private setAdobeTag(value: string, pillID: string) {
    if (window.digitalData && window.digitalData.page && window.digitalData.page.search) {
      const _adobePillFilter = {} as IAdobe;
      const _filterDetails = {} as IAdobeFilterDetail;
      _filterDetails.filterType = pillID;
      _filterDetails.filterValue = value;
      _adobePillFilter.pillFilterDetail = _filterDetails;
      const adobeString = CommonUtil.getPillFilterAnalyticsQuery(_adobePillFilter);
      window.digitalData.page.search.findCareFilter = adobeString;
    }
  }

  /**
   * reset to initial values to clear pill filter
   */
  private resetMedicalGroupFilter() {
    this.groupAffiliations = undefined;
    this.groupAffiliationList = {} as IProviderSearchDdlValues;
    this.searchCriteria.groupAffiliationSelectNm = '';
    this.isGroupAffiliationSearch = false;
    this._appSession.isMedicalAffSearch = false;
  }

  private resetYourMedicalGroupFilter() {
    this.groupAffiliations = undefined;
    this.memberMedicalGrpAffltn = '00';
  }

  private removeSpecialty(searchRequest) {
    if (searchRequest.searchCriteria) {
      delete searchRequest.searchCriteria.specialtyCategoryList;
      delete searchRequest.searchCriteria.subSpecialtyList;
      delete searchRequest.searchCriteria.taxonomyList;
      delete searchRequest.searchCriteria.ableToServeAsPCP;
    }
    return searchRequest;
  }

  private setPlanTypeLabels(): void {
    const labels = this.content.summaryComponent.filter.labels;
    MEDICAL_PLAN.label = labels.medicalPlan;
    DENTAL_PLAN.label = labels.dentalPlan;
    VISION_PLAN.label = labels.visionPlan;
    OON_PLAN.label = labels.oonPlan;
    ALL_PLAN_TYPES.label = labels.allPlans;

    MEDICAL_PLAN.value = PROVIDER_TYPE_OF_CARE.MEDICAL;
    DENTAL_PLAN.value = PROVIDER_TYPE_OF_CARE.DENTAL;
    VISION_PLAN.value = PROVIDER_TYPE_OF_CARE.VISION;
    ALL_PLAN_TYPES.value = this.allText;
    OON_PLAN.value = OUT_OF_NETWORK;

    SPANISH_FILTER.medicalPlanCheckBox = FILTER.medicalPlanCheckBox = MEDICAL_PLAN;
    SPANISH_FILTER.dentalPlanCheckBox = FILTER.dentalPlanCheckBox = DENTAL_PLAN;
    SPANISH_FILTER.visionPlanCheckBox = FILTER.visionPlanCheckBox = VISION_PLAN;
    SPANISH_FILTER.allPlanTypes = FILTER.allPlanTypes = ALL_PLAN_TYPES;
    SPANISH_FILTER.oonPlanCheckBox = FILTER.oonPlanCheckBox = OON_PLAN;
    if (this.selectedPlanType === '') {
      if (this.oonProviders) {
        this.selectedPlanType = OUT_OF_NETWORK;
      } else {
        this.selectedPlanType = this.allText;
      }
    }
  }

  showAllNetworkFilter(): boolean {
    return (this.isPlanFilterEnabled() && !this.oonProviders) || (document.getElementsByClassName('plan-filter')?.length >= 2 && this.oonProviders);
  }

  isPlanFilterEnabled(): boolean {
    const { hasMedicalContracts, isEyc } = this._appSession;
    const { showDentalPlan, showVisionPlan } = this.feature || {};
    return !this.showCptFilters && !isEyc && (this.oonProviders || (hasMedicalContracts && this.showPlanFilter && (showDentalPlan || showVisionPlan)));
  }

  private isSelectedPlanType(event: string): boolean {
    let isSelectedPlan = false;
    if (this.searchCriteria?.planTypeList?.length > 0) {
      isSelectedPlan = this.searchCriteria.planTypeList[0] === event;
    } else {
      isSelectedPlan = event === this.allText;
    }
    return isSelectedPlan;
  }

  onDistanceChange(event: IBaseOptions, pillID: IPillID) {
    if (!this._appSession.increasedRadius && this.searchCriteria.distanceNm !== this.selectedDistance) {
      this.isInitalLoad = false;
      this.selectedDistance = this.searchCriteria.distanceNm = event.value;
      this.bindForwardFilter();
    }
    if (pillID && pillID.adobeTag !== '') {
      this.setAdobeTagOnFilterchange(event, pillID.adobeTag);
    }
    this._appSession.increasedRadius = false;
  }

  onChangePosCodes(event: IBaseOptions, pillID: IPillID) {
    if (this._appSession.medicalPosCode !== event.id) {
      this._appSession.medicalPosCode = event.id;
      this.selectedPosCode = event.value;
      if (this.isInitalLoad === false) {
        this.applyFilter.emit({});
      }
      this.isInitalLoad = false;
    }
    if (pillID && pillID.adobeTag !== '') {
      this.setAdobeTagOnFilterchange(event, pillID.adobeTag);
    }
    this.modifierCodes = this.getModifierCodes(this._appSession.medicalPosCode);
  }
  onChangeModifierCodes(event: IBaseOptions, pillID: IPillID) {
    if (this._appSession.medicalModifierOptionCode !== event.id) {
      this._appSession.medicalModifierOptionCode = event.id;
      this.selectedModifierCode = event.value;
      if (this.isInitalLoad === false) {
        this.applyFilter.emit({});
      }
      this.isInitalLoad = false;
    }
    if (pillID && pillID.adobeTag !== '') {
      this.setAdobeTagOnFilterchange(event, pillID.adobeTag);
    }
    if (typeof _satellite !== 'undefined') {
      _satellite.track(this.content.dataAnalytics.medCodeFilterResultsFindCare);
    }
  }

  getModifierCodes(posCd: string) {
    const selectedPos = (this.posList || []).find((item) => {
      return item.pos.code === posCd;
    });
    return (selectedPos?.modifierList || []).map((modifierCd) => {
      const modifierCode = {} as IBaseOptions;
      modifierCode.label = modifierCode.value = modifierCd?.name?.slice(INDEX_ZERO, INDEX_FIFTY) ?? '';
      modifierCode.id = modifierCd.code;
      return modifierCode;
    });
  }

  /** Filter Deselection methods - START */

  /**
   * This method helps to iterate through the search parameters and
   * bind the filter deselection.
   */
  bindInitalFilterDeselection() {
    Object.keys(this.pillID).forEach((param: string) => {
      if (this.pillID[param]?.label?.length) {
        this.bindFilterDeselection(this.pillID[param].label);
      }
    });
  }

  /**
   * This method helps to iterate through the filter deselection category and
   * re-bind the filter deselection.
   */
  bindAllFilterDeselection() {
    const filterKeys = this._appliedFilterService.getFilterKeys();
    filterKeys?.forEach((category: string) => {
      this.bindFilterDeselection(category);
    });
  }

  /**
   * This method helps to identify the filter category and
   * set the filter deselection object and create map object by calling AppliedFilterService.
   */
  bindFilterDeselection(category: string) {
    if (this.isFilterDeselectionEnabled) {
      const values: IFilter[] = [];
      switch (category) {
        case this.pillID.fadProviderType.label:
          const _isProcedureFiltersEnabled = this.isProcedureFiltersEnabled();
          if (this.feature?.showProviderTypes && (this.searchCriteria?.providerTypeCodeList?.length > 1 || _isProcedureFiltersEnabled)) {
            const _providerTypeList = this.providerTypeList.filter((provType) => provType?.code && this.searchCriteria.providerTypeCodeList.includes(provType.code));
            if (_isProcedureFiltersEnabled || _providerTypeList?.length > 1) {
              this.searchCriteria.providerTypeCodeList.forEach((item) => {
                const selectedProviderTypeObj = this.providerTypeList?.find((option) => option?.code === item);
                if (selectedProviderTypeObj) {
                  values.push(this.getFilterObject(selectedProviderTypeObj.code, selectedProviderTypeObj.label));
                }
              });
            }
          }
          break;
        case this.pillID.gender.label:
          if (this.feature?.showGender) {
            this.searchCriteria?.genderCodeList?.forEach((item) => {
              const selectedGenderObj = this.filter?.genderFilter?.options?.find((option) => option?.value === item);
              if (selectedGenderObj) {
                values.push(this.getFilterObject(selectedGenderObj.value, selectedGenderObj.label));
              }
            });
          }
          break;
        case this.pillID.speciality.label:
          if (this.feature?.showSpeciality) {
            this.searchCriteria?.specialtySelectNm?.forEach((item) => {
              const selectedSpecialtyObj = this.specialties?.options?.find((option) => option?.value === item);
              if (selectedSpecialtyObj) {
                values.push(this.getFilterObject(selectedSpecialtyObj.value, selectedSpecialtyObj.label));
              }
            });
          }
          break;
        case this.pillID.subSpeciality.label:
          if (this.feature?.showSubSpeciality) {
            this.searchCriteria?.taxonomySelectNm?.forEach((item) => {
              const selectedTaxonomyObj = this.subSpecialties?.options?.find((option) => option?.value === item);
              if (selectedTaxonomyObj) {
                values.push(this.getFilterObject(selectedTaxonomyObj.value, selectedTaxonomyObj.label));
              }
            });
          }
          break;
        case this.pillID.pcp.label:
          if (this.feature?.showAbleToServePCP && this.searchCriteria?.ableToServePcp) {
            values.push(this.getFilterObject(this.searchCriteria.ableToServePcp.toString(), this.content.summaryComponent.filter.labels.integratedPCP2));
          }
          break;
        case this.pillID.profile.label:
          if (this.feature?.showTeleHealth && this.searchCriteria?.teleHealthInd) {
            values.push(this.getFilterObject(this.searchCriteria.teleHealthInd.toString(), this.content.summaryComponent.filter.labels.acceptTeleHealth1));
          }
          if (this.feature?.showScheduleAppointment && this.searchCriteria?.scheduleAptmntAvailable) {
            values.push(this.getFilterObject(this.searchCriteria.scheduleAptmntAvailable.toString(), this.content.summaryComponent.filter.labels.scheduleAppointment));
          }
          if (this.feature?.showAcceptNewPatient && this.searchCriteria?.acceptingNewPatients) {
            values.push(this.getFilterObject(this.searchCriteria.acceptingNewPatients.toString(), this.content.summaryComponent.filter.labels.acceptNewPatient1));
          }
          if (this.feature?.showAcceptMedicaid && this.searchCriteria?.medicaidInd) {
            values.push(this.getFilterObject(this.searchCriteria.medicaidInd.toString(), this.content.summaryComponent.filter.labels.acceptsMedicaid));
          }
          if (this.feature?.showECP && this.searchCriteria?.ecpprovider) {
            values.push(this.getFilterObject(this.searchCriteria.ecpprovider.toString(), this.content.summaryComponent.filter.labels.ecpprovider));
          }
          if (this.feature?.showBoardCertification && this.searchCriteria?.boardCertification) {
            values.push(this.getFilterObject(this.searchCriteria.boardCertification.toString(), this.content.summaryComponent.filter.boardcertification1));
          }
          if (this.searchCriteria?.extendedHoursOnly) {
            values.push(this.getFilterObject(this.searchCriteria.extendedHoursOnly.toString(), this.content.summaryComponent.filter.labels.extendedHours));
          }
          break;
        case this.pillID.recognition.label:
          if (this.networkProgramReferenceV2) {
            if (this.feature?.showTier && this.searchCriteria?.providerTier?.length) {
              values.push(this.getFilterObject(this.searchCriteria.providerTier.toString(), this.tierIndicator?.name));
            }
            this.searchCriteria?.recognitionCodes?.forEach((code) => {
              const recognition = this.recognitionFilterList?.find((rec) => rec.code === code);
              if (recognition) {
                values.push(this.getFilterObject(recognition.code, recognition.name));
              }
            });
          } else {
            if (this.feature?.showBCP && this.searchCriteria?.blueCarePrimePreferredSpecialistOnly) {
              values.push(this.getFilterObject(this.searchCriteria.blueCarePrimePreferredSpecialistOnly.toString(), this.content.summaryComponent.filter.labels.bcp));
            }
            if (this.feature?.showTier && this.searchCriteria?.providerTier?.length) {
              values.push(this.getFilterObject(this.searchCriteria.providerTier.toString(), this.tierLabel));
            }
            if (this.feature?.showUpswing && this.searchCriteria?.upswingOnly) {
              values.push(this.getFilterObject(this.searchCriteria.upswingOnly.toString(), this.content.summaryComponent.filter.labels.upswing));
            }
            if (this.feature?.showSNYDental && this.searchCriteria?.snyDentalOnly) {
              values.push(this.getFilterObject(this.searchCriteria.snyDentalOnly.toString(), this.content.summaryComponent.filter.labels.snyDental));
            }
            if (this.feature?.showCCare && this.searchCriteria?.hasCcare) {
              values.push(this.getFilterObject(this.searchCriteria.hasCcare.toString(), this.content.summaryComponent.filter.labels.ccare));
            }
            if (this.feature?.showCooperativeCare && this.searchCriteria?.cooperativeCareOnly) {
              values.push(this.getFilterObject(this.searchCriteria.cooperativeCareOnly.toString(), this.content.summaryComponent.filter.labels.cooperativeCare));
            }
            if (this.feature?.showSosTier && this.searchCriteria?.sosOnly) {
              values.push(this.getFilterObject(this.searchCriteria.sosOnly.toString(), this.content.summaryComponent.filter.labels.sosTier1));
            }
            if (this.feature?.showBluePrecision && this.searchCriteria?.bluePrecision) {
              values.push(this.getFilterObject(this.searchCriteria.bluePrecision.toString(), this.content.summaryComponent.filter.labels.bluePrecision));
            }
            if (this.feature?.showBDTC && this.searchCriteria?.blueDistinct?.length) {
              values.push(this.getFilterObject(this.searchCriteria.blueDistinct.toString(), this.content.summaryComponent.filter.labels.blueDistinctionTotalCare));
            }
            if (this.feature?.showEPHC && this.searchCriteria?.enhancedPersonalCare) {
              values.push(this.getFilterObject(this.searchCriteria.enhancedPersonalCare.toString(), this.content.summaryComponent.filter.labels.enhancedPHC));
            }
            if (this.feature?.showOMH && this.searchCriteria?.nyOMHOnly) {
              values.push(this.getFilterObject(this.searchCriteria.nyOMHOnly.toString(), this.content.summaryComponent.filter.labels.omh));
            }
            if (this.feature?.showOMH && this.searchCriteria?.nyOASASOnly) {
              values.push(this.getFilterObject(this.searchCriteria.nyOASASOnly.toString(), this.content.summaryComponent.filter.labels.oasas));
            }
            if (this.feature?.showCenterOfExcellence && this.searchCriteria?.centerOfExcellence) {
              values.push(this.getFilterObject(this.searchCriteria.centerOfExcellence.toString(), this.content.summaryComponent.filter.labels.centerOfExcellence));
            }
            if (this.feature?.showLgbtqFilter && this.searchCriteria?.lgbtqProviderOnly) {
              values.push(this.getFilterObject(this.searchCriteria.lgbtqProviderOnly.toString(), this.content.summaryComponent.filter.labels.LGBTQAlly));
            }
            if (this.feature?.showProviderOfDistinction && this.searchCriteria?.providerOfDistinction) {
              values.push(this.getFilterObject(this.searchCriteria.providerOfDistinction.toString(), this.content.summaryComponent.filter.labels.providerOfDistinction));
            }
            if (this.feature?.showMSP && this.searchCriteria?.msp) {
              values.push(this.getFilterObject(this.searchCriteria.msp.toString(), this.content.summaryComponent.filter.labels.msp));
            }
            if (this.feature?.showValueBasedProvider && this.searchCriteria?.valueBasedProvider) {
              values.push(this.getFilterObject(this.searchCriteria.valueBasedProvider.toString(), this.content.summaryComponent.filter.labels.valueBasedProvider));
            }
            if (this.searchCriteria?.greenRibbonOnly) {
              values.push(this.getFilterObject(this.searchCriteria.greenRibbonOnly.toString(), this.content.summaryComponent.filter.labels.greenRibbon));
            }
            if (this.feature?.showLwrCpyPcpRcgnFltr && this.searchCriteria?.lowerCopayPCP) {
              values.push(this.getFilterObject(this.searchCriteria.lowerCopayPCP.toString(), this.content.summaryComponent.filter.labels.lowerCopayPCP));
            }
          }
          break;
        case this.pillID.language.label:
          if (this.feature?.showLanguage) {
            this.searchCriteria?.languages?.forEach((item) => {
              const selectedLanguageObj = this.languageList?.options?.find((option) => option?.value === item);
              if (selectedLanguageObj) {
                values.push(this.getFilterObject(selectedLanguageObj.value, selectedLanguageObj.label));
              }
            });
          }
          break;
        case this.pillID.ethnicity.label:
          if (this.feature?.showEthnicity) {
            this.searchCriteria?.ethnicityList?.forEach((item) => {
              const selectedEthnicityObj = this.ethnicityList?.options?.find((option) => option?.value === item);
              if (selectedEthnicityObj) {
                values.push(this.getFilterObject(selectedEthnicityObj.value, selectedEthnicityObj.label));
              }
            });
          }
          break;
        case this.pillID.levelOfCare.label:
          if (this.feature?.showLevelOfCare) {
            this.searchCriteria?.levelOfCareList?.forEach((item) => {
              const selectedLevelOfCareObj = this.levelOfCares?.options?.find((option) => option?.value === item);
              if (selectedLevelOfCareObj) {
                values.push(this.getFilterObject(selectedLevelOfCareObj.value, selectedLevelOfCareObj.label));
              }
            });
          }
          break;
        case this.pillID.aoe.label:
          if (this.feature?.showAreaofExpertise) {
            this.searchCriteria?.aoeSelectNm?.forEach((item) => {
              const selectedAOEObj = this.aoeList?.options?.find((option) => option?.value === item);
              if (selectedAOEObj) {
                values.push(this.getFilterObject(selectedAOEObj.value, selectedAOEObj.label));
              }
            });
          }
          break;
        case this.pillID.ada.label:
          this.searchCriteria?.adaSelectNm?.forEach((item) => {
            const selectedADAObj = this.adaList?.options?.find((option) => option?.value === item);
            if (selectedADAObj) {
              values.push(this.getFilterObject(selectedADAObj.value, selectedADAObj.label));
            }
          });
          break;
        case this.pillID.bdc.label:
          if (this.feature?.showBDC) {
            this.searchCriteria?.blueDistinctionCenter?.forEach((item) => {
              if (item?.code) {
                const blueDistinctionCategory = this.content.summaryComponent.filter.bdcValue[item.code];
                let blueDistinction = item?.bd ?? undefined;
                let blueDistinctionPlus = item?.bdcPlus ?? undefined;
                const selectedBDCObj = this.bdcFilterList?.options?.find((option) => option?.value === item.code);
                if (blueDistinctionCategory && selectedBDCObj) {
                  if (blueDistinctionPlus === selectedBDCObj.bdcPlus?.trueValue) {
                    values.push(this.getFilterObject(item.code + '-' + selectedBDCObj.bdcPlus.trueValue, blueDistinctionCategory + ' - ' + selectedBDCObj.bdcPlus?.label));
                  }
                  if (blueDistinction === selectedBDCObj.bd?.trueValue) {
                    values.push(this.getFilterObject(item.code + '-' + selectedBDCObj.bd.trueValue, blueDistinctionCategory + ' - ' + selectedBDCObj.bd?.label));
                  }
                }
              }
            });
          }
          break;
        case this.pillID.hospitalaffiliation.label:
          if (this.feature?.showAffialition && this.searchCriteria?.hospitalAffiliationSelectNm?.length && this.searchCriteria?.hospitalAffiliationSelectNm !== '00') {
            const selectedHospitalAffiliationObj = this.hospitalAffiliationList?.options?.find((option) => option?.value === this.searchCriteria.hospitalAffiliationSelectNm);
            if (selectedHospitalAffiliationObj) {
              values.push(this.getFilterObject(selectedHospitalAffiliationObj.value, selectedHospitalAffiliationObj.label));
            }
          }
          break;
        case this.pillID.groupaffiliation.label:
          let selectedGroupAffiliationObj: IOptions;
          if (this.showMemberMedicalGrpAffiliation && this.memberMedicalGrpAffltn?.length && this.memberMedicalGrpAffltn !== '00') {
            selectedGroupAffiliationObj = this.medicalGrpAffiliationList?.options?.find((option) => option?.value === this.memberMedicalGrpAffltn);
          } else if (this.feature?.showMedicalGroupAffiliation && this.searchCriteria?.groupAffiliationSelectNm?.length && this.searchCriteria?.groupAffiliationSelectNm !== '00') {
            selectedGroupAffiliationObj = this.groupAffiliationList?.options?.find((option) => option?.value === this.searchCriteria.groupAffiliationSelectNm);
          }
          if (selectedGroupAffiliationObj) {
            values.push(this.getFilterObject(selectedGroupAffiliationObj.value, selectedGroupAffiliationObj.label));
          }
          break;
        case this.pillID.patientPopulation.label:
          if (this.feature?.showPatientPopulation) {
            this.searchCriteria?.patientPreferenceSelectNm?.forEach((item) => {
              const selectedPatientPreferenceObj = this.patientPreferenceList?.options?.find((option) => option?.value === item);
              if (selectedPatientPreferenceObj) {
                values.push(this.getFilterObject(selectedPatientPreferenceObj.value, selectedPatientPreferenceObj.label));
              }
            });
          }
          break;
        case this.pillID.pharmacyLevel.label:
          if (this.feature?.showPharmacyLevel) {
            if (this.searchCriteria?.pharmacyLevel?.length > 0) {
              values.push(this.getFilterObject(this.searchCriteria.pharmacyLevel, this.content.summaryComponent.filter.labels.level1));
            }
          }
          break;
        case this.pillID.pharmacyPreferred.label:
          if (this.feature?.showPharmPrefered) {
            if (this.searchCriteria?.pharmacyLevel?.length > 0) {
              values.push(this.getFilterObject(this.searchCriteria.pharmacyLevel, this.content.summaryComponent.filter.labels.preferred1));
            }
          }
          break;
        case this.pillID.pharmacyFeatures.label:
          if (this.feature?.showPharmacyFeatures && this.pharmacyFeatureList?.options?.length > 0) {
            this.pharmacyFeatureList.options.filter((item) => this.searchCriteria[item.value]).forEach((item) => values.push(this.getFilterObject(item.value, item.label)));
          }
          break;
        case this.pillID.pharmacyType.label:
          if (this.feature?.showPharmacyType && this.pharmacyTypeList?.options?.length > 0) {
            this.searchCriteria?.dispenseTypeCode?.forEach((item) => {
              const option = this.pharmacyTypeList.options.find((option) => option.value === item);
              if (option) {
                values.push(this.getFilterObject(item, option.label));
              }
            });
          }
          break;
        case this.pillID.serviceAvailable.label:
          if (this.feature?.showServiceAvailable) {
            this.searchCriteria?.visionSrvcAvailableList?.forEach((item) => {
              const selectedServiceAvailableObj = this.filter?.serviceAvailable?.options?.find((option) => option?.value === item);
              if (selectedServiceAvailableObj) {
                values.push(this.getFilterObject(selectedServiceAvailableObj.value, selectedServiceAvailableObj.label));
              }
            });
          }
          break;
        case this.pillID.licenseType.label:
          if (this.feature?.showLicenseType && this.searchCriteria?.providerLicenseType && this.searchCriteria?.providerLicenseType !== '00') {
            const selectedLicenseTypeObj = this.licenseTypeList?.options?.find((option) => option?.value === this.searchCriteria.providerLicenseType);
            if (selectedLicenseTypeObj) {
              values.push(this.getFilterObject(selectedLicenseTypeObj.value, selectedLicenseTypeObj.label));
            }
          }
          break;
        case this.pillID.phone.label:
          if (this.feature?.showPhone && this.searchCriteria?.providerPhone?.length > 0) {
            values.push(this.getFilterObject(this.searchCriteria.providerPhone, this.searchCriteria.providerPhone));
          }
          break;
        case this.pillID.cme.label:
          this.searchCriteria.cmeValue?.forEach((item) => {
            const selectedCme = FILTER.cmeDesignation.options.find((option) => option?.value === item);
            if (selectedCme) {
              values.push(this.getFilterObject(selectedCme.value, selectedCme.label));
            }
          });
          break;
        case this.pillID.email.label:
          if (this.feature?.showEmail && this.searchCriteria?.providerEmail?.length > 0) {
            values.push(this.getFilterObject(this.searchCriteria.providerEmail, this.searchCriteria.providerEmail));
          }
          break;
        case this.pillID.plan.label:
          this.searchCriteria?.planTypeList?.forEach((item) => {
            let planNetwork = undefined;
            switch (item) {
              case MEDICAL_PLAN.value:
                planNetwork = MEDICAL_PLAN.label;
                break;
              case VISION_PLAN.value:
                planNetwork = VISION_PLAN.label;
                break;
              case DENTAL_PLAN.value:
                planNetwork = DENTAL_PLAN.label;
                break;
              case ALL_PLAN_TYPES.value:
                planNetwork = ALL_PLAN_TYPES.label;
                break;
              case OON_PLAN.value:
                planNetwork = OON_PLAN.label;
                break;
            }
            if (planNetwork) {
              values.push(this.getFilterObject(item, planNetwork));
            }
          });
          break;
        case this.pillID.officeService.label:
          if (this.feature?.showOfficeService && this.searchCriteria?.ofcSrvcSelectNm?.length > 0) {
            this.searchCriteria.ofcSrvcSelectNm?.forEach((item) => {
              const selectedOfficeServiceObj = this.ofcSrvcList?.options?.find((option) => option?.value === item);
              if (selectedOfficeServiceObj) {
                values.push(this.getFilterObject(selectedOfficeServiceObj.value, selectedOfficeServiceObj.label));
              }
            });
          }
      }

      this._appliedFilterService.addFilter(category, values);
    }
  }

  /**
   * This method helps to identify the filter category and
   * trigger the change event on filter deselection.
   */
  removeAppliedFilter(filter: IFilter) {
    if (filter?.category && this.searchCriteria) {
      const category = filter.category;
      switch (category) {
        case this.pillID.fadProviderType.label:
          if (this.isProcedureFiltersEnabled() || this.searchCriteria.providerTypeCodeList?.length > 1) {
            const selectedProviderTypeObj = this.providerTypeList?.find((option) => option?.code === filter.value);
            if (selectedProviderTypeObj) {
              this.onProviderTypeChange(false, selectedProviderTypeObj);
            }
          }
          break;
        case this.pillID.gender.label:
          this.onGenderChange('', true, this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.speciality.label:
          this.onSpecialtyChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.subSpeciality.label:
          const removeSubSpecialty = this.searchCriteria?.taxonomySelectNm?.includes(filter.value);
          if (removeSubSpecialty) {
            this.onSubSpecialtyChange('', this.getOptionObject(filter.value, filter.name));
          }
          break;
        case this.pillID.pcp.label:
          this.searchCriteria.ableToServePcp = false;
          const pcpEvent = {
            isSelected: false,
            definition: this.filter.serveAsPCPCheckBox
          };
          this.onFilterChange(pcpEvent, this.pillID.pcp);
          break;
        case this.pillID.profile.label:
          let profileEvent = null;
          switch (filter?.name) {
            case this.content.summaryComponent.filter.labels.acceptTeleHealth1:
              this.searchCriteria.teleHealthInd = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.teleHealthCheckbox
              };
              break;
            case this.content.summaryComponent.filter.labels.scheduleAppointment:
              this.searchCriteria.scheduleAptmntAvailable = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.scheduleAppointmentCheckbox
              };
              break;
            case this.content.summaryComponent.filter.labels.acceptNewPatient1:
              this.searchCriteria.acceptingNewPatients = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.newPatientCheckBox
              };
              break;
            case this.content.summaryComponent.filter.labels.acceptsMedicaid:
              this.searchCriteria.medicaidInd = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.medicaidCheckbox
              };
              break;
            case this.content.summaryComponent.filter.labels.ecpprovider:
              this.searchCriteria.ecpprovider = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.ecpCheckbox
              };
              break;
            case this.content.summaryComponent.filter.boardcertification1:
              this.searchCriteria.boardCertification = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.boardCertified
              };
              break;
            case this.content.summaryComponent.filter.labels.extendedHours:
              this.searchCriteria.extendedHoursOnly = false;
              profileEvent = {
                isSelected: false,
                definition: this.filter.extendedHoursCheckbox
              };
              break;
          }
          if (profileEvent) {
            this.onFilterChange(profileEvent, this.pillID.profile);
          }
          break;
        case this.pillID.recognition.label:
          let recognitionEvent = null;
          if (this.networkProgramReferenceV2) {
            if (filter.value === TIER1) {
              this.searchCriteria.providerTier = '';
              recognitionEvent = { isSelected: false, definition: this.recognitionTier };
            }
            if (Array.isArray(this.searchCriteria.recognitionCodes) && this.searchCriteria.recognitionCodes.length) {
              const index = this.searchCriteria.recognitionCodes.findIndex((item) => item === filter.value);
              if (index !== -1) {
                this.searchCriteria.recognitionCodes.splice(index, 1);
                this.recognitionFilterList?.forEach((rec) => {
                  if (rec.code === filter.value) {
                    rec.isSelected = false;
                  }
                });
                recognitionEvent = {
                  isSelected: false,
                  definition: this.recognitionFilterList?.find((rec) => rec.code === filter.value)?.definition
                };
              }
            }
          } else {
            switch (filter?.name) {
              case this.content.summaryComponent.filter.labels.bcp:
                this.searchCriteria.blueCarePrimePreferredSpecialistOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.bcpCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.benefitTier1:
              case this.content.summaryComponent.filter.labels.bvvProvider:
                this.searchCriteria.providerTier = '';
                recognitionEvent = {
                  isSelected: false,
                  definition: this.recognitionTier
                };
                break;
              case this.content.summaryComponent.filter.labels.upswing:
                this.searchCriteria.upswingOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.upswing
                };
                break;
              case this.content.summaryComponent.filter.labels.snyDental:
                this.searchCriteria.snyDentalOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.snyDental
                };
                break;
              case this.content.summaryComponent.filter.labels.ccare:
                this.searchCriteria.hasCcare = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.ccare
                };
                break;
              case this.content.summaryComponent.filter.labels.cooperativeCare:
                this.searchCriteria.cooperativeCareOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.cooperativeCareCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.sosTier1:
                this.searchCriteria.sosOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.sosTier
                };
                break;
              case this.content.summaryComponent.filter.labels.bluePrecision:
                this.searchCriteria.bluePrecision = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.bluePrecisionCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.blueDistinctionTotalCare:
                this.searchCriteria.blueDistinct = '';
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.blueDistinctCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.enhancedPHC:
                this.searchCriteria.enhancedPersonalCare = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.enhancedPersonalCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.LGBTQAlly:
                this.searchCriteria.lgbtqProviderOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.lgbtqAlly
                };
                break;
              case this.content.summaryComponent.filter.labels.omh:
                this.searchCriteria.nyOMHOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.omhCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.oasas:
                this.searchCriteria.nyOASASOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.oasasCheckBox
                };
                break;
              case this.content.summaryComponent.filter.labels.centerOfExcellence:
                this.searchCriteria.centerOfExcellence = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.centerOfExcellence
                };
                break;
              case this.content.summaryComponent.filter.labels.providerOfDistinction:
                this.searchCriteria.providerOfDistinction = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.providerOfDistinction
                };
                break;
              case this.content.summaryComponent.filter.labels.msp:
                this.searchCriteria.msp = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.msp
                };
                break;
              case this.content.summaryComponent.filter.labels.valueBasedProvider:
                this.searchCriteria.valueBasedProvider = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.valueBasedProvider
                };
                break;
              case this.content.summaryComponent.filter.labels.greenRibbon:
                this.searchCriteria.greenRibbonOnly = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.greenRibbon
                };
                break;
              case this.content.summaryComponent.filter.labels.lowerCopayPCP:
                this.searchCriteria.lowerCopayPCP = false;
                recognitionEvent = {
                  isSelected: false,
                  definition: this.filter.lowerCopayPCPCheckBox
                };
                break;
            }
          }
          if (recognitionEvent) {
            this.onFilterChange(recognitionEvent, this.pillID.recognition);
          }
          break;
        case this.pillID.language.label:
          this.onLanguageChange('', true, this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.ethnicity.label:
          this.onEthnicityChange('', true, this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.levelOfCare.label:
          this.onLevelOfCareChange('', true, this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.aoe.label:
          this.onAoeChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.ada.label:
          this.onAdaChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.bdc.label:
          const values = filter?.value?.split('-');
          if (values?.length === 2) {
            const bdCode = values[0];
            const bdValue = values[1];
            switch (bdValue) {
              case BLUE_DISTINCTINON_CENTER_PLUS.trueValue:
                this.onBlueDistinctionTypeClick('', true, bdCode);
                break;
              case BLUE_DISTINCTINON_CENTER.trueValue:
                this.onBlueDistinctionTypeClick('', false, bdCode);
                break;
            }
          }
          break;
        case this.pillID.hospitalaffiliation.label:
          this.onHospitalAffiliationChange('00');
          break;
        case this.pillID.groupaffiliation.label:
          if (this.memberMedicalGrpAffltn?.length && this.memberMedicalGrpAffltn !== '00') {
            this.onMemberMedicalGrpChange('00', true, true);
          } else {
            this.onGroupAffiliationChange('00', true, true);
          }
          break;
        case this.pillID.patientPopulation.label:
          this.onPatientPreferenceChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.pharmacyLevel.label:
          this.searchCriteria.pharmacyLevel = '';
          const pharmacyLevelEvent = {
            isSelected: false,
            definition: this.filter.pharmacyLevelCheckBox
          };
          this.onFilterChange(pharmacyLevelEvent, this.pillID.pharmacyLevel);
          break;
        case this.pillID.pharmacyPreferred.label:
          this.searchCriteria.pharmacyLevel = '';
          const pharmacyPreferredEvent = {
            isSelected: false,
            definition: this.filter.pharmacyPreferredCheckBox
          };
          this.onFilterChange(pharmacyPreferredEvent, this.pillID.pharmacyPreferred);
          break;
        case this.pillID.pharmacyFeatures.label:
          this.onPharmacyFeaturesChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.pharmacyType.label:
          this.onDespenseTypeChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.serviceAvailable.label:
          this.onServiceAvailableChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.licenseType.label:
          this.onLicenseTypeChange('00');
          break;
        case this.pillID.phone.label:
          this.searchCriteria.providerPhone = '';
          this.onFilterChange('', this.pillID.phone);
          break;
        case this.pillID.email.label:
          this.searchCriteria.providerEmail = '';
          this.onFilterChange('', this.pillID.email);
          break;
        case this.pillID.plan.label:
          this.selectedPlanType = document.getElementsByClassName('plan-filter')?.length < 2 ? PROVIDER_TYPE_OF_CARE.MEDICAL : this.allText;
          const selectedPlan = document.getElementsByClassName('plan-filter')?.length < 2 ? PROVIDER_TYPE_OF_CARE.MEDICAL : ALL_PLAN_TYPES.value;
          this.onPlanTypeChange(selectedPlan, true);
          break;
        case this.pillID.cme.label:
          this.onCMEDesignationChange('', this.getOptionObject(filter.value, filter.name));
          break;
        case this.pillID.officeService.label:
          this.onOfcSrvcChange('', this.getOptionObject(filter.value, filter.name));
      }
    }
  }

  getFilterObject(value: string, name: string) {
    return { value: value, name: name } as IFilter;
  }

  getOptionObject(value: string, name: string) {
    return { label: name, value: value } as IOptions;
  }

  resetFilterCategory() {
    this._filterCategoryService.filterCategoryList = [];
  }

  /** Filter Deselection methods - END */
  private getMedicalGroupSearchName(medicalGrpName: string): string {
    if (!this.searchCriteria?.includeHealthSystemSearch && medicalGrpName) {
      return medicalGrpName.indexOf(this.content.summaryComponent.filter.medicalGroup) === -1
        ? medicalGrpName
        : medicalGrpName.replace(this.content.summaryComponent.filter.medicalGroup, EMPTY_STRING);
    }
    return EMPTY_STRING;
  }

  private getHealthSystemProviderName(affiliationList: Array<IAffiliationOption>): Array<IAffiliationOption> {
    affiliationList.forEach((prov) => {
      if (prov?.extendedProviderInfo?.isEligibleForHealthSystem && prov?.providerName) {
        prov.providerName =
          prov.providerName.indexOf(this.content.summaryComponent.filter.healthSystem) === -1 ? this.content.summaryComponent.filter.healthSystem + prov.providerName.toUpperCase() : prov.providerName;
      } else {
        prov.providerName =
          prov.providerName.indexOf(this.content.summaryComponent.filter.medicalGroup) === -1 ? this.content.summaryComponent.filter.medicalGroup + prov.providerName : prov.providerName;
      }
    });
    this.isHealthSystemFormatDone = true;
    return affiliationList;
  }

  private managePrimeGroupDisclaimer() {
    if (
      this.showMemberMedicalGrpAffiliation &&
      this.memberMedicalGrpAffltn &&
      this.medicalGrpAffiliationList?.options?.length > 1 &&
      this.memberMedicalGrpAffltn !== '00' &&
      !this.searchCriteria.ableToServePcp &&
      !this.searchCriteria.includeHealthSystemSearch &&
      !this.isPrimeGroupRemoved
    ) {
      const primeGroupSearchMsg: IMessage = {
        contentId: PRIME_GROUP_SEARCH_MESSAGE,
        content: this._appSession.hasHealthSystem ? this.content.searchCriteriaComponent.alerts.yourMedicalHealthSystemMsg : this.content.searchCriteriaComponent.alerts.yourMedicalGroupSearchMsg,
        type: ALERT_TYPE.INFORMATION
      } as IMessage;
      this._infoListSvc.addMessage(primeGroupSearchMsg);
      this._infoListSvc.update();
    } else {
      this._infoListSvc.removeMsgById(PRIME_GROUP_SEARCH_MESSAGE);
      this._infoListSvc.update();
    }
  }

  private isProcedureFiltersEnabled(): boolean {
    return (this._appSession.isEyc || this.showCptFilters) ?? false;
  }
}
