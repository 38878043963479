import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-rewards',
  templateUrl: './providerRewardsCmp.html'
})
export class ProviderRewardsComponent extends BaseComponent {
  @Input() recognitionsData: IProvider;
  @Input() recognitionHeader: string;
  recognitionContent = this.content?.result?.recognitionCard;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }
}
