import { AfterViewInit, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CHINESE_LANG_BRANDS, Locale } from '../../../common/constants/app-constants';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { AppEvents } from '../../../common/enums/appEvents';
import { IWindow } from '../../../common/interfaces/iWindow';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppSession } from '../../../common/values/appSession';
import { VEP } from '../../../fad/search-providers/values/providerSearchConstants';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { GenericModalService } from '../../common/components/shareable/modal/services/genericModalSvc';
import { PageAlertHandler } from '../../common/components/shareable/page-alert/service/pageAlertHandler';
import { PageAlertSvc } from '../../common/components/shareable/page-alert/service/pageAlertSvc';
import { FindCareFeature } from '../../common/enums/findCareFeature';
import { FindCarePageType } from '../../common/enums/findCarePages';
import { IModalMetaInfo } from '../../common/interfaces/iModalContent';
import { IPageTitle } from '../../common/interfaces/iPageTitle';
import { VEP_BEH_HEALTH } from './../../../fad/search-providers/values/providerSearchConstants';
@Component({
  moduleId: module.id,
  selector: 'app-fc-landing-cmp',
  templateUrl: './landingCmp.html'
})
export class LandingComponent extends BaseComponent implements OnInit, AfterViewInit {
  page = FindCarePageType.Landing;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  englishSpanishToggleLabel: string;
  private _brand: string;
  chineseMotionPointUrl: string;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    @Inject('Window')
    private _window: IWindow,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _genericModalService: GenericModalService,
    private _pageAlertSvc: PageAlertSvc
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.onPreLoad();
    this.setPageHeader();
    this.englishSpanishToggleLabel = this.content?.landing.englishSpanishToggleLabel;
    this._brand = this._appSession.metaData.brandCd;
    this._pageAlertSvc.getPageAlerts(PageAlertHandler.buildPageAlertRequest(FindCarePageType.Landing, this._appSession));
    this._appSession.searchTerm = '';
  }

  get showChineseLink(): boolean {
    return !this.isSecureState && this.hasFeature(FindCareFeature.FC_CHN_LOCALE) && CHINESE_LANG_BRANDS.includes(this._brand.toUpperCase());
  }

  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  onLanguageClick() {
    if (this._appSession?.metaData?.locale) {
      this._appSession.metaData.locale = this._appSession.metaData.locale === Locale.SPANISH ? Locale.ENGLISH : Locale.SPANISH;
    }

    if (!this.isSecureState) {
      this._appSession.searchParams = undefined;
    }

    this._navigationService.navigateByUrl(AppNavigations.FCR_HOME_PATH);
  }

  // Get Chinese MotionPoint URL based on brand from appConfig
  // @param _brand - Brand code
  getchineseMotionPointUrl(_brand: string): string {
    return this._appSession.appConfig?.chineseMotionPointUrl[_brand.toUpperCase()];
  }

  // Open MotionPoint site in new tab if brand is ABC, EBC or EBCBS and not in secure state
  openMotionPointSite() {
    // below url will not become blank as it this will be called only if isShowChinese is true
    this.chineseMotionPointUrl = this.getchineseMotionPointUrl(this._brand);
    const windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
    // Add protocol if not present
    this.chineseMotionPointUrl = this.chineseMotionPointUrl.indexOf('://') <= 0 ? 'http://' + this.chineseMotionPointUrl : this.chineseMotionPointUrl;
    const winArgs = [this.chineseMotionPointUrl, '_blank'];
    winArgs.push(windowParams);
    // Open the window
    this._window.open.apply(this._window, winArgs);
  }

  ngAfterViewInit() {
    this.checkLeavingDisclaimerModal();
  }

  setPageHeader() {
    if (this.content && this.content.common && this.content.common.pageHeader && this.content.common.pageTitle) {
      const pageTitleObj: IPageTitle = {
        header: this.content.common.pageHeader.fadPageTitle,
        title: this.content.common.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageTitleObj);
      this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]).emit(this._appSession.metaData.brandCd);
    }
  }

  checkLeavingDisclaimerModal() {
    const prefix = this._appSession.searchParams?.plan?.alphaPrefix ?? this._appSession?.deeplinkParams?.alphaprefix;
    if (prefix && prefix.toUpperCase() === VEP) {
      const modalMetaInfo: IModalMetaInfo = {} as IModalMetaInfo;
      modalMetaInfo.modalId = 'MD_LEAVING_SITE_VEP';
      modalMetaInfo.urlToBeRedirected = VEP_BEH_HEALTH;
      this._genericModalService.openGenericSidePanel(modalMetaInfo);
    }
  }
}
