import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { LeavingDisclaimerComponent } from '../../../common/components/leaving-disclaimer/leavingDisclaimerCmp';
import { IOptions } from '../../../common/interfaces/iAppMetadata';
import { IWindow } from '../../../common/interfaces/iWindow';
import { FeatureFlagService } from '../../../common/services/featureFlagInitializer';
import { RouteUtil } from '../../../common/utilities/routeUtil';
import { ProcedureCategoryService } from '../../../eyc/cost-search/services/procedureCategorySvc';
import { IAddressDetails } from '../../../fad/search-providers/interfaces/iAddressForm';
import { FindCareFeature } from '../../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../findcare/common/utilities/featureManagerUtil';
import { IPlanRuleParameters, IRuleParameters } from '../../rules/interfaces/iRulesParameters';
import { RulesService } from '../../rules/services/RulesSvc';
import { IPlanRule } from '../interfaces/iPlanRule';
import { IProviderType } from '../interfaces/iProviderType';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { LocationService } from './../../../common/components/location/services/locationService';
import { ADDRESS_SELECT, AppConstants, ContentLanguageCode, Locale } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { AppEvents } from './../../../common/enums/appEvents';
import { IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { AddressByLatLongResponse, LocationResponse } from './../../../common/models/locationAddress';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ProviderOrchestrationService } from './../../provider-details/services/providerOrchestrationSvc';
import { CommonUtil } from './../../utilities/commonUtil';
import { GA_TERMINATION_PROVIDERS, PROVIDER_SEARCH_DISTANCE, STATE_GA } from './../values/providerSearchConstants';
import { PFIntegratedSearchComponent } from './pfIntegratedSearchCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-search-cmp',
  templateUrl: '../views/pfSearchContainerCmp.html',
  providers: []
})
export class PFSearchContainerComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('leavingDisclaimerModal')
  leavingDisclaimerModal: LeavingDisclaimerComponent;
  @ViewChild('integratedsearch')
  private _integratedSearchCmp: PFIntegratedSearchComponent;
  planRule: IPlanRule;
  locale: string;
  dataModifiedOn: string = '';
  showSearchLoader: boolean = true;
  planSelection: boolean = false;
  searchCriteria: boolean = false;
  primaryState: string = '';

  typesDdl: any = {
    title: 'Types',
    options: []
  };

  specialtyDdl: any = {
    title: 'Specialty',
    options: [
      {
        label: 'All',
        value: '',
        id: 'cB_Specialty_',
        name: 'cB_Specialty_',
        trueValue: '',
        falseValue: '',
        isChecked: false
      }
    ]
  };

  subSpecialtyDdl: any = {
    title: 'Sub Specialty',
    options: []
  };

  coordinates: any = {
    longitude: '',
    latitude: ''
  };

  searchParams: ISearchParameters;
  isShowChinese: boolean = false;
  loadAppInitializationRules: boolean = true;
  url: any;
  _brand: string;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  private navigationSubscription: Subscription;
  private changePlanSaveClickSubscription: Subscription;

  constructor(
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerUtilityService: ProviderUtilityService,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    private _router: Router,
    @Inject('Window')
    private _window: IWindow,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _appUtility: AppUtility,
    private _featureFlagSvc: FeatureFlagService,
    private _changePlanService: ChangePlanService,
    private _procedureCategoryService: ProcedureCategoryService,
    private _ruleService: RulesService,
    private _providerOrchestrationService: ProviderOrchestrationService,
    private _infoListSvc: InfoListService,
    private _routeUtil: RouteUtil,
    private _locationService: LocationService,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');

    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd && !this._appSession.metaData.isBrowserNavigationEnabled) {
        this.initializeInvites();
      }
    });

    this._locationService.pinCode.subscribe((data) => {
      if (data) {
        this.setGATerminationMsg();
      }
    });

    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.subscribe((path: AppNavigations) => {
      if (path === AppNavigations.SEARCH_PROVIDERS_PATH) {
        this.loadAppInitializationRules = true;
        this.ngOnInit();
      }
    });
  }

  initializeInvites() {
    // Set default values and re-fetch any data you need.
    this.setPageHeader();
    this.setSearchParams();
    if (this._appSession.isIdentifactionSearch === true) {
      if (this._appSession && this._appSession.searchParams) {
        this.retainSearchCriteriaValue();
      }
    }

    if (this._appSession.provDataModifiedOn) {
      this.dataModifiedOn = this._appSession.provDataModifiedOn;

      this.initializeView();
    }
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
    // Temporarly added. Once provider types are completely removed from app
    // session by Renaissance team below statement can be removed.
    this._appSession.providerTypes = [];

    // getDataModified will return authentication. This token should be used in all API request.
    // API request with token will be considered as unauthorized and API will return 401 error code.
    if (this._appSession.provDataModifiedOn === '') {
      this.showSearchLoader = true;
      this._providerUtilityService.getDataModified().then(
        (result: any) => {
          if (result.codeTypeList && result.codeTypeList.length > 0) {
            this._appSession.provDataModifiedOn = result.codeTypeList[0].code;
            this._appSession.metaData.token = result.token;
            this.dataModifiedOn = this._appSession.provDataModifiedOn;
            this.setDefaultLocation();
            this._featureFlagSvc.load().then((data) => this.initializeView());
          }
        },
        (error: any) => {
          try {
            this.onCategoryError('PROV_DATA_MDFD_DT', error);
          } catch (e) {}
        }
      );
    } else {
      this.dataModifiedOn = this._appSession.provDataModifiedOn;

      this.initializeView();
    }
    /* This is temporary code changes our side enable the chinse link in ABC or EBC or EBCBS
    and long term link will enable in RCP side */
    this._brand = this._appSession.metaData.brandCd;
    if (!this._appSession.isSecureState && (this._brand === 'ABC' || this._brand === 'EBC' || this._brand === 'EBCBS')) {
      this.isShowChinese = true;
    }

    if (this._appSession.isChangePlan) {
      this._appSession.addressObj = {} as IAddressDetails;
      this._appSession.isChangePlan = false;
      this.showPlanSelection();
    }
  }

  ngAfterViewInit() {
    this.setPageHeader();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }
  /* This is temporary code changes our side enable the chinse link in ABC or EBC or EBCBS
      and long term link will enable in RCP side - start here */
  get showChineseLink(): boolean {
    return FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_CHN_LOCALE, this._appSession);
  }
  getchineseMotionPointUrl(_brand: string) {
    switch (_brand.toUpperCase()) {
      case 'ABC':
        return this._appSession.appConfig?.chineseMotionPointUrl['ABC'];
      case 'EBC':
      case 'EBCBS':
        return this._appSession.appConfig?.chineseMotionPointUrl['EBCBS'];
      default:
        return;
    }
  }
  openMotionPointSite() {
    const url = this.getchineseMotionPointUrl(this._brand);
    let windowParams: string;
    this.url = url;
    windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
    this.url = this.url.indexOf('://') <= 0 ? 'http://' + this.url : this.url;
    const winArgs = [this.url, '_blank'];
    if (windowParams) {
      winArgs.push(windowParams);
    }
    this._window.open.apply(this._window, winArgs);
  }
  /* This is temporary code changes our side enable the chinse link in ABC or EBC or EBCBS
    and long term link will enable in RCP side - end here */

  initializeView() {
    this.setSearchParams();
    if (this._appSession.isSecureState === false) {
      this.planSelection = true;
      this.searchCriteria = false;
    }

    if (this._appSession && this._appSession.searchParams) {
      this.retainSearchCriteriaValue();
    }

    if (this._appSession.isSecureState && this.searchParams.plan) {
      if (this.searchParams && this.searchParams.plan.securePlanLabelKey) {
        this.searchParams.plan.name = this.content.searchCriteriaComponent.labels[this.searchParams.plan.securePlanLabelKey];
      }
    }
    this.getProviderTypes();
  }
  /***
   * set the Page Header and Title dynamically based on pages
   */
  setPageHeader() {
    if (this.content.globalHeaderComponent && this.content.globalHeaderComponent.pageHeader && this.content.globalHeaderComponent.pageHeader.fadPageTitle) {
      const pageFadObj = {
        header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
        title: this.content.globalHeaderComponent.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageFadObj);
    }
  }

  setSearchParams() {
    this._appSession.isCareProvider = false;
    this._appSession.filterSearchParams = undefined;
    this._appSession.isMemberMedicalGrpSearch = false;
    if (this._appSession.isSecureState && this._appSession.filterChangePlan?.careType) {
      this._appSession.filterChangePlan.careType = '';
    }
    this.searchParams = {
      stateCd: {
        code: this.primaryState
      },
      typeSelectNm: 'P',
      specialtySelectNm: [],
      coordinates: this.coordinates,
      acceptingNewPatients: false,
      ableToServePcp: false,
      medicaidInd: false,
      ecpprovider: false,
      cooperativeCareOnly: false,
      sosOnly: false,
      bluePrecision: false,
      blueDistinct: '',
      blueDistinctPlus: false,
      enhancedPersonalCare: false,
      zip: '',
      genderCd: '',
      provNm: '',
      speicalityDdlValues: this.specialtyDdl,
      providerTypeDdlValues: this.typesDdl,
      subSpeicalityDdlValues: this.subSpecialtyDdl,
      categoryDescription: {},
      plan: {},
      distanceNm: PROVIDER_SEARCH_DISTANCE,
      brandCode: this._appSession.metaData && this._appSession.metaData.brandCd ? this._appSession.metaData.brandCd : 'ABCBS',
      typeOfCareNm: '',
      stateDdlValues: [],
      planCategoryDdlValues: [],
      planDdlValues: [],
      coverageTypeCode: '',
      boardCertification: false,
      providerTier: '',
      visionSrvcAvailableList: [],
      blueDistinctionCenter: [],
      blueDistinctionType: '',
      blueDistinctionTypePlus: '',
      hasPersonalizedMatch: false,
      centerOfExcellence: false,
      providerOfDistinction: false,
      includePrimeGroupSearch: true,
      msp: false,
      valueBasedProvider: false
    };
  }

  retainSearchCriteriaValue() {
    this.searchParams.plan = this._appSession.searchParams.plan;
    this.searchParams.provNm = this._appSession.searchParams.provNm ? this._appSession.searchParams.provNm : '';
    this.searchParams.zip = this._appSession.searchParams.zip && this._appSession.searchParams.zip.trim() ? this._appSession.searchParams.zip.trim() : '';
    this.searchParams.typeSelectNm = this._appSession.searchParams.typeSelectNm;
    this.searchParams.providerTypeCodeList = [];
    this.searchParams.specialtySelectNm = this._appSession.searchParams.specialtySelectNm;
    this.searchParams.distanceNm = this._appSession.searchParams.distanceNm;
    this.searchParams.stateCd = this._appSession.searchParams.stateCd;
    this.searchParams.typeOfCareNm = this._appSession.searchParams.typeOfCareNm;
    this.searchParams.stateDdlValues = this._appSession.searchParams.stateDdlValues;
    this.searchParams.planCategoryDdlValues = this._appSession.searchParams.planCategoryDdlValues;
    this.searchParams.planDdlValues = this._appSession.searchParams.planDdlValues;
    if (this._appSession.deeplinkParams && this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      this.searchParams.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }
    this.searchParams.city = this._appSession.searchParams.city ? this._appSession.searchParams.city : '';
    if (this.searchParams.coordinates && this._appSession.searchParams.coordinates) {
      this.searchParams.coordinates.latitude = this._appSession.searchParams.coordinates.latitude.toString();
      this.searchParams.coordinates.longitude = this._appSession.searchParams.coordinates.longitude.toString();
    }
    this.searchParams.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;
    this.searchParams.visionBVV = this._appSession.searchParams.visionBVV;
    this.searchParams.hearUSAEGR = this._appSession.searchParams.hearUSAEGR;
    this.searchParams.identificationNumber = this._appSession.searchParams.identificationNumber;
    this.searchParams.countyCode = this._appSession.searchParams.countyCode;
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchOption = '';
    this._appSession.hospSearchTerm = '';
    this._appSession.medicalGrpSearchTerm = '';
    if (
      (!this._appSession.isDeepLink && this._appSession.searchParams.plan && this._appSession.searchParams.plan.name !== this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown]) ||
      (this._appSession?.planRule && this._appSession?.planRule?.isMedicareGov)
    ) {
      this.showSearchCriteria();
    }
  }

  showSearchCriteria() {
    this.planSelection = false;
    if (this._appSession?.planRule?.isMedicareGov) {
      this.searchCriteria = true;
      return;
    }
    if (this.searchParams) {
      this._appSession.searchParams = this.searchParams;
      const planRequest = CommonUtil.buildPlanRuleRequest(this.searchParams, this._appSession);
      if (!this._appSession.isChangePlan && this.loadAppInitializationRules) {
        this.loadAddressFormPage(planRequest);
      }
    } else {
      this.searchCriteria = true;
    }
  }

  setGATerminationMsg() {
    const _routeUrl = this._routeUtil.getResolvedUrl(this._route.snapshot);
    const stateCode = this._appSession.searchParams.stateCd?.code ? this._appSession.searchParams.stateCd.code : '';
    // Pushing GA Terminating Provider notification message to the info list Svc when plan state belongs GA
    if (
      this.showGATerminatingProviders &&
      this._appSession.planRule?.showGACareGapNotificationMsg &&
      !this._dataHelper.isEmptyString(stateCode) &&
      stateCode.toLocaleUpperCase() === STATE_GA &&
      _routeUrl == AppNavigations.SEARCH_PROVIDERS_PATH
    ) {
      this._infoListSvc.addMsgById(GA_TERMINATION_PROVIDERS);
    } else {
      this._infoListSvc.removeMsgById(GA_TERMINATION_PROVIDERS);
    }
    this._infoListSvc.update();
  }

  loadAddressFormPage(planRequest?: IPlanRuleParameters) {
    this.showSearchLoader = true;
    this.loadAppInitializationRules = false;
    this._ruleService.getAppInitializationRules(planRequest).then(
      (result: IPlanRule) => {
        if (result) {
          this.showSearchLoader = false;
          this.planRule = {
            isLiberty: result.isLiberty ? result.isLiberty : false,
            isBVV: result.isBVV ? result.isBVV : false,
            isBVVI: result.isBVVI ? result.isBVVI : false,
            isHCS: result.isHCS ? result.isHCS : false,
            isVSP: result.isVSP ? result.isVSP : false,
            isEyeQuest: result.isEyeQuest ? result.isEyeQuest : false,
            isSuperior: result.isSuperior ? result.isSuperior : false,
            isEyeMed: result.isEyeMed ? result.isEyeMed : false,
            isTransportation: result.isTransportation ? result.isTransportation : false,
            isDentaQuest: result.isDentaQuest ? result.isDentaQuest : false,
            isIndividual: result.isIndividual ? result.isIndividual : false,
            isGroup: result.isGroup ? result.isGroup : false,
            isMedicaid: result.isMedicaid ? result.isMedicaid : false,
            isMedicareGov: result.isMedicareGov ? result.isMedicareGov : false,
            transportationLocation: result.transportationLocation,
            isCaHmoDmhc: result.isCaHmoDmhc,
            cobrandRule: result.cobrandRule,
            showGACareGapNotificationMsg: result.showGACareGapNotificationMsg ? result.showGACareGapNotificationMsg : false,
            is32BJMember: result.is32BJMember ? result.is32BJMember : false,
            isOhEyeMed: result.isOhEyeMed ? result.isOhEyeMed : false,
            isOONProviderEnabled: result.isOONProviderEnabled ? result.isOONProviderEnabled : false
          };
          this._appSession.adpPgmCode = result.hppPgmCode;
          this._appSession.planRule = this.planRule;
          this._appSession.isCaHmoDmhc = this.planRule?.isCaHmoDmhc || false;
          this._appSession.searchParams.brandCode = this._appSession?.metaData?.brandCd;
          if (Array.isArray(result?.availableProviders) && result?.availableProviders?.length) {
            this._appSession.providerTypes = CommonUtil.populateProviderTypes(result.availableProviders, this._appSession, true);
          }
          if (this._appSession.isSecureState && this.planRule?.isCaHmoDmhc && !this._appSession.isHcidSearch) {
            this.setHomeAddressDetails();
          }
          this.setGATerminationMsg();
          this.searchCriteria = true;
        }
      },
      (error: any) => {
        try {
          this.onCategoryError('PLAN_RULE', error);
          this.showSearchLoader = false;
          this.searchCriteria = true;
        } catch (e) {}
      }
    );
  }

  setHomeAddressDetails() {
    if (this._appSession.addressObj?.homeAddress?.zipCode) {
      if (this._appSession.addressObj.addressModel === ADDRESS_SELECT.HOME) {
        this._appSession.searchParams.zip = this.searchParams.postalCode = this._appSession.addressObj.homeAddress?.zipCode;
        this.searchParams.streetAddress = this._appSession.addressObj.homeAddress?.streetAddress;
        this.searchParams.addressLine2 = this._appSession.addressObj.homeAddress?.addressLine2;
        this.searchParams.city = this._appSession.addressObj.homeAddress?.city;
        this.searchParams.stateCd = this._appSession.addressObj.homeAddress?.state;
        this._appSession.customAddressSearch = false;
      } else if (this._appSession.addressObj.addressModel === ADDRESS_SELECT.WORK) {
        this._appSession.searchParams.zip = this.searchParams.postalCode = this._appSession.addressObj?.workAddress?.zipCode;
        this.searchParams.streetAddress = this._appSession.addressObj?.workAddress?.streetAddress;
        this.searchParams.addressLine2 = this._appSession.addressObj?.workAddress?.addressLine2;
        this.searchParams.city = this._appSession.addressObj?.workAddress?.city;
        this.searchParams.stateCd = this._appSession.addressObj?.workAddress?.state;
        this._appSession.customAddressSearch = false;
      } else if (this._appSession.addressObj.addressModel === ADDRESS_SELECT.CUSTOM_ADDRESS) {
        this.setCoustomAddressSearch();
      }
    } else {
      this.setCoustomAddressSearch();
    }
    if (this.integratedSearchCmp?.locationCmp?.locationTxt) {
      this.integratedSearchCmp.updateZipcode(this.searchParams.postalCode);
    }
  }

  private setCoustomAddressSearch() {
    this.searchParams.postalCode = this._appSession?.searchParams?.zip;
    this._appSession.customAddressSearch = true;
  }

  loadCahmoPage() {
    this.searchCriteria = false;
  }

  showPlanSelection() {
    this.loadAppInitializationRules = true;
    this.planSelection = true;
    this.searchCriteria = false;
    this.searchParams.speicalityDdlValues = this.specialtyDdl;
    this.searchParams.providerTypeDdlValues.options.values = this.typesDdl;
    if (this.searchParams.subSpeicalityDdlValues && this.searchParams.subSpeicalityDdlValues.options) {
      this.searchParams.subSpeicalityDdlValues.options.values = this.subSpecialtyDdl;
    }
    if (CommonUtil.isMemberSecure(this._appSession)) {
      if (this._appSession?.planRule && !this._appSession?.planRule?.isMedicareGov) {
        if (
          this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown &&
          this._appSession &&
          this._appSession.metaData &&
          this._appSession.metaData.appContract &&
          this._appSession.metaData.appContract.memberList &&
          this._appSession.metaData.appContract.memberList.length > 0
        ) {
          const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
          if (_index > -1) {
            const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
            const coverageDetails = !this.multiCoverageEnabled ? _member?.coverageDetails : _member?.coverageDetailsV2?.find((coverage) => coverage.coverageUid === this._appSession.coverageUid);
            if (coverageDetails && coverageDetails['coverageType']) {
              this._appSession.searchParams.typeOfCareNm = this._appSession.filterChangePlan.careType ? this._appSession.filterChangePlan.careType : coverageDetails['coverageType'];
            }
          }
          this.planSelection = true;
          this.searchCriteria = false;
          this._appSession.landingTab = undefined;
          this._appSession.isPlanUnknown = true;
        } else {
          this.planSelection = true;
          this.searchCriteria = false;
          this._appSession.isPlanUnknown = true;
          this._appUtility.resetAppToPublicState();
        }
      } else {
        this.showSearchCriteria();
      }
    }

    this._appUtility.resetAppToPublicState();

    if (this._appSession.isSecureState === true) {
      this.ngOnInit();
    }
  }

  getProviderTypes() {
    this.showSearchLoader = false;
  }

  buildRuleRequest() {
    let _prefix = '';
    if (this.searchParams.plan && this.searchParams.plan.alphaPrefix && this.searchParams.plan.alphaPrefix !== '') {
      _prefix = this.searchParams.plan.alphaPrefix;
    } else if (this._appSession.deeplinkParams.alphaprefix && this._appSession.deeplinkParams.alphaprefix !== '') {
      _prefix = this._appSession.deeplinkParams.alphaprefix;
    }
    const ruleRequest: IRuleParameters = {
      brand: this._appSession.metaData.brandCd,
      prefix: _prefix,
      isDeepLink: this._appSession.isDeepLink,
      deeplinkAction: this._appSession.deeplinkParams?.action,
      ableToServePcp: this.searchParams.ableToServePcp
    };
    return ruleRequest;
  }

  bindProviderTypes(data: any) {
    let code: any;
    const providerTypes: IProviderType[] = [];
    code = data.codeTypeList;
    for (let i = 0; i < code.length; i++) {
      const _codeName: IProviderType = {
        code: code[i].code,
        name: code[i].name,
        licenseList: code[i].licenseCodeList && code[i].licenseCodeList.length > 0 ? code[i].licenseCodeList : [],
        isChecked: code[i].isChecked ? code.isChecked : false
      };
      providerTypes.push(_codeName);
    }
    this._appSession.providerTypes = providerTypes;
    this.showSearchLoader = false;
  }

  onCategoryError(type: string, error: any) {
    this.showSearchLoader = false;
    throw error;
  }

  onLanguageClick() {
    this.loadAppInitializationRules = true;
    this._appSession.isDeepLink = false;
    this._appSession.providerTypes = [];
    if (this._appSession?.metaData?.locale) {
      this._appSession.metaData.locale = this._appSession.metaData.locale === Locale.ENGLISH ? Locale.SPANISH : Locale.ENGLISH;
    }
    this._document.documentElement.lang = this._appSession?.metaData?.locale?.toString() === Locale.ENGLISH ? ContentLanguageCode.SPANISH : ContentLanguageCode.ENGLISH;
    // Both component need to reset for rendering language contents initialized on OnInit events
    this.planSelection = false;
    this.searchCriteria = false;
    this._contentHelper.resetContent();
    if (this._appSession.isSecureState === false) {
      this._appSession.searchParams = undefined;
    }
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
  }

  openLeavingDisclaimerModal(url: any) {
    this.locale = this._appSession.metaData.locale.toString();
    if (url) {
      this.leavingDisclaimerModal.url = url;
    }

    let _prefix = '';
    if (!this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)) {
      _prefix = this.searchParams.plan.alphaPrefix;
    }

    let _prefixContent = null;
    if (this.content.leavingDisclaimerComponent[_prefix]) {
      _prefixContent = this.content.leavingDisclaimerComponent[_prefix];
    }

    this.leavingDisclaimerModal.header = _prefixContent ? _prefixContent.header : this.content.leavingDisclaimerComponent.header;
    this.leavingDisclaimerModal.content1 = _prefixContent ? _prefixContent.content1 : this.content.leavingDisclaimerComponent.content1;
    this.leavingDisclaimerModal.content2 = _prefixContent ? _prefixContent.content2 : this.content.leavingDisclaimerComponent.content2;
    this.leavingDisclaimerModal.content3 = _prefixContent ? _prefixContent.content3 : '';
    this.leavingDisclaimerModal.closeBtnText = _prefixContent ? _prefixContent.btnClose : this.content.leavingDisclaimerComponent.closeBtnText;
    this.leavingDisclaimerModal.continueBtnText = _prefixContent ? _prefixContent.btnContinue : this.content.leavingDisclaimerComponent.continueBtnText;

    this.leavingDisclaimerModal.show();
  }

  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  onLocationError() {
    this.integratedSearchCmp.isLocationValid();
  }

  onPlanError() {
    this.integratedSearchCmp.planUnknownError = true;
  }

  onModal(event: any) {
    this.integratedSearchCmp.openModal(event);
  }

  onAnalytics(event: IAdobeSearchDetail) {
    this.integratedSearchCmp.setAnalyticsQuery(event);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  setDefaultLocation() {
    if (!this._appSession.defaultLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.onGeoLocationSuccess(position);
      });
    }
  }

  onGeoLocationSuccess(position: any) {
    if (position && position.coords) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      if (latitude !== '' && longitude !== '') {
        this._providerUtilityService.getAddressByLatLong(latitude, longitude).then((address: AddressByLatLongResponse) => {
          if (address.zipcode !== '') {
            const zipCode = address.zipcode;
            const formattedAddress = address.formattedAddress;
            // Recalling bing service to check if latitue and longitude is returning valid zip code in united states
            this._providerUtilityService.getGeoLocation(zipCode).then((result: LocationResponse[]) => {
              if (result && Object.keys(result).length > 0) {
                const results = this._appUtility.buildLocationResponse(result);
                this._appSession.defaultLocation = {
                  zip: zipCode,
                  state: results[Object.keys(results)[0]].stateCd,
                  latitude: results[Object.keys(results)[0]].lat,
                  longitude: results[Object.keys(results)[0]].long,
                  formattedAddress: formattedAddress
                };
              }
            });
          }
        });
      }
    }
  }

  get integratedSearchCmp(): PFIntegratedSearchComponent {
    return this._integratedSearchCmp;
  }
}
