import { Inject, Injectable } from '@angular/core';
import { ICompareRequest, ICompareResponse, IDetailsParameters, IPlanList } from '../interfaces/iCompareProvider';
import { IProvider } from '../interfaces/iProvider';
import { ProviderCompareService } from '../services/providerCompareSvc';
import { AppSession } from './../../../common/values/appSession';

@Injectable()
export class ComapreProviderHandler {
  selectedProvidersDetailsResponse: ICompareResponse[];
  constructor(
    private _compareService: ProviderCompareService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  getProviders(providers: IProvider[]): Promise<any> {
    if (!providers || providers.length === 0) {
      return Promise.resolve([]);
    }

    return this._compareService.getCompare(this.buildCompareRequest(providers));
  }

  private buildCompareRequest(providers: IProvider[]) {
    const req = {} as ICompareRequest;
    req.providerDetail = [];
    const plan: IPlanList = {
      identifier: this._appSession.searchParams.plan.identifier,
      isNationalPlan: this._appSession.searchParams.plan.isNationalPlan
    };

    const netwrkList = [];
    for (const network of this._appSession.searchParams.plan.networkList || []) {
      const net = {
        code: network
      };
      netwrkList.push(net);
    }

    (providers || []).forEach((provider) => {
      const request: IDetailsParameters = {
        providerIdentifier: provider.providerIdentifier,
        addressIdentifier: provider.location.address.addressId,
        brandCode: this._appSession.metaData.brandCd,
        state: undefined,
        distance: this._appSession.searchParams.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
        planStateCode: this._appSession.searchParams.plan.stateCd,
        rxChoiceNtwkIndicator: '',
        specialtyCategoryList: this._appSession.searchParams.specialtySelectNm,
        networkList: netwrkList,
        alphaPrefix: this._appSession?.searchParams?.plan?.alphaPrefix,
        location: {
          latitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.latitude : '',
          longitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.longitude : ''
        },
        planList: new Array(plan),
        locale: (this._appSession.metaData && this._appSession.metaData.locale && this._appSession.metaData.locale.toString()) || ''
      };
      if (provider.providerTypeCodeList) {
        request.providerTypeCodeList = provider.providerTypeCodeList;
      }

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid && !this._appSession.isPlanUnknown) {
        request.mbrUid = this._appSession.metaData.appContract.mbrUid;
      }

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid && !this._appSession.isPlanUnknown) {
        request.contractUid = this._appSession.metaData.appContract.contractUid;
      }
      req.providerDetail.push(request);
    });
    return req;
  }
}
