import { Component } from '@angular/core';
import { FindCarePageType } from '../../../enums/findCarePages';
import { PageAlertComponent } from './pageAlertCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-page-alert-v2-cmp',
  templateUrl: './resultPageAlertCmp.html',
  providers: []
})
export class ResultPageAlertComponent extends PageAlertComponent {

  page = FindCarePageType.Results;
}
