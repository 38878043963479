import { Injectable } from '@angular/core';
import { IMemberKeyRequest } from '../../common/interfaces/iMemberKey';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { apiNameList } from './../../../environments/api-name-list';
import { HttpMethod } from './../../common/enums/httpMethodEnum';
import { BaseService } from './../../common/services/baseService';
import { HttpClientService } from './httpClientService';
@Injectable()
export class MemberKeyService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getMemberKey(reqObj: IMemberKeyRequest): Promise<any> {
    const apiUrl = apiNameList.restApi.memberKey;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
