/// <reference types="bingmaps"/>

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { RIGHT, THREE } from '../../constants/app-constants';
import { IFinalProvider } from '../../interfaces/iCommonSearchResponse';
import { DataService } from '../../services/dataService';
import { SliderService } from '../../services/sliderSvc';
import { AppConfig } from '../../values/appConfig';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { ProviderDetailsNavigationService } from './../../../fad/provider-details/services/providerDetailsNavigationSvc';
import { IMapRequest } from './../../../fad/search-results/interfaces/iMapRequest';
import { INFOBOX_EVENTS } from './../../enums/infoBox';
import { IDirectionAttributes } from './../../interfaces/iDirectionAttributes';
import { BingMapsLoader } from './../../services/bingMapsLoader';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { IMapHoverEvent, IMapHoverOffEvent } from './iMapEvents';
import { MapOverlayHelper } from './mapOverlayHlpr';
@Component({
  moduleId: module.id,
  selector: 'app-fad-map-cmp',
  templateUrl: './pfMapCmp.html'
})
export class PFMapComponent extends BaseComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  virtualProviders: IFinalProvider[];
  @Input('locationData')
  locationData: IMapRequest[];
  @Output()
  mapPinClickEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('mapView')
  mapView: any;
  @ViewChild('directionsItinerary')
  directionsItinerary: any;
  @ViewChild('InfoBoxV1') infoBoxV1: ElementRef<HTMLDivElement>;
  @ViewChild('InfoBoxV2') infoBoxV2: ElementRef<HTMLDivElement>;
  @ViewChild('virtualProvidersPanel')
  virtualProvidersPanel: TemplateRef<HTMLElement>;
  @Input()
  showTileViewInfoBox: boolean;
  @Input()
  highlightPushPin: boolean = false;
  @Output()
  openMapModal: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  scheduleAppointment: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openAssignPCP: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  assignCare: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openSpecialityModal: EventEmitter<any> = new EventEmitter<any>();

  virtualProvidersModelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  providersearchType: boolean = false;
  startLocation: string;
  endLocation: string;
  directionFlag: boolean = false;
  isShortestTimeSelected: boolean = false;
  isShortestDistanceSelected: boolean = true;
  tooltip: Microsoft.Maps.Infobox;
  map: Microsoft.Maps.Map;
  directionsManager: any;
  locationText: string;
  mapDirections: string;
  @ViewChild('directionsInputContainer')
  directionsInputContainer: any;
  showDirectionInput = false;
  @Input()
  uniqueId = '';
  @Input()
  addressId = '';
  pinHighlightColor = 'blue';
  index: number = 0;
  eventKey: string = '';
  event: any = [];

  appConfig: AppConfig;

  private _onProviderMapTileHoverSubscription: Subscription;
  private _onProviderMapTileHoverOutSubscription: Subscription;
  private _virtualOptionsClickSubs: Subscription;

  private ACTIVE_ICON;
  private INACTIVE_ICON;
  private PM_ICON = this.getCommonImageURL('personalized-match-pin.png');
  isEnhancedMapView: boolean;
  selectedLocation: IMapRequest;

  constructor(
    _route: ActivatedRoute,
    _eventHandler: EventHandler,
    _contentHelper: ContentHelper,
    private loader: BingMapsLoader,
    private _cdf: ChangeDetectorRef,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _providerUtilityService: ProviderUtilityService,
    private _overlayHlpr: MapOverlayHelper,
    private _sidePanel: SidePanel,
    private _dataService: DataService,
    private _sliderService: SliderService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
    this.appConfig = this._appSession.appConfig;
    this.ACTIVE_ICON = this.appConfig?.bingMaps.icons.activePin;
    this.INACTIVE_ICON = this.appConfig?.bingMaps.icons.inactivePin;
    this._onProviderMapTileHoverSubscription = this._providerDetailsNavigationService.onProviderMapTileHover.subscribe((professional) => {
      if (
        professional !== null &&
        typeof professional !== 'undefined' &&
        professional !== '' &&
        professional.location &&
        professional.location.address &&
        professional.location.address.latitude &&
        professional.location.address.latitude !== '' &&
        professional.location.address.longitude &&
        professional.location.address.longitude !== '' &&
        this.locationData &&
        this.locationData.length > 0 &&
        this.map
      ) {
        this.onRightTileMouseOver(professional);
      }
    });
    this._onProviderMapTileHoverOutSubscription = this._providerDetailsNavigationService.onProviderMapTileHoverOut.subscribe((professional) => {
      if (
        professional !== null &&
        typeof professional !== 'undefined' &&
        professional !== '' &&
        professional.location &&
        professional.location.address &&
        professional.location.address.latitude &&
        professional.location.address.latitude !== '' &&
        professional.location.address.longitude &&
        professional.location.address.longitude !== '' &&
        this.locationData &&
        this.locationData.length > 0 &&
        this.map
      ) {
        this.onRightTileMouseOut(professional);
      }
    });
  }

  async ngAfterViewInit() {
    if (this.map) {
      // Map is already loaded and created.
      return;
    }

    if (!this.mapView?.nativeElement) {
      throw new Error('Map view html element is not yet initialized');
    }

    // Load Bing Maps API
    await this.loader.loadMapsLibrary();
    const latitude = this._appSession.searchParams?.coordinates?.latitude;
    const longitude = this._appSession.searchParams?.coordinates?.longitude;

    const countyLatitude = this._appSession.searchParams?.coordinates?.countyLatitude;
    const countyLongitude = this._appSession.searchParams?.coordinates?.countyLongitude;

    if (latitude && longitude && latitude !== '0' && longitude !== '0') {
      this.map = new Microsoft.Maps.Map(this.mapView.nativeElement, {
        credentials: this.appConfig?.bingMaps.apiKey,
        center: new Microsoft.Maps.Location(latitude, longitude),
        zoom: 10
      });
    } else if (countyLatitude && countyLongitude && countyLatitude !== '0' && countyLongitude !== '0') {
      this.map = new Microsoft.Maps.Map(this.mapView.nativeElement, {
        credentials: this.appConfig?.bingMaps.apiKey,
        center: new Microsoft.Maps.Location(countyLatitude, countyLongitude),
        zoom: 9
      });
    } else {
      this.map = new Microsoft.Maps.Map(this.mapView.nativeElement, {
        credentials: this.appConfig?.bingMaps.apiKey
      });
    }

    this.setMap(this.locationData ? this.locationData : null);
    this.displayVirtualOptionsLayer();
  }

  ngOnInit(): void {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    this.isEnhancedMapView = true;
  }

  ngOnChanges(args: any) {
    if (args?.locationData) {
      this.setMap(this.locationData ? this.locationData : null);
    }
    if (args?.virtualProviders) {
      this.displayVirtualOptionsLayer();
    }
  }

  ngOnDestroy() {
    if (typeof this._onProviderMapTileHoverSubscription !== 'undefined' && this._onProviderMapTileHoverSubscription !== null) {
      this._onProviderMapTileHoverSubscription.unsubscribe();
    }
    if (typeof this._onProviderMapTileHoverOutSubscription !== 'undefined' && this._onProviderMapTileHoverOutSubscription !== null) {
      this._onProviderMapTileHoverOutSubscription.unsubscribe();
    }
    if (this._virtualOptionsClickSubs) {
      this._virtualOptionsClickSubs.unsubscribe();
    }
  }

  setMap(locationData?: IMapRequest[]) {
    if (!this.map || !Array.isArray(locationData) || locationData.length <= 0) {
      this.providersearchType = true;
      return;
    }

    this.locationData = locationData;

    if (this.map.layers) {
      this.map.layers.clear();
    }
    const locs: Array<Microsoft.Maps.Location> = [];
    const layer: Microsoft.Maps.Layer = new Microsoft.Maps.Layer();
    const locationPromises: any[] = [];
    let similarLocations: Array<Microsoft.Maps.Location>;
    this.locationData.forEach((locData, i) => {
      locationPromises.push(
        this.getLocation(locData).then((mapLocation) => {
          if (!mapLocation) {
            return;
          }

          // Identify multiple providers in same location.
          similarLocations = locs.filter((l) => {
            try {
              const lat1 = this.getFixedDecimal(l.latitude);
              const lon1 = this.getFixedDecimal(l.longitude);
              const lat2 = this.getFixedDecimal(parseFloat(locData.latitude));
              const lon2 = this.getFixedDecimal(parseFloat(locData.longitude));
              return lat1 === lat2 && lon1 === lon2;
            } catch (err) {
              return false;
            }
          });

          // Adjust pin anchor so that user can see all pins under same location. TODO: Need to identify
          // a better logic to display multiple pins under same location.
          let anchorX = 12;
          let anchorY = 12;
          const lastSimilarLoc = similarLocations.pop();
          if (lastSimilarLoc) {
            //@ts-ignore
            anchorX = lastSimilarLoc.anchorX + (Math.floor(Math.random() * 16) + 1);
            //@ts-ignore
            anchorY = lastSimilarLoc.anchorY + (Math.floor(Math.random() * 16) + 1);
          }

          locs[i] = mapLocation;
          //@ts-ignore
          mapLocation.addressId = locData.addressId;
          //@ts-ignore
          mapLocation.anchorX = anchorX;
          //@ts-ignore
          mapLocation.anchorY = anchorY;

          let pushpin: Microsoft.Maps.Pushpin;
          const areEqualAddrsId = this.addressId !== '' && locData.addressId !== '' && this.addressId === locData.addressId;
          //Define Pushpin properties
          const text = locData.providerSearch ? locData.text : `${i + 1}`;
          const title = locData.providerSearch ? locData.title : locData.pharmacyName;

          if (areEqualAddrsId && this.showTileViewInfoBox) {
            pushpin = new Microsoft.Maps.Pushpin(mapLocation, {
              icon: this.ACTIVE_ICON,
              anchor: new Microsoft.Maps.Point(anchorX, anchorY)
            });
          }

          if (areEqualAddrsId && !this.showTileViewInfoBox) {
            //Map View in Drive directions
            this.locationText = locData.title;
            this.mapDirections = this.content?.mapModal?.mapText?.replace(/{LOCATION}/gi, this.locationText);
            pushpin = new Microsoft.Maps.Pushpin(mapLocation, {
              title,
              text,
              color: this.pinHighlightColor
            });
          }

          if (!areEqualAddrsId && this.showTileViewInfoBox) {
            //Map View in Search Results page
            locData.isPersonalizedMatch = i < THREE && this.highlightPushPin;
            const pinIcon = i < THREE && this.highlightPushPin ? this.PM_ICON : this.INACTIVE_ICON;
            pushpin = new Microsoft.Maps.Pushpin(mapLocation, {
              icon: pinIcon,
              anchor: new Microsoft.Maps.Point(anchorX, anchorY)
            });
          }

          if (!areEqualAddrsId && !this.showTileViewInfoBox) {
            //Map View in Compare Providers page
            pushpin = new Microsoft.Maps.Pushpin(mapLocation, {
              title,
              text
            });
          }

          if (this.showTileViewInfoBox) {
            pushpin.metadata = {
              addressId: locData.addressId,
              providerIdentifier: locData.providerDetails.providerIdentifier,
              index: i
            };
          } else {
            pushpin.metadata = { provNm: locData.providerSearch ? locData.provNm : '' };
          }

          this.providersearchType = locData.providerSearch;
          layer.add(pushpin);

          if (locData.providerSearch) {
            this.tooltip = new Microsoft.Maps.Infobox(this.map.getCenter(), {
              visible: false,
              showPointer: false,
              showCloseButton: false
            });

            this.tooltip.setMap(this.map);

            Microsoft.Maps.Events.addHandler(this.tooltip, 'click', (event: any) => {
              this.infoBoxOnClick(event);
            });

            Microsoft.Maps.Events.addHandler(pushpin, 'mouseover', (event: any) => {
              this.onHover(event);
            });

            Microsoft.Maps.Events.addHandler(pushpin, 'click', (event: any) => {
              this.mapPinClickEvent.emit({ id: locData.addressId });
              if (this.isMobileView) {
                this.onMapPushpinClick(event);
              }
            });

            Microsoft.Maps.Events.addHandler(pushpin, 'mouseout', (event: any) => {
              this.onHoverOff(event);
            });
          }
          locData.mapPushpin = pushpin;
        })
      );
    });

    if (locationPromises.length) {
      Promise.all(locationPromises).then(() => {
        this.map.layers?.insert(layer);

        //Pick locations with latitude and longitude
        const validMapLocs = locs.filter((loc) => {
          return loc && loc.latitude && loc.longitude;
        });
        const bestview: Microsoft.Maps.LocationRect = Microsoft.Maps.LocationRect.fromLocations(validMapLocs);
        if (validMapLocs.length === 1) {
          this.map?.setView({
            center: bestview.center,
            zoom: 11
          });
        } else {
          if (typeof this.map?.setView === 'function') {
            this.map.setView({
              bounds: bestview,
              zoom: 8
            });
          }
        }
      });
    }

    //Get users current location
    this.getCurrentLoc().then((usersCurLoc) => {
      if (usersCurLoc) {
        locs.push(usersCurLoc);
        //create a pin for cur location
        const curLocPushpin = new Microsoft.Maps.Pushpin(usersCurLoc, {
          color: 'rgb(40, 108, 226)'
        });
        layer.add(curLocPushpin);
      }
    });
  }

  displayVirtualOptionsLayer() {
    if (!Array.isArray(this.virtualProviders) || this.virtualProviders.length === 0) {
      return;
    }
    if (!this.map?.layers) {
      return;
    }

    if (this._virtualOptionsClickSubs) {
      this._virtualOptionsClickSubs.unsubscribe();
    }
    let overlay = this._overlayHlpr.createOverlay();
    this.map.layers.insert(overlay);
    this._virtualOptionsClickSubs = this._overlayHlpr.virtualOptionsClick.subscribe((event) => {
      this.onVirtualOptionsClick(event);
    });
  }

  getCurrentLoc(): Promise<Microsoft.Maps.Location> {
    return new Promise((resolve, reject) => {
      const options: PositionOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      const onSuccess: PositionCallback = (position: GeolocationPosition) => {
        let loc = new Microsoft.Maps.Location(position.coords.latitude, position.coords.longitude);
        resolve(loc);
      };
      const onError: PositionErrorCallback = (error: GeolocationPositionError) => {
        resolve(null);
      };
      //Request the user's location
      navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    });
  }

  onVirtualOptionsClick(event: MouseEvent) {
    this.virtualProvidersModelRef = this._sidePanel.open(RIGHT, this.virtualProvidersPanel);
  }

  onClose() {
    this.virtualProvidersModelRef?.close();
  }

  isGreyedOut(professional: IFinalProvider, enableFutureProvider: boolean): boolean {
    // Feature flag to disable future effective provider navigation to details page.
    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }
    return !!(professional?.eycCostInfo?.isGreyedout || (!enableFutureProvider && professional?.isFutureEffective));
  }

  showProviderDetails(professional: IFinalProvider) {
    this.onClose();
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    this._dataService.setPageTitle(pageFadObj);
    this._providerDetailsNavigationService.navigateToProviderDetails(professional);
  }

  /**
   * Handle infobox click events
   */
  infoBoxOnClick(event) {
    this.resetEvent();

    if (event?.originalEvent?.target?.id || event?.originalEvent?.target?.parentNode?.id) {
      const eventTargetId = Boolean(event.originalEvent.target.id) ? event.originalEvent.target.id : event.originalEvent.target.parentNode.id;
      this.eventKey = this.getProviderEvent(eventTargetId);
      this.handleEvents(this.eventKey);
    }
  }

  focusMap() {
    this.mapView.nativeElement.focus();
  }

  createDirections(directionAttributes: IDirectionAttributes) {
    document.getElementById('get-direction-error').style.display = 'none';
    const regex = new RegExp(/\n/gm);
    this.startLocation = regex.test(directionAttributes.startLocation) ? directionAttributes.startLocation.replace(/\n/gm, '') : directionAttributes.startLocation;
    this.endLocation = regex.test(directionAttributes.endLocation) ? directionAttributes.endLocation.replace(/\n/gm, '') : directionAttributes.endLocation;
    this.isShortestDistanceSelected = directionAttributes.isShortestDistanceSelected;
    this.isShortestTimeSelected = directionAttributes.isShortestTimeSelected;
    this.showDirectionInput = directionAttributes.showDirectionInput;
    this.directionFlag = true;
    Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
      this.directionsManager = new Microsoft.Maps.Directions.DirectionsManager(this.map);
      // Set Route Mode to driving
      const startWaypoint = new Microsoft.Maps.Directions.Waypoint({ address: this.startLocation });
      this.directionsManager.addWaypoint(startWaypoint);
      this.locationText = this.startLocation + this.endLocation;
      this.mapDirections =
        this.content?.mapModal?.directionTextFrom?.replace(/{STARTLOCATION}/gi, this.startLocation) + this.content?.mapModal?.directionTextTo?.replace(/{ENDLOCATION}/gi, this.endLocation);
      const endWaypoint = new Microsoft.Maps.Directions.Waypoint({ address: this.endLocation });
      this.directionsManager.addWaypoint(endWaypoint);
      Microsoft.Maps.Events.addHandler(this.directionsManager, 'directionsError', this.getDirectionsError);
      this.directionsManager.setRequestOptions({
        routeMode: Microsoft.Maps.Directions.RouteMode.driving,
        routeDraggable: false,
        routeOptimization: this.isShortestTimeSelected ? Microsoft.Maps.Directions.RouteOptimization.shortestTime : Microsoft.Maps.Directions.RouteOptimization.shortestDistance,
        distanceUnit: Microsoft.Maps.Directions.DistanceUnit.miles,
        maxRoutes: 1
      });
      // Set the element in which the itinerary will be rendered
      this.directionsManager.setRenderOptions({
        itineraryContainer: this.directionsItinerary.nativeElement,
        displayDisclaimer: false
      });
      if (this.showDirectionInput) {
        this.directionsManager.showInputPanel(`directionsInputContainer${this.uniqueId}`);
      }
      this.directionsManager.calculateDirections();
    });
  }

  getDirectionsError(e) {
    document.getElementById('get-direction-error').style.display = 'block';
  }

  clearDirections() {
    document.getElementById('get-direction-error').style.display = 'none';
    if (this.directionsItinerary) {
      this.directionsItinerary.nativeElement.innerHTML = '';
    }
    this.directionFlag = false;
    if (this.directionsManager) {
      this.directionsManager.clearAll();
    }
  }

  onHover(event: IMapHoverEvent) {
    if (!event?.target?.metadata || !this.tooltip) {
      return;
    }
    const metadata = event.target.metadata;

    if (this.showTileViewInfoBox) {
      const selectedProvider = this.locationData?.find((loc) => {
        return metadata.addressId === loc.addressId && metadata.providerIdentifier === loc.providerDetails.providerIdentifier;
      });
      this.selectedLocation = selectedProvider;
      this.index = metadata.index;
      this.event = event;
      setTimeout(() => {
        this.tooltip.setOptions({
          location: event.location ? event.location : event.target.getLocation(),
          htmlContent: this.getHtmlTemplate(),
          visible: true,
          //@ts-ignore
          autoAlignment: true
        });
      }, 250);

      event.target.setOptions({
        icon: this.ACTIVE_ICON
      });
    } else {
      this.tooltip.setOptions({
        location: event.target.getLocation(),
        htmlContent: '<div style="background-color:white; padding: 5px;"><div>' + metadata.provNm + '</div><div>' + event.target.entity.title + '</div></div>',
        visible: true,
        //@ts-ignore
        autoAlignment: true
      });
    }
  }

  onHoverOff(event: IMapHoverOffEvent) {
    this.hideToolTip();
    if (!event?.target) {
      return;
    }
    if (this.showTileViewInfoBox) {
      const index = event.target.metadata?.index;
      event.target.setOptions({
        icon: index < 3 && this.highlightPushPin ? this.PM_ICON : this.INACTIVE_ICON
      });
    }
  }

  onAppointmentSchedule(data: any) {
    this.scheduleAppointment.emit(data);
  }

  onAssignCare(data: any) {
    this.assignCare.emit(data);
  }

  getFilteredLocationData(professional: any): IMapRequest[] {
    return this.locationData?.filter((provider) => {
      if (
        provider &&
        provider.addressId &&
        provider.providerDetails &&
        provider.providerDetails.providerIdentifier &&
        professional &&
        professional.providerIdentifier &&
        professional.location &&
        professional.location.address &&
        professional.location.address.addressId
      ) {
        return provider.providerDetails.providerIdentifier === professional.providerIdentifier && provider.addressId === professional.location.address.addressId;
      }
    });
  }

  onMapPushpinClick(event: any) {
    if (event && event.target && event.target.metadata && typeof event.target.metadata.index !== 'undefined' && event.target.metadata.index !== '' && this.locationData[event.target.metadata.index]) {
      this._providerDetailsNavigationService.mapPushpinClicked(event.target.metadata);
      const el = document.querySelector('.flex-tile-wrap-mobile');
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private hideToolTip() {
    this.tooltip.setOptions({
      visible: false
    });
  }

  /**
   * Reset event key and force to trigger onChanges
   */
  private resetEvent() {
    this.eventKey = '';
    this._cdf.detectChanges();
  }

  /**
   * Extract event key from element id.
   * @param eventId
   */
  private getProviderEvent(eventId: string) {
    let key: string = '';

    if (typeof eventId !== 'undefined' && eventId !== null && eventId !== '') {
      const n = eventId.lastIndexOf('-') || 0;
      key = eventId.substring(0, n + 1);
    }

    return key;
  }

  /**
   * Function to take actions for each infobox events
   * @param eventKey
   */
  private handleEvents(eventKey: string) {
    switch (eventKey) {
      case INFOBOX_EVENTS.MAP_ICON: {
        this.openMapModal.emit(this.selectedLocation.providerDetails);
        break;
      }
      case INFOBOX_EVENTS.ASSIGN_PCP: {
        this.openAssignPCP.emit(this.selectedLocation.providerDetails);
        break;
      }
      case INFOBOX_EVENTS.ASSIGN_CARE: {
        this.assignCare.emit(this.selectedLocation.providerDetails);
        break;
      }
      case INFOBOX_EVENTS.LOCATION_SLIDER: {
        this._sliderService.openLocationSlideOut(this.selectedLocation.providerDetails);
        break;
      }
      case INFOBOX_EVENTS.SPECIALTY_SLIDER:
      case INFOBOX_EVENTS.ALL_SPECIALTY_SLIDER: {
        this.openSpecialityModal.emit({ provider: this.selectedLocation.providerDetails });
        break;
      }
      case INFOBOX_EVENTS.RATING_SLIDER: {
        this._sliderService.openRatingSlideOut();
        break;
      }
      case INFOBOX_EVENTS.SHOW_MORE: {
        if (this.event) {
          this.hideToolTip();
          this.onHover(this.event);
        }
        break;
      }
      case INFOBOX_EVENTS.COST_DETAILS_SLIDER:
        this._sliderService.openCostSlideOut();
        break;
      default:
        break;
    }
  }

  private getLocation(locationData: IMapRequest): Promise<Microsoft.Maps.Location> {
    return new Promise<Microsoft.Maps.Location>((resolve) => {
      (async () => {
        if (!locationData) {
          resolve(null);
          return;
        }
        if (locationData.latitude && locationData.longitude) {
          resolve(new Microsoft.Maps.Location(locationData.latitude, locationData.longitude));
        } else if (locationData.address) {
          try {
            //Call PreCare API to get latitude & longitude
            const data = await this._providerUtilityService.getAddressByQuery(locationData.address);
            if (data.latitude.length > 0 && data.longitude.length > 0) {
              resolve(new Microsoft.Maps.Location(data.latitude, data.longitude));
            } else {
              resolve(null);
            }
          } catch (err) {
            //TODO: handle error
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })();
    });
  }

  private getHtmlTemplate(): string {
    if (this.infoBoxV2) {
      return this.infoBoxV2.nativeElement.innerHTML;
    } else if (this.infoBoxV1) {
      return this.infoBoxV1.nativeElement.innerHTML;
    }
    return '';
  }

  private getFixedDecimal(value: number) {
    if (typeof value === 'undefined' || value === null) {
      throw new Error('Invalid input');
    }

    return Math.round(value * 1000) / 1000;
  }

  private onRightTileMouseOver(professional: any) {
    const filteredLocationData = this.getFilteredLocationData(professional);
    if (filteredLocationData.length > 0) {
      const locationData = filteredLocationData[0];
      const hoverEvent = {
        target: locationData.mapPushpin
      };
      Microsoft.Maps.Events.invoke(locationData.mapPushpin, 'mouseover', hoverEvent);
    }
  }

  private onRightTileMouseOut(professional: any) {
    const filteredLocationData = this.getFilteredLocationData(professional);
    if (filteredLocationData.length > 0) {
      const locationData = filteredLocationData[0];
      const hoverEvent = {
        target: locationData.mapPushpin
      };

      Microsoft.Maps.Events.invoke(locationData.mapPushpin, 'mouseout', hoverEvent);
    }
  }
}
