import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-list-cmp',
  templateUrl: './providerListCmp.html'
})
export class ProviderListComponent implements AfterViewInit, OnChanges {
  @Input() providerRemoved: IProvider = null;
  @Input() clearAll: boolean = false;
  @Input() providers: IProvider[] = [];
  @Input() mapVisible = true;
  @Output() providerHover = new EventEmitter<IProvider>();
  @Output() providerLeave = new EventEmitter<IProvider>();
  @Output() selectedProvider = new EventEmitter<IProvider>();
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  activeIndex: number | null;
  private _selectedPushpinProvider: IProvider;
  @ViewChildren('providerCard') providerCards: QueryList<ElementRef>;
  @Input() showProviderCard: boolean;

  @Input()
  set selectedPushpinProvider(provider: IProvider) {
    this._selectedPushpinProvider = provider;
    this.scrollToActiveProvider();
  }

  constructor(private _renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showProviderCard'] && !this.showProviderCard) {
      this.activeIndex = null;
    }
  }

  ngAfterViewInit() {
    this.scrollToActiveProvider();
  }

  get selectedPushpinProvider(): IProvider {
    return this._selectedPushpinProvider;
  }

  onProviderHover(provider: IProvider) {
    this.providerHover.emit(provider);
  }

  onProviderLeave(provider: IProvider) {
    this.providerLeave.emit(provider);
  }

  /**
   * Method to share provider card data.
   * Getting the selected provider details that needs to be shown in provider details card.
   * @param provider Provider data.
   */
  onShowProviderCard(provider: IProvider) {
    this.selectedProvider.emit(provider);
  }

  /**
   * Method to set selected Provider.
   * Method to highlight currently selected provider card from the scrollable cards list on left side.
   * @param index selected card index.
   */
  setProviderSelected(index: number) {
    this.activeIndex = null;
    this.activeIndex = index;
    if (this.activeIndex === index) {
      this.selectedPushpinProvider = null;
    }
  }

  /**
   * Method to select a Provider.
   * Method to emit status which is for selected provider card to be shown or closed.
   * @param status boolean value to show or close.
   */
  onSendCardStatus(status: boolean) {
    this.cardSelected.emit(status);
  }

  /**
   * Method to Set selected Provider Card Active.
   * Method to highlight currently selected provider card from the scrollable cards list on left side
   * when user clicks through map marker pin
   */
  scrollToActiveProvider() {
    if (this._selectedPushpinProvider) {
      const element = this._renderer.selectRootElement('#provider-' + this._selectedPushpinProvider?.providerIdentifier, true);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.onSendCardStatus(true);
        this.onShowProviderCard(this._selectedPushpinProvider);
        const index = this.findProviderIndexById(this._selectedPushpinProvider?.providerIdentifier);
        if (index !== -1) {
          this.setProviderSelected(index);
        }
      }
    }
  }

  /**
   * Method to Find Provider Card.
   * Getting index of selected provider from list of providers based on unique Provider Identifier.
   * @param providerID Unique Provider Identifer.
   */
  findProviderIndexById(providerID: string): number {
    return this.providers.findIndex((provider) => provider.providerIdentifier === providerID);
  }
}
