import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { SearchExecutionMode } from '../../findcare/common/constants/common';
import { IHttpHeader } from './../interfaces/iHttpHeader';
import { AppUtility } from './../utilities/appUtil';
import { AppSession } from './../values/appSession';
import { HttpClientService } from './httpClientService';

@Injectable()
export class BaseService {
  constructor(
    @Inject(AppSession) public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {}

  get baseURL(): string {
    let baseUrl = this.appSession.appConfig?.providerFinder.baseUrl[this.appSession.state][this.appUtility.getBrandFromUrl().toLocaleLowerCase()];

    if (!baseUrl && typeof baseUrl === 'undefined') {
      baseUrl = this.appSession.appConfig?.providerFinder.baseUrl[this.appSession.state]['abcbs'];
    }

    return baseUrl;
  }

  /**
   * Checks if the current execution mode is secure.
   * @returns {boolean} True if the execution mode is secure, otherwise false.
   */
  get isSecureExecution(): boolean {
    return this.appSession.appState.executionMode === SearchExecutionMode.SECURE;
  }

  protected get timeZone() {
    return moment.tz.guess();
  }

  /**
   *
   * @returns default headers Array for an API call
   */
  getHeaders(): IHttpHeader[] {
    return [
      {
        name: 'Content-Type',
        value: 'application/json'
      }
    ];
  }
}
