import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'officeHours'
})
export class OfficeHoursPipe implements PipeTransform {
  transform(hours: string[], compareProvidersContent: any): string[] {
    const daysPattern = /^(M|Tu|W|Th|F|Sa|Su)\s+/;
    const allDays = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];
    const officeHoursMap = new Map<string, string>();

    hours.forEach((hour) => {
      const day = hour.match(daysPattern)?.[1];
      if (day) {
        const hoursData = hour.replace(daysPattern, '').replace(' TO ', ' - ');
        if (hoursData === '00:00 AM - 00:00 AM') {
          officeHoursMap.set(day, compareProvidersContent?.labels?.notOpen);
        } else {
          officeHoursMap.set(day, hoursData);
        }
      }
    });

    return allDays.map((day) => officeHoursMap.get(day) || compareProvidersContent?.labels?.notOpen);
  }
}
