import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { TabbedViewComponent } from '@anthem/uxd/tabs';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../../../app/common/values/appConfig';
import { IDeeplink } from '../../../common/components/app-secure/models/iDeeplink';
import { IMetadata } from '../../../common/components/app-secure/models/iMetadata';
import { SecureStateHandler } from '../../../common/components/app-secure/services/secureStateHandler';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ScenarioAlertSvc } from '../../../common/components/scenarioAlert/services/pfScenarioAlertSvc';
import { AppConstants, AppExternalCommands, BRAND_CODE, Locale } from '../../../common/constants/app-constants';
import { AppEvents } from '../../../common/enums/appEvents';
import { IAdobe, IAdobePlanDetail } from '../../../common/interfaces/iAdobe';
import { IBaseCode } from '../../../common/interfaces/iBaseCode';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { IWindow } from '../../../common/interfaces/iWindow';
import { DataHelper } from '../../../common/services/dataHelper';
import { EventHandler } from '../../../common/services/eventHandler';
import { AbstractSearchStrategy } from '../../../common/services/strategySvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { FindCareFeature } from '../../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../findcare/common/utilities/featureManagerUtil';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { CommonUtil } from '../../utilities/commonUtil';
import { IAddressDetails } from '../interfaces/iAddressForm';
import { IGenericDataResponse } from '../interfaces/iGenericDataResponse';
import { IPlan, IPlanCategory } from '../interfaces/iPlan';
import { ICobrandRule } from '../interfaces/iPlanRule';
import { IPublicPlan } from '../interfaces/iPublicPlan';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { ITranslation } from '../interfaces/iTranslation';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE_CARE, PROVIDER_TYPE_OF_CARE, SPANISH_PROVIDER_TYPE_CARE, UNIMASS_BRAND } from '../values/providerSearchConstants';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-plan-selection-cmp',
  templateUrl: '../views/pfPlanSelectionCmp.html',
  providers: []
})
export class PFPlanSelectionComponent extends BaseComponent implements OnInit {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  gotoSearchCriteria: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  showSearchLoader: boolean = true;
  typeCareDropDown = PROVIDER_TYPE_CARE;
  brand: string = '';
  domain: string = '';
  href: string = '';
  errorText: string;
  planChanged: boolean = false;
  alphaPrefix: string = '';
  searchStrategy: AbstractSearchStrategy | undefined;
  alphaPrefixError: boolean = false;
  metaData = {} as IMetadata;
  metadataError: boolean = false;
  careDisplay: string = '';
  selectedCareError: boolean = false;
  stateDisplay: string = '';
  selectedStateError: boolean = false;
  categoryDisplay: string = '';
  selectedCategoryError: boolean = false;
  planDisplay: string = '';
  selectedPlanError: boolean = false;
  locale: Locale;
  searchType: string = 'plan';
  showEBCBlock: boolean = false;
  @ViewChild('landingTabCtrl')
  landingTabCtrl: TabbedViewComponent;
  @ViewChild('planSelectionSidePanel')
  planSelectionSidePanel: TemplateRef<HTMLElement>;
  stateDisabled: boolean = true;
  planDisabled: boolean = true;
  networkDisabled: boolean = true;
  continueDisabled: boolean = true;
  isSecureState: boolean = false;
  showCaptcha = false;
  googleCaptchaFeature = false;
  appConfig: AppConfig;

  radioObjectPlan = {
    label: 'Search by Plan',
    id: 'searchByPlan',
    name: 'searchOption',
    value: 'plan'
  };
  radioObjectID = {
    label: 'Search by ID Number',
    id: 'searchIDNumber',
    name: 'searchOption',
    value: 'idNumber',
    analyticsText: ''
  };

  /* FTB-276: cleaned up radioMedicaid and labels constants declarations */

  locateRCPLoginUrl: string = '';
  locateRCPRegisterUrl: string = '';
  landingPage: string;
  unimassBrand: boolean;
  sidePanelRef: ModalRef<any>;
  readonly BRANDCODE = BRAND_CODE;

  private _onPfMemberSummaryErrorSub: Subscription;
  private _pfMemberSummaryErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.MEMBER_SUMMARY_ERR]);
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    private _providerUtilityService: ProviderUtilityService,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _secureStateHandler: SecureStateHandler,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _ruleService: RulesService,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _sidePanel: SidePanel,
    private _scenarioAlertSvc: ScenarioAlertSvc
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent', 'planSelectionComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.appConfig = this._appSession.appConfig;
    this._appSession.isHcidSearch = false;
    this._appSession.mbu = '';
    this._appSession.underwritingStateCode = '';
    this.googleCaptchaFeature = FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_GOOGLE_RECAPTCHA, this._appSession);
    if (this._appSession.changePlanMetaData && this._appSession.changePlanMetaData?.identificationNumber) {
      this._appSession.changePlanMetaData.identificationNumber = '';
    }
    if (this._appSession.searchParams && this._appSession.searchParams?.identificationNumber) {
      this._appSession.searchParams.identificationNumber = '';
    }
    this._appUtility.scrollToTop();
    this.radioObjectPlan.label = this.content?.labels?.searchTogglePlan || 'Search by Plan';
    this.radioObjectID.label = this.content?.labels?.searchToggleId || '';
    this.brand = this._appSession.metaData.integratedBrandCode || this._appSession.metaData.brandCd;
    this.unimassBrand = this.brand.toLowerCase() === UNIMASS_BRAND;
    this.isSecureState = CommonUtil.isMemberSecure(this._appSession);
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale === Locale.SPANISH ? Locale.SPANISH : Locale.ENGLISH;
    if (this._appSession.isDeepLink) {
      this.validateDeeplink();
    } else if (this.googleCaptchaFeature && this._appSession?.deeplinkParams?.hcid) {
      this.alphaPrefix = this._appSession.deeplinkParams.hcid;
      this.showCaptcha = true;
    } else if (!this.googleCaptchaFeature && this._appSession?.deeplinkParams?.hcid) {
      this.validateHcid(this._appSession.deeplinkParams.hcid);
    } else {
      this.showSearchLoader = false;
      this.initializeLabels();
      this.setDefault();
      if (CommonUtil.isMemberSecure(this._appSession) && this._appSession.isPlanUnknown === true) {
        if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.typeOfCareNm) {
          this.getStateDropdown(this._appSession.searchParams.typeOfCareNm);
        }
      }
    }
    this.handleHcidError();
    this.getRCPLoginAndRegisterUrl();
    this.getBrandEBCBlock();
    //When cobrandRule exists, remove it.
    if (this._appSession.planRule?.cobrandRule) {
      const allBrands = AppConstants.BRAND;
      const brandConfig = allBrands.find((b) => {
        return this._dataHelper.areEqualStrings(b.brand, this._appSession.metaData.brandCd);
      });
      this._appSession.planRule.cobrandRule.isCobrand = false;
      this._appSession.planRule.cobrandRule.object.cobrand = '';
      this._appSession.planRule.cobrandRule.object.imageName = brandConfig.logo;
      this.brand = brandConfig.brand;
      this.emitCobrandEvent();
    }
    this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]).emit(this.brand);
    let supportedBrandLabel = this.WcsUtility.getSupportedBrandLabel(this.brand, this.content);
    if (!this._dataHelper.isEmptyString(supportedBrandLabel)) {
      this.content.labels = this.WcsUtility.overrideTheContent(this.content.labels, this.content[supportedBrandLabel]);
    }
  }

  initializeLabels() {
    if (this._appSession.metaData.locale === Locale.SPANISH) {
      this.typeCareDropDown = SPANISH_PROVIDER_TYPE_CARE;
    }
    this.careDisplay = this.content.labels.defaultCareText;
    this.stateDisplay = this.content.labels.defaultStateText;
    this.categoryDisplay = this.content.labels.defaultPlanCategoryText;
    this.planDisplay = this.content.labels.defaultPlanOrText;
  }

  onSearchAsGuestClick() {
    this.alphaPrefixError = false;
    this.errorText = '';
    this.showCaptcha = false;
    this.openPlanSearchPanel();
  }

  isWLPBrand(): boolean {
    return this.brand === BRAND_CODE.WLP ? true : false;
  }

  ngAfterViewInit() {
    if (this.searchParams?.stateDdlValues?.length) {
      this.stateDisabled = false;
      this.planDisabled = false;
      this.networkDisabled = false;
      this.continueDisabled = false;
    }
    setTimeout(() => {
      if (this.showMemberTab && this.landingTabCtrl) {
        this.landingTabCtrl.activeTab = this._appSession.landingTab;
      }
    });
  }

  setDefault() {
    if (this.searchParams.typeOfCareNm && this.searchParams.typeOfCareNm.length > 0) {
      this.typeCareDropDown.options.forEach((data) => {
        if (this.searchParams.typeOfCareNm.toLowerCase() === data.value) {
          this.careDisplay = data.label;
        }
      });
    }

    if (this.searchParams.stateDdlValues && this.searchParams.stateDdlValues.length > 0) {
      this.searchParams.stateDdlValues.forEach((data: any) => {
        if (this.searchParams.plan && this.searchParams.plan.stateCd === data.value.trim()) {
          this.stateDisplay = data.label.trim();
        }
      });
    }

    if (this.searchParams.planCategoryDdlValues && this.searchParams.planCategoryDdlValues.length > 0) {
      this.searchParams.planCategoryDdlValues.forEach((data: any) => {
        if (!this._dataHelper.isEmptyString(this.searchParams.plan.category.catCode) && this.searchParams.plan.category.catCode === data.value.trim()) {
          this.categoryDisplay = data.label.trim();
        }
      });
    }

    if (this.searchParams.planDdlValues && this.searchParams.planDdlValues.length > 0) {
      this.searchParams.planDdlValues.forEach((data: any) => {
        if (this.searchParams.plan && this.searchParams.plan.name + '__' + this.searchParams.plan.identifier === data.value) {
          this.planDisplay = data.label;
        }
      });
    }
  }

  goToSearchCriteria(evt: string = '') {
    this._appSession.backToFindCareClicked = false;
    if (!this._appSession?.metaData?.addresses?.length) {
      this._appSession.addressObj = {} as IAddressDetails;
    }
    if (evt === 'prefix') {
      if (this.alphaPrefix) {
        this.showSearchLoader = true;
        if (this.alphaPrefix.length <= 4) {
          this.validateAlphaPrefix(this.alphaPrefix);
        } else {
          if (!(this.googleCaptchaFeature && !this._appSession.captchaToken)) {
            this.validateHcid(this.alphaPrefix);
          } else {
            this.alphaPrefixError = true;
            this.showCaptcha = false;
            this.errorText = this.content.alerts.captchaRequiredError;
          }
        }
        this.formReset();
      } else {
        // Show an error msg when a member in public find care landing page hits search btn with empty value.
        this.alphaPrefixError = true;
        this.errorText = this.content.alerts.idOrPrefix;
      }
    } else {
      if (this.searchParams.typeOfCareNm === '') {
        this.selectedCareError = true;
        this.errorText = this.content.alerts.typeCare;
      } else if (this.searchParams.plan.stateCd === undefined || this.searchParams.plan.stateCd === '') {
        this.selectedStateError = true;
        this.errorText = this.content.alerts.searchState;
      } else if (this.searchParams.plan.category === undefined || (this.searchParams.plan.category && this.searchParams.plan.category.catCode === '')) {
        this.selectedCategoryError = true;
        this.errorText = this.content.alerts.category;
      } else if (this.searchParams.plan.name === undefined || this.searchParams.plan.name === '') {
        this.selectedPlanError = true;
        this.errorText = this.content.alerts.plan;
      } else {
        this.selectedCareError = false;
        this.selectedStateError = false;
        this.selectedPlanError = false;
        this.selectedCategoryError = false;
        this.errorText = '';
        this.showSearchLoader = true;
        this.setPlanDetailsAnalyticsQuery();
        this.closePlanSearchPanel();
        if (this.searchParams.plan.urlRedirect && this.searchParams.plan.urlRedirect !== '') {
          this.showSearchLoader = false;
          this.leavingDisclaimer.emit(this.searchParams.plan.urlRedirect);
        } else {
          if (this.planChanged) {
            this.resetSearchParams();
          }
          this.setStandAloneFlag(this.searchParams.plan.careType);
          this._appSession.searchParams = this.searchParams;
          this.getRules();
          this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
          this.gotoSearchCriteria.emit();
        }
      }
    }
  }
  getStateDropdown(careType: string) {
    if (careType !== '') {
      this.formReset();
      this.searchParams.typeOfCareNm = careType;
      this.getStateList();
      this.setDefault();
    }
  }

  getStateList() {
    this.showSearchLoader = true;
    this._providerUtilityService.getStateList(this.getBrand(), this.searchParams.typeOfCareNm).then(
      (result) => {
        if (result?.data?.length) {
          this.buildStateDropDown(result.data);
          this.stateDisabled = false;
        } else {
          this.stateDisabled = true;
        }
        this.planDisabled = true;
        this.networkDisabled = true;
        this.continueDisabled = true;
        this.showSearchLoader = false;
      },
      (error: unknown) => {
        this.showSearchLoader = false;
        this.showApiError(error);
      }
    );
  }

  getPlanCategoryDropDown(state: string) {
    if (state !== '') {
      this.selectedStateError = false;
      this.resetCategoryDD();
      this.resetPlanDD();
      const _state = state.trim();
      const _care = this.searchParams.typeOfCareNm;
      this.showSearchLoader = true;
      this.searchParams.plan.stateCd = _state;
      this.setDefault();
      this._providerUtilityService.getPlanCategoryList(_state, this.getBrand(), _care).then(
        (result: IGenericDataResponse<Array<ITranslation>>) => {
          if (result && result.data) {
            this.buildPlanCategoryDropDown(result.data);
            this.planDisabled = false;
            this.networkDisabled = true;
            this.continueDisabled = true;
          }
          this.showSearchLoader = false;
        },
        (error: unknown) => {
          this.showSearchLoader = false;
          this.showApiError(error);
        }
      );
    }
  }

  getPlanDropDown(category: string) {
    if (category !== '') {
      this.selectedCategoryError = false;
      let _categoryList: any[];
      let _planCategory: any;
      const _care = this.searchParams.typeOfCareNm;
      const _state = this.searchParams.plan && this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '';
      const _category = category.trim();
      this.showSearchLoader = true;
      this.resetPlanDD();
      _categoryList = this.searchParams.planCategoryDdlValues;
      _planCategory = _categoryList.filter((c) => {
        return c.value === _category;
      })[0];
      if (_planCategory) {
        this.searchParams.plan.category = {
          catCode: _planCategory.value,
          catName: _planCategory.label
        };
      }
      this.setDefault();
      this._providerUtilityService.getPlanList(_state, this.getBrand(), _care, category).then(
        (result: IGenericDataResponse<Array<IPublicPlan>>) => {
          this.buildPlanDropDown(result.data);
          this.networkDisabled = false;
          this.continueDisabled = this.planDisplay === this.content.labels.defaultPlanOrText;
          this.showSearchLoader = false;
        },
        (error: unknown) => {
          this.showSearchLoader = false;
          this.showApiError(error);
        }
      );
    }
  }

  buildPlanCategoryDropDown(planCategoryList: Array<ITranslation>) {
    planCategoryList.forEach((planCategory: ITranslation) => {
      this.searchParams.planCategoryDdlValues.push({
        label: this.locale === Locale.SPANISH ? this.WcsUtility.getSpanishPlanCategoryName(planCategory.code, planCategory.name) : planCategory.name,
        value: planCategory.code
      });
    });

    // Pre-select the plan category if the plan category dropdown having only single item.
    if (CommonUtil.isEmpty(this.searchParams.plan?.category?.catCode) && this.searchParams.planCategoryDdlValues.length === 1) {
      this.categoryDisplay = this.searchParams.planCategoryDdlValues[0].label.trim();
      // Bind Plan options.
      this.getPlanDropDown(this.searchParams.planCategoryDdlValues[0].value.trim());
    }
  }

  buildPlanDropDown(planList: Array<IPublicPlan>) {
    planList.forEach((plan: IPublicPlan) => {
      this.searchParams.planDdlValues.push({
        label: plan.name,
        value: plan.name + '__' + plan.id,
        networklist: plan.networkList,
        nationalPlanIndicator: plan.isNationalPlan,
        urlRedirect: plan.urlRedirect,
        alphaPrefix: plan.prefix,
        planCategoryList: plan.planCategoryList && plan.planCategoryList.length > 0 ? plan.planCategoryList : []
      });
    });

    // Pre-select the plan if the plan dropdown having only single item.
    if (CommonUtil.isEmpty(this.searchParams.plan?.identifier) && this.searchParams.planDdlValues.length === 1) {
      this.planDisplay = this.searchParams.planDdlValues[0].label.trim();
      // Bind selected Plan.
      this.setPlanDetails(this.searchParams.planDdlValues[0].value.trim());
    }
  }

  buildStateDropDown(result: Array<IBaseCode>) {
    result.forEach((state: IBaseCode) => {
      this.searchParams.stateDdlValues.push({ label: state.name, value: state.code });
    });

    // Pre-select the state if the state dropdown having only single item.
    if (CommonUtil.isEmpty(this.searchParams.plan?.stateCd) && this.searchParams.stateDdlValues.length === 1) {
      this.stateDisplay = this.searchParams.stateDdlValues[0].label.trim();
      // Bind Plan category options.
      this.getPlanCategoryDropDown(this.searchParams.stateDdlValues[0].value.trim());
    }
  }

  formReset() {
    this.alphaPrefix = '';
    this.resetSearchParams();
    this.resetCareTypeDD();
    this.resetStateDD();
    this.resetCategoryDD();
    this.resetPlanDD();
    this._appSession.isDentalStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;
  }

  resetCareTypeDD() {
    this.selectedCareError = false;
    this.careDisplay = this.content.labels.defaultCareText;
    this.searchParams.typeOfCareNm = '';
    this.searchParams.plan = {};
  }

  resetStateDD() {
    this.selectedStateError = false;
    this.stateDisplay = this.content.labels.defaultStateText;
    this.searchParams.stateDdlValues = [];
    this.searchParams.plan = {};
  }

  resetCategoryDD() {
    this.selectedCategoryError = false;
    this.categoryDisplay = this.content.labels.defaultPlanCategoryText;
    this.searchParams.planCategoryDdlValues = [];

    const _state = this.searchParams.plan && this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '';
    this.searchParams.plan = {};
    if (_state !== '') {
      this.searchParams.plan.stateCd = _state;
    }
  }

  getUnimassContent() {
    const unicareMassHelpText = this.content.labels.unicareMassHelpText;
    return `${unicareMassHelpText.msgText}<a target='_blank' class='landing-link no-underline' href='{0}'>{1}</a>{2}`
      .replace('{0}', unicareMassHelpText.brandUrls[(this.brand || '').toLowerCase()])
      .replace('{1}', unicareMassHelpText.linkText)
      .replace('{2}', unicareMassHelpText.msgText2);
  }

  resetPlanDD() {
    this.selectedPlanError = false;
    this.planDisplay = this.content.labels.defaultPlanOrText;
    this.searchParams.planDdlValues = [];

    const _state = this.searchParams.plan && this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '';
    const _category = this.searchParams.plan && this.searchParams.plan.category ? this.searchParams.plan.category : {};
    this.searchParams.plan = {};
    if (_state !== '') {
      this.searchParams.plan.stateCd = _state;
    }
    if (_category && _category.catCode && _category.catCode !== '') {
      this.searchParams.plan.category = _category;
    }
  }

  resetSearchParams() {
    this.searchParams.stateCd = '';
    this.searchParams.provNm = '';
    this.searchParams.zip = '';
    this.searchParams.typeSelectNm = '';
    this.searchParams.providerTypeCodeList = [];
    this.searchParams.specialtySelectNm = [];
    this.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this.searchParams.city = '';
  }

  showApiError(error: unknown) {
    //to do
    throw error;
  }

  setPlanDetails(planDetails: any) {
    let planNetworkList = [];
    let nationalPlanIndicator = false;
    let url = '';
    let prefix = '';
    let planCategory: IPlanCategory;
    this.planChanged = true;
    const _planCategory = this.searchParams.plan && this.searchParams.plan.category ? this.searchParams.plan.category.catCode : '';
    this.selectedPlanError = false;
    this.selectedPlanError = false;

    if (planDetails) {
      if (this.searchParams.planDdlValues && this.searchParams.planDdlValues.length > 0) {
        let networkArry: any[];
        networkArry = this.searchParams.planDdlValues;
        const plan = networkArry.filter((c) => {
          return c.value && c.value === planDetails;
        })[0];
        if (plan) {
          url = plan.urlRedirect && plan.urlRedirect.trim() !== '' ? plan.urlRedirect.trim() : '';
          prefix = plan.alphaPrefix && plan.alphaPrefix.trim() !== '' ? plan.alphaPrefix.trim() : '';
          if (plan.planCategoryList && plan.planCategoryList.length > 0) {
            planCategory = {
              catCode: this.searchParams.plan?.category?.catCode,
              catName: this.searchParams.plan?.category?.catName,
              productPlanTypeCode: plan.planCategoryList[0].productPlanTypeCode,
              productTypeList: plan.planCategoryList
            };
          }
          nationalPlanIndicator = plan.nationalPlanIndicator;
          if (plan.networklist && plan.networklist.length > 0) {
            planNetworkList = plan.networklist;
          }
        }
      }
      this.selectedPlanError = false;
      const planInformation = planDetails.split('__');
      const selectedPlan: IPlan = {
        identifier: planInformation[1],
        name: planInformation[0],
        isNationalPlan: nationalPlanIndicator,
        stateCd: this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '',
        networkList: planNetworkList,
        urlRedirect: url,
        careType: this.searchParams.typeOfCareNm,
        alphaPrefix: prefix,
        category: planCategory
      };
      this.searchParams.plan = selectedPlan;
      this._appSession.networkList = planNetworkList;
      if (this._appSession.deeplinkParams && !this._appSession.deeplinkParams.client) {
        this._appSession.deeplinkParams = {} as IDeeplink;
      }
      this.continueDisabled = false;
    }
    this.setDefault();
  }

  validateDeeplink() {
    if (this._appSession.deeplinkParams.planstate) {
      this._providerUtilityService.getAllPlanList(this._appSession.deeplinkParams.planstate, this.getBrand()).then(
        (result: IGenericDataResponse<Array<IPublicPlan>>) => {
          const planResult: number = (result?.data || []).findIndex((item) => {
            return item.name.replace(/[<>;,()]/g, '').toLocaleLowerCase() === this._appSession.deeplinkParams.planname.replace(/[<>;,()]/g, '').toLocaleLowerCase();
          });
          if (planResult !== -1) {
            this.validatePlanResult(result, planResult);
          } else {
            // Reset to plan selection page since plan name is not matching.
            this.showSearchLoader = false;
            this.resetDeeplink();
            this.initializeLabels();
            this.setDefault();
          }
        },
        (error: unknown) => {
          this.showSearchLoader = false;
          this.showApiError(error);
        }
      );
    } else if (this._appSession.deeplinkParams.alphaprefix) {
      this.validateAlphaPrefix(this._appSession.deeplinkParams.alphaprefix);
    }
  }

  setSearchParamsfromDeeplink() {
    if (this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      this.searchParams.ableToServePcp = true;
    }
    if (this._appSession.deeplinkParams.provtype) {
      //this.searchParams.typeSelectNm = this._appSession.deeplinkParams.provtype.toLocaleUpperCase();
      this._appSession.searchParams.providerTypeCodeList = this._appSession.deeplinkParams.provtype.split('|').map((x) => x.toLocaleUpperCase());
    }
    if (this._appSession.deeplinkParams.zipcode) {
      this.searchParams.zip = this._appSession.deeplinkParams.zipcode;
    }
  }

  resetDeeplink() {
    this._appSession.isDeepLink = false;
    this._appSession.deeplinkParams = {} as IDeeplink;
  }

  validateAlphaPrefix(alphaprefix: string) {
    this._appSession.deeplinkParams.alphaprefix = alphaprefix; //temp fix need to change
    this._providerUtilityService.getAlphaPrefix(alphaprefix, Locale.ENGLISH).then(
      (result: any) => {
        if (result && result.codeTypeList && result.codeTypeList.length > 0) {
          const _networkCode = result.codeTypeList[0].code;
          const _networkName = result.codeTypeList[0].name;
          const selectedPlan: IPlan = {
            identifier: '',
            name: _networkName ? _networkName : _networkCode,
            isNationalPlan: null,
            stateCd: '',
            networkList: [],
            alphaPrefix: alphaprefix
          };
          this._appSession.networkList = [];
          this.searchParams.plan = selectedPlan;
          if (this._appSession.isDeepLink) {
            this.setSearchParamsfromDeeplink();
          }
          this.alphaPrefixError = false;
          this.errorText = '';
          this._appSession.showPrefixPopup = true;
          this.getRules();
          this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
          this.gotoSearchCriteria.emit();
        } else {
          this.searchType = 'idNumber';
          // updated error idOrPrefix text with hcidError
          this.errorText = this.content.alerts.hcidError;
          this.alphaPrefixError = true;
          this.showSearchLoader = false;
        }
      },
      (error: unknown) => {
        this.searchType = 'idNumber';
        this.errorText = this.content.alerts.idOrPrefix;
        this.alphaPrefixError = true;
        this.showSearchLoader = false;
        this.showApiError(error);
      }
    );
  }
  validatePlanResult(result: IGenericDataResponse<Array<IPublicPlan>>, planResult: number) {
    let url = '';
    let prefix = '';
    let planCategory: IPlanCategory;
    if (
      result.data &&
      result.data[planResult] &&
      result.data[planResult].planType &&
      result.data[planResult].planType.toLocaleLowerCase() === this._appSession.deeplinkParams.plantype.toLocaleLowerCase()
    ) {
      this.searchParams.typeOfCareNm = result.data[planResult].careType.trim().toUpperCase();
      let planNetworkList = [];
      const plan = result.data[planResult];
      let nationalPlanIndicator = false;
      if (plan) {
        url = plan.urlRedirect && plan.urlRedirect.trim() !== '' ? plan.urlRedirect.trim() : '';
        prefix = plan.prefix && plan.prefix.trim() !== '' ? plan.prefix.trim() : '';
        if (plan.planCategoryList && plan.planCategoryList.length > 0) {
          planCategory = {
            catCode: plan.planCategoryList[0],
            catName: plan.planCategoryList[0],
            productPlanTypeCode: plan.planType
          };
        }
        nationalPlanIndicator = plan.isNationalPlan;
        if (plan.networkList && plan.networkList.length) {
          planNetworkList = plan.networkList;
        }
      }
      const selectedPlan: IPlan = {
        identifier: result.data[planResult].id,
        name: result.data[planResult].name,
        isNationalPlan: nationalPlanIndicator,
        stateCd: this._appSession.deeplinkParams.planstate.trim(),
        networkList: planNetworkList,
        urlRedirect: url,
        careType: this.searchParams.typeOfCareNm,
        alphaPrefix: prefix,
        category: planCategory
      };
      this.searchParams.plan = selectedPlan;
      if (this._appSession.isDeepLink) {
        this.setSearchParamsfromDeeplink();
      }
      this.setStandAloneFlag(this.searchParams.plan.careType);
      this.gotoSearchCriteria.emit();
    } else {
      this.showSearchLoader = false;
    }
  }

  validateHcid(identificationNumber: string) {
    // DSEPRECARE-19914 Insurance tab information is not same in HCID search because of hardcoded value for brand code
    const brandCd = this._appSession && this._appSession.metaData && this._appSession.metaData.brandCd ? this._appSession.metaData.brandCd : 'ABCBS';
    this.errorText = '';
    this.alphaPrefixError = false;
    this.metaData.dplUrl = this._appSession.deeplinkParams.deeplinkUrl;
    this.metaData.hcid = identificationNumber;
    this.metaData.brand = brandCd;
    this.metaData.isCastlightTransparency = false;
    this.metaData.isEmulateFlag = false;
    this.metaData.isSecureState = false;
    this._appSession.isSecureState = false;
    this._secureStateHandler.pfEmMode = false;
    this._appSession.isIdentifactionSearch = true;
    this._appSession.isFirstTimeLoad = false;
    this._appSession.providerTypes = [];
    this._appSession.networkList = [];
    if (this.googleCaptchaFeature) {
      //TODO: Model needs cleaning from ground up to remove any.
      (this.metaData as any).siteKey = this._appSession.captchaToken;
    }
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  handleHcidError() {
    if (CommonUtil.isMemberSecure(this._appSession) === false) {
      this._onPfMemberSummaryErrorSub = this._pfMemberSummaryErrorEvent.subscribe((detail: IEventDetail) => {
        this.errorText = detail.message;
        this.alphaPrefixError = true;
        this.showSearchLoader = false;
      });
    }
  }

  getIDCardImagePath() {
    let imageName: string;

    if (AppConstants.BRAND && AppConstants.BRAND.length > 0 && this._appSession.metaData && this._appSession.metaData.brandCd) {
      const index = AppConstants.BRAND.findIndex((x) => x.brand === this._appSession.metaData.brandCd.toLocaleUpperCase());

      if (index >= 0) {
        imageName = AppConstants.BRAND[index].idCardImg;
      }
    }
    return this.getCommonImageURL(imageName);
  }

  onCancelButtonClick() {
    this.formReset();

    if (this._appSession.isSecureState === false) {
      const eventDetail = {} as IEventDetail;

      eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
      eventDetail.message = AppExternalCommands.RCP_PF_LANDING.MSG;
      eventDetail.target = AppExternalCommands.RCP_PF_LANDING.CMD;

      this._pfAppCommandEvent.emit(eventDetail);
    }
  }

  //Alpha prefix search on press enterkey events
  onAlphaPrefixKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.goToSearchCriteria('prefix');
    }
    this.showCaptcha = this.alphaPrefix?.length > 4;
  }
  //Sets the Analytics query for plan by search - Only on RCP integrated page
  setPlanDetailsAnalyticsQuery() {
    if (window.digitalData && window.digitalData.page && window.digitalData.page.search) {
      const _adobePlan = {} as IAdobe;
      const _planDetails = {} as IAdobePlanDetail;
      _planDetails.careType = this.searchParams.typeOfCareNm;
      _planDetails.category = this.categoryDisplay;
      _planDetails.planName = this.searchParams.plan.name;
      _planDetails.state = this.searchParams.plan.stateCd;
      _adobePlan.planDetail = _planDetails;
      window.digitalData.page.search.planDetailsSearchQuery = CommonUtil.getPlanDetailsAnalyticsQuery(_adobePlan);
    }
  }

  // Based on Plan Care type set the standalone flag.
  setStandAloneFlag(careType: string) {
    const _careType = careType ? careType.toUpperCase().trim() : '';
    switch (_careType) {
      case PROVIDER_TYPE_OF_CARE.DENTAL: {
        this._appSession.isDentalStandAlone = true;
        break;
      }
      case PROVIDER_TYPE_OF_CARE.VISION: {
        this._appSession.isVisionStandAlone = true;
        break;
      }
      case PROVIDER_TYPE_OF_CARE.PHARMACY: {
        this._appSession.isPharmacyStandAlone = true;
        break;
      }
    }
  }

  goToRCPLogin(url: string) {
    if (typeof url !== 'undefined' && url !== null) {
      this._window.open(url, '_self');
    }
  }

  getRCPLoginAndRegisterUrl() {
    // The below domain extracting logic will be clened up once, public team sends the correct brand for anthembluecros
    this.domain = 'anthembluecross';
    const hasMatchingDomain = this.hasMatchingDomain(this.domain);
    if (hasMatchingDomain) {
      this.locateRCPLoginUrl = this.appConfig?.login[this.locale]['brand'][this.domain];
      this.locateRCPRegisterUrl = this.appConfig?.register[this.locale]['brand'][this.domain];
    } else {
      this.locateRCPLoginUrl = this.appConfig?.login[this.locale]['brand'][this.brand.toLowerCase()];
      this.locateRCPRegisterUrl = this.appConfig?.register[this.locale]['brand'][this.brand.toLowerCase()];
    }
  }

  hasMatchingDomain(domain: string): boolean {
    this.href = window.location.href.toLowerCase();
    return this.href.indexOf(domain) >= 0;
  }

  getBrandEBCBlock() {
    const index = AppConstants.BRAND.findIndex((x) => x.brand === this._appSession.metaData.brandCd.toLocaleUpperCase());
    if (index >= 0) {
      this.showEBCBlock = AppConstants.BRAND[index].showSixStateMedicaid;
      /* FTB-276: cleaned up radioMedicaid options code */
    }
  }

  //TODO refactor
  onMedicaidBtnClick(statename: string) {
    if (typeof statename !== 'undefined' && statename !== null) {
      this._window.open(this.appConfig?.MedicaidState[this.locale]['state'][statename], '_blank');
    }
  }
  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  get showMemberTab() {
    return !this._appSession.isPlanUnknown;
  }

  get showLoginSection(): boolean {
    return this._appSession.isPlanUnknown !== true;
  }

  getRules() {
    this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
      (data: IRulesResponse) => {
        if (data) {
          this._appSession.feature = data;
        }
      },
      (error: unknown) => {
        throw error;
      }
    );
  }

  emitCobrandEvent() {
    //Emit PF_APP_COMMAND event to notify the Consumer Public page to refresh the logo with cobrand
    const eventDetail = {} as IEventDetail;
    const cobrandRule: ICobrandRule = this._appSession.planRule.cobrandRule;
    eventDetail.target = cobrandRule.target;
    eventDetail.type = cobrandRule.type;
    eventDetail.message = cobrandRule.message;
    eventDetail.object = cobrandRule.object;
    this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]).emit(eventDetail);
  }

  closePlanSearchPanel() {
    this.sidePanelRef.close();
  }
  openPlanSearchPanel() {
    // open side panel - plan selection section
    this.sidePanelRef = this._sidePanel.open('right', this.planSelectionSidePanel);
  }

  resolvedCaptcha($event: string) {
    if ($event) {
      this._appSession.captchaToken = $event;
    } else {
      this._appSession.captchaToken = '';
    }
  }

  private getBrand(): string {
    return this.brand === BRAND_CODE.ABCNY ? BRAND_CODE.ABCBS : this.brand;
  }
}
