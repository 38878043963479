import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CommonAlertModule } from '../../../../src/app/common/components/common-alert/commonAlertModule';
import { InfoListModule } from '../../../../src/app/common/components/info-list/pfInfoListModule';
import { AssignPcpModule } from '../../../../src/app/common/components/pcp/pfAssignPcpModule';
import { CostSlideOutModule } from '../common/components/shareable/cost-slide-out/costSlideOutModule';
import { EmailModule } from '../common/components/shareable/email/emailModule';
import { GenericSidePanelModule } from '../common/components/shareable/generic-side-panel/genericSidePanelModule';
import { HealthwiseModule } from '../common/components/shareable/healthwise/healthwiseModule';
import { LookUpContainerModule } from '../common/components/shareable/look-up-container/lookUpContainerModule';
import { MemberPlanProgressModule } from '../common/components/shareable/member-plan-progress/memberPlanProgressModule';
import { GenericModalModule } from '../common/components/shareable/modal/genericModalModule';
import { PageAlertsModule } from '../common/components/shareable/page-alert/pageAlertsModules';
import { PaginationModule } from '../common/components/shareable/pagination/paginationModule';
import { SearchHeaderModule } from '../common/components/shareable/search-header/SearchHeaderModule';
import { SearchSlideOutModule } from '../common/components/shareable/search-slide-out/searchSlideOutModule';
import { JoinPipe } from '../common/pipes/joinPipe';
import { OfficeHoursPipe } from '../common/pipes/officeHoursPipe';
import { TransformMemberPlanProgressPipe } from '../common/pipes/transformMemberPlanProgressPipe';
import { TruncatePipe } from '../common/pipes/truncateNamePipe';
import { ComapreProviderHandler } from './classes/comapreProviderHandler';
import { DisclaimersModule } from './components/disclaimers/disclaimersModule';
import { FilterAccordionComponent } from './components/filterAccordion/filterAccordionCmp';
import { FilterCheckboxListComponent } from './components/filterCheckboxList/filterCheckboxListCmp';
import { FilterDeselectionComponent } from './components/filterDeselection/filterDeselectionCmp';
import { FilterHeaderComponent } from './components/filterHeader/filterHeaderCmp';
import { FilterSidePanelComponent } from './components/filterSidePanel/filterSidePanelCmp';
import { ProviderAddressComponent } from './components/provider/provider-address/providerAddressCmp';
import { ProviderPhoneComponent } from './components/provider/provider-phone/providerPhoneCmp';
import { ProviderSpecialtyComponent } from './components/provider/provider-specialty/providerSpecialtyCmp';
import { ProviderCardComponent } from './components/providerCard/providerCardCmp';
import { AssignCareComponent } from './components/providerCardDetails/assignCare/assignCareCmp';
import { InfoListComponent } from './components/providerCardDetails/infoListAlert/infoListCmp';
import { ProviderCardDetailsComponent } from './components/providerCardDetails/providerCardDetailsCmp';
import { ProviderCompareComponent } from './components/providerCompare/providerCompareCmp';
import { ProviderCompareSidePanelComponent } from './components/providerCompareSidePanel/providerCompareSidePanelCmp';
import { ProviderCptCostInfoComponent } from './components/providerCostDetails/providerCptCostInfo/providerCptCostInfoCmp';
import { ProviderPricingDetailContainerCmp } from './components/providerCostDetails/providerPricingDetailContainer/providerPricingDetailContainerCmp';
import { ProviderProcedureCostInfoCmp } from './components/providerCostDetails/providerProcedureCostInfo/providerProcedureCostInfoCmp';
import { ProviderListComponent } from './components/providerList/providerListCmp';
import { ProviderMapComponent } from './components/providerMap/providerMapCmp';
import { InsuranceCardComponent } from './components/providerMoreDetails/insuranceCard/insuranceCardCmp';
import { LicenseCardComponent } from './components/providerMoreDetails/licenseCard/licenseCardCmp';
import { ProviderMoreDetailsComponent } from './components/providerMoreDetails/providerMoreDetailsCmp';
import { ServicesCardComponent } from './components/providerMoreDetails/servicesCard/servicesCardCmp';
import { AffiliationCardComponent } from './components/providerOverview/affiliationCard/affiliationCardCmp';
import { CptCardComponent } from './components/providerOverview/cptCard/cptCardCmp';
import { PcpListComponent } from './components/providerOverview/pcpList/pcpListCmp';
import { ProviderOverviewComponent } from './components/providerOverview/providerOverviewCmp';
import { RecognitionCardComponent } from './components/providerOverview/recognitionCard/recognitionCardCmp';
import { SpecialityCardComponent } from './components/providerOverview/specialityCard/specialityCardCmp';
import { ProviderRewardsComponent } from './components/providerRewards/providerRewardsCmp';
import { ResultComponent } from './components/resultCmp';
import { SelectedProviderComponent } from './components/selectedProvider/selectedProviderCmp';
import { VirtualConnectNowComponent } from './components/virtualConnectNowCmp/virtualConnectNowCmp';
@NgModule({
  declarations: [
    ResultComponent,
    ProviderListComponent,
    ProviderCardComponent,
    ProviderMapComponent,
    ProviderSpecialtyComponent,
    ProviderPhoneComponent,
    ProviderAddressComponent,
    TransformMemberPlanProgressPipe,
    FilterHeaderComponent,
    FilterSidePanelComponent,
    FilterAccordionComponent,
    FilterDeselectionComponent,
    FilterCheckboxListComponent,
    ProviderCardDetailsComponent,
    ProviderCompareComponent,
    ProviderCompareSidePanelComponent,
    ProviderOverviewComponent,
    ProviderMoreDetailsComponent,
    PcpListComponent,
    CptCardComponent,
    RecognitionCardComponent,
    SpecialityCardComponent,
    InsuranceCardComponent,
    ServicesCardComponent,
    AffiliationCardComponent,
    LicenseCardComponent,
    AssignCareComponent,
    SelectedProviderComponent,
    ProviderPricingDetailContainerCmp,
    ProviderCptCostInfoComponent,
    VirtualConnectNowComponent,
    ProviderProcedureCostInfoCmp,
    InfoListComponent,
    ProviderRewardsComponent,
    TruncatePipe,
    OfficeHoursPipe,
    JoinPipe
  ],
  exports: [ResultComponent, TruncatePipe, OfficeHoursPipe, JoinPipe],
  imports: [
    UxSharedModule,
    FormsModule,
    CommonModule,
    SearchHeaderModule,
    CostSlideOutModule,
    HealthwiseModule,
    MemberPlanProgressModule,
    SearchSlideOutModule,
    DisclaimersModule,
    PaginationModule,
    EmailModule,
    LookUpContainerModule,
    InfoListModule,
    CommonAlertModule,
    AssignPcpModule,
    PageAlertsModule,
    GenericSidePanelModule,
    GenericModalModule
  ],
  providers: [TransformMemberPlanProgressPipe, ComapreProviderHandler]
})
export class ResultModule {}
