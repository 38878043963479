import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { FilterSidePanelComponent } from '../filterSidePanel/filterSidePanelCmp';
import { SliderDirection } from './../../../common/constants/common';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-header-cmp',
  templateUrl: './filterHeaderCmp.html'
})
export class FilterHeaderComponent extends BaseComponent implements OnInit {
  filterContent = this.content?.result?.filter;
  sortFilter = this.content?.result?.summaryComponent;
  sortOptions = this.filterContent?.sortFilters;
  isSortContainerVisible: boolean = false;
  selectedSort = this.sortFilter?.sortOptions?.distance;
  @ViewChild('searchResultsFilterModal')
  searchResultsFilterModal: FilterSidePanelComponent;
  @Output()
  selectedSortOption: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit(): void {}

  openFilterPanel() {
    this.searchResultsFilterModal.openSidePanel(SliderDirection.RIGHT);
  }

  showSortContainer() {
    this.isSortContainerVisible = true;
  }

  selectSortingOption(option: any) {
    this.selectedSort = option.value;
    this.isSortContainerVisible = false;
    this.selectedSortOption.emit(option.label);
  }
}
