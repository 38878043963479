import { IBrandConfig, IRebrandConfig } from '../interfaces/iBrandConfig';
import { IProviderSearchDdlValues } from './../../fad/search-providers/interfaces/iProviderSearchDdlValues';

export enum Locale {
  ENGLISH = 'en_US',
  SPANISH = 'es_US'
}

export enum ContentLanguageCode {
  ENGLISH = 'en',
  SPANISH = 'es'
}

export const MAPPING_LOCALE_TO_CONTENT_LANGUAGE = {
  en_US: 'en',
  es_US: 'es'
};

export const ANTHEM = 'Anthem';

export const LOW_RANGE_AGE = 0;
export const HIGH_RANGE_AGE = 99;

export class AppConstants {
  static get ENV_LOCAL(): string {
    return 'LOCAL';
  }
  static get ENV_MOCK(): string {
    return 'MOCK';
  }
  static get ENV_DEV(): string {
    return 'DEV';
  }
  static get ENV_SIT(): string {
    return 'SIT';
  }
  static get ENV_UAT(): string {
    return 'UAT';
  }
  static get ENV_PERF(): string {
    return 'PERF';
  }
  static get ENV_PROD(): string {
    return 'PROD';
  }
  static get MESSAGEID(): string {
    return '123457';
  }
  static get APPID(): string {
    return 'FAD';
  }
  static get LANG(): IProviderSearchDdlValues {
    const _lang: IProviderSearchDdlValues = {
      title: '',
      options: [
        {
          label: 'ENGLISH',
          value: Locale.ENGLISH,
          trueValue: Locale.ENGLISH,
          id: 'cb_language_1',
          name: 'cb_language_1',
          falseValue: '',
          isChecked: false
        },
        {
          label: 'SPANISH',
          value: Locale.SPANISH,
          trueValue: Locale.SPANISH,
          id: 'cb_language_2',
          name: 'cb_language_2',
          falseValue: '',
          isChecked: false
        }
      ]
    };
    return _lang;
  }

  static get BRAND(): IBrandConfig[] {
    return [
      {
        brand: BRAND_CODE.ABCBS,
        domainName: 'Anthem',
        logo: 'top-logo-abcbs.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_BCBS_New.png',
        hostname: '.anthem.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.ABC,
        domainName: 'Anthem',
        logo: 'top-logo-abc.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_ABC_New.png',
        hostname: '.anthem.com',
        appendUrl: '/ca',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.AMV,
        domainName: 'Amerigroup',
        logo: 'top-logo-amerigroup.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.amerigroup.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.TXDCM,
        domainName: 'Anthem',
        logo: 'top-logo-txdcm.svg',
        logoClass: BRAND_LOGO_CLASS.TXDCM,
        idCardImg: '',
        hostname: '.mydellchildrens.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.EBCBS,
        domainName: 'Empireblue',
        logo: 'top-logo-ebcbs-ebc.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_Empire_New.png',
        hostname: '.empireblue.com',
        appendUrl: '',
        showSixStateMedicaid: true,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.EBC,
        domainName: 'Empireblue',
        logo: 'top-logo-ebcbs-ebc.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_Empire_New.png',
        hostname: '.empireblue.com',
        appendUrl: '',
        showSixStateMedicaid: true,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.SIMPLY,
        domainName: 'Simplyhealthcareplans',
        logo: 'top-logo-simply.svg',
        logoClass: BRAND_LOGO_CLASS.SIMPLY,
        idCardImg: '',
        hostname: '.simplyhealthcareplans.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HEALTHBLUE,
        domainName: 'Healthybluela',
        logo: 'top-logo-hbla.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.healthybluela.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.UNICARE,
        domainName: 'Unicare',
        logo: 'top-logo-unicare.svg',
        logoClass: BRAND_LOGO_CLASS.UNICARE,
        idCardImg: 'IDcard_Green.png',
        hostname: '.unicare.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.GW,
        domainName: 'Unicare',
        logo: 'top-logo-blank.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_Green.png',
        hostname: '.unicare.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.UNIMASS,
        domainName: 'Unicaremass',
        logo: 'top-logo-unicaremass.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_Green.png',
        hostname: '.unicaremass.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HBNC,
        domainName: 'Bcbsdirect',
        logo: 'top-logo-hbnc.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.bcbsdirect.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HBNCM,
        domainName: 'Anthem',
        logo: 'top-logo-hbncm.svg',
        logoClass: BRAND_LOGO_CLASS.HBNCM,
        idCardImg: '',
        hostname: '.healthybluenc.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.AHKP,
        domainName: 'Anthem',
        logo: 'top-logo-ahkp.svg',
        logoClass: BRAND_LOGO_CLASS.AHKP,
        idCardImg: '',
        hostname: '.anthem.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.KYMBS,
        domainName: 'Anthem',
        logo: 'top-logo-kymbs.svg',
        logoClass: BRAND_LOGO_CLASS.KYMBS,
        idCardImg: '',
        hostname: '.anthem.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.BLUEMA,
        domainName: 'Bluemedadvgrhs',
        logo: 'top-logo-bluema.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.bluemedadvgrhs.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HBMO,
        domainName: 'Anthem',
        logo: 'top-logo-hbmo.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.healthybluemo.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.EBCBSTHREELOGOS,
        domainName: 'Anthem',
        logo: 'ebcbs_3logos.svg',
        logoClass: BRAND_LOGO_CLASS.EBCBSTHREELOGOS,
        idCardImg: 'IDcard_Empire_New.png',
        hostname: '.empireblue.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.BCBSWN,
        domainName: 'Mybcbswny',
        logo: 'top-logo-bcbswn.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.mybcbswny.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.CHA,
        domainName: 'Anthem',
        logo: 'top-logo-cha.svg',
        logoClass: BRAND_LOGO_CLASS.CHA,
        idCardImg: '',
        hostname: '.clearhealthalliance.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.BCBSMN,
        domainName: 'Anthem',
        logo: 'top-logo-bcbsmn.svg',
        logoClass: BRAND_LOGO_CLASS.BCBSMN,
        idCardImg: '',
        hostname: '.anthem.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.ABCBSKROGER,
        domainName: 'Anthem',
        logo: 'top-logo-abcbskroger.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_BCBS_New.png',
        hostname: '.anthem.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.SCC,
        domainName: 'Anthem',
        logo: 'top-logo-scc.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '.png',
        hostname: '.summitcommunitycare.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.NYEMBLUAM,
        domainName: 'Anthem',
        logo: 'top-logo-ny-embluam.svg',
        logoClass: BRAND_LOGO_CLASS.EBCBSTHREELOGOS,
        idCardImg: 'IDcard_Empire_New.png',
        hostname: '.empireblue.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.NYEMPIRE,
        domainName: 'Anthem',
        logo: 'top-logo-ny-empire.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_Empire_New.png',
        hostname: '.empireblue.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.WLP,
        domainName: 'Wellpoint',
        logo: 'top-logo-wlp.svg',
        logoClass: BRAND_LOGO_CLASS.WLP,
        idCardImg: 'IDcard_WLP.png',
        hostname: '.wellpoint.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true,
        supportedBrandLabel: 'WLPLabel'
      },
      {
        brand: BRAND_CODE.WLPMASS,
        domainName: 'Anthem',
        logo: 'top-logo-wlp.svg',
        logoClass: BRAND_LOGO_CLASS.SIMPLY,
        idCardImg: '',
        hostname: '.wellpoint.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HEALTHBLUEKC,
        domainName: 'Anthem',
        logo: 'top-logo-healthblue-kc.svg',
        logoClass: BRAND_LOGO_CLASS.HEALTHBLUEKC,
        idCardImg: '',
        hostname: '.healthybluemo.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.WLPHC,
        domainName: 'Anthem',
        logo: 'top-logo-wlp-hc.svg',
        logoClass: BRAND_LOGO_CLASS.WLPHC,
        idCardImg: '',
        hostname: '.wellpoint.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.ABCNY,
        domainName: 'AnthemBlueCross',
        logo: 'anthem-bcbs-bc-logo-dual-2023.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: 'IDcard_BCBS_New.png',
        hostname: '.anthembluecross.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.SNY,
        domainName: 'AnthemBlueCross',
        logo: 'top-logo-bcbs-bc-ny.svg',
        logoClass: BRAND_LOGO_CLASS.SNY,
        idCardImg: 'IDcard_BCBS_New.png',
        hostname: '.anthembluecross.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.AGP,
        domainName: 'Anthem',
        logo: 'top-logo-amerigroup.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.amerigroup.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.SMPLY,
        domainName: 'Anthem',
        logo: 'top-logo-simply.svg',
        logoClass: BRAND_LOGO_CLASS.SIMPLY,
        idCardImg: '',
        hostname: '.simplyhealthcareplans.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.CLEAR,
        domainName: 'Anthem',
        logo: 'top-logo-cha.svg',
        logoClass: BRAND_LOGO_CLASS.CHA,
        idCardImg: '',
        hostname: '.clearhealthalliance.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.WNY,
        domainName: 'Anthem',
        logo: 'top-logo-bcbswn.svg',
        logoClass: BRAND_LOGO_CLASS.BCBSWN,
        idCardImg: '',
        hostname: '.mybcbswny.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HBL,
        domainName: 'Anthem',
        logo: 'top-logo-hbla.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.healthybluemo.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.SNYDCS,
        domainName: 'AnthemBlueCross',
        logo: 'top-logo-abc-ny.svg',
        logoClass: BRAND_LOGO_CLASS.SNY,
        idCardImg: 'IDcard_BCBS_New.png',
        hostname: '.anthembluecross.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      }
    ];
  }

  static get MEDICAID_BRAND(): IBrandConfig[] {
    return [
      {
        brand: BRAND_CODE.AGP,
        domainName: 'Anthem',
        logo: 'top-logo-amerigroup.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.amerigroup.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.SMPLY,
        domainName: 'Anthem',
        logo: 'top-logo-simply.svg',
        logoClass: BRAND_LOGO_CLASS.SIMPLY,
        idCardImg: '',
        hostname: '.simplyhealthcareplans.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.CLEAR,
        domainName: 'Anthem',
        logo: 'top-logo-cha.svg',
        logoClass: BRAND_LOGO_CLASS.CHA,
        idCardImg: '',
        hostname: '.clearhealthalliance.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.WNY,
        domainName: 'Anthem',
        logo: 'top-logo-bcbswn.svg',
        logoClass: BRAND_LOGO_CLASS.BCBSWN,
        idCardImg: '',
        hostname: '.mybcbswny.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HBL,
        domainName: 'Anthem',
        logo: 'top-logo-hbla.svg',
        logoClass: BRAND_LOGO_CLASS.COMMON,
        idCardImg: '',
        hostname: '.healthybluemo.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      },
      {
        brand: BRAND_CODE.HEALTHBLUEKC,
        domainName: 'Anthem',
        logo: 'top-logo-healthblue-kc.svg',
        logoClass: BRAND_LOGO_CLASS.HEALTHBLUEKC,
        idCardImg: '',
        hostname: '.healthybluemo.com',
        appendUrl: '',
        showSixStateMedicaid: false,
        hideMedicaid: true,
        hideMembersLabel: true
      }
    ];
  }
  static get MyPlan(): string {
    return 'myPlan';
  }
  static get PlanUnknown(): string {
    return 'planUnknown';
  }
}
export enum AppErrorMsg {
  PF_APP_ERROR = 'Provider Finder app error!',
  SERVICE_UNAVAILABLE = 'Sorry, the application encountered technical difficulty processing the request.',
  TOKEN_EXPIRED = 'Sorry, your session might have expired. Please refresh the page and try again.',
  INVALID_METADATA = 'Invalid metadata object.',
  INVALID_BRAND = 'Invalid brand.',
  INVALID_DPL_URL = 'Invalid dpl url',
  INVALID_IDENTIFICATION_NBR = 'Invalid identification number',
  INVALID_MEMBER_UID = 'Invalid member uid',
  INVALID_GROUP_ID = 'Invalid group id',
  INVALID_FIRST_NAME = 'Invalid first name',
  INVALID_LAST_NAME = 'Invalid last name',
  INVALID_DOB = 'Invalid date of birth',
  INVALID_SEQ_NBR = 'Invalid member sequence number',
  INVALID_WEB_GUID = 'Invalid web guid',
  INVALID_SOURCE_SYSTEM = 'Invalid source system',
  INVALID_USER_NAME = 'Invalid user name',
  INVALID_CONTRACTUID = 'Invalid contract Uid',
  DEFAULT_ERROR_MSG = "Sorry, we're experiencing technical difficulties at this time. Please try again later.",
  SSO_ERROR_MSG = "Sorry, looks like something isn't working. Please refresh the page and try again.  If the problem continues, contact  <a href='contact-us' data-analytics='contactUsTasks' id='link1439353740480' >Technical Support</a> ."
}

export enum ERROR_CODES {
  UNAUTHORIZED = 401,
  SERVICE_UNAVAILABLE = 503
}

export enum ERROR_TYPES {
  MEMBER_DETAILS = 'MEMBER_DETAILS',
  PROV_DATA_MDFD_DT = 'PROV_DATA_MDFD_DT',
  ALPHA_PREFIX = 'ALPHA_PREFIX',
  PLAN_RULE = 'PLAN_RULE',
  INVALID_HCID = 'INVALID_HCID'
}

export interface ICommand {
  CMD: string;
  MSG: string;
}

export class AppExternalCommands {
  static get RCP_LOGIN(): ICommand {
    return {
      CMD: 'rcp_login',
      MSG: 'Navigate to RCP login page for secure state'
    };
  }
  static get RCP_PF_LANDING(): ICommand {
    return {
      CMD: 'find-care',
      MSG: 'Navigate to RCP provider finder landing page'
    };
  }
  static get RCP_ID_CARD(): ICommand {
    return {
      CMD: 'idcard',
      MSG: 'Navigate to RCP ID card page'
    };
  }
  static get RCP_SCHEDULER_LANDING(): ICommand {
    return {
      CMD: 'appointment',
      MSG: 'Navigate to RCP Appointment schedule landing page'
    };
  }
  static get RCP_CONTACT_US(): ICommand {
    return {
      CMD: 'contact-us',
      MSG: 'Navigate to RCP Contact us page'
    };
  }
  static get RCP_LIVE_CHAT(): ICommand {
    return {
      CMD: 'LIVECHAT',
      MSG: 'Navigate to RCP Live Chat'
    };
  }
  static get RCP_FIND_CARE_LIVE_CHAT(): ICommand {
    return {
      CMD: 'TIMED_WELCOME_MESSAGE',
      MSG: 'Assist user with intented provider search'
    };
  }
  static get RCP_VIRTUAL_CARE(): ICommand {
    return {
      CMD: 'virtual-care',
      MSG: 'Navigate to RCP Virtual Care'
    };
  }
  static get RCP_CONTACT_US_CALL_MAIL_SECTION(): ICommand {
    return {
      CMD: 'contact-us?tabId=callMail&tabSection=SCHEDULECALLBACK',
      MSG: 'Navigate to RCP Contact us page call and mail section'
    };
  }
  static get RCP_CLAIMS_LANDING(): ICommand {
    return {
      CMD: 'claims',
      MSG: 'Navigate to RCP Claims landing page'
    };
  }
  static get RCP_CHAT_BOT(): ICommand {
    return {
      CMD: 'chatbot',
      MSG: 'Open RCP chatbot'
    };
  }
  static get PF_APP_CMD_PROV_INFO(): ICommand {
    return {
      CMD: 'PF_APP_CMD_PROV_INFO',
      MSG: 'Public Provider Selection for client system'
    };
  }
  static get PF_APP_SEARCH_BAR_LOADED(): ICommand {
    return {
      CMD: 'search_bar_loaded',
      MSG: 'Find Care Search Bar loaded'
    };
  }
  static get RCP_LHO_CONVERGE(): ICommand {
    return {
      CMD: 'findcare/virtual-visit',
      MSG: 'Navigate to RCP LHO Converge page'
    };
  }
  static get RCP_ENGAGEMENT_CLINICAL_PROGRAMS(): ICommand {
    return {
      CMD: 'my-health-dashboard/programs?id={PGMID}',
      MSG: 'Navigate to RCP clinical programs page under my health dashboard'
    };
  }
  static get RCP_HEALTHWELLNESS_CLINICAL_PROGRAMS(): ICommand {
    return {
      CMD: 'findcare/healthandwellnessprograms?id={PGMID}',
      MSG: 'Navigate to RCP clinical programs page under health and wellness'
    };
  }
  static get RCP_PLAN_PROGRESS(): ICommand {
    return {
      CMD: 'benefits?covtype=med',
      MSG: 'Navigate to RCP benefits page'
    };
  }
  static get PF_APP_CMD_EMAIL(): ICommand {
    return {
      CMD: 'PF_APP_CMD_EMAIL',
      MSG: 'Log the Provider details Send in Email id'
    };
  }
}

export enum AppInfoMsg {
  PF_APP_LOAD_STARTED = 'Provider Finder app load started',
  FAD_APP_LOADED = 'Find a Doctor app loaded successfully',
  EYC_APP_LOADED = 'Estimate Your Cost app loaded successfully',
  CARE_APP_LOADED = 'Care Team app loaded successfully'
}

export enum LOOKUP_CATEGORY {
  PROCEDURES = 'procedures',
  SPECIALTIES = 'specialties',
  PROVIDERS = 'providername',
  CITIES = 'cities',
  COUNTIES = 'counties'
}

export const CARE_TYPE = {
  options: [
    {
      value: 'medical',
      type: 'P'
    },
    {
      value: 'dental',
      type: 'D'
    },
    {
      value: 'vision',
      type: 'V'
    }
  ]
};

export enum SEARCH_OPTIONS {
  SEARCH_ALL = 'all',
  PROCEDURES = 'procedures',
  SPECIALTIES = 'specialties',
  PROVIDER_NAME = 'name',
  AOE = 'aoe',
  OFFICE_SERVICE = 'officeService'
}

export enum ADDRESS_SELECT {
  HOME = 'home',
  WORK = 'work',
  UPDATE = 'update',
  ZIPCODE = 'zipcode',
  CUSTOM_ADDRESS = 'custom_address'
}

export enum NETWORK_TYPECODE {
  MEDICAL = 'MEDICAL',
  VISION = 'VISION',
  DENTAL = 'DENTAL'
}

export enum MODAL_TYPE {
  TRANSPORTATION = 'TRANSPORTATION',
  HOMEHEALTHCARE = 'HOMEHEALTHCARE',
  MEDICARE_SUPPLEMENT = 'MEDICARE_SUPPLEMENT',
  GENERIC_MODAL = 'GENERIC_MODAL'
}
export enum SSO_SUBTYPE {
  VITALS = 'vitalsSSO',
  VIRTUAL_VISIT = 'kHealthRedirectURL',
  VIRTUAL_CARE_SSO_URL = 'virtualCareSSOURL'
}

export enum DEEPLINK_ACTION {
  POST_PCP_INFO = 'POST_PCP_INFO'
}

export enum ACCOUNT_TYPE {
  HRA = 'Health Reimbursement Account',
  HIA = 'Health Incentive Account',
  HIAPLUS = 'Health Incentive Account Plus',
  HSA = 'Health Savings Account',
  QHE = 'Qualify Health Expenses',
  HRA_ABBR = 'HRA',
  HSA_ABBR = 'HSA'
}

export enum FUTURE_PLAN_OPTIONS {
  MEMBER_SELECT = 'MEMBERSELECT',
  PLAN_SELECT = 'PLANSELECT',
  CARE_SELECT = 'CARESELECT'
}

export enum ORIGIN {
  CARE_TEAM = 'CARE_TEAM',
  CARE_CIRCLE = 'CARE_CIRCLE',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  PROVIDER_DETAILS = 'PROVIDER_DETAILS'
}

export enum CLIENTS {
  SYDNEYWEB = 'SYDNEYWEB', //Sydney web view
  EMPLOYER = 'EMPLOYER', //Employer portal
  PEGAWGS = 'PEGAWGS', //Solution central
  FINDCARE = 'FINDCARE', //Default value
  SYDNEYMEDICAID = 'SYDNEYMEDICAID', //Sydney medicaid portal
  HIP = 'HIP', //Healthy Innovations Platform (HIP)
  ASPEN = 'ASPEN'
}

export const BRAND_LOGO_CLASS = {
  COMMON: 'brand-logo-width',
  SIMPLY: 'simply-logo brand-logo-width',
  UNICARE: 'unicare-logo brand-logo-width',
  HBNCM: 'hbncm-logo brand-logo-width',
  BCBSMN: 'bcbsmn-logo brand-logo-width',
  BCBSWN: 'bcbswn-logo brand-logo-width',
  AHKP: 'ahkp-logo brand-logo-width',
  KYMBS: 'kymbs-logo brand-logo-width',
  TXDCM: 'txdcm-logo',
  EBCBSTHREELOGOS: 'ant-img',
  CHA: 'cha-logo',
  WLP: 'wlp-logo',
  HEALTHBLUEKC: 'healthbluekc-logo',
  WLPHC: 'wlphc-logo',
  SNY: 'sny-logo brand-logo-width'
};

export const BRAND_CODE = {
  ABCBS: 'ABCBS',
  ABC: 'ABC',
  EBCBS: 'EBCBS',
  EBC: 'EBC',
  AMV: 'AMV',
  TXDCM: 'TXDCM',
  SIMPLY: 'SHC',
  HEALTHBLUE: 'HEALTHBLUE',
  UNICARE: 'UNICARE',
  GW: 'GW',
  UNIMASS: 'UNIMASS',
  HBNC: 'HBNC',
  HBNCM: 'HBNCM',
  AHKP: 'AHKP',
  KYMBS: 'KYMBS',
  BLUEMA: 'BLUEMA',
  HBMO: 'HBMO',
  EBCBSTHREELOGOS: 'EBCBSTHREELOGOS', // This is not a brand this is just adding additional images to the brand when cobrand applicable for EBCBS,
  BCBSWN: 'BCBSWN',
  BCBSMN: 'BCBSMN',
  ABCBSKROGER: 'ABCBSKROGER',
  NYEMBLUAM: 'NYEMBLUAM',
  NYEMPIRE: 'NYEMPIRE',
  CHA: 'CHA',
  SCC: 'SCC',
  WLP: 'WLP',
  HEALTHBLUEKC: 'HEALTHBLUEKC',
  WLPHC: 'WLPHC',
  WLPMASS: 'WLPMASS',
  ABCNY: 'ABCNY',
  SNY: 'SNY',
  // Medicaid portal Brands
  AGP: 'AGP',
  SMPLY: 'SMPLY',
  CLEAR: 'CLEAR',
  WNY: 'WNY',
  HBL: 'HBL',
  SNYDCS: 'SNYDCS'
};

/**
 * expect to hold only Medicaid brand code should not add any other market brands
 */
export const MEDICAID_BRAND_CODE = ['AGP', 'SMPLY', 'CLEAR', 'WNY', 'HBL'];

export const PREFIXVALUES = {
  nyEmblemlogoIntegrationFlagKey: 'nyEmblemlogoIntegration',
  nyEmblemBrand: 'NYEMBLEMBRAND',
  PREFIX: 'n6y',
  PLAN_ALPHAPREFIX: '1469',
  NEWLOGONAME: 'ebcbs_3logos.svg',
  NEWCLASSNAME_1: 'col-xxs-6 col-xs-6 col-sm-5',
  NEWCLASSNAME_2: 'motif-theme brand-logo',
  OLDCLASSNAME_1: 'col-xxs-6 col-xs-6 col-sm-5 ant-logo-wrapper-col',
  OLDCLASSNAME_2: 'ant-logo-wrapper brand-logo',
  MOTIFTHEME_CLASS: 'motif-theme',
  LOGO_WRAPPER_COL_CLASS: 'logo-wrapper-col',
  ANT_LOGO_WRAPPER_CLASS: 'ant-logo-wrapper',
  ANT_LOGO_WRAPPER_COL_CLASS: 'ant-logo-wrapper-col',
  COBRAND_NY_EMBLEM_LOGO_TEMPLATE:
    '<div class="motif-theme brand-logo"><a href="/" aria-label="Empire BCBS home"><img class="ant-img" alt="Empire Blue Cross Blue shield home" src="{co_brand_logo}"></a></div>'
};

export const WGS_DOWN_TIME_ERROR_CODE = '5023';

//ServiceAreaValidationOuput
export const SERVICE_AREA_VALIDATION_SUCCESS_CODE = 'P';

export enum ACTION_TAKEN {
  click = 'Clicked',
  compareCare = 'CompareCareProviders',
  compareNow = 'CompareNow',
  addToCareTeam = 'AddToCareTeam',
  getDirections = 'GetDirections',
  acknowledged = 'acknowledged',
  archived = 'archived'
}

export const USER_INTERACTION = {
  landingPage: {
    providerQuickLink: {
      eventType: 'providerQuickLink',
      actionId: ''
    },
    procedureQuickLink: {
      eventType: 'procedureQuickLink',
      actionId: ''
    },
    viewMoreLink: {
      eventType: 'procedureQuickLink'
    },
    procedureYouMayBeConsideringLink: {
      eventType: 'procedureYouMayBeConsideringLink',
      actionId: ''
    }
  },
  providerSummaryPage: {
    providerTopChoiceDetailLink: {
      eventType: 'providerTopChoiceDetailLink',
      actionId: ''
    },
    providerMoreOptionDetailLink: {
      eventType: 'providerMoreOptionDetailLink',
      actionId: ''
    },
    providerNonPMDetailLink: {
      eventType: 'providerNonPMDetailLink',
      actionId: ''
    },
    compareNowLink: {
      eventType: 'compareNowLink',
      actionTaken: ACTION_TAKEN.compareNow,
      actionId: ''
    },
    addToCareTeamLink: {
      actionTaken: ACTION_TAKEN.addToCareTeam,
      actionId: ''
    },
    getDirectionsLink: {
      actionTaken: ACTION_TAKEN.getDirections,
      actionId: ''
    }
  },
  shopForProcedurePage: {
    suggestedProcedures: {
      actionTaken: ACTION_TAKEN.compareCare,
      eventType: 'suggestedProcedures'
    },
    frequentSearch: {
      actionTaken: ACTION_TAKEN.compareCare,
      eventType: 'frequentSearchProcedures'
    },
    showMoreOrLess: {
      eventType: 'frequentSearchProcedures'
    }
  }
};

export const TOP_CHOICES_RECORD_COUNT = 3;
export const CONCATE_SYMBOL = '|';
export const TILDE_SYMBOL = '~';
export const SNACKBAR = 'snackbar';
// CA Department of Managed Health Care
export const CAHMODMHC = 'caHmoDmhc';
export const THREE = 3;

export enum USER_IMPRESSION_DEST_ID {
  landingPage = 'FC_LANDING',
  shopForProcedurePage = 'SHOP_FOR_PROC'
}

export const TAB_KEYCODE = 9;
export const PCP_OPTIONAL = 'PCP_OPTIONAL';
export const PCP_REQUIRED = 'PCP_REQUIRED';
export const PCP_NOTREQUIRED = 'PCP_NOT_REQUIRED';
export const DATE_FORMAT = 'MM/dd/yyyy';

export const MEMBER_NAME = /{MEMBER NAME}/gi;
export const EFFECTIVE_DATE = /{EFFECTIVE DATE}/gi;
export const TERMINATION_DATE = /{TERMINATION DATE}/gi;
export const DOCTOR_NAME = /{DOCTOR NAME}/gi;
export const PLAN_NAME = /\{PLAN_NAME\}/gi;
export const DATE = /\{DATE\}/gi;
export const ERROR_CODE = /{ERROR CODE}/gi;
export const PROVIDER_NAME = /{providerName}/gi;
export const ANP_ERROR_CODE = 'errAnpAssignPcpSuppressed';
export const ASSIGN_PCP_SUPPRESSED_ERROR_CODE = 'errAssignPcpSuppressed';
export const ERR_NO_PCPS_IN_AFFILIATION = 'errNoPcpsInAffiliation';
export const ERR_NO_AFFILIATIONS = 'errNoAffiliations';
export const ERR_INVALID_PCP_INFO_API_RESP = 'errInvalidPcpInfoAPIResp';
export const ERR_INVALID_PCP_DETAILS_API_RESP = 'errInvalidPcpDetailsAPIResp';
export const ERR_MBR_PCP_EFF_DT_PAST_END_DT = 'errPcpMbrEffDtPastContractEndDt';
export const ERR_MBR_AGE_NOT_IN_RANGE = 'errMbrAgeNotInRange';

export enum BILLING_TYPE {
  CPT = 'CPT',
  HCPCS = 'HCPCS',
  MSDRG = 'MSDRG',
  RC = 'RC',
  APRDRG = 'APRDRG'
}
export const CA_HMO_DISCLAIMER = 'caHmoDisclaimer';
export const RIGHT = 'right';
export const REASONTYPE_FREEFORM = 'freeform';
export const COUNT = /{{count}}/gi;
export const MOCK = 'mock';
export const FALSE = 'false';
export const STEP = /\{STEP\}/gi;
export const TOTAL = /\{TOTAL\}/gi;

export const SOURCE_SYSTEM = {
  MEDISYS: 'MEDISYS',
  MWMEDSYS: 'MWMEDSYS',
  WGS: 'WGS',
  STAR: 'STAR'
} as const;

export enum SEARCH_PARAMS {
  FLUSHOTS = 'providesFluShots',
  PCP = 'ableToServePcp' // Added capability to do the PCP search via deeplink searchparams option
}

export enum SIDE_PANEL_STATUS {
  CLOSE,
  OPEN
}

export enum SEARCH_TYPE {
  MEDICAL_CODE = 'MEDICAL_CODE',
  PROCEDURE = 'TCC',
  RETAIL_PHARMACY = 'RX',
  SPECIALTY = 'SPECIALTY'
}

export enum SEARCH_LOCATION_TYPE {
  DEFAULT = 'default', //no location text mentioned in FTS search text
  ZIP = 'zip',
  CITY = 'city',
  COUNTY = 'county'
}

export enum PROCEDURE_LIST_TYPE {
  TCC = 'TCC',
  CPT = 'CPT'
}

export const NA = 'NA';
export const EMPTY_STRING = '';
export const OPTIONAL = 'OPTIONAL';
export const REQUIRED = 'REQUIRED';
export const AFFILIATION_NAME = 'affiliationName';
export const AFFILIATION_GROUP = 'affiliationGroup';
export const ID = 'id';
export const AFFILIATION_LABEL = 'affiliationLabel';
export const SERVE_PCP_Y = 'y';
export const SERVE_PCP_T = 't';
export const PROFESSIONAL = 'professional';
export const BOTH = 'both';
export const LHO = 'LHO';
export const SERVICE_LIMITS_COUNT = 1;
export const AOE = 'AOE';
export const OFFICE_SERVICE = 'OFFICE_SERVICE';
export const VIRTUAL_CARE_HUB = 'VIRTUAL_CARE_HUB';
export const STATE_NJ = 'NJ';
export const TIER = 'TIER';
export const TIER1 = 'Tier1';
export const PLANTYPE_OON = 'OON';
export const CA_STATE = 'CA';

export const OFFICE_VISIT_MEDICALCODE = {
  MEDICAL_CODE: '99214',
  SELECTED_POS_CODE_EN: 'Office',
  SELECTED_POS_CODE_ES: 'Consultorio',
  SEARCH_TERM:
    'CPT-99214 - Office or other outpatient visit for the evaluation and management of an established patient, which requires a medically appropriate history and/or examination and moderate level of medical decision making. When using total time on the date of the encounter for code selection, 30 minutes must be met or exceeded.',
  DESCRIPTION:
    'Office or other outpatient visit for the evaluation and management of an established patient, which requires a medically appropriate history and/or examination and moderate level of medical decision making. When using total time on the date of the encounter for code selection, 30 minutes must be met or exceeded.'
} as const;

export enum REBRAND_CODE {
  WLPMASS = 'WLPMASS'
}

export const REBRAND_CONFIG: Record<REBRAND_CODE, IRebrandConfig> = {
  WLPMASS: {
    brand: BRAND_CODE.WLP,
    prefix: '6004'
  }
};

export const CHINESE_LANG_BRANDS = ['ABC', 'EBC', 'EBCBS'];
