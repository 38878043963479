import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { DataHelper } from '../../../../common/services/dataHelper';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import {
  COUPE_HEALTH_ALERT,
  HAS_COUPE_HEALTH,
  PROVIDER_TYPE,
  PROVIDER_TYPE_CARE,
  PROVIDER_TYPE_OF_CARE,
  SPANISH_PROVIDER_TYPE_CARE
} from '../../../../fad/search-providers/values/providerSearchConstants';
import { LANDING_TAB_VIEW } from '../../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { AppConstants, FUTURE_PLAN_OPTIONS, Locale } from '../../../constants/app-constants';
import { AppNavigations } from '../../../constants/app-navigations';
import { DeeplinkUrl } from '../../../enums/deepLinkUrls';
import { EventHandler } from '../../../services/eventHandler';
import { NavigationService } from '../../../services/navigationService';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { IDeeplink } from '../../app-secure/models/iDeeplink';
import { IMetadata } from '../../app-secure/models/iMetadata';
import { MemberSummaryService } from '../../app-secure/services/memberSummarySvc';
import { SecureStateHandler } from '../../app-secure/services/secureStateHandler';
import { BaseComponent } from '../../base-component/baseCmp';
import { GeoLocationService } from '../../geoLocation/services/geoLocationService';
import { InfoListService } from '../../info-list/services/infoListSvc';
import { ScenarioAlertSvc } from '../../scenarioAlert/services/pfScenarioAlertSvc';
import { IChangePlanParam } from '../models/iChangePlanParam';
import { ChangePlanEvent, IChangePlanEvent } from './../../../enums/changePlanEvent';
import { IAppContract, ICoverageDetails, IMemberContract, IMultiMemberContract, IOptions, IProductName } from './../../../interfaces/iAppMetadata';
import { RouteUtil } from './../../../utilities/routeUtil';
import { IChangePlanMemberOptions, IChangePlanOptions } from './../interfaces/iChangePlanOptions';
import { ChangePlanService } from './../services/changePlanSvc';

@Component({
  moduleId: module.id,
  selector: 'app-change-plan',
  templateUrl: './pfChangePlanCmp.html'
})
export class PFChangePlanComponent extends BaseComponent implements OnInit, OnDestroy {
  get changePlanLabel(): string {
    return CommonUtil.isMemberSecure(this._appSession) ? this.getChangePlanLblForSecureState() : this.content.commonContents.labels.changePlanPublic;
  }

  get showChangePlanLink(): boolean {
    return this.getShowChangePlanLinkValue();
  }

  get getPrefixText(): string {
    if (
      !this._appSession?.isSecureState &&
      !this._dataHelper.isEmptyString(this._appSession?.deeplinkParams?.alphaprefix) &&
      this._dataHelper.areEqualStrings(this._appSession.deeplinkParams.alphaprefix, this._appSession.searchParams?.plan?.name)
    ) {
      return this.content.searchCriteriaComponent.labels.findingCarePrefix.replace(/{{PREFIX}}/gi, this._appSession.deeplinkParams.alphaprefix);
    }
    return '';
  }

  @ViewChild('changePlanSidePanel')
  changePlanSidePanel: TemplateRef<HTMLElement>;
  @ViewChild('showMemberPlans')
  showMemberPlans: TemplateRef<HTMLElement>;
  @Output()
  onChangePlanClick: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  searchParams: ISearchParameters;
  @Input()
  selectedPlanText?: string;
  @Input()
  isCareTeamPage?: boolean = false;
  @Input()
  showPlanText?: boolean = true;
  sidePanelRef: ModalRef<any, any>;
  careSelectModel: string = '';
  planSelectModel: string = '';
  memberSelectModel: string = '';
  isMemberSecure: boolean = false;
  metaData = {} as IMetadata;
  changePlanParam = {} as IChangePlanParam;
  multiMemberRes: IMultiMemberContract;
  isMemberchanged: boolean = false;
  UID: string = '';
  selectedMbrUID: string = '';
  selectedProviderType: string = '';
  locale: string = '';
  selectedTypeofCare: string = '';
  navigation: AppNavigations;
  isProviderTypeChanged: boolean = false;
  providerTypeCareOption: any = [];
  careValue: string = '';
  showPageProgress: boolean = false;
  showApiError: boolean = false;
  coverageDetailError: boolean = false;
  hasOnlyOneContract: boolean = false;
  planSelectionLinkClickSub: Subscription;
  planNetworkLinkClickSub: Subscription;
  planStartingDate: string = '';
  hideCoveragePicker: boolean;
  planNames: string[] = [];
  showMultiplansLink = false;
  showMemberPlansLink = false;
  showPlanBasedOnNetwork = false;
  multiplePlansText: string;
  planNetworks: string;
  hasMultiplePlans = false;
  hasMultipleMembers = false;
  hasMultipleMedicalPlans = false;
  memberOptions: IChangePlanMemberOptions[] = [];
  products: IProductName[] = [];
  planText: string = '';
  selectedCoverageUid: string = '';

  constructor(
    @Inject(AppSession)
    protected _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _memberSummaryService: MemberSummaryService,
    private _secureStateHandler: SecureStateHandler,
    private _routeUtil: RouteUtil,
    private _changePlanService: ChangePlanService,
    private _titleCasePipe: TitleCasePipe,
    private _scenarioAlertSvc: ScenarioAlertSvc,
    private _dataHelper: DataHelper,
    private _geoLocationSvc: GeoLocationService,
    private _infoListSvc: InfoListService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');

    this.planNetworkLinkClickSub = this._changePlanService.onPlanNetworkLinkDisplay.subscribe((planTypes: string[]) => {
      this.setPlanBasedOnNetwork(planTypes);
    });
  }

  private setPlanBasedOnNetwork(planTypes: string[]): void {
    this.showPlanBasedOnNetwork = false;
    if (this.isMemberSecure) {
      if (this._appSession.isEyc && this.selectedPlanText) {
        this.planText = this.selectedPlanText;
        this.planNames = [];
        this.showMultiplansLink = false;
        this.showMemberPlansLink = true;
      } else if (planTypes && planTypes.length > 0) {
        this.setPlanName(planTypes);
      } else {
        if (this.multiMemberRes) {
          this.getMemberPlans(this.multiMemberRes.contractList);
        } else if (CommonUtil.isMultiContractAllowedClient(this._appSession.deeplinkParams?.client)) {
          this.getMultiMemberContract();
        }
      }
    }
  }

  private setPlanName(planTypes: string[]): void {
    let plans: string[] = [];
    if (this.products?.length > 0) {
      (planTypes || []).forEach((plantype: string) => {
        const productPlans: IProductName[] = this.products.filter((product: IProductName) => (product.type || '').toLowerCase() === (plantype || '').toLowerCase());

        (productPlans || []).forEach((product: IProductName) => {
          if (product.name && plans.indexOf(product.name) === -1) {
            plans.push(product.name);
          }
        });
      });

      if (plans.length > 1) {
        this.planNames = plans;
        this.showMultiplansLink = true;
        this.showMemberPlansLink = true;
        this.showPlanBasedOnNetwork = true;
        this.sortPlanNames();
      } else if (plans.length === 1) {
        const _memberName = this._titleCasePipe.transform(this._appSession.getMemberName());
        if (_memberName) {
          this.joinMemberNameWithPlanName(plans[0], _memberName);
          this.planNames = [];
          this.showMultiplansLink = false;
          this.showMemberPlansLink = true;
          this.showPlanBasedOnNetwork = true;
        }
      }
    }
  }

  ngOnChanges() {
    if (this.selectedPlanText && !this.showPlanBasedOnNetwork) {
      this.planText = this.selectedPlanText;
    }
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    this.metaData = this._appSession.changePlanMetaData;
    this.locale = this._appSession.metaData?.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;

    if (this.locale && this.locale === Locale.ENGLISH) {
      this.providerTypeCareOption = PROVIDER_TYPE_CARE;
    } else {
      this.providerTypeCareOption = SPANISH_PROVIDER_TYPE_CARE;
    }
    this.planNetworks = ' ' + (this.content.commonContents.labels.planNetworks || '');
    //this.resetParam();
    if (this.isMemberSecure && CommonUtil.isMultiContractAllowedClient(this._appSession?.deeplinkParams?.client)) {
      this.getMultiMemberContract();
    } else {
      this.showMemberPlansLink = true;
    }

    this.planSelectionLinkClickSub = this._changePlanService.onPlanSelectionLinkClick.subscribe((value: boolean) => {
      if (value) {
        this.openSlider('right');
      }
    });
  }

  ngOnDestroy() {
    if (typeof this.planSelectionLinkClickSub !== 'undefined' && this.planSelectionLinkClickSub !== null) {
      this.planSelectionLinkClickSub.unsubscribe();
    }
    if (typeof this.planNetworkLinkClickSub !== 'undefined' && this.planNetworkLinkClickSub !== null) {
      this.planNetworkLinkClickSub.unsubscribe();
    }
  }

  /** Open Side Panel */
  openSidePanel(direction: any) {
    if (
      !this.isMemberSecure ||
      (this.searchParams &&
        this.searchParams.plan &&
        this.searchParams.plan.name &&
        this.searchParams.plan.name === this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown] &&
        CommonUtil.hasMultipleUnknownPrefix(this._appSession))
    ) {
      this._appSession.landingTab = LANDING_TAB_VIEW.GUEST_VIEW;
      this.onChangePlanClick.emit();
    } else {
      this.openSlider(direction);
    }
    if (!this.isMemberSecure) {
      if (!isEmpty(this._appSession.geolocationInfo)) {
        this._appSession.geolocationInfo = {};
      }
      if (!isEmpty(this._appSession.defaultLocation)) {
        this._appSession.defaultLocation = {};
      }
      if (!isEmpty(this._appSession.selectLocationForm)) {
        this._appSession.selectLocationForm = {};
      }
    }
    this._appSession.customAddressSearch = false;
  }

  openPlanSlidePanel(direction: any): void {
    this.sidePanelRef = this._sidePanel.open(direction, this.showMemberPlans);
  }

  setNavigation(dplParams: IDeeplink): AppNavigations {
    let navigation: AppNavigations;

    if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.COST_SEARCH) {
      navigation = AppNavigations.COST_SEARCH_PATH;
      this._appSession.isEyc = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.SEARCH_RESULTS) {
      navigation = AppNavigations.SEARCH_RESULTS_PATH;
      this._appSession.initialSearch = true;
    } else {
      navigation = AppNavigations.SEARCH_PROVIDERS_PATH;
    }

    return navigation;
  }
  /** Event handler for click of close button  */
  onClose() {
    this.sidePanelRef.close();
  }

  getMultiMemberContract() {
    this.showPageProgress = true;
    if (this.metaData.isSecureState) {
      this._memberSummaryService.getMultiMemberDetails(this.metaData, this._appSession.metaData.pfEmMode).then(
        (result: any) => {
          this.onMultiMemberContractSuccess(result);
        },
        (error: any) => {
          this.showPageProgress = false;
          this.onError('SecureState_Error', error);
        }
      );
    }
  }

  onError(type: string, error: any) {
    this.showPageProgress = false;
    this.showApiError = true;
    throw error;
  }

  onMultiMemberContractSuccess(result: IMultiMemberContract) {
    this.showPageProgress = false;
    this.showApiError = false;
    this.multiMemberRes = result;
    this.resetParam();
    if (result && typeof result !== undefined) {
      if (result.contractList && this._appSession && this._appSession.metaData && this._appSession.metaData.contractUid) {
        result.contractList.forEach((item, index) => {
          if (item.memberFeatures.includes(HAS_COUPE_HEALTH)) {
            this.setCoupeHealthAlert();
          }
          if (item.contractUid === this._appSession.metaData.contractUid) {
            result.contractList.splice(index, 1);
            result.contractList.unshift(item);
          }
        });
        if (!this._appSession.isEyc) {
          this.getMemberPlans(result.contractList);
        } else {
          this.showMemberPlansLink = true;
        }
      }
      for (const contract of result.contractList || []) {
        this.getDropdownOfChangePlan(contract);
      }
      this.setDefaultCareType();
      if (this._appSession.filterChangePlan.isPreviouState) {
        this.getCareDropDown(this._appSession.filterChangePlan.careType);
        this.getPlanDropDown(this._appSession.filterChangePlan.planType);
        this.getMemberDropDown(this._appSession.filterChangePlan.memberType);
      }

      this.hasOnlyOneContract = result.contractList && result.contractList.length && result.contractList.length === 1 && result.contractList[0].memberList.length === 1;
      if (!this.multiCoverageEnabled) {
        const hasFutureCoverage =
          Object.keys(result.contractList[0].memberList[0].futureCoverageDetails).length &&
          result.contractList[0].memberList[0].futureCoverageDetails.productNames !== undefined &&
          result.contractList[0].memberList[0].futureCoverageDetails.productNames.length === 1;
        // if Member only have one plan and no dependents then, the coverage picker should not be displayed -
        // suppressing the coverage picker
        this.hideCoveragePicker =
          this.hasOnlyOneContract &&
          !hasFutureCoverage &&
          Object.keys(result.contractList[0].memberList[0].coverageDetails).length &&
          result.contractList[0].memberList[0].coverageDetails.productNames.length === 1;
      } else {
        this.hideCoveragePicker =
          this.hasOnlyOneContract &&
          result?.contractList[0]?.memberList[0]?.coverageDetailsV2?.length === 1 &&
          result?.contractList[0]?.memberList[0]?.coverageDetailsV2[0]?.productNames?.length === 1;
      }

      if (this.changePlanParam.careSelectDropDown && this.changePlanParam.careSelectDropDown.length > 0) {
        let careSelectDropDown = !this.multiCoverageEnabled ? this.changePlanParam.careSelectDropDown.filter((x: any) => x.isValidCareSelectOption) : this.changePlanParam.careSelectDropDown;
        if (careSelectDropDown && careSelectDropDown.length > 0) {
          this.changePlanParam.careSelectDropDown = careSelectDropDown;
        }
      }
      this.emitChangePlanEvent(result.contractList[0], this._appSession.metaData?.appContract?.mbrUid);
    }
  }

  getDropdownOfChangePlan(contract: IMemberContract) {
    if (contract && typeof contract !== undefined) {
      //get Member List
      for (const member of contract.memberList) {
        if (member.isChecked) {
          this.selectedMbrUID = member.mbrUid;
          this.getProduct(member, contract.contractUid);
        }
      }
    }
  }

  getPlanAndCoverage(memberList: Array<IOptions>, contractUid) {
    this.changePlanParam.planSelectDropDown = [];
    this.changePlanParam.careSelectDropDown = [];
    if (memberList && typeof memberList !== undefined) {
      for (const member of memberList) {
        this.getProduct(member, contractUid);
      }
    }
  }

  getProduct(member: IOptions, contractUid: string) {
    if (member && !this._dataHelper.isEmptyArray(member.coverageDetailsV2)) {
      const coverages: Array<ICoverageDetails> = [];
      this.coverageDetailError = false;
      if (!this.multiCoverageEnabled) {
        coverages.push(member.coverageDetails);
      } else {
        coverages.push(...(member.coverageDetailsV2 || []));
      }
      coverages.forEach((coverageDetails) => {
        const isEmbeddedCoverage = this.isEmbeddedCoverage(coverageDetails);
        for (const productType of coverageDetails.productNames || []) {
          let product = this.providerTypeCareOption.options.filter((x) => x.value && productType && productType.type && x.value.toLocaleLowerCase() === productType.type.toLocaleLowerCase());
          if (isEmbeddedCoverage) {
            product = [this.providerTypeCareOption.options[0]];
          }
          if (product && product.length > 0 && product[0].value === PROVIDER_TYPE_OF_CARE.PHARMACY.toLowerCase()) {
            // Assigning showMedicarePartDPharmacyPlan flag to show pharmacy
            // for medicare part D pharmacy members in coverage picker
            if (!this._appSession.feature.showMedicarePartDPharmacyPlan) {
              product.length = 0;
            }
          }
          if (product && product.length > 0 && productType.type !== '' && productType.name !== '') {
            if (
              this.changePlanParam.careSelectDropDown
                .map((x) => {
                  return x.label;
                })
                .indexOf(product[0].label) === -1
            ) {
              this.changePlanParam.careSelectDropDown.push({
                label: product[0].label,
                value: product[0].value,
                contractUid,
                isValidCareSelectOption:
                  (!this._dataHelper.isEmptyString(coverageDetails?.surrogatePrefix) && isEmpty(coverageDetails?.networkList)) ||
                  this._dataHelper.areEqualStrings(product[0].value, PROVIDER_TYPE_OF_CARE.MEDICAL),
                mbrUid: member.mbrUid,
                provider_type: PROVIDER_TYPE[productType.type.toLocaleUpperCase()] ? PROVIDER_TYPE[productType.type.toLocaleUpperCase()] : PROVIDER_TYPE.DOCTOR_PROFESSIONAL,
                typeofCare: PROVIDER_TYPE_OF_CARE[productType.type.toLocaleUpperCase()] ? PROVIDER_TYPE_OF_CARE[productType.type.toLocaleUpperCase()] : PROVIDER_TYPE_OF_CARE.MEDICAL
              });
            }
          }
        }
      });
    } else {
      this.coverageDetailError = true;
    }
    if (
      !this.multiCoverageEnabled &&
      member &&
      typeof member !== 'undefined' &&
      typeof member.futureCoverageDetails !== 'undefined' &&
      member.futureCoverageDetails !== null &&
      typeof member.futureCoverageDetails.productNames !== 'undefined' &&
      member.futureCoverageDetails.productNames !== null
    ) {
      this.coverageDetailError = false;
      let contract: IAppContract;
      let productArray: IProductName[];
      let memberDetails: IOptions[];
      const effectiveDate = '';

      for (const productType of member.futureCoverageDetails.productNames) {
        if ((productType.type || '').toLowerCase() === PROVIDER_TYPE_OF_CARE.MEDICAL.toLowerCase()) {
          const product = this.providerTypeCareOption.options.filter((x) => x.value && productType && productType.type && x.value.toLocaleLowerCase() === productType.type.toLocaleLowerCase());
          this.getFuturePlanSliderDetails(
            member,
            memberDetails,
            FUTURE_PLAN_OPTIONS.CARE_SELECT,
            contract,
            effectiveDate,
            productArray,
            productType,
            product,
            contractUid,
            member.futureCoverageDetails
          );
        }
      }
    }
  }

  setDefaultCareType() {
    const hasMedical = this.changePlanParam.careSelectDropDown.find((x) => x.typeofCare && x.typeofCare.toLocaleUpperCase() === PROVIDER_TYPE_OF_CARE.MEDICAL);
    const hasDental = this.changePlanParam.careSelectDropDown.find((x) => x.typeofCare && x.typeofCare.toLocaleUpperCase() === PROVIDER_TYPE_OF_CARE.DENTAL);
    const hasVision = this.changePlanParam.careSelectDropDown.find((x) => x.typeofCare && x.typeofCare.toLocaleUpperCase() === PROVIDER_TYPE_OF_CARE.VISION);
    const hasPharmacy = this.changePlanParam.careSelectDropDown.find(
      (x) => x.typeofCare && x.typeofCare.toLocaleUpperCase() === PROVIDER_TYPE_OF_CARE.PHARMACY && this._appSession.feature.showMedicarePartDPharmacyPlan
    );
    const defaultContractUid = this._appSession.metaData.contractUid;
    if (typeof hasMedical !== 'undefined' && hasMedical != null && hasMedical.contractUid === defaultContractUid) {
      this.careSelectModel = hasMedical.label;
      this.careValue = hasMedical.value;
      this.selectedProviderType = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
      this.selectedTypeofCare = PROVIDER_TYPE_OF_CARE.MEDICAL;
    } else if (typeof hasDental !== 'undefined' && hasDental != null && hasDental.contractUid === defaultContractUid) {
      this.careSelectModel = hasDental.label;
      this.careValue = hasDental.value;
      this.selectedProviderType = PROVIDER_TYPE.DENTAL;
      this.selectedTypeofCare = PROVIDER_TYPE_OF_CARE.DENTAL;
    } else if (typeof hasVision !== 'undefined' && hasVision != null && hasVision.contractUid === defaultContractUid) {
      this.careSelectModel = hasVision.label;
      this.careValue = hasVision.value;
      this.selectedProviderType = PROVIDER_TYPE.VISION;
      this.selectedTypeofCare = PROVIDER_TYPE_OF_CARE.VISION;
    } else if (typeof hasPharmacy !== 'undefined' && hasPharmacy != null && hasPharmacy.contractUid === defaultContractUid) {
      this.careSelectModel = hasPharmacy.label;
      this.careValue = hasPharmacy.value;
      this.selectedProviderType = PROVIDER_TYPE.PHARMACY;
      this.selectedTypeofCare = PROVIDER_TYPE_OF_CARE.PHARMACY;
    }
    this._appSession.filterChangePlan.careType = !this._appSession.filterChangePlan.isPreviouState ? this.careValue : this._appSession.filterChangePlan.careType;
    this.getPlanName();
    this.getMemberList();
  }

  getPlanName() {
    for (const contract of this.multiMemberRes.contractList) {
      const coverages: Array<ICoverageDetails> = [];
      const member = contract.memberList.filter((x) => x.mbrUid === this.selectedMbrUID);
      if (!this.multiCoverageEnabled) {
        coverages.push(member[0].coverageDetails);
      } else {
        coverages.push(...(member[0]?.coverageDetailsV2 || []));
      }

      if (member && member.length > 0 && coverages.length) {
        this.coverageDetailError = false;
        coverages.forEach((coverageDetails) => {
          const product = (coverageDetails.productNames || []).filter((x) => x.type.toLocaleLowerCase() === this.careValue);
          let effectiveDate = '';
          const planStartDate = this.content.commonContents.labels.planStart;
          if (typeof coverageDetails.coverageEffectiveDate !== 'undefined' && coverageDetails.coverageEffectiveDate !== null && coverageDetails.coverageEffectiveDate !== '') {
            effectiveDate = coverageDetails.coverageEffectiveDate;
            effectiveDate = ' ' + '(' + planStartDate + ' ' + effectiveDate + ')';
          }

          for (const p of product) {
            if (
              this.changePlanParam.planSelectDropDown
                .map((x) => {
                  return x.label + effectiveDate;
                })
                .indexOf(p.name) === -1
            ) {
              this.changePlanParam.planSelectDropDown.push({
                label: p.name + effectiveDate,
                value: p.name + effectiveDate,
                contractUid: contract.contractUid,
                mbrUid: member[0].mbrUid,
                defaultContract: contract.defaultContract,
                provider_type: PROVIDER_TYPE[p.type.toLocaleUpperCase()] ? PROVIDER_TYPE[p.type.toLocaleUpperCase()] : PROVIDER_TYPE.DOCTOR_PROFESSIONAL,
                typeofCare: PROVIDER_TYPE_OF_CARE[p.type.toLocaleUpperCase()] ? PROVIDER_TYPE_OF_CARE[p.type.toLocaleUpperCase()] : PROVIDER_TYPE_OF_CARE.MEDICAL,
                coverageUid: coverageDetails.coverageUid
              });
            }
          }
        });
      } else {
        this.coverageDetailError = true;
      }

      if (!this.multiCoverageEnabled && member && member.length > 0 && member[0].futureCoverageDetails && member[0].futureCoverageDetails.productNames !== undefined) {
        this.coverageDetailError = false;
        let productArray: IProductName[];
        let mbrDetails: IOptions;
        const product = member[0].futureCoverageDetails.productNames.filter((x) => x.type.toLocaleLowerCase() === this.careValue);
        const effectiveDate = member[0].futureCoverageDetails.coverageEffectiveDate;
        for (const p of product) {
          this.getFuturePlanSliderDetails(mbrDetails, member, FUTURE_PLAN_OPTIONS.PLAN_SELECT, contract, effectiveDate, productArray, p);
        }
      }
    }

    const hasDefaultcontract = this.changePlanParam.planSelectDropDown.find((x) => x.defaultContract === true);

    if (typeof hasDefaultcontract !== 'undefined' && hasDefaultcontract !== null) {
      this.planSelectModel = hasDefaultcontract.label;
      if (this._appSession.filterChangePlan && !this._appSession.filterChangePlan.planType) {
        this._appSession.filterChangePlan.planType = this.planSelectModel;
      }
    } else if (this.changePlanParam && this.changePlanParam.planSelectDropDown && this.changePlanParam.planSelectDropDown.length > 0) {
      this.planSelectModel = this.changePlanParam.planSelectDropDown[0].label;
      if (this._appSession.filterChangePlan && !this._appSession.filterChangePlan.planType) {
        this._appSession.filterChangePlan.planType = this.planSelectModel;
      }
    }
  }

  titleCase(name: string) {
    return this._titleCasePipe.transform(name);
  }

  getFirstNameWithDOB(firstName: string, dob: string) {
    return firstName && dob ? this.titleCase(firstName) + ' (' + dob + ')' : firstName.length > 0 ? this.titleCase(firstName) : null;
  }

  getMemberList(isPlanChanged: boolean = false) {
    let allCoverages: Array<ICoverageDetails> = [];
    for (const contract of this.multiMemberRes.contractList) {
      for (const member of contract.memberList) {
        allCoverages = this.multiCoverageEnabled ? [...(member?.coverageDetailsV2 || [])] : [member?.coverageDetails];
        allCoverages.forEach((coverageDetails) => {
          if (coverageDetails && coverageDetails.productNames !== undefined) {
            let product: IProductName[] = [];
            if (this.changePlanParam.careSelectDropDown.length > 1 && this.hasMultiplePlans) {
              product = coverageDetails.productNames.filter((x) => x.type.toLocaleLowerCase() === this.careValue);
            } else {
              product = coverageDetails.productNames;
            }
            if (typeof product !== 'undefined' && product !== null && product.length > 0) {
              this.changePlanParam.memberSelectDropDown.push({
                label: this.getFirstNameWithDOB(member.firstName, member.dateOfBirth),
                value: member.mbrUid,
                contractUid: contract.contractUid,
                memberNumber: member.memberNumber,
                isChecked: member.isChecked,
                isDefault: contract.defaultContract,
                productNames: product
              });
            }
          }
        });
        if (!this.multiCoverageEnabled && member.futureCoverageDetails && member.futureCoverageDetails.productNames !== undefined) {
          const product = member.futureCoverageDetails.productNames.filter((x) => x.type.toLocaleLowerCase() === this.careValue);
          const covEffectiveDate = '';
          let memberDetails: IOptions[];
          this.getFuturePlanSliderDetails(member, memberDetails, FUTURE_PLAN_OPTIONS.MEMBER_SELECT, contract, covEffectiveDate, product);
        }
      }
    }
    this.memberOptions = this.changePlanParam.memberSelectDropDown;
    this.changePlanParam.memberSelectDropDown = [];
    if (isPlanChanged) {
      this.memberOptions.forEach((options: IChangePlanMemberOptions) => {
        if (
          options.productNames.some((product: IProductName) => product.name === this.planSelectModel) &&
          !this.changePlanParam.memberSelectDropDown.some((x) => x.value.indexOf(options.value) === 0)
        ) {
          this.changePlanParam.memberSelectDropDown.push(options);
        }
      });
    } else {
      this.memberOptions.forEach((options: IChangePlanMemberOptions) => {
        if (this.changePlanParam.memberSelectDropDown.length > 0) {
          if (!this.changePlanParam.memberSelectDropDown.some((x) => x.value.indexOf(options.value) === 0)) {
            this.changePlanParam.memberSelectDropDown.push(options);
          }
        } else {
          this.changePlanParam.memberSelectDropDown.push(options);
        }
      });
    }
    this.changePlanParam.memberSelectDropDown.forEach((data: any) => {
      if (data.isChecked) {
        this.memberSelectModel = data.label;
      }
    });
  }

  getCareDropDown(care: string) {
    let type: string = '';
    if (care && care.toLowerCase() !== this.careSelectModel.toLowerCase()) {
      (this.changePlanParam.careSelectDropDown || []).forEach((data: any) => {
        if (care === data.value) {
          type = data.provider_type;
          this.selectedProviderType = data.provider_type;
          this.selectedTypeofCare = data.typeofCare;
          this.selectedMbrUID = data.mbrUid;
          this.isProviderTypeChanged = true;
          this.careSelectModel = data.label;
          this.careValue = data.value;
        }
      });
      this.changePlanParam.planSelectDropDown = [];
      this.changePlanParam.memberSelectDropDown = [];
      this.planStartingDate = '';
      this.getPlanName();
      this.getMemberList();
    }
  }

  getPlanDropDown(plan: string, isPlanChange: boolean = false) {
    let type: string = '';
    if (plan !== null && typeof plan !== 'undefined') {
      this.changePlanParam.planSelectDropDown.forEach((data: any) => {
        if (plan === data.value) {
          type = data.provider_type;
          this.selectedProviderType = data.provider_type;
          this.selectedTypeofCare = data.typeofCare;
          this.selectedMbrUID = data.mbrUid;
          this.UID = data.contractUid;
          this.isProviderTypeChanged = true;
          this.planSelectModel = data.label;
          this.planStartingDate = data.date;
        }
      });
      this.changePlanParam.careSelectDropDown.forEach((data: any) => {
        if (type === data.provider_type) {
          this.careSelectModel = data.label;
        }
      });
      this.hasMultiplePlansForMemeber();
      if (plan && this.hasMultiplePlans) {
        isPlanChange = true;
      }
      this.getMemberList(isPlanChange);
    }
  }

  getMemberDropDown(member: string) {
    if (member !== null && typeof member !== 'undefined') {
      this.changePlanParam.memberSelectDropDown.forEach((data: any) => {
        if (member === data.value) {
          this.selectedMbrUID = data.value;
          this.memberSelectModel = data.label;
          this.isMemberchanged = true;
          this._appSession.filterChangePlan.memberType = this.selectedMbrUID;
          if (data.productNames && data.productNames.length > 0 && data.productNames[0].name) {
            this._appSession.filterChangePlan.planType = data.productNames[0].name;
            if (!data.productNames.some((product) => (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.MEDICAL)) {
              this.careValue = data.productNames[0].type;
            }
          }
        }
      });
    }
  }

  onSave() {
    if (!this.coverageDetailError) {
      this.changePlanParam.planSelectDropDown.forEach((data: any) => {
        if (this.planSelectModel === data.label) {
          if (this.planNames && this.planNames.length > 1 && this._appSession.filterChangePlan) {
            const index = this.planNames.indexOf(this._appSession.filterChangePlan.planType);
            if (index >= 0) {
              this.planNames.splice(index, 1, data.value);
            }
          }
          this.UID = data.contractUid;
          this._appSession.filterChangePlan.planType = data.value;
          this.selectedCoverageUid = data.coverageUid;
        }
      });

      this.changePlanParam.memberSelectDropDown.forEach((data: any) => {
        if (this.memberSelectModel === data.label) {
          this.selectedMbrUID = data.value;
          this._appSession.filterChangePlan.memberType = this.selectedMbrUID;
          this.UID = data.contractUid;
        }
      });

      if (this.isNewLocationFlowEnable && this.multiMemberRes?.contractList?.length > 0) {
        this.publishChangeMemberEvent(this.multiMemberRes?.contractList);
      }
      const previousStateCareType = this._appSession.filterChangePlan.careType ? this._appSession.filterChangePlan.careType : '';
      this._appSession.filterChangePlan.careType = this.careValue;
      this.setAppSession();
      if (!this._appSession.isEyc && this.searchParams?.typeSelectNm !== PROVIDER_TYPE.PHARMACY && this.multiMemberRes.contractList && this.multiMemberRes.contractList.length > 0) {
        this.getMemberPlans(this.multiMemberRes.contractList);
      }
      this._appSession.filterChangePlan.isPreviouState = true;
      let path = this._routeUtil.getResolvedUrl(this._route.snapshot);
      if (this._routeUtil.isSearchResultRoute(path)) {
        if (this.careValue.toLocaleUpperCase() !== previousStateCareType.toLocaleUpperCase()) {
          this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
          path = AppNavigations.SEARCH_PROVIDERS_PATH;
        } else {
          if (this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown) {
            this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
            path = AppNavigations.SEARCH_PROVIDERS_PATH;
          }
          this._changePlanService.setChangePlanSave(path);
        }
      } else {
        this._changePlanService.setChangePlanSave(path);
      }
      this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
      this.onClose();
    }
  }

  setAppSession() {
    const contract = this.multiMemberRes.contractList.filter((x) => x.contractUid === this.UID);

    const changePlanOptions: IChangePlanOptions = {} as IChangePlanOptions;

    changePlanOptions.mbrUid = this.selectedMbrUID;
    changePlanOptions.updatePostalCode = false;
    changePlanOptions.coverageUid = this.selectedCoverageUid;
    if (typeof this.planStartingDate !== 'undefined' && this.planStartingDate !== null && this.planStartingDate !== '') {
      changePlanOptions.planStartingDate = this.planStartingDate;
    }
    if (typeof contract !== 'undefined' && contract !== null && contract.length > 0) {
      this._secureStateHandler.setAppSessionVariables(contract[0], changePlanOptions);
    }
    this._appSession.isMemberMedicalGrpSearch = false;
    this._appSession.primeGroupAffiliations = null;
    this.emitChangePlanEvent(contract[0], this.selectedMbrUID);
  }

  resetParam() {
    this.changePlanParam.memberSelectDropDown = [];
    this.changePlanParam.planSelectDropDown = [];
    this.changePlanParam.careSelectDropDown = [];
    this.showMultiplansLink = false;
    this._appSession.hasMedicalContracts = false;
    this._appSession.hasStandaloneDentalContract = false;
    this._appSession.hasStandaloneVisionContract = false;
  }

  getFuturePlanSliderDetails(
    member: IOptions,
    memberDetails: IOptions[],
    type: FUTURE_PLAN_OPTIONS,
    contract?: IAppContract,
    effectiveDate?: any,
    product?: IProductName[],
    p?: IProductName,
    careproduct?: any,
    contractUid?: string,
    futureCoverageDetails?: ICoverageDetails
  ) {
    switch (type) {
      case FUTURE_PLAN_OPTIONS.MEMBER_SELECT: {
        if (
          this.changePlanParam.memberSelectDropDown
            .map((x) => {
              return x.value;
            })
            .indexOf(member.mbrUid) === -1 &&
          typeof product !== 'undefined' &&
          product !== null &&
          product.length > 0
        ) {
          this.changePlanParam.memberSelectDropDown.push({
            label: this.getFirstNameWithDOB(member.firstName, member.dateOfBirth),
            value: member.mbrUid,
            contractUid: contract.contractUid,
            memberNumber: member.memberNumber,
            isChecked: member.isChecked,
            isDefault: contract.defaultContract
          });
        }
        break;
      }
      case FUTURE_PLAN_OPTIONS.PLAN_SELECT: {
        const futurePlanStart = this.content.commonContents.labels.planStart;
        if (
          this.changePlanParam.planSelectDropDown
            .map((x) => {
              return x.label + ' ' + '(' + futurePlanStart + ' ' + effectiveDate + ')';
            })
            .indexOf(p.name) === -1
        ) {
          this.changePlanParam.planSelectDropDown.push({
            label: p.name + ' ' + '(' + futurePlanStart + ' ' + effectiveDate + ')',
            value: p.name + ' ' + '(' + futurePlanStart + ' ' + effectiveDate + ')',
            contractUid: contract.contractUid,
            mbrUid: memberDetails[0].mbrUid,
            defaultContract: contract.defaultContract,
            provider_type: PROVIDER_TYPE[p.type.toLocaleUpperCase()] ? PROVIDER_TYPE[p.type.toLocaleUpperCase()] : PROVIDER_TYPE.DOCTOR_PROFESSIONAL,
            typeofCare: PROVIDER_TYPE_OF_CARE[p.type.toLocaleUpperCase()] ? PROVIDER_TYPE_OF_CARE[p.type.toLocaleUpperCase()] : PROVIDER_TYPE_OF_CARE.MEDICAL,
            date: effectiveDate
          });
        }
        break;
      }
      case FUTURE_PLAN_OPTIONS.CARE_SELECT: {
        if (careproduct && careproduct.length > 0 && careproduct[0].value === PROVIDER_TYPE_OF_CARE.PHARMACY.toLowerCase()) {
          // Assigning showMedicarePartDPharmacyPlan flag to show pharmacy
          // for medicare part D pharmacy members in coverage picker
          if (!this._appSession.feature.showMedicarePartDPharmacyPlan) {
            careproduct.length = 0;
          }
        }
        if (careproduct && careproduct.length > 0 && p.type !== '' && p.name !== '') {
          if (
            this.changePlanParam.careSelectDropDown
              .map((x) => {
                return x.label;
              })
              .indexOf(careproduct[0].label) === -1
          ) {
            this.changePlanParam.careSelectDropDown.push({
              label: careproduct[0].label,
              value: careproduct[0].value,
              contractUid,
              isValidCareSelectOption:
                (!this._dataHelper.isEmptyString(futureCoverageDetails?.surrogatePrefix) && isEmpty(futureCoverageDetails?.networkList)) ||
                this._dataHelper.areEqualStrings(careproduct[0].value, PROVIDER_TYPE_OF_CARE.MEDICAL),
              mbrUid: member.mbrUid,
              provider_type: PROVIDER_TYPE[p.type.toLocaleUpperCase()] ? PROVIDER_TYPE[p.type.toLocaleUpperCase()] : PROVIDER_TYPE.DOCTOR_PROFESSIONAL,
              typeofCare: PROVIDER_TYPE_OF_CARE[p.type.toLocaleUpperCase()] ? PROVIDER_TYPE_OF_CARE[p.type.toLocaleUpperCase()] : PROVIDER_TYPE_OF_CARE.MEDICAL
            });
          }
        }
        break;
      }
    }
  }

  private openSlider(direction: any) {
    this.isMemberchanged = false;
    this.isProviderTypeChanged = false;
    this.sidePanelRef = this._sidePanel.open(direction, this.changePlanSidePanel);
  }

  private getChangePlanLblForSecureState(): string {
    let label: string;
    if (this.multiMemberRes && this.multiMemberRes.contractList) {
      this.hasMultiplePlansForMemeber();
      this.hasDependents();
      if (this.hasMultiplePlans && this.hasMultipleMembers) {
        label = this.content.commonContents.labels.changePlanSecure;
      } else if (this.hasMultiplePlans) {
        label = this.content.commonContents.labels.changePlanPublicMsg;
      } else if (this.hasMultipleMembers) {
        label = this.content.commonContents.labels.changeMember;
      }
    }
    return label;
  }

  protected hasMultiplePlansForMemeber(): void {
    this.hasMultipleMedicalPlans = false;
    this.hasMultiplePlans = false;
    let _medicalPlans: string[] = [];
    let _dentalPlans: string[] = [];
    let _visionPlans: string[] = [];

    if (
      this.multiMemberRes?.contractList?.length > 1 ||
      this.multiMemberRes?.contractList?.[0]?.memberList?.filter((member: IOptions) => member.mbrUid === this._appSession.metaData.appContract?.mbrUid)?.[0]?.coverageDetailsV2?.length > 1
    ) {
      for (const contract of this.multiMemberRes.contractList) {
        let memberContracts: IOptions[];
        if (!this.multiCoverageEnabled) {
          memberContracts = (contract.memberList || []).filter(
            (member: IOptions) =>
              member.mbrUid === this._appSession.metaData.appContract.mbrUid && (member.coverageDetails.hasMedicalPlan || member.coverageDetails.hasDentalPlan || member.coverageDetails.hasVisionPlan)
          );
        } else {
          memberContracts = (contract.memberList || []).filter(
            (member: IOptions) =>
              member.mbrUid === this._appSession.metaData.appContract.mbrUid && member.coverageDetailsV2.some((coverage) => coverage.hasMedicalPlan || coverage.hasDentalPlan || coverage.hasVisionPlan)
          );
        }

        if (memberContracts && memberContracts.length > 0) {
          if (!this.multiCoverageEnabled) {
            (memberContracts[0]?.coverageDetails?.productNames || []).forEach((product: IProductName) => {
              if (_medicalPlans.indexOf(product.name) === -1 && (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.MEDICAL) {
                _medicalPlans.push(product.name);
              }
              if (_dentalPlans.indexOf(product.name) === -1 && (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.DENTAL) {
                _dentalPlans.push(product.name);
              }
              if (_visionPlans.indexOf(product.name) === -1 && (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.VISION) {
                _visionPlans.push(product.name);
              }
            });
          } else {
            (memberContracts[0]?.coverageDetailsV2 || []).forEach((memberCoverage: ICoverageDetails) => {
              const isEmbeddedCoverage = this.isEmbeddedCoverage(memberCoverage);
              (memberCoverage?.productNames || []).forEach((product: IProductName) => {
                if (_medicalPlans.indexOf(product.name) === -1 && (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.MEDICAL) {
                  _medicalPlans.push(product.name);
                }
                if (_dentalPlans.indexOf(product.name) === -1 && (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.DENTAL && !isEmbeddedCoverage) {
                  _dentalPlans.push(product.name);
                }
                if (_visionPlans.indexOf(product.name) === -1 && (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.VISION && !isEmbeddedCoverage) {
                  _visionPlans.push(product.name);
                }
              });
            });
          }
        }
      }

      if ((_medicalPlans && _medicalPlans.length > 1) || (_dentalPlans && _dentalPlans.length >= 1) || (_visionPlans && _visionPlans.length >= 1)) {
        if (_medicalPlans && _medicalPlans.length > 1) {
          this.hasMultipleMedicalPlans = true;
        }
        this.hasMultiplePlans = true;
      }
    }
  }

  protected hasDependents(): void {
    this.hasMultipleMembers = this._appSession.metaData.appContract.memberList.length > 1;
  }

  private getMemberPlans(contracts: IAppContract[]): string[] {
    let planTypes: string[] = [];
    let allCoverages: ICoverageDetails[] = [];
    let hasStandalonePlans = false;
    let hasMedicalPlans = false;
    let plansInContractCount = 0;
    this.planNames = [];
    this.products = [];
    this._appSession.hasStandaloneDentalContract = false;
    this._appSession.hasStandaloneVisionContract = false;

    const metaData = this._appSession.metaData;
    (contracts || []).forEach((contract: IAppContract) => {
      if (contract?.memberList && metaData.appContract && metaData.appContract.mbrUid && metaData.appContract.coverageDetails) {
        const appContract = metaData.appContract;
        const mbrContracts: IOptions[] = contract.memberList.filter((member: IOptions) => member.mbrUid === appContract.mbrUid);
        allCoverages = this.multiCoverageEnabled ? [...(mbrContracts[0]?.coverageDetailsV2 || [])] : [mbrContracts[0]?.coverageDetails];
        allCoverages.forEach((coverageDetails) => {
          if (coverageDetails && coverageDetails.productNames && coverageDetails.productNames.length) {
            (coverageDetails.productNames || []).forEach((productName) => {
              if (productName && productName.type && productName.type.indexOf(PROVIDER_TYPE_OF_CARE.PHARMACY) === -1) {
                if (coverageDetails.isDentalStandAlone || coverageDetails.isVisionStandAlone) {
                  hasStandalonePlans = true;
                  if (coverageDetails.isDentalStandAlone) {
                    this._appSession.hasStandaloneDentalContract = true;
                  }
                  if (coverageDetails.isVisionStandAlone) {
                    this._appSession.hasStandaloneVisionContract = true;
                  }
                  plansInContractCount = this.setProducts(planTypes, productName, plansInContractCount);
                } else if (appContract.coverageDetails.productNames && (coverageDetails.hasMedicalPlan || coverageDetails.hasDentalPlan || coverageDetails.hasVisionPlan)) {
                  if (coverageDetails.hasMedicalPlan) {
                    this._appSession.hasMedicalContracts = true;
                  }
                  if (appContract.coverageDetails.productNames.some((product: IProductName) => (product.name || '').toLowerCase() === (productName.name || '').toLowerCase())) {
                    hasMedicalPlans = true;
                    plansInContractCount = this.setProducts(planTypes, productName, plansInContractCount);
                  }
                }
              }
            });
          }
        });
      }
    });
    if (!this._appSession.isEyc && ((hasStandalonePlans && hasMedicalPlans) || plansInContractCount > 1)) {
      this.onShowPlanNetworkLink();
      this.sortPlanNames();
    } else {
      this.planNames = [];
      this.showMultiplansLink = false;
    }
    this.showMemberPlansLink = true;
    return this.planNames;
  }

  private setProducts(planTypes: string[], productName: IProductName, plansInContractCount: number): number {
    if (planTypes.indexOf(productName.type) === -1) {
      this.products.push(productName);
      if (this.planNames.indexOf(productName.name) === -1) {
        this.planNames.push(productName.name);
        planTypes.push(productName.type);
        plansInContractCount++;
      }
    }
    return plansInContractCount;
  }

  onShowPlanNetworkLink(): void {
    if (this.planNames && this.planNames.length > 1) {
      let _selectedPlanText = this.content.commonContents.labels.selectedMemberPlans;
      if (this.isCareTeamPage) {
        _selectedPlanText = this.content.commonContents.labels.careTeamPlans;
      }
      const _memberName = this._titleCasePipe.transform(this._appSession.getMemberName());
      if (_memberName && _selectedPlanText) {
        this.multiplePlansText = _selectedPlanText.replace(/{NAME}/gi, _memberName.trim());
        this.showMultiplansLink = true;
      }
    }
  }

  private sortPlanNames(): void {
    if (this.planNames?.length > 1 && this.products?.length > 1) {
      const medicalProduct: IProductName = this.products.find((product: IProductName) => (product.type || '').toUpperCase() === PROVIDER_TYPE_OF_CARE.MEDICAL && this.planNames.includes(product.name));
      if (medicalProduct?.name) {
        this.planNames.sort(function (x, y) {
          return x === medicalProduct.name ? -1 : y === medicalProduct.name ? 1 : 0;
        });
      }
    }
  }

  private isEmbeddedCoverage(coverage: ICoverageDetails): boolean {
    return Boolean(coverage?.productNames?.length > 1 && coverage.productNames.every((product, index, array) => product.identifier === array[0].identifier));
  }

  joinMemberNameWithPlanName(planNm: string, memberNm: string) {
    this.planText = this.content.searchCriteriaComponent.labels.selectedMemberPlan.replace(/{NAME}/gi, memberNm).replace(/{PLAN}/gi, planNm);
  }

  emitChangePlanEvent(selectedContract: IAppContract, selectedMbrUid: string) {
    //Emit event to update the members details in members list component
    const evtObj: IChangePlanEvent = { selectedContract, selectedMbrUid };
    this._eventHandler.get(ChangePlanEvent.CHANGE_PLAN).emit(evtObj);
  }

  getShowChangePlanLinkValue(): boolean {
    /**
     * Adding hideCoveragePicker variable to supress the coverage picker.
     * Disable change plan option for cold state when prefix is YLS/1470.
     *  */
    if (this.hideCoveragePicker || (this._appSession?.feature?.isSNYMember && !this.isMemberSecure)) {
      return false;
    }

    return true;
  }

  publishChangeMemberEvent(contractList: Array<IAppContract>): void {
    (contractList || []).some((contract: IAppContract) => {
      if (this._dataHelper.areEqualStrings(this.UID, contract.contractUid)) {
        const member = contract?.memberList?.find((memberInfo: IOptions) => this._dataHelper.areEqualStrings(memberInfo.mbrUid, this.selectedMbrUID));
        if (member) {
          this._geoLocationSvc.setMemberChangeEvent(member);
          return true;
        }
      }
    });
  }

  /**
   * Method to add the Coupe health alert to Infolist Svc
   * -->Member belongs to Coupe Group and has other coverages as well. Embedded Medical experience
   * -->Multi contract scenario - where both groups have standalone medical but default contract is not medical
   */
  setCoupeHealthAlert() {
    this._appSession.hasCoupeHealth = true;
    let path = this._routeUtil.getResolvedUrl(this._route.snapshot);
    if (path === AppNavigations.SEARCH_PROVIDERS_PATH) {
      this._infoListSvc.addMsgById(COUPE_HEALTH_ALERT);
    } else {
      this._infoListSvc.removeMsgById(COUPE_HEALTH_ALERT);
    }
    this._infoListSvc.update();
  }
}
