import { Inject, Injectable } from '@angular/core';
import { Locale } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { ProviderPlanService } from '../../common/services/providerPlanSvc';
import { IProviderPlan, IProviderPlanRequest } from '../interfaces/iProviderPlan';

@Injectable({
  providedIn: 'root'
})
export class ProviderPlanHandler {
  locale: string;

  constructor(
    private _providerPlanSvc: ProviderPlanService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderPlans(providerId: string, addressId: string, state: string): Promise<IProviderPlan[]> {
    try {
      const planRequest = this.buildRequest(providerId, addressId, state);
      const apiResponse = await this._providerPlanSvc.getProviderPlans(planRequest);
      return apiResponse.insurancePlans;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildRequest(providerId: string, addressId: string, state: string): IProviderPlanRequest {
    const locale = this._appSession?.metaData?.locale?.toString() || Locale.ENGLISH.toString();
    const stateList = this.buildStateList(state);

    return {
      id: providerId ? providerId : '',
      addressId: addressId ? addressId : '',
      brandCode: this._appSession.metaData.brandCd,
      locale: locale,
      stateList: stateList
    };
  }

  private buildStateList(state: string): string[] {
    const states: string[] = [];
    if (state) {
      states.push(state);
    }

    const sessionStateCode = this._appSession.searchParams?.plan?.stateCd;
    if (sessionStateCode && sessionStateCode !== state) {
      states.push(sessionStateCode);
    }
    return states;
  }
}
