import { Inject, Injectable } from '@angular/core';
import { BRAND_CODE } from '../../../common/constants/app-constants';
import { SearchExecutionMode } from '../constants/common';
import { IBootstrapRequest, IBootstrapResponse } from '../interfaces/iBootstrap';
import { IRuleTrigger } from '../interfaces/iRuleTrigger';
import { UtilityService } from '../services/utilitySvc';
import { AppSession } from './../../../common/values/appSession';

@Injectable()
export class BootstrapHandler {
  constructor(
    private _utilitySvc: UtilityService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  /**
   * Fetches the bootstrap data for the application session.
   * It uses the UtilityService to get the bootstrap data based on the current application session state.
   * @returns {Promise<IBootstrapResponse>} A promise that resolves to the bootstrap response.
   */
  getBootstrap(): Promise<IBootstrapResponse> {
    const isSecureState = this._appSession.isSecureState;
    return this._utilitySvc.getBootstrap(isSecureState, this.buildRequest(isSecureState));
  }

  /**
   * Builds the bootstrap request object based on the current application session metadata.
   * It constructs the request with necessary criteria such as states, brands, mbus, prefixes, and groups.
   * @returns {IBootstrapRequest} The constructed bootstrap request object.
   */
  private buildRequest(isSecureState: boolean): IBootstrapRequest {
    if (isSecureState) {
      this._appSession.appState.executionMode = SearchExecutionMode.SECURE;
      return {} as IBootstrapRequest;
    }

    const { changePlanMetaData, metaData, searchParams } = this._appSession;
    const criteria: IRuleTrigger = {
      brand: metaData?.brandCd || BRAND_CODE.ABCBS
    };
    if (searchParams?.plan?.stateCd) {
      this._appSession.appState.executionMode = SearchExecutionMode.PLAN_SELECTION;
      criteria.state = searchParams.plan.stateCd;

      if (searchParams?.plan?.alphaPrefix) {
        criteria.prefix = searchParams.plan.alphaPrefix;
      }
      if (searchParams?.plan?.networkList) {
        criteria.productNetwork = searchParams.plan.networkList;
      }
    } else if (searchParams?.plan?.alphaPrefix) {
      this._appSession.appState.executionMode = SearchExecutionMode.PREFIX_SEARCH;
      criteria.prefix = searchParams.plan.alphaPrefix;
    }
    const request = { criteria } as IBootstrapRequest;
    if (changePlanMetaData?.hcid) {
      this._appSession.appState.executionMode = SearchExecutionMode.HCID_SEARCH;
      request.identificationNumber = changePlanMetaData.hcid.trim();
    }
    return request;
  }
}
