import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IGeoLocation } from '../../../../../../common/components/geoLocation/models/geoLocation';

@Injectable({
  providedIn: 'root'
})
export class SearchHeaderService {
  private _searchLocationSubject = new BehaviorSubject<IGeoLocation>(undefined);
  searchLocation = this._searchLocationSubject.asObservable();

  /**
   * Sets the search location in the search header.
   * @param location The location to set.
   */
  setSearchLocation(location: IGeoLocation): void {
    this._searchLocationSubject.next(location);
  }
}
