import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IWindow } from '../../../common/interfaces/iWindow';
import { HighlightPipe } from '../../../common/pipes/highlightPipe';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-lookup-list',
  templateUrl: './pfLookUpListCmp.html',
  providers: []
})
export class PfLookUpListComponent extends BaseComponent implements OnInit {
  @Output()
  select: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  list: any = [];
  @Input()
  category: string = '';
  @Input()
  nameField: string = '';
  @Input()
  highlightText: string;

  showMore: boolean = false;
  lookUpTopList: any = [];
  lookUpList: any = [];

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _elementRef: ElementRef,
    private _highlightPipe: HighlightPipe) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnChanges(args: any) {
    if (args['list'] && args['list'].currentValue) {
      this.lookUpList = args['list'].currentValue;
      this.lookUpTopList = this.lookUpList.slice(0, this._appSession.appConfig?.features.lookUp.minCount);
    }
  }

  showMoreItems() {
    this.showMore = !this.showMore;
  }

  onSelect(obj: any) {
    this.select.emit({ dataObj: obj, category: this.category });
  }

  highlightSearchText(value: string) {
    return this._highlightPipe.transform(value, this.highlightText, 'Multi-Word-Match');
  }
}
