import { Inject, Injectable } from "@angular/core";
import { BaseService } from '../../../../../../common/services/baseService';
import { HttpClientService } from "../../../../../../common/services/httpClientService";
import { AppUtility } from "../../../../../../common/utilities/appUtil";
import { AppSession } from "../../../../../../common/values/appSession";
import { IPageAlertRequest } from "../../../../../../findcare/common/interfaces/iPageAlert";
import { PageAlertHandler } from "./pageAlertHandler";
import { PageAlertUtility } from "./pageAlertUtility";

@Injectable({ providedIn: 'root' })
export class PageAlertSvc extends BaseService {

  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility,
    private _utilityService: PageAlertUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  async getPageAlerts(request: IPageAlertRequest) {
    const pageAlerts = await this._utilityService.getPageAlerts(request);
    PageAlertHandler.buildPageAlertMessage(pageAlerts, this.appSession, request.page);
  }
}
