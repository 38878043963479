import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { delay, isEmpty } from 'lodash';
import { IWindow } from '../../../../../../common/interfaces/iWindow';
import { DataHelper } from '../../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../common/values/appSession';
import { IModalContent, IModalMetaInfo } from '../../../../interfaces/iModalContent';
import { CommonUtility } from '../../../../utilities/commonUtil';
import { BaseComponent } from '../../../core/baseCmp';
import { GenericModalService } from '../../modal/services/genericModalSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-generic-side-panel-cmp',
  templateUrl: './genericSidePanelCmp.html'
})
export class GenericSidePanelComponent extends BaseComponent implements OnInit {
  @ViewChild('genericSidePanel')
  genericSidePanel: TemplateRef<HTMLElement>;
  sidePanelMetaInfo: IModalMetaInfo = {} as IModalMetaInfo;
  sidePanelRef: ModalRef<HTMLElement>;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _genericModalService: GenericModalService,
    @Inject('Window') private _window: IWindow,
    @Inject(DataHelper) private _dataHelper: DataHelper,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    this._genericModalService.sidePanelListener().subscribe((sidePanelInfo: IModalMetaInfo) => {
      this.sidePanelMetaInfo = sidePanelInfo;
      this.openSidePanel();
    });
  }

  get genericSidePanelTemplate(): IModalContent {
    let sidePanelContent: IModalContent = {} as IModalContent;
    sidePanelContent = this.content.common.sidePanels[this.sidePanelMetaInfo?.modalId];
    if (!isEmpty(sidePanelContent)) {
      sidePanelContent = CommonUtility.processModalContent(sidePanelContent, this.currentBrand);
    }
    return sidePanelContent;
  }

  get currentBrand() {
    return this._appSession.metaData?.brandName;
  }

  openSidePanel() {
    const overlayContainer = document.getElementsByClassName('overlay-container');
    if (overlayContainer && overlayContainer.length) {
      overlayContainer[0].innerHTML = '';
    }
    if (this.genericSidePanel) {
      if (this.sidePanelMetaInfo?.delayTime) {
        delay(() => {
          this.sidePanelRef = this._sidePanel?.open('right', this.genericSidePanel);
        }, this.sidePanelMetaInfo?.delayTime);
      } else {
        this.sidePanelRef = this._sidePanel?.open('right', this.genericSidePanel);
      }
    }
  }

  closeSidePanel() {
    this.sidePanelRef?.close();
  }

  redirectSidePanel(_url: string) {
    if (_url) {
      let windowParams: string;
      windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
      _url = _url.indexOf('://') <= 0 ? 'http://' + _url : _url;
      const winArgs = [_url, '_blank'];
      if (windowParams) {
        winArgs.push(windowParams);
      }
      this._window.open.apply(this._window, winArgs);
    }
    this.closeSidePanel();
  }
}
