import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { AntTabGroupComponent, TabbedViewComponent } from '@anthem/uxd/tabs';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { IVirtualProviderConfig } from '../../../care-circle/interfaces/iCareProvider';
import { AnpSidePanelComponent } from '../../../common/components/common-alert/anpSidePanelCmp';
import { OONDetailsComponent } from '../../../common/components/common-alert/oonDetailsCmp';
import { SlideoutMessageComponent } from '../../../common/components/common-alert/slideoutMsgCmp';
import { PFDisclaimerComponent } from '../../../common/components/disclaimers/pfDisclaimerCmp';
import { LeavingDisclaimerComponent } from '../../../common/components/leaving-disclaimer/leavingDisclaimerCmp';
import { ScenarioAlertSvc } from '../../../common/components/scenarioAlert/services/pfScenarioAlertSvc';
import { IMemberCriteria } from '../../../common/interfaces/iCommonSearchRequest';
import { IVirtualVisitSSO } from '../../../common/interfaces/iMessage';
import { IWindow } from '../../../common/interfaces/iWindow';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { ISearchParameters } from '../../search-providers/interfaces/iSearchParameters';
import { SAVNavigationService } from '../../search-providers/services/savNavigationSvc';
import { VpcMagicLinkSvc } from '../../search-providers/services/vpcMagicLinkSvc';
import { CommonUtil } from '../../utilities/commonUtil';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { IDetailsResponse, IProviderDetail } from '../models/iDetailsResponse';
import { ILocation } from '../models/iLocation';
import { ILocationAddress, ILocationRequest, ILocationsResponse } from '../models/iLocations';
import { IProviderCptCodes } from '../models/iProviderCptCodesResponse';
import { IProviderProcedureInfo } from '../models/iProviderProcedureInfo';
import { ISelectedProviderProcedures } from '../models/iSelectedProviderProcedures';
import { ProviderCptCodesService } from '../services/providerCptCodesSvc';
import { ProviderOrchestrationService } from '../services/providerOrchestrationSvc';
import { MemberPCPHelper } from './../../../care-circle/services/memberPCPHelper';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { InfoListService } from './../../../common/components/info-list/services/infoListSvc';
import { PFDrivingDirectionComponent } from './../../../common/components/map/pfDrivingDirectionCmp';
import { CaHmoPanelComponent } from './../../../common/components/pcp/caHmoPanelCmp';
import { AssignPcpComponent } from './../../../common/components/pcp/pfAssignPcpCmp';
import { ANP_ERROR_CODE, CA_HMO_DISCLAIMER, DOCTOR_NAME, Locale, MEMBER_NAME, RIGHT, TERMINATION_DATE, VIRTUAL_CARE_HUB } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { AppEvents } from './../../../common/enums/appEvents';
import { DeeplinkUrl } from './../../../common/enums/deepLinkUrls';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';
import { ISlideoutMessage } from './../../../common/interfaces/iSlideoutMsg';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { IPcpNavResponse } from './../../assign-pcp/models/iMemberPcpDetails';
import { AssignPcpNavigationService } from './../../assign-pcp/services/assignPcpNavigationSvc';
import { OUT_OF_NETWORK, PROVIDER_TYPE } from './../../search-providers/values/providerSearchConstants';
import { DETAILS_TAB_VIEW, OON_ADOBE_TAGS, OUTNETWORK } from './../../search-results/values/providerSearchConstants';
import { AffiliationResponseV2 } from './../models/iAffiliationsResponse';
import { ICptCode } from './../models/iProviderCptCostDetailRequest';
import { IProviderPlansResponse } from './../models/iProviderPlansResponse';
import { ProviderDetailsNavigationService } from './../services/providerDetailsNavigationSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fad-details-cmp',
  templateUrl: '../views/pfProviderDetailsContainerCmp.html',
  providers: []
})
export class PFProviderDetailsContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  showTabHeaders: boolean = false;
  isProviderOutOfNetwork: boolean = false;

  get isCaHmoDmhc(): boolean {
    return this._appSession?.isCaHmoDmhc;
  }

  get isServiceAreaValidated(): boolean {
    return this._appSession.serviceAreaValidated;
  }
  ssoConfig: IVirtualVisitSSO;

  @ViewChild('detailsMapModal')
  summaryMapModal: ModalComponent;
  @ViewChild('DrivingDirections') drivingDirection: PFDrivingDirectionComponent;
  @ViewChild('disclaimers')
  disclaimers: PFDisclaimerComponent;
  @ViewChild('leavingDisclaimerModal')
  leavingDisclaimerModal: LeavingDisclaimerComponent;
  @ViewChild('tabCtrl')
  tabCtrl: TabbedViewComponent;
  @ViewChild(AntTabGroupComponent)
  groupEl: AntTabGroupComponent;
  @ViewChild('assignPcpModal')
  assignPcpModal: AssignPcpComponent;
  @ViewChild('slideoutMsgCmp')
  slideoutMsgCmp: SlideoutMessageComponent;
  @ViewChild('anpSidePanelCmp') anpSidePanelCmp: AnpSidePanelComponent;
  @ViewChild('caHmoPanelCmp') caHmoPanelCmp: CaHmoPanelComponent;
  @ViewChild('oonLearnMoreDetail')
  oonLearnMoreSlider: OONDetailsComponent;
  disclaimerUrl: any;
  showDetailsLoader: boolean = false;
  costInfo: any = {};
  providerInfo: any;
  selectedProvider: any;
  selectedProviderProcedure: ISelectedProviderProcedures;
  providerCptCodes: Array<IProviderCptCodes>;
  isVitalsRequired: boolean = false;
  ruleList: any = [];
  showProcedureEstimate: boolean = false;
  toggleMapBool: boolean = false;
  showMapCmp: boolean = false;
  helpfulcontent: any;
  eycDetailsTabs: any = {};
  subscription: any;
  driveDirectionSubscription: any;
  assignPcpSubscripton: any;
  mapData: any = [];
  showMapLoader: boolean = true;
  showPlansLoader: boolean = true;
  eycShow: boolean = false;
  showInsAccepted: boolean = false;
  hasVirtualCareHub: boolean;
  showRecognitions = false;
  showAffialitions: boolean = false;
  showScheduleAppointment = false;
  drivingModalTitle: any = { id: 'map-details-modal', title: this.content.mapModal.directions };
  selectedAddress: string;
  locationDdlValues: any = [];
  showLocations: boolean = true;
  showTrayButton: boolean = false;
  showAssignPCP: boolean = false;
  showAddCareTeam: boolean = false;
  showBookAppoinment: boolean = false;
  printPageprocedureCode: string;
  printCptPage: ICptCode;
  isAffiliatedBool: boolean;
  location: any;
  locationObj: any;
  officeService: any;
  visionService: Array<IBaseCode>;
  showOfficeService: boolean = false;
  showVisionService: boolean = false;
  noPrint: boolean = false;
  loadAffiliationsPrint: boolean = false;
  loadPrintPricing: boolean = false;
  showEyc: boolean = false;
  isMemberSecure: boolean = false;
  providerDetails: any;
  locale: any;
  backText: string = '';
  navigateToUrl: AppNavigations;
  isOfficeVisitEligible: boolean = false;
  detailsLoaded: boolean = false;
  plansResponse: IProviderPlansResponse;
  detailsResponse: IDetailsResponse;
  affiliationsResponse: AffiliationResponseV2;
  providerLocationsLoaded: boolean = false;
  @ViewChild('detailsTelehealth')
  detailsTelehealth: ModalComponent;
  emptyCostInfo: boolean = false;
  minnesotaDisclaimer: boolean = false;
  showDetailPharmacyDisclaimer: boolean = false;
  private _providerClickSubscription: Subscription;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  isVirtualCare: boolean;
  isTalkSpaceSSO: boolean;
  private _promises: any[] = [];
  locationsList: Array<ILocationAddress> = [];
  onlineFlag: boolean = false;
  showPcpBadge: boolean = false;
  showFuturePcpBadge: boolean = false;
  showOutOfNetwork: boolean = false;
  outNetwork_code: string = OUTNETWORK;
  selectedProviderAddress: string;
  showPriceTab: boolean;
  adobeTags = OON_ADOBE_TAGS;

  constructor(
    @Inject(AppSession) private _appSession: AppSession,
    _route: ActivatedRoute,
    private _alertHlpr: AlertHelper,
    private _navigationService: NavigationService,
    private _eventHandler: EventHandler,
    _contentHelper: ContentHelper,
    private _appUtility: AppUtility,
    private _providerOrchestrationService: ProviderOrchestrationService,
    private _providerCptCodesService: ProviderCptCodesService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _ref: ChangeDetectorRef,
    private _infoListSvc: InfoListService,
    private _savNavigationService: SAVNavigationService,
    private _assignPcpNavSvc: AssignPcpNavigationService,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _memberPCPHelper: MemberPCPHelper,
    private _dataHelper: DataHelper,
    private _titleCasePipe: TitleCasePipe,
    @Inject('Window')
    private _window: IWindow,
    private _scenarioAlertSvc: ScenarioAlertSvc
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');

    this.initializeComponent();

    this.subscription = this._eventHandler.get(AppEvents[AppEvents.MAP_DATA_LOADED]).subscribe((val) => {
      this.mapData = val;
      this.showMapLoader = false;
    });

    this.driveDirectionSubscription = this._eventHandler.get(AppEvents[AppEvents.SHOW_DRIVE_DIRECTION]).subscribe((val) => {
      this.openMapModal(val);
    });

    this._providerClickSubscription = this._providerDetailsNavigationService.onProviderLinkClick.subscribe((value) => {
      this.initializeComponent();

      this.ngOnInit();
    });
  }

  get getPromises(): unknown[] {
    return this._promises;
  }

  set resetPromises(object: unknown[]) {
    this._promises = object;
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._scenarioAlertSvc.setScenarioAlertList([]);
    this.locationsList = [];
    this._promises = [];
    this._infoListSvc.reset();
    this._infoListSvc.setInfoListDisclaimerValue(true);
    this.isVirtualCare = this._appSession.isSecureState && this._appSession.hasVirtualUrgentCare && this.customizedFeatures.showKHealthFeatures;
    this.isTalkSpaceSSO = this._appSession.isSecureState && this._appSession.hasTalkSpaceSSO;
    this.selectedProvider = this._appSession.selectedProvider;
    this.minnesotaDisclaimer = this.selectedProvider.visibilityRule?.showMinnesotaDisclaimer;
    this.showDetailPharmacyDisclaimer = this.selectedProvider?.visibilityRule?.showDetailPharmacyDisclaimer || false;
    this.selectedAddress = this.selectedProvider.location?.address?.addressId ?? '';
    this._appUtility.scrollToTop();
    // this.showDetailsLoader = true;
    this.eycShow = this._appSession.isEyc;
    this.locale = this._appSession.metaData.locale;
    this.setBackToPageNavigation();
    this.isOfficeVisitEligible = this.checkOfficeVisitCostEligibility();
    if (this.isOfficeVisitEligible || this.isEycEligible()) {
      this.costInfo = this._appSession.selectedProvider.eycCostInfo || {};
      if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.eycSearchParam) {
        if (this._appSession.searchParams.eycSearchParam && this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName) {
          this.costInfo.procedureName = this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
        }

        if (this._appSession.searchParams.eycSearchParam.costDescription) {
          this.costInfo.procedureDefintion = this._appSession.searchParams.eycSearchParam.costDescription;
        }

        if (this._appSession.searchParams.eycSearchParam && this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureCode) {
          this.costInfo.procedureCode = this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureCode;
        }
      }
    }
    this.setDefaultTab();
    this.showEyc = this._appSession.isEyc;
    this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    this.hasVirtualCareHub = this._appSession.isSecureState && this._appSession.hasVirtualCareHubWeb;
  }

  isEycEligible() {
    if (
      CommonUtil.isMemberSecure(this._appSession) &&
      this._appSession &&
      this._appSession.selectedProvider &&
      this._appSession.selectedProvider.eycCostInfo &&
      !this._appSession.selectedProvider.isRecordFromSPFI
    ) {
      return true;
    }
    return false;
  }

  checkOfficeVisitCostEligibility() {
    if (
      CommonUtil.isMemberSecure(this._appSession) &&
      this._appSession &&
      this.showOfficeVisitCost &&
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam === undefined &&
      this._appSession.searchParams.providerTypeCodeList.length > 0 &&
      this._appSession.searchParams.providerTypeCodeList.indexOf(PROVIDER_TYPE.DOCTOR_PROFESSIONAL) !== -1 &&
      !isEmpty(this._appSession.selectedProvider?.pdtKey) &&
      this._appSession.selectedProvider?.costInfo
    ) {
      return true;
    }
    return false;
  }

  /***
   * set the Page Header and Title dynamically based on pages
   */
  setPageHeader() {
    if (this._appSession && this._appSession.isEyc) {
      const pageEycObj = {
        header: this.content.globalHeaderComponent.pageHeader.eycPageTitle,
        title: this.content.globalHeaderComponent.pageTitle.details
      };
      if (this.content.globalHeaderComponent && this.content.globalHeaderComponent.pageHeader && this.content.globalHeaderComponent.eycPageTitle) {
        this._headerEvent.emit(pageEycObj);
      }
    } else {
      const pageFadObj = {
        header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
        title: this.content.globalHeaderComponent.pageTitle.details
      };
      if (this.content.globalHeaderComponent && this.content.globalHeaderComponent.pageHeader && this.content.globalHeaderComponent.pageHeader.fadPageTitle) {
        this._headerEvent.emit(pageFadObj);
      }
    }
  }

  ngAfterViewInit() {
    this.setPageHeader();
  }

  getPrecareLocations(detail: IProviderDetail) {
    const loc: ILocation = {
      latitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.latitude : '',
      longitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.longitude : ''
    };
    const identifier = this._appSession.searchParams.plan && this._appSession.searchParams.plan.identifier ? this._appSession.searchParams.plan.identifier.trim() : '';
    const netwrkList = this._appSession.searchParams.plan.networkList;
    const providerTypeList = [PROVIDER_TYPE.VISION, PROVIDER_TYPE.DENTAL, PROVIDER_TYPE.HOSPITAL, PROVIDER_TYPE.BEHAVIORAL_HEALTH];

    if (
      detail.isRegionalProviderIndicator ||
      detail.isRemoteProviderIndicator ||
      (detail.isOnlineProviderIndicator && this.selectedProvider && providerTypeList.some((x) => this.selectedProvider.providerTypeCodeList?.includes(x)))
    ) {
      this.onlineFlag = true;
    }
    const locationsParams: ILocationRequest = {
      id: this.selectedProvider?.providerIdentifier,
      isOnlineProvider: this.onlineFlag,
      brandCode: this._appSession.metaData.brandCd,
      isShipDirectToDoor: this.selectedProvider?.isShipDirectToDoor,
      distance: this._appSession.searchParams.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
      planStateCode: this._appSession.searchParams.plan.stateCd,
      networks: netwrkList,
      alphaPrefix: CommonUtil.getAlphaPrefix(this._appSession),
      latitude: loc.latitude?.toString(),
      longitude: loc.longitude?.toString(),
      plans: identifier !== '' ? [identifier] : undefined,
      locale: this.locale ?? Locale.ENGLISH,
      specialtyCategories: this._appSession?.searchParams?.specialtySelectNm?.length ? this._appSession?.searchParams?.specialtySelectNm?.filter((x) => x !== '00') : undefined
    };

    locationsParams.addressId = this.selectedProvider?.location && this.selectedProvider?.location.address ? this.selectedProvider?.location.address.addressId : '';

    if (this.selectedProvider?.providerTypeCodeList) {
      locationsParams.typeCodes = this.selectedProvider?.providerTypeCodeList;
    }
    const memberCritria: IMemberCriteria = {};
    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
      memberCritria.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid) {
      memberCritria.contractUid = this._appSession.metaData.appContract.contractUid;
    }
    //support HCID search
    if (this._appSession.searchParams?.identificationNumber && !this._appSession.isPlanUnknown) {
      memberCritria.identificationNumber = this._appSession.searchParams?.identificationNumber;
    }

    if (this._appSession?.searchParams?.stateCd?.code) {
      locationsParams.state = this._appSession.searchParams.stateCd.code;
    }
    if (this._appSession?.searchParams?.countyCode) {
      locationsParams.countyCode = this._appSession.searchParams.countyCode;
    }

    // Add aditional parameters for CVS Pharmacy API.
    if (this.selectedProvider?.providerTypeCodeList && this.selectedProvider?.providerTypeCodeList.length === 1 && this.selectedProvider?.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
      locationsParams.typeCodes = [PROVIDER_TYPE.PHARMACY];
    }
    if (locationsParams && locationsParams.typeCodes?.includes(PROVIDER_TYPE.PHARMACY)) {
      locationsParams.providerName = this.selectedProvider?.providerName;
      locationsParams.pharmacyNumber = this.selectedProvider?.providerIdentifier;
      locationsParams.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;

      const location = this.selectedProvider?.location;
      if (location && location.address && location.address.state) {
        locationsParams.state = location.address.state;
      } else if (this._appSession.searchParams.stateCd && this._appSession.searchParams.stateCd.code) {
        locationsParams.state = this._appSession.searchParams.stateCd.code;
      }

      if (location && location.address && location.address.postalCode) {
        locationsParams.postalCode = location.address.postalCode;
      } else if (this._appSession.searchParams.zip) {
        locationsParams.postalCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
      }

      if (location && location.address && location.address.city) {
        locationsParams.city = location.address.city;
      } else if (this._appSession.searchParams.city) {
        locationsParams.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
      }
    }
    if ((CommonUtil.isMemberSecure(this._appSession) || this._appSession?.searchParams?.identificationNumber) && this._appSession?.isFutureCoverage) {
      memberCritria.isFuturePlan = this._appSession?.isFutureCoverage;
    }

    locationsParams.memberCriteria = memberCritria;
    locationsParams.coverageType = SearchRequestUtil.isOutOfNetwork(this._appSession) ? OUT_OF_NETWORK : undefined;

    if (Array.isArray(this.locationsList) && this.locationsList.length === 0) {
      this._providerOrchestrationService
        .getProviderLocations(locationsParams)
        .then(
          (result: ILocationsResponse) => {
            if (result && Array.isArray(result.locations)) {
              this.locationsList = result.locations;
            }
            this.onLocationsSuccess(result);
            if (this.isMemberEligibleForCptLookUp) {
              this.showProcedureEstimate = true;
            }
            if ((this.isOfficeVisitEligible && this.selectedProvider?.costInfo) || this.isEycEligible()) {
              this.getProviderProcedures();
            }
            Promise.all(this._promises)
              .then(() => {
                if (this.isEycEligible() || this.checkOfficeVisitCostEligibility()) {
                  this.showProcedureEstimate = true;
                  // existing EYC flow => to hide pricing tab for category 'F'
                  if (
                    !this.isCostTransparencyDetailsPage &&
                    (this.selectedProviderProcedure?.providerCosts || []).findIndex(({ procedureCode }) => procedureCode === this.costInfo?.procedureCode) === -1
                  ) {
                    this.showProcedureEstimate = false;
                  }
                }
              })
              .finally(() => {
                this.detailsLoaded = true;
              });
          },
          (error: any) => {
            this.onError(error);
          }
        )
        .finally(() => {
          this.providerLocationsLoaded = true;
        });
    }
  }

  buildLocationDropDown(result: any) {
    let _index = 0;
    let addressText = '';
    let valueIndex = 0;
    for (const location of result) {
      addressText = location.addressOne + ' ' + location.addressTwo;
      valueIndex = this.locationDdlValues.findIndex((x) => {
        return x.label === addressText;
      });
      if (valueIndex <= 0) {
        const objLocation = {
          label: addressText,
          value: location.addressId,
          id: 'cB_Location_' + _index,
          name: 'cB_Location_' + _index,
          trueValue: location.addressId,
          falseValue: '',
          isChecked: false
        };
        this.locationDdlValues.push(objLocation);
        if (location.addressOne === '') {
          this.showLocations = false;
        }
      }
      _index++;
    }
  }
  onLocationsSuccess(result: any) {
    if (result.locations && result.locations.length > 0) {
      this.locationObj = result.locations[0];
      this.buildLocationDropDown(result.locations);
    }
  }

  onError(error: any) {
    throw error;
  }
  setDetailPage(addressId: string, isMapPinClick = false) {
    if (addressId !== '' && this.selectedAddress !== addressId) {
      if (isMapPinClick === false) {
        this.selectedAddress = addressId;
        this.setDefaultTab();
      }
    }
  }

  selectedAddressChange(addressId: string) {
    if (this.selectedProviderAddress && this.selectedProviderAddress !== addressId) {
      this.selectedProviderAddress = addressId;
      this.detailsResponse = undefined;
      this.affiliationsResponse = undefined;
      this.plansResponse = undefined;
    }
  }

  setDefaultTab() {
    this.isAffiliatedBool = this._appSession.selectedProvider.isAffiliated !== undefined && this._appSession.selectedProvider.isAffiliated;

    if (this.groupEl) {
      this.groupEl.selectedIndex = this.isAffiliatedBool ? DETAILS_TAB_VIEW.PRICING_VIEW : DETAILS_TAB_VIEW.PROFILE_VIEW;
    }
  }

  getTabIndexById(tabId) {
    return this.eycDetailsTabs.tabs.findIndex((tab) => {
      return tab.tabId === tabId;
    });
  }
  setMapPinClick($event) {
    if ($event && $event.id) {
      this.setDetailPage($event.id, true);
    }
  }
  removeProcedureTab() {
    this.showProcedureEstimate = false;
    this.eycDetailsTabs.tabs = this.eycDetailsTabs.tabs.filter((tab) => {
      return tab.tabId !== 'tabTile3';
    });
  }

  setDisclaimerLoader(value: any) {
    this.ruleList = value.ruleList;
    this.isVitalsRequired = value.isVitals;
  }

  goToSearchCriteria() {
    if (!this._appSession.integratedSearch && this._appSession.isEyc) {
      this._navigationService.navigateByUrl(AppNavigations.COST_SEARCH_PATH);
    } else {
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
    }
  }

  goToSearchResult() {
    this._appSession.initialSearch = true;
    this._providerDetailsNavigationService.resetSelectedProviders();
    if (this._appSession?.deeplinkParams?.deeplinkUrl === DeeplinkUrl.PROVIDER_DETAILS) {
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    } else {
      this._navigationService.navigateByUrl(this.navigateToUrl);
    }
  }

  setBackToPageNavigation() {
    this.navigateToUrl = this._navigationService.previousRoute;
    switch (this._navigationService.previousRoute) {
      case AppNavigations.CARE_TEAM_PATH:
        this.backText = this.content.summaryComponent.backToCareTeam;
        break;
      case AppNavigations.CARE_CIRCLE_PATH:
        this.backText = this.content.summaryComponent.backToCareTeam;
        break;
      case AppNavigations.SEARCH_RESULTS_PATH:
        this.backText = this.content.summaryComponent.backToResults;
        break;
      case AppNavigations.ASSIGN_PCP_PATH:
        this.backText = this.content.summaryComponent.backToResults;
        this.navigateToUrl = AppNavigations.SEARCH_RESULTS_PATH;
        break;
      case AppNavigations.SEARCH_PROVIDERS_PATH:
        if (
          this._appSession.isCareProvider ||
          (this.selectedProvider &&
            this.selectedProvider.providerNetworkStatus &&
            this.selectedProvider.providerNetworkStatus.statusCode &&
            this.selectedProvider.providerNetworkStatus.statusCode === OUTNETWORK)
        ) {
          this.backText = this.content.summaryComponent.backToFindCare;
        } else {
          this.navigateToUrl = AppNavigations.SEARCH_RESULTS_PATH;
          this.backText = this.content.summaryComponent.backToResults;
        }
        break;
      default:
        this.backText = this._appSession?.deeplinkParams?.adrskey ? this.content.summaryComponent.backToResults : this.content.summaryComponent.backToFindCare;
        this.navigateToUrl = AppNavigations.SEARCH_PROVIDERS_PATH;
        break;
    }
  }

  navigateBack() {
    if (this._providerDetailsNavigationService.showResultsPage) {
      this.goToSearchResult();
    } else {
      this._providerDetailsNavigationService.navigateToPreviousProvider();
    }
  }

  toggleMap() {
    setTimeout(() => {
      this.toggleMapBool = !this.toggleMapBool;
      if (!this.showMapCmp) {
        this.showMapCmp = !this.showMapCmp;
      }
    }, 100);
  }

  openMapModal(provider: any) {
    this.summaryMapModal.show();
    this.drivingDirection.openMapModal(provider);
  }

  closeModal(modal: string) {
    this.noPrint = false;
    if (modal === 'map') {
      this.summaryMapModal.hide();
    } else if (modal === 'telehealth') {
      this.detailsTelehealth.hide();
    } else {
      return;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.driveDirectionSubscription.unsubscribe();
    if (this._appSession.isSpecialtyOfficeVisitCost) {
      this._appSession.searchTerm = undefined;
    }
    if (typeof this._providerClickSubscription !== 'undefined' && this._providerClickSubscription !== null) {
      this._providerClickSubscription.unsubscribe();
    }
    this._infoListSvc.setInfoListDisclaimerValue(false);
  }
  setTabVisibility(value: any) {
    if (!value.showInsuranceAccepted) {
      this.showInsAccepted = false;
      this.eycDetailsTabs.tabs = this.eycDetailsTabs.tabs.filter((tab) => {
        return tab.tabId !== 'tabTile2';
      });
    } else {
      this.showInsAccepted = true;
    }
    this.showAffialitions = value.showAffialition;
    this.showRecognitions = value.showRecognitionsTab;
    this.showScheduleAppointment = value.showScheduleAppointment;
    this.setDefaultTab();
    if (!this.selectedProvider?.visibilityRule) {
      this.selectedProvider.visibilityRule = value;
    }
    if (this.showAffialitions || this.showRecognitions || this.showInsAccepted || this.showProcedureEstimate || this.showOfficeService || this.showVisionService) {
      this.showTabHeaders = true;
    }
  }

  procedureCodeData(procedureCode: string) {
    this.printPageprocedureCode = procedureCode;
  }

  cptCodeData(cptCode: ICptCode) {
    this.printCptPage = cptCode;
  }

  printPage() {
    this.loadPrintPricing = true;
    this.noPrint = false;
    //hiding feedback button and chat-box in print Pdf page by adding no-print className
    if (document.getElementById('kampyleButtonContainer')) {
      document.getElementById('kampyleButtonContainer').setAttribute('class', 'no-print');
    }

    if (document.getElementById('chat-bot-launcher')) {
      document.getElementById('chat-bot-launcher').classList.add('no-print');
    }

    setTimeout(() => {
      window.print();
    }, 300);
  }

  openDomainDisclaimerModal(url: any) {
    this.disclaimerUrl = url;
    this.leavingDisclaimerModal.show();
  }

  scrolltoDisclaimer() {
    const el = document.getElementById('details-position-to-scroll');
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.expandDisclaimer();
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  expandDisclaimer() {
    if (typeof this.disclaimers !== 'undefined' && this.disclaimers !== null) {
      this.disclaimers.showMoreItems();
    }
  }

  addAriaLabelTab() {
    const tabTitle = document.getElementById('pfProviderDetailsTab').getElementsByClassName('viewButton');
    if (tabTitle && tabTitle !== undefined && tabTitle.length > 0) {
      for (let i = 0; i < tabTitle.length; i++) {
        tabTitle[i].setAttribute('aria-label', this.tabCtrl.tabbedView.tabs[i].heading);
      }
    }
  }

  // get provider details from child component - detailsCmp
  setProviderDetails(provider: IProviderDetail) {
    if (typeof provider !== 'undefined' && provider !== null) {
      this.providerDetails = provider;
      this.selectedProvider.firstName = provider.name.firstName;
      this.selectedProvider.lastName = provider.name.lastName;
      this.selectedProvider.nationalProviderIdentifier = provider.nationalProviderIdentifier;
      this.selectedProvider.specialties = provider.specialities;
      this.selectedProvider.performance = provider.performance;
      this.selectedProvider.providerNetworkStatus = provider.providerNetworkStatus;
      this.selectedProvider.providerTier = provider.providerTier || '';
      this.selectedProvider.visualIndicators = provider.visualIndicator;
      this.selectedProvider.isVirtualProvider = provider.isVirtualProvider || false;
      this.selectedProvider.callToAction = provider.callToAction;
      this.selectedProvider.isShipDirectToDoor = provider?.isShipDirectToDoor || false;
      this.selectedProvider.isOnlineProviderIndicator = provider.isOnlineProviderIndicator || false;
      this.isProviderOutOfNetwork = this.selectedProvider.providerNetworkStatus?.statusCode === this.outNetwork_code;
      this.showPriceTab = provider.visibilityRule?.showPricingTab;
      if (provider?.pcp?.identifier) {
        this.selectedProvider.pcpList = provider.pcp.identifier;
      }
      this.showAssignPCPBtn(provider.visibilityRule, this.selectedProvider);
      this.showAddCareTeamBtn(provider.visibilityRule, this.selectedProvider);
      this.showPCPBadge(this.selectedProvider);
      this.showFuturePCPBadge(this.selectedProvider);
      this.getPrecareLocations(provider);
      this.bindScenarioBasedAlert(provider);
      if (this.selectedProvider && !CommonUtil.isValidString(this.selectedProvider.providerName)) {
        this.selectedProvider.providerName = provider?.name?.providerName;
        if (this.selectedProvider.providerName && provider?.titleList?.length > 0) {
          this.selectedProvider.providerName = `${this.selectedProvider.providerName}, ${provider.titleList.join(', ')}`;
        }
      }
    }
  }

  bindScenarioBasedAlert(provider: IProviderDetail) {
    const searchParams: ISearchParameters = {};
    searchParams.providerTypeCodeList = provider.providerTypeCodeList;
    searchParams.taxonomySelectNm = provider.specialities?.map((x) => x.specialtyCode) || [];
    this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, searchParams));
  }

  showAssignPCPBtn(detailVisibility: IRulesResponse, selectedPovider) {
    this.showAssignPCP = false;
    if (
      detailVisibility &&
      detailVisibility.showAssignPCP &&
      this.customizedFeatures.showAssignPCPBtn &&
      this.isAssignPcpEnabled &&
      this._memberPCPHelper.isNotAMemberPCPProvider(selectedPovider) &&
      this._memberPCPHelper.isNotAMemberFuturePCPProvider(selectedPovider)
    ) {
      this.showAssignPCP = true;
    }
  }

  showAddCareTeamBtn(detailVisibility: any, selectedPovider) {
    this.showAddCareTeam = false;
    if (
      this.isAddCareTeamEnabled &&
      detailVisibility &&
      detailVisibility.showAddCareTeam &&
      this.customizedFeatures.showAdddCareTeam &&
      this._memberPCPHelper.isNotAMemberPCPProvider(selectedPovider) &&
      this._memberPCPHelper.isNotAMemberFuturePCPProvider(selectedPovider)
    ) {
      this.showAddCareTeam = true;
    }
  }

  showPCPBadge(selectedPovider) {
    this.showPcpBadge = false;
    if (selectedPovider && !this._memberPCPHelper.isNotAMemberPCPProvider(selectedPovider)) {
      this.showPcpBadge = true;
    }
  }

  showFuturePCPBadge(selectedPovider) {
    this.showFuturePcpBadge = false;
    if (selectedPovider && !this._memberPCPHelper.isNotAMemberFuturePCPProvider(selectedPovider)) {
      this.showFuturePcpBadge = true;
    }
  }

  /**
   * call the side panel for assign pcp
   */
  onAssignPcpClick() {
    this.providerDetails.location = {};
    this.providerDetails.location.address = this.location;
    this.providerDetails.providerName = this.selectedProvider.providerName;

    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(this.providerDetails);
    this.handleAssignPcpNavPromise(navPromise, this.providerDetails, RIGHT);
  }

  onContinueAssignPcp() {
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(this._appSession.selectedProvider, true);
    this.handleAssignPcpNavPromise(navPromise, this._appSession.selectedProvider, RIGHT);
  }

  async handleAssignPcpNavPromise(navPromise: Promise<IPcpNavResponse>, professional: any, direction: string) {
    try {
      const resp = await navPromise;
      //Open the old Assign as PCP Flow
      if (!resp.navigatedToAssignPcpPage) {
        this.assignPcpModal.openSidePanel(direction, professional, true);
      }
    } catch (error) {
      //Handle Errors using the error.code value
      if (error.code === ANP_ERROR_CODE) {
        this.anpSidePanelCmp.openSidePanel();
      } else if (error.code === CA_HMO_DISCLAIMER) {
        this.caHmoPanelCmp.openSidePanel();
      } else {
        //Display other Error messages in UI
        const slideoutMsgContent = this.content.assignPcpCmpContent.slideoutMsgCmpContent;
        let message = this._dataHelper.getValueByKey<string>(error.code, slideoutMsgContent);
        let title: string = slideoutMsgContent['title_' + error.code] ?? slideoutMsgContent.title;
        const mbrName = this._titleCasePipe.transform(this._appSession.getMemberName());
        message = message.replace(DOCTOR_NAME, professional.providerName);
        message = message.replace(TERMINATION_DATE, error.terminationDt ?? '');
        message = message.replace(MEMBER_NAME, mbrName);
        const slideMessage: ISlideoutMessage = {
          title,
          close: slideoutMsgContent.close,
          message
        };
        this.slideoutMsgCmp.showMessage(slideMessage);
      }
      throw error;
    }
  }

  locationResponseData(location: any) {
    this.showOfficeService = false;
    this.showVisionService = false;
    if (location && location.length > 0) {
      this.location = location[0];
      this.selectedProvider.location = {
        address: this.location
      };
      if (location[0].officeService && location[0].officeService.length > 0) {
        this.showOfficeService = true;
        this.officeService = location[0].officeService;
      }
      if (location[0].visionService && location[0].visionService.length > 0) {
        this.showVisionService = true;
        this.visionService = location[0].visionService;
      }
    }
  }

  onScheduleAppointment(data: any) {
    if (data && data.loader) {
      this.showDetailsLoader = true;
    } else {
      this.showDetailsLoader = false;
      if (data && data.apiError) {
        this._alertHlpr.openAlert(this.content.alerts.apiError, 'negative');
      }
    }
  }

  onAssignCare(data: any) {
    if (data && data.loader) {
      this.showDetailsLoader = true;
    } else {
      this.showDetailsLoader = false;
    }
  }

  printDrivingDirection() {
    this.noPrint = true;
    setTimeout(() => {
      window.print();
    }, 300);
  }

  /**
   * costInfo have all the value is zero then remove from procedurecost array list.
   * @param procedureArr
   */
  removeZeroCostProcedures(procedureArr) {
    const nonZeroArr = [];
    procedureArr.forEach((obj) => {
      const checkNonZero = Object.values(obj.costInfo).some((val) => val !== '0');
      if (checkNonZero) {
        nonZeroArr.push(obj);
      }
    });
    return nonZeroArr;
  }
  openTelehealthModal() {
    this.detailsTelehealth.show();
  }

  provDetailsLoaded(details: IDetailsResponse) {
    this.detailsResponse = details;
  }

  provAffiliationsLoaded(affiliations: AffiliationResponseV2) {
    this.affiliationsResponse = affiliations;
  }

  provPlansLoaded(plans: IProviderPlansResponse) {
    this.plansResponse = plans;
  }

  get printEnabled(): boolean {
    return this.detailsLoaded && (!this.showAffialitions || !isEmpty(this.affiliationsResponse)) && (!this.showInsAccepted || !isEmpty(this.plansResponse)) && !isEmpty(this.detailsResponse);
  }

  get showAssignPCPPanel() {
    return this.isMemberSecure || this.customizedFeatures?.showAssignPCPPanel;
  }

  get isCareProviderNavigation() {
    return this._appSession.isCareProvider;
  }

  private initializeComponent() {
    this.eycDetailsTabs = Object.assign({}, this.content.detailsComponent.tab);
    this.locationDdlValues = [];
  }

  private getProviderProcedures() {
    const providerProcedureRequest: IProviderProcedureInfo = {
      providerIdentifier: this.selectedProvider.providerIdentifier || '',
      addressIdentifier: this.selectedAddress || '',
      isFuturePlan: this._appSession.isFutureCoverage,
      locale: this.locale || '',
      pdtKey: this._appSession.selectedProvider.pdtKey,
      isDentalEyc:
        this._appSession.hasDentalEYC &&
        this._appSession.searchParams &&
        this._appSession.searchParams.eycSearchParam &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL,
      contractUid: this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid ? this._appSession.metaData.appContract.contractUid : ''
    };
    this._promises.push(
      this._providerOrchestrationService.getProviderProcedureDetails(providerProcedureRequest).then(
        (result: any) => {
          // checking expected procedure from NCCT to display correct details in Pricing Tab and avoid mismatch cost information between summary and details page
          if (
            this.costInfo &&
            'procedureCode' in this.costInfo &&
            result &&
            result.providerCosts &&
            result.providerCosts.length > 0 &&
            result.providerCosts.findIndex((procedure) => procedure.procedureCode === this.costInfo.procedureCode) !== -1
          ) {
            this.selectedProviderProcedure = result;
            this.selectedProviderProcedure.providerTier = this.selectedProvider.providerTier || '';
            if (this.locationObj) {
              this.selectedProviderProcedure.providerSituation = this.locationObj.providerSituations || '';
            }
          }
        },
        (error: any) => {
          this.onError(error);
        }
      )
    );
  }

  goToServiceAreaValidation(): void {
    this._savNavigationService.navigate();
  }

  get isMemberEligibleForCptLookUp(): boolean {
    return this._appSession.metaData.appContract?.hasCostTransparency && this.isCostTransparencyDetailsPage && !this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
  }

  goToVirtualCare() {
    this._navigationService.navigateByUrl(AppNavigations.VIRTUAL_CARE_PATH);
  }

  goToPublicUrl(url: string) {
    window.open(url, '_blank');
  }

  isValidCtaAction(callToAction: IVirtualProviderConfig) {
    return !isEmpty(callToAction);
  }

  performProviderNavigation(callToAction: IVirtualProviderConfig) {
    if (callToAction?.internalRedirectId === VIRTUAL_CARE_HUB) {
      this.goToVirtualCare();
    } else if (callToAction?.virtualCareUrl) {
      this.goToPublicUrl(callToAction.virtualCareUrl);
    }
  }

  getProviderName(): string {
    let providerName = this.selectedProvider?.networkRegisteredName;
    if (!providerName && this.selectedProvider?.titleList?.length > 0) {
      providerName = `${this.selectedProvider?.providerName}, ${this.selectedProvider?.titleList.join(', ')}`;
    } else if (!providerName) {
      providerName = this.selectedProvider?.providerName;
    }
    return providerName;
  }

  isCallToActionInternal(callToAction: IVirtualProviderConfig) {
    return !isEmpty(callToAction) && callToAction?.internalRedirectId === VIRTUAL_CARE_HUB;
  }

  virtualURlSsoConfigValue(callToAction: IVirtualProviderConfig): IVirtualVisitSSO {
    this.ssoConfig = this.WcsUtility.getVirtualCareUrlSsoConfig(callToAction.ssoKey, true);
    return this.ssoConfig;
  }

  openOONProvidersLearnMore(): void {
    this.oonLearnMoreSlider.openSidePanel();
  }
}
