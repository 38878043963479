import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-last-updated',
  templateUrl: './lastUpdated.html',
  providers: []
})
export class LastUpdatedComponent extends BaseComponent implements OnInit {
  dataModifiedOn = '';
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler
  ) {
    super(_route, _eventHandler, _appSession);
    this.dataModifiedOn = this._appSession?.provDataModifiedOn ?? '';
  }
  ngOnInit(): void {}
}
