import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';
import { LocationComponent } from '../../../../../../../common/components/location/locationCmp';
import { AppExternalCommands, Locale, SEARCH_LOCATION_TYPE } from '../../../../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../../../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../../../../common/interfaces/iEventDetail';
import { LocationResponse } from '../../../../../../../common/models/locationAddress';
import { HighlightPipe } from '../../../../../../../common/pipes/highlightPipe';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { SearchSvc } from '../../../../../../../common/services/searchSvc';
import { AppUtility } from '../../../../../../../common/utilities/appUtil';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../../../common/values/contentHelper';
import { IRulesResponse } from '../../../../../../../fad/rules/interfaces/iRulesResponse';
import { RulesService } from '../../../../../../../fad/rules/services/RulesSvc';
import { ITranslation } from '../../../../../../../fad/search-providers/interfaces/iAdaListResp';
import {
  IFtsAssistanceSelection,
  IFtsClientAssistanceResponse,
  IFtsClientRequest,
  IFtsLocationDetails,
  IFtsSuggestion,
  IFtsSuggestionResponse,
  IMlAssistanceItem
} from '../../../../../../../fad/search-providers/interfaces/iFtsClientInteraction';
import { IMap } from '../../../../../../../fad/search-providers/interfaces/iMap';
import { ISearchParameters } from '../../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { FtsSvc } from '../../../../../../../fad/search-providers/services/ftsSvc';
import { ProviderUtilityService } from '../../../../../../../fad/search-providers/services/providerUtilitySvc';
import { SAVNavigationService } from '../../../../../../../fad/search-providers/services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from '../../../../../../../fad/search-providers/values/providerSearchConstants';
import { AvailableSuggestionService } from '../../../../../../../fad/search-results/services/availableSuggestionSvc';
import { CommonUtil } from '../../../../../../../fad/utilities/commonUtil';
import { ActionService } from '../../../../../services/actionSvc';
import { BaseComponent } from '../../../../core/baseCmp';
import { GenericModalService } from '../../../modal/services/genericModalSvc';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fc-free-text-search-cmp',
  templateUrl: './freeTextSearchCmp.html',
  providers: []
})
export class FreeTextSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Input()
  showChangeLocation: boolean;
  @ViewChild('openAddressSelection')
  locationSidePanel: TemplateRef<HTMLElement>;
  @Output()
  reloadSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  openSwitchLocation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  loadCahmoPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('searchTermIdentifier')
  searchTermInput: ElementRef;
  @ViewChild('location')
  locationCmp: LocationComponent;
  @ViewChild('ftsMoreExamples')
  ftsMoreExamplesSidePanel: TemplateRef<HTMLElement>;
  @ViewChild('dialogWidth') parentElement: ElementRef;
  adjustedWidth: string;
  previous_searchParams: ISearchParameters;
  locale: string;
  searchWatermark = '';
  searchCriteria: ISearchParameters;
  showLookUp = false;
  suggestionError = false;
  suggestionFailure = false;
  firstSuggestion = false;
  noSearchCriteria = false;
  searchTerm = '';
  previousSearchTerm = '';
  ftsPhrase = '';
  assistanceSelection: Array<IFtsAssistanceSelection>;
  ftsSuggestionLookUpList: IFtsSuggestion<ITranslation>[] = [];
  ftsSuggestionLookUpTopList: IFtsSuggestion<ITranslation>[] = [];
  ftsAssistanceList: IMlAssistanceItem[]; //Assistance phrase options to help complete the search texts - returned by AssistanceAPI
  availableSuggestionList: IFtsSuggestion<ITranslation>[];
  locationDetails: IFtsLocationDetails;
  inputMinLength = environment.features.lookUp.inputLength;
  inputMaxLength = environment.features.lookUp.inputMaxLength;
  executionCount: number = 0;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;

  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _freeTxtSearchSvc: FtsSvc,
    private _highlightPipe: HighlightPipe,
    private _ruleService: RulesService,
    private _elementRef: ElementRef,
    private _sidePanel: SidePanel,
    private _savNavigationService: SAVNavigationService,
    private _availableSuggestionSvc: AvailableSuggestionService,
    private _providerUtilityService: ProviderUtilityService,
    private _appUtility: AppUtility,
    private searchService: SearchSvc,
    private _genericModalService: GenericModalService,
    private _actionService: ActionService
  ) {
    super(_route, _eventHandler, _appSession);
  }
  ngOnDestroy(): void {
    //When switching from Free Text Search to Regular search
    if (!this.isFtsFeatureActive) {
      this.clearLookUpList();
      this.resetSearchCriteria();
      this.resetAppSessionParams();
    }
    this.resetInitialLocation();
  }

  resetInitialLocation(): void {
    this.searchCriteria = {
      ...this.searchCriteria,
      ...this._appSession.location
    };
    this.searchParams = { ...this.searchParams, ...this._appSession.location };
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.locale = this._appSession?.metaData?.locale?.toString() ?? Locale.ENGLISH;

    if (this._appSession) {
      this._appSession.integratedSearch = true;
      this.searchTerm = this._appSession.searchTerm ?? '';
      if (!this._appSession.location.coordinates) {
        this._providerUtilityService.getGeoLocation(this._appSession.location.zip).then((result: LocationResponse[]) => {
          if (result && Object.keys(result).length) {
            const results = this._appUtility.buildLocationResponse(result);
            this._appSession.location.coordinates = {
              latitude: results[Object.keys(results)[0]].lat,
              longitude: results[Object.keys(results)[0]].long
            } as IMap;
          }
        });
      }
      this.setBrowserLocation();
    }
    this.searchWatermark = this.content?.common?.pageHeader?.freeTextSearch?.searchTextHint;
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_SEARCH_BAR_LOAD];
    eventDetail.message = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.MSG;
    eventDetail.target = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
    this.resetAssistanceSelection();
    // Set the focus on the search input field
    document.getElementById('fts-txt-search-field').focus();
  }

  /*
   * Lifecycle hook that is called after Angular has fully initialized a component's view.
   */
  ngAfterViewInit() {
    const parentWidth = this.parentElement.nativeElement.offsetWidth;

    // Subtract 10px and format as a string with 'px'
    this.adjustedWidth = parentWidth - 2 + 'px';
    fromEvent(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        // Time in milliseconds between key events
        debounceTime(200),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // get value
        map((event) => {
          return event;
        })
      )
      .subscribe((event: KeyboardEvent) => {
        this.onKeyUpEvent(event);
      });
  }

  /*
   * Method to handle the focus event on the search input field
   */
  onFocus() {
    if (this.searchTerm === '') {
      this.firstSuggestion = true;
      this.showLookUp = true;
    }
  }

  /*
   * Method to handle the changes in the search parameters
   */
  ngOnChanges(args: any) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.setSearchParams(args['searchParams'].currentValue);
    }
  }

  setSearchParams(params: ISearchParameters) {
    this.searchCriteria = params;
  }
  get showLoading() {
    return this.hasLookUpItems();
  }
  initiateSearchTermActions(_searchTerm: string) {
    if (this.previousSearchTerm === _searchTerm) {
      return;
    }
    this.previousSearchTerm = _searchTerm;
    this.clearLookUpList();
    this.suggestionError = false;
    this.suggestionFailure = false;
    this.noSearchCriteria = false;
    this.firstSuggestion = false;
    if (_searchTerm.length > this.inputMinLength) {
      if (this.isFtsFeatureActive) {
        const assistanceRequest = this.bindFtsAssistanceRequest(_searchTerm);
        this.getFreeTxtSearchAssistance(assistanceRequest);
      }
    } else {
      this.resetAssistanceSelection();
    }
  }

  onKeyUpEvent(event: KeyboardEvent) {
    this.firstSuggestion = false;
    const _searchTerm = this.searchTerm;
    switch (event.key) {
      case 'Enter':
        this.initiateSearch();
        break;
      case 'backspace':
      case 'delete':
        this.resetAssistanceSelection();
        break;
      case 'ArrowDown':
        if (this.ftsAssistanceList?.length) {
          const listItems = document.querySelectorAll('.look-up-item-border div');
          (listItems?.[0] as HTMLElement).focus();
        }
        break;
      default:
        this.initiateSearchTermActions(_searchTerm);
    }
  }

  initiateSearch(): void {
    this.clearLookUpList();
    //TODO: remove/update the resetAssistanceSelection after suggestion API results are fully stable
    this.resetAssistanceSelection();
    this.hideLookup();
    if (this.isLocationValid()) {
      this.bindSuggestions(this.searchTerm);
    }
  }

  /* Method to handle the keydown event of tab and arrowdown */
  onKeydownFromSearchLink(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        const addressLink = document.getElementById('address-selection-link');
        addressLink.focus();
        event.preventDefault();
        this.hideLookup();
        break;
      case 'ArrowDown':
        if (this.ftsAssistanceList.length) {
          const listItems = document.querySelectorAll('.look-up-item-border div');
          (listItems?.[0] as HTMLElement).focus();
        }
        break;
    }
  }
  /* Method to handle the keydown event */
  onKeydownThroughAssistance(event: KeyboardEvent, assistance: IMlAssistanceItem, index: number) {
    const listItems = document.querySelectorAll('.look-up-item-border div');
    const inputField = document.getElementById('fts-txt-search-field');
    switch (event.key) {
      case 'Enter':
      case ' ':
        this.onAssistanceSelect(assistance);
        event.preventDefault();
        break;
      case 'ArrowUp':
        (listItems[(index - 1 + listItems.length) % listItems.length] as HTMLElement).focus();
        event.preventDefault();
        break;
      case 'ArrowDown':
        (listItems[(index + 1) % listItems.length] as HTMLElement).focus();
        event.preventDefault();
        break;
      case 'Tab':
        inputField.focus();
        event.preventDefault();
        break;
    }
  }

  hideLookup(): void {
    this.showLookUp = false;
  }

  /**
   * Binds suggestions based on the provided search term.
   * @param {string} searchTerm - The term to search for.
   */
  bindSuggestions(searchTerm: string): void {
    this._appSession.searchTerm = searchTerm;
    // Create a suggestion request based on the search term
    const suggestionRequest = this.bindFtsAssistanceRequest(searchTerm);
    // Call the getSuggestion method from the _freeTxtSearchSvc service
    this._freeTxtSearchSvc.getSuggestion(suggestionRequest).then(
      (result: IFtsSuggestionResponse) => {
        if (!result?.locationDetails) {
          this.showLookUp = true;
          this.suggestionFailure = true;
          return;
        }

        const firstCriteria = result.suggestionList?.[0]?.criteria;
        const hasValidSearchCriteria =
          firstCriteria && (firstCriteria.specialtyCategoryList?.length || firstCriteria.taxonomyList?.length || firstCriteria.providerName !== '' || firstCriteria.npi !== '');
        if (hasValidSearchCriteria) {
          this._availableSuggestionSvc.setSuggestion(result.suggestionList);
          this.searchForSuggestion(result.suggestionList[0], result.locationDetails);
        } else {
          this.showLookUp = true;
          this.noSearchCriteria = true;
        }
      },
      (error: unknown) => {
        try {
          // If there's an error, call the onCategoryError method with the error
          this.onCategoryError('suggestionError', error);
          this.showLookUp = true;
          this.suggestionError = true;
        } catch (e) {}
      }
    );
  }

  /*
   * Method to reset the search criteria
   */
  resetSearchCriteria() {
    const _providerTypeCode = 'P';
    const _providerTypeCodeList = this.searchCriteria.providerTypeCodeList;
    const _eycSearchParam = this.searchCriteria.eycSearchParam;
    this.clearSearchCriteria();
    this.searchCriteria.typeSelectNm = _providerTypeCode;
    this.searchCriteria.providerTypeCodeList = _providerTypeCodeList;
    this.searchCriteria.eycSearchParam = _eycSearchParam;

    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }
  }

  getErrorMessage(): string | void {
    if (this.suggestionFailure) {
      return this.content?.common?.pageHeader?.freeTextSearch?.suggestionFailure;
    }
    if (this.noSearchCriteria) {
      return this.content?.common?.pageHeader?.freeTextSearch?.noSuggestionCriteriaFound;
    }
  }

  resetAppSessionParams(eyc: boolean = false) {
    this._appSession.pageNumber = undefined;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
  }

  //Method to handle summary page navigation and provider summary page update event.
  reloadProviderSummary() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (
      this._appSession &&
      _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH &&
      this._appSession.feature &&
      this._appSession.feature.showBCP &&
      this._appSession.feature.showPreFilterBCP &&
      this._appSession.searchParams
    ) {
      this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
    }
    if (this._contentHelper.isSearchResultRoute(_routeUrl) && this._appSession.isCaHmoDmhc && !this._appSession.serviceAreaValidated) {
      this._savNavigationService.navigate();
    } else if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.reloadSearch.emit(true);
    } else {
      this._savNavigationService.navigate();
    }
    this._actionService.setSearchClick(true);
  }

  /*
   * Method to handle the free text search
   */
  onDataChanged(data: string) {
    const _searchTerm = data.trim();
    if (this._appSession) {
      this._appSession.searchTerm = _searchTerm;
    }
    this._appSession.isSearchTerm = true;
    const _routeUrl: string = '/fc-result';
    if (_searchTerm.length === 0 && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.resetSearchCriteria();
      this._appSession.searchTerm = this.searchTerm;
      this._appSession.searchParams = this.searchCriteria;
      this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this._appSession.isSearchTerm = false;
    }
  }

  //Method to highlight the search term in the assistance list
  highlightText(value: string) {
    return this._highlightPipe.transform(value, this.searchTerm, 'Multi-Word-Match');
  }

  /*
   * Method to handle the error in the free text search
   */
  onCategoryError(type: string, error: unknown) {
    throw error;
  }

  /*
   * Method to clear the look up list
   */
  clearLookUpList() {
    this.hideLookup();
    this.ftsAssistanceList = [];
    this.ftsSuggestionLookUpList = [];
    this.ftsSuggestionLookUpTopList = [];
  }

  /*
   * Method to clear the search criteria
   */
  clearSearchCriteria() {
    let _searchCriteria: ISearchParameters;
    _searchCriteria = {
      ...this._appSession.location,
      ...this.searchCriteria,
      typeOfCareNm: 'medical',
      stateDdlValues: this.searchCriteria.stateDdlValues,
      planCategoryDdlValues: this.searchCriteria.planCategoryDdlValues,
      planDdlValues: this.searchCriteria.planDdlValues,
      coverageTypeCode: this.searchCriteria.coverageTypeCode,
      typeSelectNm: '',
      specialtySelectNm: [],
      taxonomySelectNm: [],
      providerTypeCodeList: [],
      blueDistinctionCenter: [],
      categoryDescription: {},
      visionSrvcAvailableList: [],
      provNm: '',
      aoeSelectNm: [],
      ofcSrvcSelectNm: [],
      nationalProviderIdentifier: '',
      npi: '',
      acceptingNewPatients: undefined,
      languages: [],
      genderCodeList: [],
      includePrimeGroupSearch: true,
      eycPrefix: this._appSession?.searchParams?.eycPrefix,
      isPrefixStateEnabledForProcSearch: this._appSession?.searchParams?.isPrefixStateEnabledForProcSearch
    };

    if (this.searchCriteria.identificationNumber) {
      _searchCriteria.identificationNumber = this.searchCriteria.identificationNumber;
    }

    if (this._appSession.deeplinkParams?.pcponly?.toLocaleLowerCase() === 'y') {
      _searchCriteria.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }

    this.searchCriteria = _searchCriteria;
    this._appSession.isSpecialitySearch = false;
    this._appSession.isAoeSearch = false;
    this._appSession.isOfcSrvcSearch = false;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.isEyc = false;
  }

  isLocationValid() {
    return this._appSession.location.coordinates && this._appSession.location.coordinates.latitude !== '' && this._appSession.location.coordinates.longitude !== '';
  }

  //Method to reload the provider summary page based on the search criteria.
  loadProviderSummary() {
    if (this.searchTerm) {
      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
        (rules: IRulesResponse) => {
          const isActionBlockedByModal = this._genericModalService.isActionBlockedByModal(rules);
          if (!isActionBlockedByModal) {
            this.invokeActions(rules);
          }
        },
        (error: Error) => {
          try {
            this.onCategoryError('RULE', error);
          } catch (e) {}
        }
      );
    }
  }

  resetforHPN() {
    this.searchTerm = this._appSession.searchTerm;
  }

  get noResultsText() {
    return this.content.common.pageHeader.searchCriteria.labels.noResultsMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  // Logic to display defaultSearchText info. msg in search bar
  get defaultSearchText() {
    return this.content.common.pageHeader.searchCriteria.labels.defaultSearchMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  hasLookUpItems() {
    if (this.firstSuggestion) return false;
    else return this.ftsAssistanceList?.length || this.ftsSuggestionLookUpList?.length;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (
      typeof this._elementRef.nativeElement !== 'undefined' &&
      this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' &&
      event !== null &&
      typeof event.target !== 'undefined' &&
      event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      event.target.className !== 'search-bar-container' &&
      !this._appSession.isCaHmoDmhc
    ) {
      this.executionCount++;
      if (event.target.className !== 'btn btn-primary' && !this.isLocationValid()) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      } else {
        this.hideLookup();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target && event.target.screen && event.target.screen.availWidth) {
      //Handle search text box watermark for mobile screen.
      if (event.target.screen.availWidth < 900) {
        this.searchWatermark = this.content.common.pageHeader.searchCriteria.labels.search;
      }
    }
  }

  private invokeActions(rules: IRulesResponse) {
    if (rules) {
      this.resetAppSessionParams();
      this._appSession.feature = rules;
      this.reloadProviderSummary();
    }
  }

  isCaHmoDmhc() {
    return this._appSession.isCaHmoDmhc;
  }

  loadCaHmo() {
    this.loadCahmoPage.emit(true);
  }

  //Method to bind the free text search assistance request
  bindFtsAssistanceRequest(_searchTerm: string): IFtsClientRequest {
    return {
      text: encodeURI(_searchTerm),
      cursorPosition: this.trackCursor(),
      memberCoordinates: {
        latitude: this._appSession.location.coordinates?.latitude?.toString(),
        longitude: this._appSession.location.coordinates?.longitude?.toString()
      },
      clientCoordinates: this._appSession.clientCoordinates,
      distance: this._appSession.searchParams?.distanceNm?.toString(),
      contractUid: this._appSession.metaData?.appContract?.contractUid,
      coverageUid: this._appSession.coverageUid,
      mbrUid: this._appSession.metaData?.appContract?.mbrUid,
      assistanceSelection: this.assistanceSelection
    };
  }

  //Method to get the free text search assistance
  getFreeTxtSearchAssistance(assistanceRequest: IFtsClientRequest): void {
    this._freeTxtSearchSvc.getAssistance(assistanceRequest).then(
      (result: IFtsClientAssistanceResponse) => {
        if (result) {
          this.ftsPhrase = result.phrase;
          if (result.assistanceList?.length > 0) {
            this.ftsAssistanceList = result.assistanceList;
          } else {
            this.ftsAssistanceList = [];
          }
          if (result.suggestionList?.length > 0) {
            this.ftsSuggestionLookUpList = result.suggestionList;
            this.ftsSuggestionLookUpTopList = result.suggestionList.slice(0, environment.features.lookUp.minCount);
          } else {
            this.ftsSuggestionLookUpList = [];
            this.ftsSuggestionLookUpTopList = [];
          }

          if (this.hasLookUpItems()) {
            // this.showloading1 = true;
            this.showLookUp = true;
          } else {
            this.hideLookup();
          }
        }
      },
      (error: Error) => {
        try {
          this.onCategoryError('getAssistance', error); ///assistanceAPI error
        } catch (e) {}
      }
    );
  }

  resetAssistanceSelection() {
    this.assistanceSelection = [];
  }

  addAssistanceSelection(assistance: IMlAssistanceItem) {
    if (assistance) {
      const item = { [assistance.type]: assistance.text };
      this.assistanceSelection.unshift(item);
      this.assistanceSelection.splice(1, 1);
    }
  }

  searchForSuggestion(suggestion: IFtsSuggestion<ITranslation>, locationDetails: IFtsLocationDetails = {}) {
    this.clearLookUpList();
    this.clearSearchCriteria();

    if (suggestion.criteria) {
      if (locationDetails) {
        this.searchCriteria.zip = this.searchCriteria.city = this.searchCriteria.countyCode = '';
        this.searchCriteria.coordinates = {
          latitude: locationDetails.latitude,
          longitude: locationDetails.longitude
        };
        this.searchCriteria.stateCd = {
          code: locationDetails.stateCode
        };
        switch (locationDetails.locationType) {
          case SEARCH_LOCATION_TYPE.CITY:
            this.searchCriteria.city = locationDetails.city;
            this.searchCriteria.zip = locationDetails.displayName;
            break;
          case SEARCH_LOCATION_TYPE.COUNTY:
            this.searchCriteria.countyCode = locationDetails.countyCode;
            this.searchCriteria.city = locationDetails.countyName?.toLowerCase().includes('county') ? locationDetails.countyName : locationDetails.countyName + ' County';
            this.searchCriteria.zip = locationDetails.stateCode ? this.searchCriteria.city + ', ' + locationDetails.stateCode : this.searchCriteria.city;
            this.searchCriteria.coordinates = {
              latitude: '0',
              longitude: '0'
            };
            break;
          default: //when locationType is ZIP, DEFAULT
            this.searchCriteria.zip = locationDetails.zipCode;
        }
      } else {
        this.searchCriteria = {
          ...this.searchCriteria,
          ...this._appSession.location
        };
      }
      // assign distance
      this.searchCriteria.distanceNm = locationDetails ? locationDetails.distance : PROVIDER_SEARCH_DISTANCE;

      //pharmacy search
      if (suggestion.criteria.specialtyCategoryList?.[0]?.code === '201' && this._appSession.hasRxCvsIndicator) {
        //TODO remove this after MLOps attains the capability to identify Pharmacy search
        this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY;
        this.searchCriteria.typeSelectNm = PROVIDER_TYPE.PHARMACY;
        this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
        this.searchCriteria.providerTypeCodeList = [PROVIDER_TYPE.PHARMACY];
      } else {
        //specialty search
        this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      }
      // set ableToServePcp, acceptingNewPatients, language list & gender list in search criteria
      this.searchCriteria.ableToServePcp = suggestion.criteria.ableToServeAsPcp;
      this.searchCriteria.acceptingNewPatients = suggestion.criteria.acceptsNewPatient;
      this.searchCriteria.languages = suggestion.criteria.languageList?.length > 0 ? suggestion.criteria.languageList?.map((language) => language.code) : undefined;
      this.searchCriteria.genderCodeList = suggestion.criteria.genderList?.length > 0 ? suggestion.criteria.genderList : undefined;

      // define specialtyCategory list & taxonomy list in search criteria
      this.searchCriteria.specialtySelectNm = suggestion.criteria.specialtyCategoryList?.map((category) => category.code);
      this.searchCriteria.taxonomySelectNm = (suggestion.criteria.taxonomyList || []).map((category) => category.code);
      // define provider name, npi  in search criteria
      this.searchCriteria.provNm = suggestion.criteria.providerName;
      this.searchCriteria.npi = suggestion.criteria.npi;
      this.searchCriteria.nationalProviderIdentifier = suggestion.criteria.npi;
      this.loadSpecialtyProvider();
    }
  }

  loadSpecialtyProvider() {
    this._appSession.isSpecialitySearch = true;

    this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  //When user selects an assistance word from the free text search lookup
  onAssistanceSelect(assistance: IMlAssistanceItem) {
    this.clearLookUpList();
    if (this.searchTerm && this.ftsPhrase) {
      const index = this.searchTerm.toLowerCase().lastIndexOf(this.ftsPhrase.toLowerCase());
      if (index !== -1) {
        this.searchTerm = this.searchTerm.slice(0, index) + assistance.text + this.searchTerm.slice(index + this.ftsPhrase.length);
      }
    }
    this.addAssistanceSelection(assistance);
    document.getElementById('fts-txt-search-field').focus();
  }

  openAddressSelectionPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.locationSidePanel);
  }

  onClose() {
    if (!this.isLocationValid()) {
      this.searchParams = this.previous_searchParams;
      this._appSession.searchParams = this.searchParams;
    } else {
      this.sidePanelRef.close();
      this.loadProviderSummary();
    }
  }

  //Method to open the more examples side panel
  openFtsMoreExamplesPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.ftsMoreExamplesSidePanel);
  }

  trackCursor() {
    return this.searchTermInput.nativeElement.selectionStart;
  }

  //Method to get the current geo position
  async setBrowserLocation() {
    if (!this._appSession.clientCoordinates && navigator.geolocation) {
      try {
        const userPosition = await this.getCurrentGeoPosition();
        if (userPosition?.coords) {
          this._appSession.clientCoordinates = {
            latitude: userPosition.coords.latitude.toString(),
            longitude: userPosition.coords.longitude.toString()
          };
        }
      } catch (error) {
        console.error('Error getting user location', error);
      }
    }
  }
}
