import { IClinic } from './../../fad/search-results/interfaces/iSummaryResp';
export interface IMessage {
  contentId?: string;
  content?: string;
  contentAriaLabelId?: string;
  type?: string;
  focus?: boolean;
  category?: MsgCategory;
  clinicName?: string;
  clinic?: IClinic;
  value?: string;
}

export enum MsgCategory {
  DEFAULT = 'DEFAULT',
  ONSITE_CLINICS = 'ONSITE_CLINICS'
}

export interface IAlertMessage {
  id: string;
  displayText: string;
  orderSequence?: string;
  ariaLabel?: string;
  isAnchorText?: boolean;
  navigationUrl?: string;
  inAppNavigation?: boolean;
  linkLabel?: string;
  mobileInAppNavigationUrl?: string;
  targetAttribute?: string;
  linkDataAnalyticsTag?: string;
  mobileLinkDataAnalyticsTag?: string;
  externalIcon?: boolean;
}

export interface IVirtualVideoVisitSSO {
  errorMessage: string;
  iconAfterClasses: string;
  iconBeforeClasses: string;
  linkClasses: string;
  linkText: string;
  ssoErrorClass: string;
  ssoErrorPageHeader: string;
  ssoErrorRedirectUrl: string;
  ssoErrorType: string;
  ssoSubType: string;
  ssoType: string;
  analyticsText: string;
}

export interface IVirtualVisitSSO extends IVirtualVideoVisitSSO {
  careType?: string;
  ssoKey?: string;
  imageSource?: string;
}

export interface IGenericModalTemplate {
  header?: string,
  message: string,
  close?: string
  closeAriaLabel?: string
}
