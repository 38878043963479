import { PageAlertType } from '../../constants/common';

//TODO: UPDATE THE ALERT TYPE CONFIG CORRECTLY
export const PAGE_ALERT_TYPE = {
  PA_COVID_FINDER_ALERT: PageAlertType.INFORMATION,
  PA_32BJ_MEMBER_ALERT: PageAlertType.INFORMATION,
  PA_API_ERROR: PageAlertType.NEGATIVE,
  PA_AVESIS: PageAlertType.INFORMATION,
  PA_BEHAVIORAL: PageAlertType.INFORMATION,
  PA_BEHAVIORAL_ALERT: PageAlertType.INFORMATION,
  PA_BHSimplyMPL: PageAlertType.INFORMATION,
  PA_CPT: PageAlertType.INFORMATION,
  PA_CPT_PD_BACK_PROVIDER: PageAlertType.INFORMATION,
  PA_DELTA: PageAlertType.INFORMATION,
  PA_DENTAQUEST: PageAlertType.INFORMATION,
  PA_DME_ALERT: PageAlertType.INFORMATION,
  PA_DMESimplyMPL: PageAlertType.INFORMATION,
  PA_DNTAQST_FWDHLTH: PageAlertType.INFORMATION,
  PA_EMORY_ALERT: PageAlertType.INFORMATION,
  PA_EMPTY_REULTS_RESULT_PAGE: PageAlertType.INFORMATION,
  PA_EYEQUEST: PageAlertType.INFORMATION,
  PA_EYEMED: PageAlertType.INFORMATION,
  PA_GA_ALERT: PageAlertType.INFORMATION,
  PA_GA_TERMINATION_PROVIDERS: PageAlertType.INFORMATION,
  PA_GreenMedicalBH: PageAlertType.INFORMATION,
  PA_HCS: PageAlertType.INFORMATION,
  PA_HEALTHY_BLUE_EYEMED: PageAlertType.INFORMATION,
  PA_HomeHealthSimplyMPL: PageAlertType.INFORMATION,
  PA_HOME_HEALTH_CARE: PageAlertType.INFORMATION,
  PA_INCREASED_RADIUS_ALERT: PageAlertType.INFORMATION,
  PA_KYPORTAL: PageAlertType.INFORMATION,
  PA_LABS_ALERT: PageAlertType.INFORMATION,
  PA_LCS: PageAlertType.INFORMATION,
  PA_LIBERTY: PageAlertType.INFORMATION,
  PA_MAGELLAN_PHARMACY: PageAlertType.INFORMATION,
  PA_MARCH: PageAlertType.INFORMATION,
  PA_MD_DENTAL: PageAlertType.INFORMATION,
  PA_MEMBER_32BJ_ALERT: PageAlertType.INFORMATION,
  PA_MPLVISION: PageAlertType.INFORMATION,
  PA_MSK_WINFERTILITY: PageAlertType.INFORMATION,
  PA_MSSP_ALERT: PageAlertType.INFORMATION,
  PA_NO_COST: PageAlertType.INFORMATION,
  PA_PLAN_NOT_COVER: PageAlertType.INFORMATION,
  PA_BENEFIT_DENIED: PageAlertType.INFORMATION,
  PA_NO_OO_RESULT_PAGE: PageAlertType.INFORMATION,
  PA_NON_EMG_SVC: PageAlertType.INFORMATION,
  PA_NPN_PDF: PageAlertType.INFORMATION,
  PA_OHDENTAQUEST: PageAlertType.INFORMATION,
  PA_OHEYEMED: PageAlertType.INFORMATION,
  PA_OONsearchNoResultSummaryMsg: PageAlertType.INFORMATION,
  PA_PLAN_NOT_COVERED: PageAlertType.INFORMATION,
  PA_PROMOTED_PROCEDURES_ALERT: PageAlertType.INFORMATION,
  PA_PUBLICPHARMACY: PageAlertType.INFORMATION,
  PA_REFINE_SEARCH: PageAlertType.INFORMATION,
  PA_SKYGEN: PageAlertType.INFORMATION,
  PA_SPCLTY_PHMCY_ALERT: PageAlertType.INFORMATION,
  PA_SUPERIOR: PageAlertType.INFORMATION,
  PA_SUPPRESSED_MEDSUPP: PageAlertType.INFORMATION,
  PA_TENNCARE_PHARMACY: PageAlertType.INFORMATION,
  PA_TEXCHIP: PageAlertType.INFORMATION,
  PA_TEXCHIP_PERINATAL: PageAlertType.INFORMATION,
  PA_TEXSTAR: PageAlertType.INFORMATION,
  PA_TN_DENTAQUEST: PageAlertType.INFORMATION,
  PA_TN_LIBERTY: PageAlertType.INFORMATION,
  PA_UNIMASS_SECURE_EYC_ALERT: PageAlertType.INFORMATION,
  PA_VSP: PageAlertType.INFORMATION,
  PA_WLP_BH_ALERT: PageAlertType.INFORMATION,
  PA_noResultDefaultMsgNew: PageAlertType.INFORMATION,
  // DISCLAIMERS
  PA_D136: PageAlertType.INFORMATION,
  PA_D254: PageAlertType.INFORMATION,
  PA_D262: PageAlertType.INFORMATION,
  PA_D264: PageAlertType.INFORMATION,
  PA_D268: PageAlertType.INFORMATION,
  PA_D284: PageAlertType.INFORMATION,
  PA_D37: PageAlertType.INFORMATION
};

export const ALERT_CUSTOMER_SUPPORT_BRAND_URL_CONFIG = {
  PA_BEHAVIORAL_ALERT: {
    en_US: {
      ABC: 'https://www.anthem.com/ca/contact-us/',
      ABCBS: 'https://www.anthem.com/contact-us/',
      EBCBS: 'https://www.empireblue.com/contact-us/',
      UNICARE: 'https://www.unicare.com/contact-us/',
      AMV: 'https://www.amerigroup.com/contact-us/'
    },
    es_US: {
      ABC: 'https://espanol.anthem.com/ca/contact-us/',
      ABCBS: 'https://espanol.anthem.com/contact-us/',
      EBCBS: 'https://espanol.empireblue.com/contact-us/',
      UNICARE: 'https://www.unicare.com/contact-us/'
    }
  },
  PA_UNIMASS_SECURE_EYC_ALERT: {
    en_US: {
      UNIMASS: 'https://www.unicaremass.com/contact-us/'
    },
    es_US: {
      UNIMASS: 'https://www.unicaremass.com/contact-us/'
    }
  },
  PA_WLP_BH_ALERT: {
    en_US: {
      WLP: 'https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic/'
    },
    es_US: {
      WLP: 'https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic/'
    }
  }
};
