import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../../../../environments/api-name-list';
import { HttpMethod } from '../../../../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../../../../common/services/baseService';
import { HttpClientService } from '../../../../../../common/services/httpClientService';
import { AppUtility } from '../../../../../../common/utilities/appUtil';
import { AppSession } from '../../../../../../common/values/appSession';
import { IPageAlertRequest, IPageAlertResponse } from '../../../../../../findcare/common/interfaces/iPageAlert';

@Injectable({
  providedIn: 'root'
})
export class PageAlertUtility extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getPageAlerts(request: IPageAlertRequest): Promise<IPageAlertResponse> {
    const url = this.appSession.isSecureState ? this.baseURL + apiNameList.restApi.securePageAlert : this.baseURL + apiNameList.restApi.publicPageAlert;
    const headers = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: url,
      data: request,
      headers: headers
    });
  }
}
