export enum FindCareFeature {
  FC_BROWSER_BACK_BUTTON = 'FC_BROWSER_BACK_BUTTON',
  FC_CARE_CIRCLE_PCP_ALL = 'FC_CARE_CIRCLE_PCP_ALL',
  FC_CARE_CIRCLE_PCP_BADGE = 'FC_CARE_CIRCLE_PCP_BADGE',
  FC_CARE_TEAM_ADD = 'FC_CARE_TEAM_ADD',
  FC_CHN_LOCALE = 'FC_CHN_LOCALE',
  FC_CS90_BENEFIT_VISIT = 'FC_CS90_BENEFIT_VISIT',
  FC_DENIAL_OF_SERVICE = 'FC_DENIAL_OF_SERVICE',
  FC_EVENT_IMPRESSION = 'FC_EVENT_IMPRESSION',
  FC_EVENT_INTERACTION = 'FC_EVENT_INTERACTION',
  FC_EVENT_INTERCEPTION = 'FC_EVENT_INTERCEPTION',
  FC_EVENT_PUBLISH = 'FC_EVENT_PUBLISH',
  FC_EXPORT_EMAIL = 'FC_EXPORT_EMAIL',
  FC_EXPORT_PDF = 'FC_EXPORT_PDF',
  FC_FILTER_BDC_EYC = 'FC_FILTER_BDC_EYC',
  FC_FTS = 'FC_FTS',
  FC_FTS_DS_PROC_LKUP = 'FC_FTS_DS_PROC_LKUP',
  FC_GOOGLE_RECAPTCHA = 'FC_GOOGLE_RECAPTCHA',
  FC_LIVE = 'FC_LIVE',
  FC_LIVE_CHAT_SUPPORT = 'FC_LIVE_CHAT_SUPPORT',
  FC_LOCATION_BING_VALIDATE = 'FC_LOCATION_BING_VALIDATE',
  FC_LOOKUP_BASIC = 'FC_LOOKUP_BASIC',
  FC_LOOKUP_OON_PROVIDER = 'FC_LOOKUP_OON_PROVIDER',
  FC_MACHINE_READABLE = 'FC_MACHINE_READABLE',
  FC_MBR_ELIG_RULE_MGN = 'FC_MBR_ELIG_RULE_MGN',
  FC_MBR_MULTICONTRACT = 'FC_MBR_MULTICONTRACT',
  FC_MOLS_LOCATION_MGN = 'FC_MOLS_LOCATION_MGN',
  FC_PRECACHE = 'FC_PRECACHE',
  FC_PROVIDER_APPT_VALIDATE = 'FC_PROVIDER_APPT_VALIDATE',
  FC_PROVIDER_RATING = 'FC_PROVIDER_RATING',
  FC_PROVIDER_VCARD = 'FC_PROVIDER_VCARD',
  FC_SEARCH_CPT = 'FC_SEARCH_CPT',
  FC_SEARCH_CPT_OON_COST = 'FC_SEARCH_CPT_OON_COST',
  FC_SEARCH_FROM_MAP = 'FC_SEARCH_FROM_MAP',
  FC_SEARCH_IMPROVE = 'FC_SEARCH_IMPROVE',
  FC_SEARCH_NTWK_SPCLTY = 'FC_SEARCH_NTWK_SPCLTY',
  FC_SEARCH_OON = 'FC_SEARCH_OON',
  FC_SEARCH_SPCLTY_OFFICE_COST = 'FC_SEARCH_SPCLTY_OFFICE_COST',
  FC_SEARCH_TCC = 'FC_SEARCH_TCC',
  FC_TRAVEL_DURATION = 'FC_TRAVEL_DURATION',
  FC_V5 = 'FC_V5',
  HAS_CASTLIGHT = 'HAS_CASTLIGHT',
  HAS_COUPE_HEALTH = 'HAS_COUPE_HEALTH',
  HAS_EYC = 'HAS_EYC',
  HAS_FC_FTS = 'HAS_FC_FTS',
  HAS_HEALTH_WELLNESS_LEGACY_EXPERIENCE = 'HAS_HEALTH_WELLNESS_LEGACY_EXPERIENCE',
  HAS_HEALTHSYSTEM = 'HAS_HEALTHSYSTEM',
  HAS_LHO = 'HAS_LHO',
  HEALTH_ADVOCACY = 'HEALTH_ADVOCACY',
  ONSITE_CLINICS = 'ONSITE_CLINICS',
  PCP_REMOVE = 'PCP_REMOVE',
  SUPPRESS_LHO = 'SUPPRESS_LHO',
  SUPPRESS_VPC = 'SUPRESS_VPC',
  TALKSPACE_SSO = 'TALKSPACE_SSO',
  TELEHEALTH = 'TELEHEALTH',
  VIRTUAL_URGENT_CARE = 'VIRTUAL_URGENT_CARE',
  VIRTUALCARE_TEXT_WITH_DOCTOR = 'VIRTUALCARE_TEXT_WITH_DOCTOR',
  VPC = 'VPC'
}
