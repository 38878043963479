import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GenericSidePanelModule } from '../common/components/shareable/generic-side-panel/genericSidePanelModule';
import { GlobalHeaderModule } from '../common/components/shareable/global-header/globalHeaderModule';
import { GenericModalModule } from '../common/components/shareable/modal/genericModalModule';
import { PageAlertsModule } from '../common/components/shareable/page-alert/pageAlertsModules';
import { GeoLocationModule } from './../../common/components/geoLocation/geoLocationModule';
import { CommonDirectivesModule } from './../../common/directives/commonDirectivesModule';
import { HomeComponent } from './components/homeCmp';
import { PlanSelectionComponent } from './components/plan-selection/planSelectionCmp';

@NgModule({
  declarations: [HomeComponent, PlanSelectionComponent],
  exports: [HomeComponent, PlanSelectionComponent],
  imports: [CommonModule, UxSharedModule.forRoot(), FormsModule, CommonDirectivesModule, GeoLocationModule, GlobalHeaderModule, PageAlertsModule, GenericSidePanelModule, GenericModalModule],
  providers: []
})
export class HomeModule {}
