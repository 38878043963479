import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GenericModalService } from './../modal/services/genericModalSvc';
import { GenericSidePanelComponent } from './components/genericSidePanelCmp';
@NgModule({
  declarations: [GenericSidePanelComponent],
  providers: [GenericModalService],
  exports: [GenericSidePanelComponent],
  imports: [CommonModule, UxSharedModule]
})
export class GenericSidePanelModule {}
