import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { MachineReadableComponent } from './machineReadableCmp';

@NgModule({
  declarations: [MachineReadableComponent],
  exports: [MachineReadableComponent],
  imports: [CommonModule, FormsModule, UxSharedModule],
  providers: []
})
export class MachineReadableModule {}
