import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { RedirectUrlResponse } from '../../../../fad/search-providers/interfaces/magicLinkVpcItemTypes';
import { VirtualCareSSOUrlSvc } from '../../../../fad/search-providers/services/virtualCareSSOURLSvc';
import { VpcMagicLinkSvc } from '../../../../fad/search-providers/services/vpcMagicLinkSvc';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { SSO_SUBTYPE } from '../../../constants/app-constants';
import { DataHelper } from './../../../../common/services/dataHelper';
import { EventHandler } from './../../../../common/services/eventHandler';
import { AppEvents } from './../../../enums/appEvents';
import { IWindow } from './../../../interfaces/iWindow';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { BaseComponent } from './../../base-component/baseCmp';
import { SSOErrorType } from './../enums/ssoErrorType';
import { ISAMLParam } from './../interfaces/iSamlParam';
import { ISSOEvent } from './../interfaces/iSsoEvent';
import { SSOApi } from './../services/ssoApi';

@Component({
  moduleId: module.id,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-fad-sso-form-cmp]',
  template: `
    <div class="hidden">
      <form id="pfssoForm" name="pfssoForm" [target]="formTarget" method="POST" [action]="actionUrl">
        <input type="hidden" name="SAMLResponse" [value]="samlAssertion" />
        <ng-container *ngFor="let p of samlParams"> <input type="hidden" [name]="p.name"
        [value]="p.value" /> </ng-container>
        <button #ssoSubmitBtn name="ssoSubmitBtn" type="submit" aria-hidden="true"
        value="sso" style="display:none;">sso</button>
      </form>
    </div>
  `
})
export class AppSSOFormComponent extends BaseComponent implements OnDestroy {
  ssoBrand: string;
  ssoBrandSite: string;
  actionUrl: string;
  samlAssertion: string;
  samlParams: Array<ISAMLParam> = [];
  formTarget: string = '_blank';
  ssoWindow: any = null;
  disclaimerEnabled: boolean = false;
  disclaimerContent: string;
  ssoEvent: ISSOEvent;
  isInstamed: boolean = false;
  externalPageId: string = '';
  externalPageUrl: string = '';
  showExternalModal: boolean = false;

  private _ssoEvent: EventEmitter<ISSOEvent> = this._eventHandler.get(AppEvents[AppEvents.SSO]);
  private _callSaml: EventEmitter<void> = this._eventHandler.get(AppEvents[AppEvents.CALL_SAML]);
  @ViewChild('ssoSubmitBtn')
  private submitBtn: ElementRef;
  private _subscription1: any;
  private _subscriptionSaml: any;

  constructor(
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _ssoApi: SSOApi,
    @Inject('Window')
    private _window: IWindow,
    private _alertHlpr: AlertHelper,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _doc: any,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _virtualCareUrlSSOService: VirtualCareSSOUrlSvc,
    private _dataHlpr: DataHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, '');

    this._subscription1 = this._ssoEvent.subscribe((event: ISSOEvent) => {
      this.ssoEvent = event;
      this.actionUrl = '';
      this.samlAssertion = '';
      this.samlParams = [];
      this.formTarget = '_blank';
      this.ssoWindow = null;
      this.ssoBrandSite = '';
      this.ssoBrand = '';
      this.showExternalModal = false;

      if (this._appSession.metaData.brandCd) {
        this.ssoBrandSite = this.getSSOBrand();
        this.ssoBrand = this.ssoBrandSite.split('.')[0];
      }

      if (event.isDirectLink) {
        this.actionUrl = event.directLink;
        this.handleSsoSuccess(event, {
          vendorUrl: event.directLink,
          samlassertion: '',
          additionalParams: event.additionalParams
        });
      } else {
        this.getSAMLData(event);
      }
    });
    this._subscriptionSaml = this._callSaml.subscribe(() => {
      this.getSAMLData(this.ssoEvent);
    });
  }

  ngOnDestroy() {
    if (this._subscription1) {
      this._subscription1.unsubscribe();
    }
    if (this._subscriptionSaml) {
      this._subscriptionSaml.unsubscribe();
    }
  }

  getSSOBrand() {
    let brand: string;
    switch (this._appSession.metaData.brandCd.toLowerCase()) {
      case 'abc':
        brand = 'Anthem.com/ca';
        break;
      case 'bcbsga':
        brand = 'bcbsga.com';
        break;
      case 'ebc':
        brand = 'Empireblue.com';
        break;
      case 'ebcbs':
        brand = 'Empireblue.com';
        break;
      default:
        brand = 'Anthem.com';
        break;
    }
    return brand;
  }

  private getSAMLData(event: ISSOEvent) {
    if (!event.type && !event.subType) {
      return;
    }

    if (event.target === '_self') {
      this.formTarget = 'iframeself';
    } else if (event.isOCPBridge) {
      this.formTarget = '_self';
    } else {
      this.formTarget = event.type + event.subType;
    }

    //TODO: remove after testing. changed to use window.open for all browsers to support communication over diff domains
    let windowParams: string;
    if (event.isPcp || event.newWindow) {
      windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
    }

    const winArgs = [this.baseSSOURL + '/ssoLoader.html', this.formTarget];

    if (windowParams) {
      winArgs.push(windowParams);
    }

    if (!event.isOCPBridge) {
      this.ssoWindow = this._window.open.apply(this._window, winArgs);
    }

    if (this._dataHlpr.areEqualStrings(event.subType, SSO_SUBTYPE.VIRTUAL_VISIT)) {
      this._appSession.isKHealthModalOpen = true;
      CommonUtil.redirectKHealth(event.careType,this._appSession,this._vpcMagicLinkService,this._window).then(
        (result: RedirectUrlResponse) => {
          this.handleSsoSuccess(event, result);
        },
        (error: any) => {
          if (this.ssoWindow) {
            this.ssoWindow.close();
          }
          this.showError(event);
          throw error;
        }
      );
    } else if (this._dataHlpr.areEqualStrings(event.subType, SSO_SUBTYPE.VIRTUAL_CARE_SSO_URL)) {
      CommonUtil.virtualCareSSOURL(this._appSession,this._virtualCareUrlSSOService,event.ssoKey).then(
        (result: RedirectUrlResponse) => {
          this.handleSsoSuccess(event, result);
        },
        (error: any) => {
          if (this.ssoWindow) {
            this.ssoWindow.close();
          }
          this.showError(event);
          throw error;
        }
      );
    } else {
      this._ssoApi.getSSOData(event.pdtStrKey).then(
        (result: unknown) => {
          this.handleSsoSuccess(event, result);
        },
        (error: any) => {
          if (this.ssoWindow) {
            this.ssoWindow.close();
          }
          this.showError(event);
          //throw error;
        }
      );
    }
  }

  private handleSsoSuccess(event: ISSOEvent, data: any) {
    this.handleNormalSsoLink(event, data);

    this.processFormSubmit();
  }

  private handleNormalSsoLink(event: ISSOEvent, data: any) {
    this.actionUrl = data.vendorUrl;
    this.samlAssertion = data.samlAssertion;
    this.samlParams = this.getSAMLParams(data.additionalParams);
  }

  private processFormSubmit() {
    setTimeout(
      () => {
        if (this.ssoWindow) {
          let samlHTML = '';
          if ((this._window.document.forms as any)['pfssoForm'].outerHTML) {
            samlHTML = (this._window.document.forms as any)['pfssoForm'].outerHTML;
          } else if (((this._window.document.forms as any)['pfssoForm']) &&
            ((this._window.document.forms as any)['pfssoForm']).length > 0 &&
            ((this._window.document.forms as any)['pfssoForm']).item) {
              // IE 11 is reading outerHTML as undefined hence adding below logic.
              samlHTML = ((this._window.document.forms as any)['pfssoForm']).item(0).outerHTML;
          }

          this.ssoWindow.document.write(samlHTML + '<script nonce="MemberPortal">window.document.forms[0].submit();</script>');
        } else {
          (this._window.document.forms as any)['pfssoForm']?.submit();
        }
      },
      this._window.isIE ? 2000 : 100
    );
  }

  private getSAMLParams(params: any = {}): Array<ISAMLParam> {
    const result: any = [];
    // tslint:disable-next-line:forin
    for (const p in params) {
      result.push({
        name: p,
        value: this.isInstamed === true &&
          params[p].toString().includes('&#47;') ? params[p].toString().replace(/&#47;/g, '/') : params[p]
      });
    }

    return result;
  }

  private showError(event: ISSOEvent) {
    if (event.errorDefinition.type === SSOErrorType.OUTSIDE && event.errorDefinition.errorElement) {
      // ADD ERROR MESSAGE CONTENT
      event.errorDefinition.errorElement.innerHTML = this.getErrorMessage(event);
      // REMOVE fa-info CLASS
      this._renderer.removeClass(event.errorDefinition.errorElement.parentElement.parentElement
        .childNodes[0].childNodes[0], 'fa-info');
      // ADD fa-exclamation ClaimsSummarySvc
      this._renderer.addClass(event.errorDefinition.errorElement.parentElement.parentElement
        .childNodes[0].childNodes[0], 'fa-exclamation');
      //ADD 'ant-negative' CLASS
      this._renderer.addClass(event.errorDefinition.errorElement.parentElement.parentElement.parentElement,
        'ant-negative');
      //SHOW ERROR
      this._renderer.removeClass(event.errorDefinition.errorElement.parentElement.parentElement
        .parentElement.parentElement, 'hidden');
    } else {
      this._alertHlpr.openAlert(this.getErrorMessage(event), 'negative', 10000);
    }
  }

  private getErrorMessage(event: ISSOEvent): string {
    return event.errorDefinition.errorMessage || 'We\'re having trouble redirecting you to external vendor, \
    Please try again or <a href="contact-us">contact us</a>.';
  }
}
