import { Injectable } from '@angular/core';
import { HttpMethod } from '../enums/httpMethodEnum';
import { ResponseType } from '../enums/responseTypeEnum';
import { IUrlParam } from '../interfaces/iUrlParam';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';
@Injectable({ providedIn: 'root' })
export class SummaryPrintSvc extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute<T>(printRequest: T, apiName: string): Promise<Buffer> {
    const apiUrl = `${apiName}/pdf`;
    const urlParams: IUrlParam[] = [
      {
        name: 'locale',
        value: this.appSession?.metaData?.locale as string,
        isQueryParam: true
      },
      {
        name: 'tz',
        value: this.timeZone,
        isQueryParam: true
      }
    ];

    const headers = this.getHeaders();
    headers.push({
      name: 'Accept',
      value: 'application/octet-stream'
    });

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: printRequest,
      headers: headers,
      urlParams,
      responseType: ResponseType.ArrayBuffer
    });
  }
}
