import { Component, EventEmitter, HostListener, Inject, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HighlightPipe } from '../../../../app/common/pipes/highlightPipe';
import { ICptSystemYPosInfoInResponse } from '../../../../app/fad/provider-details/models/iCptSystemYPosInfoInResponse';
import { IProviderCptCodesRequest } from '../../../../app/fad/provider-details/models/iProviderCptCodesRequest';
import { IProviderCptCodes, IProviderCptCodesResponse } from '../../../../app/fad/provider-details/models/iProviderCptCodesResponse';
import { ProviderCptCodesService } from '../../../../app/fad/provider-details/services/providerCptCodesSvc';
import { CptSummaryModel } from '../../../../app/fad/search-results/models/cptSummaryModel';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ViewHealthwiseComponent } from '../../../common/components/healthwise/pfViewHealthwiseCmp';
import { EMPTY_STRING, Locale, NA, OFFICE_VISIT_MEDICALCODE, THREE } from '../../../common/constants/app-constants';
import { ICptCostInfo, IServiceLimit, PFSearchCostInfo } from '../../../common/interfaces/iCptMedicalResponse';
import { DropDownItem } from '../../../common/models/dropDownItem';
import { DataHelper } from '../../../common/services/dataHelper';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IProcedureCostEst, ProviderProcedureInfo } from '../../../fad/provider-details/models/iProcedureCostEst';
import { ICptCode, IProviderCptCostDetailRequest } from '../../../fad/provider-details/models/iProviderCptCostDetailRequest';
import { IProviderCost, ISelectedProviderProcedures } from '../../../fad/provider-details/models/iSelectedProviderProcedures';
import { ProviderOrchestrationService } from '../../../fad/provider-details/services/providerOrchestrationSvc';
import { PROVIDER_TYPE } from '../../../fad/search-providers/values/providerSearchConstants';
import { ICostInfo, IProvider } from '../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { IProviderCostDetails } from '../models/iProviderCostDetails';
import { IProviderCostEstimateResponse } from '../models/iProviderCostEstimateResponse';
import { PFCostInfoComponent } from './../../../common/components/cost-info/pfCostInfoCmp';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';
import { ProviderCptCostDetailService } from './../../../fad/provider-details/services/providerCptCostDetailSvc';

declare let window: any;
declare let _satellite: any;

@Component({
  moduleId: module.id,
  selector: 'app-pf-pricing-details-cmp',
  templateUrl: '../views/pfPricingDetailContainerCmp.html'
})
export class PFPricingDetailContainerComponent extends BaseComponent {
  @Input()
  procedureCostInfo: ICostInfo = {} as ICostInfo;
  @Input()
  isMemberEligibleForCptLookUp: boolean;
  @Input()
  printPageprocedureCode: string;
  @Input()
  printCptPage: ICptCode;
  @Input()
  procedureInfo: ISelectedProviderProcedures = {} as ISelectedProviderProcedures;
  @Input()
  cptCodeList: Array<IProviderCptCodes> = [];
  @ViewChild('costDetailModal')
  costDetailModal: PFCostInfoComponent;
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;
  @Output()
  procedureCodeData: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  cptCodeData: EventEmitter<ICptCode> = new EventEmitter<ICptCode>();
  showCostLoader: boolean = true;
  pricingDropdownValues: Array<DropDownItem> = [];
  selectedOption: string;
  codeList: Array<DropDownItem> = [];
  cptList: Array<DropDownItem> = [];
  procedureList: Array<DropDownItem> = [];
  selectedCode: string;
  selectedProcedureCode: string;
  selectedCptCode: string;
  selectedPosCode: string;
  selectedModifierCode: string;
  selectedProcedureObj: ProviderProcedureInfo = {} as ProviderProcedureInfo;
  selectedCptObj: IProviderCptCodes = {} as IProviderCptCodes;
  placeOfServiceList: ICptSystemYPosInfoInResponse[] = [];
  selectedPosObj: ICptSystemYPosInfoInResponse = {} as ICptSystemYPosInfoInResponse;
  costEstimate: ICostInfo = {} as ICostInfo;
  cptCostInfo: ICptCostInfo = {} as ICptCostInfo;
  cacheCptCostInfo: ICptCostInfo = {} as ICptCostInfo;
  selectedProvider: IProvider = {} as IProvider;
  posList: Array<DropDownItem> = [];
  modifierList: Array<DropDownItem> = [];
  selectedPos: string;
  selectedModifier: string;
  showAddlDd: boolean;
  isProcedureSelected: boolean;
  hraNocostText: string;
  costDetails: IProviderCostDetails = {} as IProviderCostDetails;
  showCostError: boolean;
  coinsuranceDesc: string;
  headerText: string;
  pricingDDCssCls: string;
  defaultPricingTabObj: IProviderCptCodes = {} as IProviderCptCodes;
  noProvProcedureInfoData: boolean = false;
  defaultModifierList: DropDownItem[] = [];
  defaultPosList: DropDownItem[] = [];
  showMedicalCodeLookup: boolean = false;
  searchTerm: string;
  medicalCodePlaceHolder: string = '';
  medicalCodeTimerId: NodeJS.Timeout;
  embeddedServiceLimits: Array<IServiceLimit>;
  hasServiceLimit: boolean;
  orthoTypeFlag: string;
  missingToothPeriodFlag: string;
  hasStatusCode = false;
  hideCostDetails = false;
  hasPDTValue = false;
  hasDentalServiceLimits?: boolean;
  constructor(
    public cptSummaryModel: CptSummaryModel,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _cptSearchModel: CptSummaryModel,
    private _providerOrchestrationService: ProviderOrchestrationService,
    private _providerCptCostDetailService: ProviderCptCostDetailService,
    private _providerCptCodesService: ProviderCptCodesService,
    private _highlightPipe: HighlightPipe,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(DataHelper)
    private _dataHelper: DataHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appSession.selectedCodeStatus = false;
    this.headerText = this.content.pricingCmpContent.cptCode;
    if (this._appSession.isEyc) {
      this.selectedCode = this.content.pricingCmpContent.defaultSelectedCode;
    } else {
      this.medicalCodePlaceHolder = this.content.pricingCmpContent.defaultSelectedCode;
    }
    this.selectedPos = this.content.pricingCmpContent.defaultPos;
    this.selectedModifier = this.content.pricingCmpContent.defaultModifierCode;
    this.buildPricingDropdown();
    this.defaultModifierList = [{ label: this.content.pricingCmpContent.defaultModifierCode, value: NA }];
    this.defaultPosList = [{ label: this.content.pricingCmpContent.defaultPos, value: EMPTY_STRING }];

    this._cptSearchModel.cptSummaryResponse.subscribe((data) => {
      if (data) {
        this.embeddedServiceLimits = data.serviceLimits;
        this.hasServiceLimit = data.hasServiceLimit;
        this.orthoTypeFlag = data.orthoTypeFlag;
        this.missingToothPeriodFlag = data.missingToothPeriodFlag;
        const sourceSystem = (data.providerList || []).find((provider) => provider.providerIdentifier === this._appSession.selectedProvider.providerIdentifier)?.sourceSystemName;
        this.hasPDTValue = ['PDT'].includes(sourceSystem);
        this.hasDentalServiceLimits = data.hasDentalServiceLimits;
        this.cptCostInfo = this.cacheCptCostInfo = (data.providerList || []).find((provider) => provider.providerIdentifier === this._appSession.selectedProvider.providerIdentifier)?.costInfo;
      }
    });
    this.setValuesForSlideOutPanel();
  }

  ngOnChanges(args: SimpleChanges) {
    const printPageprocedureCode = args['printPageprocedureCode'];
    const printCptPageVal = args['printCptPage'];
    if (printPageprocedureCode && printPageprocedureCode.currentValue && printPageprocedureCode.previousValue && printPageprocedureCode.currentValue !== printPageprocedureCode.previousValue) {
      this.onProcedureChange(args['printPageprocedureCode'].currentValue);
    }
    if (
      printCptPageVal &&
      printCptPageVal.currentValue &&
      (!printCptPageVal.previousValue || (printCptPageVal.previousValue && !this._dataHelper.deepEqual(printCptPageVal.currentValue, printCptPageVal.previousValue)))
    ) {
      this.selectedCptCode = printCptPageVal.currentValue.medicalCd;
      this.selectedPosCode = printCptPageVal.currentValue.posCd;
      this.selectedModifierCode = printCptPageVal.currentValue.modifierCd;
      this.selectedProvider = this._appSession.selectedProvider;
      if (this.cptCodeList?.length) {
        this.setCptDefaultValue();
      }
    }
  }

  removeZeroCostProcedures(procedureArr: Array<IProviderCost>): Array<IProviderCost> {
    const nonZeroArr = [];
    procedureArr.forEach((procedure) => {
      if (procedure.procedureCode === this.selectedProcedureCode) {
        nonZeroArr.push(procedure);
      } else {
        const checkNonZero = Object.values(procedure.costInfo).some((val) => val !== '0');
        if (checkNonZero) {
          nonZeroArr.push(procedure);
        }
      }
    });
    return nonZeroArr;
  }

  onProcedureChange(selectedProcedureCode: string) {
    if (selectedProcedureCode === '') {
      selectedProcedureCode = this.selectedProcedureObj.procedureCode;
    }
    this.showCostLoader = true;
    if (this.procedureInfo && this.procedureInfo.providerCosts.length > 0) {
      let costInfoArr = [];
      this.procedureInfo.providerCosts.forEach((providerCost) => {
        if (providerCost.procedureCode === selectedProcedureCode) {
          this.selectedProcedureObj = providerCost;
          costInfoArr = [...costInfoArr, providerCost.costInfo];
        }
      });
      this.selectedProcedureObj.costInfoArr = costInfoArr;
      this.selectedCode = this.selectedProcedureObj.procedureName;
      this.getProcedureSvcList(this.selectedProcedureObj);
      if ('procedureCode' in this.procedureCostInfo && this.selectedProcedureObj.procedureCode === this.selectedProcedureCode) {
        this.showCostLoader = false;
        this.costEstimate = this.procedureCostInfo;
        const regCoinsurancePercent = /{COINSURANCEPRECENT}/gi;
        if (this.procedureCostInfo.coinsurancePercent > 0) {
          this.procedureCostInfo.hideNegative = false;
          this.coinsuranceDesc = this.content.eycPricing.coinsuranceDesc.replace(regCoinsurancePercent, this.procedureCostInfo.coinsurancePercent || '0');
        } else {
          this.procedureCostInfo.hideNegative = true;
          this.coinsuranceDesc = this.content.eycPricing.defaultCoinsuranceDesc;
        }
      } else {
        this.getEstimateCost(selectedProcedureCode);
      }
      //set adobe analytics for pricing dropdown change
      this.setPricingTabProcedureDDAnalytics(this.costEstimate);
      this.procedureCodeData.emit(selectedProcedureCode);
    }
  }

  getProcedureSvcList(procedureService: ProviderProcedureInfo) {
    const tempContainer = {};
    if (procedureService && procedureService.procedureServicesList) {
      this.selectedProcedureObj.procedureServicesList = procedureService.procedureServicesList.filter((serviceName) => !tempContainer[serviceName.name] && (tempContainer[serviceName.name] = true));
    }
  }

  getEstimateCost(procedureCode: string) {
    this.showCostLoader = true;
    const costEstimateParams = this.constructReqParams(procedureCode);
    this._providerOrchestrationService.getProviderCostEstimate(costEstimateParams).then(
      (result: IProviderCostEstimateResponse) => {
        if (result && result.providerEycCostInfo && result.providerEycCostInfo.length > 0) {
          this.onSuccess(result.providerEycCostInfo[0]);
        }
      },
      (error: any) => {
        try {
          this.onError(error);
        } catch (e) {}
      }
    );
  }

  onError(error) {
    this.showCostLoader = false;
    this.showCostError = true;
    throw error;
  }

  //Sets the Analytics query for EYC cost search - Only on RCP integrated page
  setPricingTabProcedureDDAnalytics(costEst: ICostInfo) {
    if (Object(costEst) && Object(costEst).length === 0) {
      return;
    }
    if (window?.digitalData?.page?.pageInfo?.procedureName) {
      window.digitalData.page.pageInfo.procedureName = costEst.procedureName;
    }
  }

  constructReqParams(selectedProcedureCode: string): IProcedureCostEst {
    const procedureCostEstReq: IProcedureCostEst = {
      providerIdentifier: this.selectedProvider.providerIdentifier,
      addressIdentifier: this.selectedProvider.location && this.selectedProvider.location.address ? this.selectedProvider.location.address.addressId : '',
      isFuturePlan: this._appSession.isFutureCoverage,
      professionalSequenceNumber: this.selectedProvider.sequenceNumber,
      pdtKey: this.selectedProvider.pdtKey,
      locale: this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH,
      eycProcedureCriteria: { procedureCode: selectedProcedureCode },
      providerCostInfo: [],
      providerSituations: this.procedureInfo.providerSituation ? this.procedureInfo.providerSituation : '',
      providerTier: this.procedureInfo.providerTier ? this.procedureCostInfo.providerTier : '',
      oopEligible: this._appSession.oopEligible ? this._appSession.oopEligible : false,
      providerProcedureInfo: {
        procedureCode: selectedProcedureCode,
        professionalSequenceNumber: this.selectedProvider && this.selectedProvider.sequenceNumber ? this.selectedProvider.sequenceNumber : '',
        providerCosts: this.selectedProcedureObj && this.selectedProcedureObj.costInfoArr && this.selectedProcedureObj.costInfoArr.length > 0 ? this.selectedProcedureObj.costInfoArr : []
      },
      isDentalEyc:
        this._appSession.hasDentalEYC &&
        this._appSession.searchParams &&
        this._appSession.searchParams.eycSearchParam &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL,
      provCategoryCode: this.selectedProvider.providerCategoryCode
    };

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
      procedureCostEstReq.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }
    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid) {
      procedureCostEstReq.contractUid = this._appSession.metaData.appContract.contractUid;
    }
    if (this.selectedProcedureObj.costInfoArr && this.selectedProcedureObj.costInfoArr.length > 0) {
      const costArr = [];
      this.selectedProcedureObj.costInfoArr.forEach((costInfoObj) => {
        const costInfo = {
          inpatientLowCost: costInfoObj.costILC,
          inpatientHighCost: costInfoObj.costIHC,
          typicalLowCost: costInfoObj.costTLC,
          typicalHighCost: costInfoObj.costTHC,
          avgAllowedCost: costInfoObj.costTAC
        };
        costArr.push(costInfo);
      });
      procedureCostEstReq.providerCostInfo = costArr;
    }
    return procedureCostEstReq;
  }

  setCostInfo() {
    const expansion = CommonUtil.getAccountExpansion(this.procedureCostInfo.accntType);
    const regx = /{ACCOUNT}/gi;
    const regCoinsurancePercent = /{COINSURANCEPRECENT}/gi;
    this.hraNocostText = this.content.eycProcedure.hraNocostText.replace(regx, this.procedureCostInfo.accntType || '');
    if (this.procedureCostInfo.coinsurancePercent > 0) {
      this.procedureCostInfo.hideNegative = false;
      this.coinsuranceDesc = this.content.eycPricing.coinsuranceDesc.replace(regCoinsurancePercent, this.procedureCostInfo.coinsurancePercent || '0');
    } else {
      this.procedureCostInfo.hideNegative = true;
      this.coinsuranceDesc = this.content.eycPricing.defaultCoinsuranceDesc;
    }
    this.costDetails.hraCostAriaLabel = this.content.eycPricing.hraCost.replace(regx, expansion || '');
    this.costDetails.hraCost = this.content.eycPricing.hraCost.replace(regx, this.procedureCostInfo.accntType || '');
    this.costDetails.hraDesc = this.content.eycPricing.hraDesc.replace(regx, this.procedureCostInfo.accntType || '');
    this.costDetails.hraDescAriaLabel = this.content.eycPricing.hraDesc.replace(regx, expansion || '');
  }

  createDropdown(baseCodeList: Array<IBaseCode>): Array<DropDownItem> {
    const finalBaseCode = [];
    for (const baseCode of baseCodeList) {
      if (baseCode) {
        const procedure = {
          label: baseCode.name,
          value: baseCode.code
        };
        finalBaseCode.push(procedure);
      }
    }
    return finalBaseCode;
  }

  buildProcedureDropdown() {
    this.selectedProcedureCode = this.procedureCostInfo.procedureCode || '';
    if (this.procedureInfo && this.procedureInfo.providerCosts && this.procedureInfo.providerCosts.length > 0) {
      this.setProcedureDropdownValues(this.procedureInfo.providerCosts);
    } else {
      this.showCostLoader = false;
      this.costEstimate = this.procedureCostInfo;
      this.noProvProcedureInfoData = true;
    }
  }

  onSuccess(costEst) {
    this.costEstimate = costEst;
    this.showCostError = false;
    this.showCostLoader = false;
    this.coinsurancePercentage();
  }

  coinsurancePercentage() {
    const regCoinsurancePercent = /{COINSURANCEPRECENT}/gi;
    if (this.costEstimate && this.costEstimate.coinsurancePercent === 0) {
      this.costEstimate.coinsurancePercent = 0;
    }
    if (this.procedureCostInfo.coinsurancePercent > 0) {
      this.procedureCostInfo.hideNegative = false;
      this.coinsuranceDesc = this.content.eycPricing.coinsuranceDesc.replace(
        regCoinsurancePercent,
        this.costEstimate.coinsurancePercent ? this.costEstimate.coinsurancePercent : this.procedureCostInfo.coinsurancePercent || '0'
      );
    } else {
      this.procedureCostInfo.hideNegative = true;
      this.coinsuranceDesc = this.content.eycPricing.defaultCoinsuranceDesc;
    }
  }

  onPosChange(posCode: string, isEventFromUi: boolean = false) {
    if (posCode === EMPTY_STRING) {
      return;
    }

    if (CommonUtil.isValidString(posCode)) {
      this.selectedPosObj = this.placeOfServiceList.find((x) => this._dataHelper.areEqualStrings(x.pos?.code, posCode));
    } else {
      this.selectedPosObj = null;
    }

    this.selectedModifierCode = isEventFromUi ? null : this.selectedModifierCode;
    if (!this.selectedPosObj) {
      this.selectedPosCode = '';
      this.selectedPos = this.content.pricingCmpContent.defaultPos;
    } else if (this.selectedModifierCode == null) {
      // if selected-pos-obj has modifier list and contain 'NA' assign
      // selected-modifier-code as NA
      const toAssignNAModifierCode = this.selectedPosObj.modifierList?.length && this.selectedPosObj.modifierList.find((modifierCodeOption) => modifierCodeOption.code === NA);
      if (toAssignNAModifierCode) {
        this.selectedModifierCode = NA;
      } else if (this.selectedPosObj.modifierList?.length > 0) {
        this.selectedModifierCode = this.selectedPosObj.modifierList[0]?.code;
      }
    }

    if (this.selectedPosObj) {
      this.selectedPosCode = this.selectedPosObj.pos?.code;
      this.selectedPos = this.selectedPosObj.pos?.name;
      this.modifierList = this.createDropdown(this.selectedPosObj.modifierList);
      const modifierCode = this.selectedModifierCode;
      if (modifierCode) {
        this.onModifierChange(modifierCode);
      }
    } else {
      this.modifierList = this.defaultModifierList;
      this.onModifierChange(null);
    }
    if (typeof _satellite !== 'undefined') {
      _satellite.track(this.content.pricingCmpContent.dataAnalytics.selectPlaceServiceDropDetailFindCare);
    }
  }

  async setCptDefaultValue() {
    this.codeList = this.cptList;
    if (CommonUtil.isValidString(this.selectedCptCode)) {
      this.selectedCptObj = this.cptCodeList.find((x) => x.medicalCode?.code === this.selectedCptCode) ?? this.cptCodeList[0];
      this.defaultPricingTabObj = this.selectedCptObj;
      this.defaultPricingTabObj.medicalCode.posCd = this.selectedPosCode;
      this.defaultPricingTabObj.medicalCode.modifierCd = this.selectedModifierCode;
    } else {
      this.selectedCptObj = this.defaultPricingTabObj;
      this.selectedCptCode = this.selectedCptObj?.medicalCode?.code ?? '';
    }

    if (this.selectedCptObj && this.selectedCptCode) {
      this.selectedCptCode = this.selectedCptObj.medicalCode?.code;
      this.selectedCode = this.selectedCptObj.medicalCode?.name;
      await this.getProviderCptCostDetails();
      this.setPosDropDownValues();
    } else {
      this.showAddlDd = false;
      this.selectedCode = '';
      this.medicalCodePlaceHolder = this.content.pricingCmpContent.defaultSelectedCode;
    }
  }

  setProcedureDefaultValue() {
    this.setCostInfo();
    this.codeList = this.procedureList;
    this.selectedCode = this.procedureList.find((x) => x.value === this.selectedProcedureCode)?.label;
    if (this.selectedCode) {
      this.onProcedureChange(this.selectedProcedureCode);
    } else {
      this.selectedCode = this.content.pricingCmpContent.defaultSelectedCode;
    }
  }

  setCptDropdownValues(cptCodeList: IProviderCptCodes[]) {
    for (const cptCode of cptCodeList) {
      if (cptCode.medicalCode) {
        this.cptList.push({
          label: cptCode.medicalCode.name,
          value: cptCode.medicalCode.code
        });
      }
    }
  }

  setProcedureDropdownValues(result: Array<IProviderCost>) {
    const noDuplicate = this.removeDuplicates(result); /* Remove duplicate procedures */
    const nonZeroCost = this.removeZeroCostProcedures(noDuplicate); /*Removed procedure with zero cost */
    const sortedProcedure = [...nonZeroCost].sort((a, b) => {
      if (a.procedureName < b.procedureName) {
        return -1;
      }
      return a.procedureName > b.procedureName ? 1 : 0;
    });
    for (const proc of sortedProcedure) {
      const procedure = {
        label: proc.procedureName,
        value: proc.procedureCode
      };

      this.procedureList.push(procedure);
    }
    // no procedure selected scenario. go with default procedure.
    if (!this.selectedProcedureCode && sortedProcedure && sortedProcedure.length > 0) {
      this.selectedProcedureCode = sortedProcedure[0].procedureCode;
    }
  }

  onModifierChange(modifierCode: string) {
    if (modifierCode === EMPTY_STRING || this.selectedPos === this.content.pricingCmpContent.defaultPos) {
      return;
    }

    let selectedModifierObj: IBaseCode;
    if (CommonUtil.isValidString(modifierCode)) {
      selectedModifierObj = this.selectedPosObj.modifierList.find((x) => x.code === modifierCode);
    }

    if (typeof selectedModifierObj === 'object' && !this._dataHelper.isEmptyObject(selectedModifierObj)) {
      this.selectedModifier = selectedModifierObj.name;
      this.selectedModifierCode = selectedModifierObj.code;
    } else {
      this.selectedModifier = this.content.pricingCmpContent.defaultModifierCode;
      this.selectedModifierCode = '';
    }

    if (CommonUtil.isNotBlank(this.selectedCptCode) && CommonUtil.isNotBlank(this.selectedPosCode) && CommonUtil.isNotBlank(this.selectedModifierCode)) {
      this.getCptCostDetail();
    }
    if (typeof _satellite !== 'undefined') {
      _satellite.track(this.content.pricingCmpContent.dataAnalytics.selecteModifierDropDetailFindCare);
    }
    this.cptCodeData.emit({ medicalCd: this.selectedCptCode, posCd: this.selectedPosCode, modifierCd: this.selectedModifierCode });
  }

  buildCptDropdown() {
    if (this.cptCodeList && this.cptCodeList.length > 0) {
      this.setCptDropdownValues(this.cptCodeList);
    }
  }

  async onCptOrProcedureChange(code: string) {
    if (code === EMPTY_STRING || code === this.selectedCptCode || code === this.selectedProcedureObj?.procedureCode) {
      return;
    }

    if (this.isProcedureSelected) {
      this.onProcedureChange(code);
    } else {
      this.selectedCptCode = code;
      this.selectedModifierCode = null;
      this.selectedPosCode = null;
      this.selectedPos = this.content.pricingCmpContent.defaultPos;
      this.selectedModifier = this.content.pricingCmpContent.defaultModifierCode;
      this.medicalCodePlaceHolder = '';
      this.posList = [];
      this.modifierList = [];
      await this.setCptDefaultValue();
    }

    if (typeof _satellite !== 'undefined') {
      _satellite.track(this.content.pricingCmpContent.dataAnalytics.selectProcMedDropDetailFindCare);
    }
  }

  removeDuplicates(procedureArr: Array<IProviderCost>): Array<IProviderCost> {
    const filteredArr = procedureArr.reduce((acc, current) => {
      const match = acc.find((item) => item.procedureCode === current.procedureCode);
      if (!match) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }

  buildPricingDropdown() {
    this.pricingDropdownValues = this.content.pricingCmpContent.pricingDropdown;
    if (this._dataHelper.isEmptyObject(this.procedureCostInfo) && !this.procedureInfo) {
      this.pricingDropdownValues = this.pricingDropdownValues.filter((list) => {
        return list.value !== 'procedure';
      });
      this.headerText = this.content.pricingCmpContent.cptCode;
    }
    if (!(this.cptCodeList && this.cptCodeList.length) && !this.isMemberEligibleForCptLookUp) {
      this.pricingDropdownValues = this.pricingDropdownValues.filter((list) => {
        return list.value !== 'cptcode';
      });
      this.headerText = this.content.pricingCmpContent.procedureCode;
    }
    this.pricingDDCssCls = this.headerText.includes(this.content.pricingCmpContent.pricingDropdown[0].label) ? 'pricing-dropdown-cmp' : 'pricing-dropdown-cmp-cpt';
    this.selectedProvider = this._appSession.selectedProvider;
    this.buildCptDropdown();
    if (this.procedureCostInfo && this.procedureCostInfo.procedureCode) {
      this.selectedOption = this.content.pricingCmpContent.pricingDropdown[0].label;
      this.selectedProcedureCode = this.procedureCostInfo.procedureCode;
      this.isProcedureSelected = true;
      this.buildProcedureDropdown();
      this.setProcedureDefaultValue();
    } else if (this.cptCodeList) {
      this.isProcedureSelected = false;
      this.selectedOption = this.content.pricingCmpContent.pricingDropdown[1].label;
      this.selectedCptCode = this.selectedCptCode ? this.selectedCptCode : this._appSession.medicalCode;
      this.selectedPosCode = this.selectedPosCode ? this.selectedPosCode : this._appSession.medicalPosCode;
      this.selectedModifierCode = this.selectedModifierCode ? this.selectedModifierCode : this._appSession.medicalModifierOptionCode;
      this.setCptDefaultValue();
    }
  }

  setPosDropDownValues() {
    this.showAddlDd = true;
    const pos = this.placeOfServiceList.map((a) => a.pos);
    this.posList = pos.length ? this.createDropdown(pos) : this.defaultPosList;
    const posCode = this.selectedPosCode ? this.selectedPosCode : this._appSession.medicalPosCode;
    this.onPosChange(posCode);
  }

  async getProviderCptCostDetails() {
    this.showCostLoader = true;
    this.showCostError = false;
    this.hasStatusCode = false;
    this.hideCostDetails = false;
    this.hasStatusCode = false;
    this.hideCostDetails = false;
    const locale: string = this._appSession.metaData.locale.toString() ?? Locale.ENGLISH;
    const req = this.createCptCostDetailRequest();
    if (req?.medicalCd) {
      const costDetailResponse = await this._providerCptCostDetailService.getProviderCptCostDetail(req, locale).catch(() => undefined);
      if (this.hasPDTValue && Object.keys(costDetailResponse).length === 0 && this._appSession.billingType) {
        this.hideCostDetails = true;
        this.hasStatusCode = true;
      }
      if (typeof costDetailResponse === 'object' && Object.keys(costDetailResponse).length) {
        this.cptCostInfo = costDetailResponse.costInfo;
        this.placeOfServiceList = costDetailResponse.posList;
        this.showCostLoader = false;
        this.setValuesForSlideOutPanel();
      } else {
        if (!this.posList?.length) {
          // indicates provider-cpt-cost-details is triggered by
          // changing cpt dropdown
          this.placeOfServiceList = [];
          this.showCostError = true;
        }
        this.cptCostInfo = null;
        this.showCostLoader = false;
      }
    }
  }

  cptValueCheck(appSession) {
    if (appSession) {
      this._appSession.billingType = this.selectedCptObj.medicalCode?.billingType;
      this._appSession.medicalCode = this.selectedCptCode;
      this._appSession.medicalPosCode = this.selectedPosCode;
      this._appSession.medicalModifierOptionCode = this.selectedModifierCode;
      this._appSession.searchTerm = this.selectedCode;
    }
  }

  async getCptCostDetail() {
    if (this.isDropdownChanged()) {
      await this.getProviderCptCostDetails();
    } else {
      this.cptCostInfo = this.cacheCptCostInfo;
      this.setValuesForSlideOutPanel();
      this.showCostLoader = false;
    }
  }

  createCptCostDetailRequest(): IProviderCptCostDetailRequest {
    const req: IProviderCptCostDetailRequest = {
      providerKey: this.selectedProvider.providerIdentifier,
      addrKey: this.selectedProvider?.location?.address?.addressId ?? '',
      mbrUid: this._appSession.metaData?.appContract?.mbrUid ?? '',
      contractUid: this._appSession.metaData?.appContract?.contractUid ?? '',
      medicalCd: this.selectedCptCode,
      posCd: this.selectedPosCode,
      modifierCd: this.selectedModifierCode,
      billingType: this.selectedCptObj.medicalCode?.billingType ?? this.selectedCptObj.billingType,
      isFuturePlan: this._appSession.isFutureCoverage,
      pdtStrKey: this.hasPDTValue ? CommonUtil.getPdtStrKey(this.selectedProvider) : '',
      providerPlanType: this.selectedProvider?.providerPlanType ?? undefined,
      stateCode: this._appSession?.searchParams?.stateCd?.code ?? ''
    };
    return req;
  }

  isDropdownChanged(): boolean {
    return (
      (this.selectedCptCode && this.selectedCptCode !== this._appSession.medicalCode) ||
      (this.selectedPosCode && this.selectedPosCode !== this._appSession.medicalPosCode) ||
      (this.selectedModifierCode && this.selectedModifierCode !== this._appSession.medicalModifierOptionCode)
    );
  }

  setValuesForSlideOutPanel() {
    const costInfoReq: PFSearchCostInfo = {
      modifierCode: this.selectedModifier,
      placeOfService: this.selectedPos,
      costInfo: this.cptCostInfo,
      serviceLimits: this.embeddedServiceLimits,
      hasServiceLimit: this.hasServiceLimit,
      orthoTypeFlag: this.orthoTypeFlag,
      missingToothPeriodFlag: this.missingToothPeriodFlag,
      hasDentalServiceLimits: this.hasDentalServiceLimits
    } as PFSearchCostInfo;
    this._cptSearchModel.setCptCostResponse(costInfoReq);
  }

  onMedicalCodeSearch(searchTerm: string) {
    if (this.medicalCodeTimerId) {
      clearTimeout(this.medicalCodeTimerId);
    }
    const _searchTerm = searchTerm.trim();
    this.cptCodeList = [];
    this.searchTerm = searchTerm;
    this.showMedicalCodeLookup = false;
    this.medicalCodeTimerId = setTimeout(
      () => {
        if (_searchTerm.length >= THREE && CommonUtil.isValidString(this.getSearchText(_searchTerm))) {
          this.getMedicalCodesBasedOnSearchTerm();
          this.showMedicalCodeLookup = true;
        }
      },
      300,
      _searchTerm,
      searchTerm
    );
  }

  highlightText(value: string): SafeHtml {
    return this._highlightPipe.transform(value, this.searchTerm, 'Multi-Word-Match');
  }

  private async getMedicalCodesBasedOnSearchTerm() {
    if (this._appSession?.selectedCodeStatus) {
      const { providerKey, addrKey, mbrUid, contractUid, isFuturePlan, pdtStrKey, providerPlanType } = this.createCptCostDetailRequest();
      const providerCptCodesSearchRequest = {
        providerKey,
        addrKey,
        mbrUid,
        contractUid,
        isFuturePlan,
        pdtStrKey,
        providerPlanType,
        searchText: this.getSearchText(this.searchTerm)
      } as IProviderCptCodesRequest;
      const locale: string = this._appSession.metaData.locale.toString() ?? Locale.ENGLISH;
      let searchTermResponse: IProviderCptCodesResponse;
      if (CommonUtil.isValidString(providerCptCodesSearchRequest.searchText)) {
        searchTermResponse = await this._providerCptCodesService.getProviderCptCodes(providerCptCodesSearchRequest, locale).catch(() => undefined);
      }
      this.cptCodeList = searchTermResponse?.providerCptCodes ?? [];
    } else {
      if (this._appSession.medicalCode) {
        if (this._appSession?.medicalCode === OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE && !CommonUtil.isValidString(this._appSession?.medicalCodeDescription)) {
          this._appSession.medicalCodeDescription = OFFICE_VISIT_MEDICALCODE.DESCRIPTION;
        }
        this.cptCodeList = [
          {
            billingType: '',
            medicalCode: {
              code: this._appSession?.medicalCode,
              name: this._appSession?.searchTerm,
              billingType: this._appSession?.billingType,
              description: this._appSession.medicalCodeDescription
            }
          }
        ];
        await this.onMedicalCodeSelect(this._appSession?.medicalCode);
      }
      this.posList?.find((e) => {
        if (e.label == this._appSession?.selectedPosCode) {
          this.onPosChange(e.value, true);
        }
      });
      const selectedModifierList = (this.selectedPosObj?.modifierList || []).find((x) => x.code === this._appSession?.selectedModifierCode);
      if (selectedModifierList) {
        this.onModifierChange(selectedModifierList.code);
      }
      this._appSession.selectedCodeStatus = true;
    }
  }

  async onMedicalCodeSelect(code: string) {
    if (code === EMPTY_STRING || code === this.selectedCptCode) {
      this.selectedCode = this.selectedCptObj.medicalCode?.name;
    } else {
      await this.onCptOrProcedureChange(code);
    }
    this.showMedicalCodeLookup = false;
  }

  private getSearchText(searchText: string): string {
    if (searchText?.length >= 2) {
      return searchText?.includes('-') ? searchText?.split('-')[1] : searchText;
    } else {
      return '';
    }
  }

  @HostListener('document:click', ['$event'])
  async onDocumentClick(event) {
    if (this._appSession?.selectedPosCode && this._appSession.medicalCode) {
      await this.getMedicalCodesBasedOnSearchTerm();
      this._appSession.selectedCodeStatus = true;
    }
  }
}
