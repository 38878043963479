import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { FeatureManagerUtility } from '../../findcare/common/utilities/featureManagerUtil';
import { AppSession } from '../values/appSession';
import { AppNavigations } from './../constants/app-navigations';

export interface NavigationStack {
  navigation: string;
  data: any;
}

@Injectable()
export class NavigationService {
  private _curRoute: AppNavigations;
  private _preRoute: AppNavigations;
  private _rootRoute: AppNavigations;
  public navigationStack: NavigationStack[] = [];
  public navigationEventEmitter = new EventEmitter<NavigationStack>();

  constructor(
    private router: Router,
    private appSession: AppSession
  ) {}

  navigateByUrl(navigation: AppNavigations, queryParams?: Params, state?: Record<string, any>): void {
    this._preRoute = this._curRoute;

    // Check if the FCR feature is live based on the bootstrap response
    if (FeatureManagerUtility.isFcrEnabled(this.appSession)) {
      if (navigation === AppNavigations.SEARCH_PROVIDERS_PATH) {
        navigation = this.appSession.navigateToLanding ? AppNavigations.FCR_LANDING_PATH : AppNavigations.FCR_HOME_PATH;
      } else if (navigation === AppNavigations.SEARCH_RESULTS_PATH) {
        navigation = AppNavigations.FCR_RESULT_PATH;
      }
    }
    this._curRoute = navigation;

    if (
      !this._rootRoute &&
      (navigation === AppNavigations.CARE_TEAM_PATH ||
        navigation === AppNavigations.CARE_CIRCLE_PATH ||
        navigation === AppNavigations.SEARCH_PROVIDERS_PATH ||
        navigation === AppNavigations.PROMOTED_PROCEDURES_PATH ||
        navigation === AppNavigations.VIRTUAL_CARE_PATH ||
        navigation === AppNavigations.CARE_NOW ||
        (queryParams && queryParams['rootRoute'] === true))
    ) {
      this._rootRoute = navigation;
    } else if (navigation === AppNavigations.DEFAULT_ROUTE_PATH) {
      // reset root route
      this._rootRoute = undefined;
    }
  }

  get currentRoute(): AppNavigations {
    return this._curRoute;
  }

  get previousRoute(): AppNavigations {
    return this._preRoute;
  }

  get rootRoute(): AppNavigations {
    return this._rootRoute;
  }

  get currentNavigationRoute(): string {
    return this.currentNavigationRoute;
  }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class OutletNavigationService extends NavigationService {
  constructor(
    private _router: Router,
    private _appSession: AppSession,
    activatedRoute: ActivatedRoute
  ) {
    super(_router, _appSession);
  }

  navigateByUrl(navigation: AppNavigations, queryParams?: Params, state?: Record<string, any>): void {
    super.navigateByUrl(navigation, queryParams);
    const navigationExtras: NavigationExtras = {
      replaceUrl: false,
      queryParams: queryParams || {}
    };

    if (!isEmpty(queryParams) && queryParams['action'] === 'back') {
      navigationExtras.queryParams = {
        action: 'back'
      };
    }

    switch (this.currentRoute) {
      case AppNavigations.VIRTUAL_CARE_PATH:
        //  write logic here put condtion to excute any one line from below two lines
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.VIRTUAL_CARE_PATH_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.VIRTUAL_CARE_PATH_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.VIRTUAL_CARE_PATH_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.CARE_TEAM_PATH:
      case AppNavigations.CARE_CIRCLE_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.CARE_CIRCLE_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.CARE_CIRCLE_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.CARE_CIRCLE_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.PROMOTED_PROCEDURES_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.PROMOTED_PROCEDURES_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.PROMOTED_PROCEDURES_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.PROMOTED_PROCEDURES_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.SEARCH_PROVIDERS_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.SEARCH_PROVIDERS_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_PROVIDERS_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.SEARCH_RESULTS_PATH:
      case AppNavigations.SEARCH_RESULTS_NEXT:
        // Determine the navigation outlet based on the feature flag and browser navigation capability
        const navigateToOutlet = (isNext: boolean) => {
          const outlet = isNext ? AppNavigations.SEARCH_RESULTS_NEXT_OUTLET : AppNavigations.SEARCH_RESULTS_OUTLET;
          queryParams = { ...queryParams };
          const data = { queryParams, state };
          if (this._appSession.metaData.isBrowserNavigationEnabled) {
            this.navigationEventEmitter.emit({ navigation: outlet, data });
          } else {
            const urlToBeRouted = outlet;
            this._appSession.navigationStack.push({ commands: [urlToBeRouted], extras: navigationExtras });
            this._router.navigateByUrl(urlToBeRouted, navigationExtras);
          }
        };
        navigateToOutlet(true);
        break;
      case AppNavigations.SEARCH_RESULTS_PRINT_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.SEARCH_RESULTS_PRINT_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_RESULTS_PRINT_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.SEARCH_RESULTS_PRINT_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.PROVIDER_DETAILS_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.PROVIDER_DETAILS_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.PROVIDER_DETAILS_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.PROVIDER_DETAILS_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.COST_SEARCH_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.COST_SEARCH_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.COST_SEARCH_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.COST_SEARCH_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.EMULATE_SECURE_STATE_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.EMULATE_SECURE_STATE_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.EMULATE_SECURE_STATE_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.EMULATE_SECURE_STATE_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.EMULATE_COST_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.EMULATE_COST_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.EMULATE_COST_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.EMULATE_COST_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.DEFAULT_ROUTE_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.DEFAULT_ROUTE_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.DEFAULT_ROUTE_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.DEFAULT_ROUTE_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.ERROR_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.ERROR_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.ERROR_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.ERROR_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.MAINTENANCE_PAGE_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.MAINTENANCE_PAGE_ROUTE_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.MAINTENANCE_PAGE_ROUTE_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.MAINTENANCE_PAGE_ROUTE_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.SERVICE_AREA_VALIDATION_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.SERVICE_AREA_VALIDATION_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.SERVICE_AREA_VALIDATION_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.SERVICE_AREA_VALIDATION_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.ASSIGN_PCP_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.ASSIGN_PCP_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.ASSIGN_PCP_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.ASSIGN_PCP_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.FCR_HOME_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.FCR_HOME_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.FCR_HOME_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.FCR_HOME_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.FCR_LANDING_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.FCR_LANDING_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.FCR_LANDING_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.FCR_LANDING_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.FCR_RESULT_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.FCR_RESULT_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.FCR_RESULT_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.FCR_RESULT_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.CARE_NOW:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.CARE_NOW_PATH_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.CARE_NOW_PATH_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.CARE_NOW_PATH_OUTLET, navigationExtras);
        }
        break;
      case AppNavigations.DENIAL_OF_SERVICE_PATH:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.DENIAL_OF_SERVICE_PATH_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.DENIAL_OF_SERVICE_PATH_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.DENIAL_OF_SERVICE_PATH_OUTLET, navigationExtras);
        }
        break;
      default:
        if (this._appSession.metaData.isBrowserNavigationEnabled) {
          this.navigationEventEmitter.emit({ navigation: AppNavigations.SEARCH_PROVIDERS_OUTLET, data: { queryParams, state } });
        } else {
          this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_PROVIDERS_OUTLET], extras: navigationExtras });
          this._router.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_OUTLET, navigationExtras);
        }
        break;
    }
    this._appSession.navigationStackCount = this._appSession.navigationStackCount + 1;
  }

  get currentNavigationRoute(): string {
    return this._appSession.navigationStack[this._appSession.navigationStack?.length - 1]?.commands[0] ?? this.navigationStack[this.navigationStack.length - 1]?.navigation;
  }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class PathNavigationService extends NavigationService {
  constructor(
    private _router: Router,
    private _appSession: AppSession,
    activatedRoute: ActivatedRoute
  ) {
    super(_router, _appSession);
  }

  navigateByUrl(navigation: AppNavigations, queryParams?: Params, state?: Record<string, any>): void {
    super.navigateByUrl(navigation, queryParams);
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge'
    };
    if (queryParams && typeof queryParams !== 'undefined' && queryParams !== null) {
      navigationExtras.queryParams = queryParams;
    }

    switch (this.currentRoute) {
      case AppNavigations.VIRTUAL_CARE_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.VIRTUAL_CARE_PATH] });
        this._router.navigateByUrl(AppNavigations.VIRTUAL_CARE_PATH);
        break;
      case AppNavigations.CARE_TEAM_PATH:
      case AppNavigations.CARE_CIRCLE_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.CARE_CIRCLE_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.CARE_CIRCLE_PATH], navigationExtras);
        break;
      case AppNavigations.PROMOTED_PROCEDURES_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.PROMOTED_PROCEDURES_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.PROMOTED_PROCEDURES_PATH], navigationExtras);
        break;
      case AppNavigations.SEARCH_PROVIDERS_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_PROVIDERS_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.SEARCH_PROVIDERS_PATH], navigationExtras);
        break;
      case AppNavigations.SEARCH_RESULTS_PATH:
      case AppNavigations.SEARCH_RESULTS_NEXT:
        navigationExtras.queryParams = { ...navigationExtras.queryParams };
        this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_RESULTS_NEXT], extras: navigationExtras });
        this._router.navigate([AppNavigations.SEARCH_RESULTS_NEXT], navigationExtras);
        break;
      case AppNavigations.SEARCH_RESULTS_PRINT_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_RESULTS_PRINT_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.SEARCH_RESULTS_PRINT_PATH], navigationExtras);
        break;
      case AppNavigations.PROVIDER_DETAILS_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.PROVIDER_DETAILS_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.PROVIDER_DETAILS_PATH], navigationExtras);
        break;
      case AppNavigations.COST_SEARCH_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.COST_SEARCH_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.COST_SEARCH_PATH], navigationExtras);
        break;
      case AppNavigations.EMULATE_SECURE_STATE_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.EMULATE_SECURE_STATE_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.EMULATE_SECURE_STATE_PATH], navigationExtras);
        break;
      case AppNavigations.EMULATE_COST_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.EMULATE_COST_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.EMULATE_COST_PATH], navigationExtras);
        break;
      case AppNavigations.ERROR_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.ERROR_PATH], extras: navigationExtras });
        this._router.navigateByUrl(AppNavigations.ERROR_PATH);
        break;
      case AppNavigations.MAINTENANCE_PAGE_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.MAINTENANCE_PAGE_PATH], extras: navigationExtras });
        this._router.navigateByUrl(AppNavigations.MAINTENANCE_PAGE_PATH);
        break;
      case AppNavigations.SERVICE_AREA_VALIDATION_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.SERVICE_AREA_VALIDATION_PATH], extras: navigationExtras });
        this._router.navigateByUrl(AppNavigations.SERVICE_AREA_VALIDATION_PATH);
        break;
      case AppNavigations.ASSIGN_PCP_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.ASSIGN_PCP_PATH], extras: navigationExtras });
        this._router.navigateByUrl(AppNavigations.ASSIGN_PCP_PATH);
        break;
      case AppNavigations.FCR_HOME_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.FCR_HOME_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.FCR_HOME_PATH], navigationExtras);
        break;
      case AppNavigations.FCR_LANDING_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.FCR_LANDING_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.FCR_LANDING_PATH], navigationExtras);
        break;
      case AppNavigations.FCR_RESULT_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.FCR_RESULT_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.FCR_RESULT_PATH], navigationExtras);
        break;
      case AppNavigations.CARE_NOW:
        this._appSession.navigationStack.push({ commands: [AppNavigations.CARE_NOW], extras: navigationExtras });
        this._router.navigate([AppNavigations.CARE_NOW], navigationExtras);
        break;
      case AppNavigations.DENIAL_OF_SERVICE_PATH:
        this._appSession.navigationStack.push({ commands: [AppNavigations.DENIAL_OF_SERVICE_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.DENIAL_OF_SERVICE_PATH], navigationExtras);
        break;
      default:
        this._appSession.navigationStack.push({ commands: [AppNavigations.SEARCH_PROVIDERS_PATH], extras: navigationExtras });
        this._router.navigate([AppNavigations.SEARCH_PROVIDERS_PATH], navigationExtras);
        break;
    }

    this._appSession.navigationStackCount = this._appSession.navigationStackCount + 1;
  }

  get currentNavigationRoute(): string {
    return this._router.url;
  }
}
