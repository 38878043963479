import { forOwn, isEmpty } from 'lodash';
import { EMPTY_STRING, TILDE_SYMBOL } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { IProvider } from '../../result/interfaces/iProvider';
import { PROVIDER_TYPE, SearchExecutionMode } from '../constants/common';
import { IModalContent } from '../interfaces/iModalContent';

export class CommonUtility {
  /**
   * Determines if the member is in a secure state based on the app session.
   * @param appSession The application session.
   * @returns {boolean} True if the member is in a secure state, otherwise false.
   */
  static isMemberSecure(appSession: AppSession): boolean {
    // Check if the identification number is present and not empty
    const hasIdentificationNumber = appSession?.searchParams?.identificationNumber && appSession.searchParams.identificationNumber.trim() !== '';

    // If the identification number is present, the state is not secure
    if (hasIdentificationNumber) {
      return false;
    }

    // Otherwise, return the secure state from the app session
    return appSession.isSecureState;
  }

  static isCptSearch(appSession: AppSession): boolean {
    return appSession?.isMedicalCodeSearch;
  }

  static getPdtStrKey(selectedProvider: IProvider): string {
    if (CommonUtility.isValidString(selectedProvider?.pdtStrKey)) {
      return selectedProvider.pdtStrKey;
    } else if (!isEmpty(selectedProvider?.pdtKey)) {
      return (
        (selectedProvider.pdtKey.planCode ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.productId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerNumber ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerMasterId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.locationMasterId ?? EMPTY_STRING)
      );
    }
    return EMPTY_STRING;
  }

  static isValidString(key: unknown): boolean {
    if (key && typeof key === 'string' && key !== '' && key.trim().length) {
      return true;
    }
    return false;
  }

  static replaceContentWithLiterals(content: string = '', pattern: string, value: string): string {
    const regExPattern = new RegExp(`{${pattern}}`, 'gi');
    return content.replace(regExPattern, value);
  }

  static processModalContent(modalContent: IModalContent, brand: string): IModalContent {
    modalContent.title = CommonUtility.replaceContentWithLiterals(modalContent?.title, 'brand', brand);
    modalContent.body = CommonUtility.replaceContentWithLiterals(modalContent?.body, 'brand', brand);
    forOwn(modalContent.content, (value, key) => {
      modalContent.content[key] = CommonUtility.replaceContentWithLiterals(value, 'brand', brand);
    });
    return modalContent;
  }

  static getProviderTypes(appSession: AppSession): Array<string> {
    let allProviderTypes = appSession?.appState?.executionMode === SearchExecutionMode.SECURE ? appSession.providerTypes.filter((x) => x.code !== PROVIDER_TYPE.PHARMACY) : appSession.providerTypes;
    const providerTypes = [];
    const { opsIndicator } = appSession.appState;
    if (opsIndicator) {
      if (opsIndicator.isDentalStandAlone) {
        providerTypes.push(PROVIDER_TYPE.DENTAL);
      } else if (opsIndicator.isVisionStandAlone) {
        providerTypes.push(PROVIDER_TYPE.VISION);
      } else if (opsIndicator.isRxStandAlone) {
        providerTypes.push(PROVIDER_TYPE.PHARMACY);
      }
    }
    return providerTypes.length ? providerTypes : allProviderTypes?.map((x) => x.code) || [];
  }
}
