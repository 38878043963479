import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UxSharedModule } from '@anthem/uxd-shared';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './appCmp';
import { CareCircleModule } from './care-circle/careCircleModule';
import { CareCircleService } from './care-circle/services/careCircleSvc';
import { MemberPCPHelper } from './care-circle/services/memberPCPHelper';
import { PFCareNowModule } from './care-now/pfCareNowModule';
import { AppErrorModule } from './common/components/app-error/appErrorModule';
import { AppMaintenanceModule } from './common/components/app-maintenance/appMaintenanceModule';
import { AppSecureCostModule } from './common/components/app-secure-cost/appSecureCostModule';
import { AppSecureModule } from './common/components/app-secure/appSecureModule';
import { MemberSummaryService } from './common/components/app-secure/services/memberSummarySvc';
import { BaseComponent } from './common/components/base-component/baseCmp';
import { CommonAlertModule } from './common/components/common-alert/commonAlertModule';
import { DenialOfServiceModule } from './common/components/denial-of-service/denialOfServiceModule';
import { GlobalBackBtnModule } from './common/components/global-back-button/globalBackBtnModule';
import { GlobalFooterModule } from './common/components/global-footer/globalFooterModule';
import { GlobalHeaderModule } from './common/components/global-header/globalHeaderModule';
import { LocationService } from './common/components/location/services/locationService';
import { NavigationModule } from './common/components/navigation/navigationModule';
import { ScenarioAlertModule } from './common/components/scenarioAlert/pfScenarioAlertModule';
import { SSOApi } from './common/components/sso/services/ssoApi';
import { CustomPipesModule } from './common/pipes/customPipesModule';
import { CustomSlicePipe } from './common/pipes/customSlicePipe';
import { HighlightPipe } from './common/pipes/highlightPipe';
import { PhonePatternPipe } from './common/pipes/phonePatternPipe';
import { TrimText } from './common/pipes/trimText';
import { WeekdaysPipe } from './common/pipes/weekdaysPipe';
import { AppInitializer, appInitializerFactory } from './common/services/appInitializer';
import { AuthGuardService } from './common/services/authGuardService';
import { AuthInterceptor } from './common/services/authInterceptor';
import { BaseService } from './common/services/baseService';
import { DataHelper } from './common/services/dataHelper';
import { DataService } from './common/services/dataService';
import { DeeplinkHandler } from './common/services/deeplinkHandler';
import { EventHandler } from './common/services/eventHandler';
import { FeatureFlagService } from './common/services/featureFlagInitializer';
import { GlobalErrorHandler } from './common/services/globalErrorHandler';
import { HostnameService } from './common/services/hostNameService';
import { HttpClientService } from './common/services/httpClientService';
import { MbrAgeLimitService } from './common/services/mbrAgeLimitSvc';
import { getWindow } from './common/services/moduleHelpers';
import { NavigationService, PathNavigationService } from './common/services/navigationService';
import { UserInteraction } from './common/services/userInteraction';
import { WcsContentResolver } from './common/services/wcsContentResolver';
import { UtilityModule } from './common/utilities/utilityModule';
import { AppConfig } from './common/values/appConfig';
import { AppSession } from './common/values/appSession';
import { ContentHelper } from './common/values/contentHelper';
import { PFCostResultsModule } from './eyc/cost-results/pfCostResultsModule';
import { PFCostSearchModule } from './eyc/cost-search/pfCostSearchModule';
import { ProcedureCategoryService } from './eyc/cost-search/services/procedureCategorySvc';
import { PFAssignPcpV2Module } from './fad/assign-pcp/pfAssignPcpV2Module';
import { CacheService } from './fad/cache/services/cacheSvc';
import { ProviderCompareService } from './fad/compare-providers/services/ProviderCompareSvc';
import { ProviderCptCodesService } from './fad/provider-details/services/providerCptCodesSvc';
import { ProviderCptCostDetailService } from './fad/provider-details/services/providerCptCostDetailSvc';
import { ProviderOrchestrationService } from './fad/provider-details/services/providerOrchestrationSvc';
import { RulesService } from './fad/rules/services/RulesSvc';
import { PersonalizationChecklistModel } from './fad/search-providers/models/personalizationChecklistModel';
import { PFSearchModule } from './fad/search-providers/pfSearchModule';
import { CareActionMessageSvc } from './fad/search-providers/services/careActionMessageSvc';
import { PersonalizationChecklistSvc } from './fad/search-providers/services/personalizationChecklistSvc';
import { ProviderUtilityService } from './fad/search-providers/services/providerUtilitySvc';
import { QuickLinksService } from './fad/search-providers/services/quickLinksSvc';
import { PFSearchResultsModule } from './fad/search-results/pfSearchResultsModule';
import { ProviderSummaryService } from './fad/search-results/services/providerSummarySvc';
import { BootstrapHandler } from './findcare/common/classes/bootstrapHandler';
import { ActionService } from './findcare/common/services/actionSvc';
import { UtilityService } from './findcare/common/services/utilitySvc';
import { BootstrapService } from './findcare/common/store/bootstrapSvc';
import { FindCareModule } from './findcare/findCareModule';
import { PFPromotedProceduresModule } from './kodiak-procedure/promoted-procedures/pfPromotedProceduresModule';
import { PFPromotedProcedureService } from './kodiak-procedure/promoted-procedures/services/pfPromotedProcedureSvc';
import { PFVirtualCareModule } from './virtual-care/pfVirtualCareModule';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    UxSharedModule.forRoot(),
    BrowserAnimationsModule,
    CareCircleModule,
    PFSearchModule,
    PFSearchResultsModule.forRoot(),
    CommonAlertModule,
    PFCostSearchModule,
    PFCostResultsModule,
    GlobalHeaderModule,
    GlobalBackBtnModule,
    NavigationModule,
    AppSecureModule,
    AppSecureCostModule,
    GlobalFooterModule,
    AppMaintenanceModule,
    UtilityModule.forRoot(),
    AppErrorModule.forRoot(),
    CustomPipesModule,
    PFPromotedProceduresModule,
    PFVirtualCareModule.forRoot(),
    ScenarioAlertModule,
    PFAssignPcpV2Module.forRoot(),
    FindCareModule.forRoot(),
    PFCareNowModule.forRoot(),
    DenialOfServiceModule
  ],
  providers: [
    HttpClientService,
    DataService,
    AppSession,
    ProviderUtilityService,
    CareCircleService,
    PFPromotedProcedureService,
    ProviderSummaryService,
    ProviderOrchestrationService,
    ProviderCptCodesService,
    ProviderCptCostDetailService,
    ProviderCompareService,
    EventHandler,
    SSOApi,
    LocationService,
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppInitializer],
      useFactory: appInitializerFactory
    },
    AppConfig,
    HostnameService,
    {
      provide: 'Window',
      useFactory: getWindow
    },
    WcsContentResolver,
    BaseComponent,
    RulesService,
    CacheService,
    ProcedureCategoryService,
    QuickLinksService,
    UserInteraction,
    DataHelper,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    AuthGuardService,
    MemberSummaryService,
    {
      provide: NavigationService,
      useClass: PathNavigationService
    },
    ContentHelper,
    BaseService,
    DeeplinkHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    Title,
    HighlightPipe,
    PhonePatternPipe,
    TrimText,
    WeekdaysPipe,
    DatePipe,
    FeatureFlagService,
    CareActionMessageSvc,
    PersonalizationChecklistSvc,
    PersonalizationChecklistModel,
    MemberPCPHelper,
    MbrAgeLimitService,
    CurrencyPipe,
    CustomSlicePipe,
    UtilityService,
    BootstrapHandler,
    BootstrapService,
    ActionService
  ]
})
export class AppModule {}
