import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidePanel } from '@anthem/uxd/modal';
import { SecureStateHandler } from '../../../common/components/app-secure/services/secureStateHandler';
import { ScenarioAlertSvc } from '../../../common/components/scenarioAlert/services/pfScenarioAlertSvc';
import { IWindow } from '../../../common/interfaces/iWindow';
import { DataHelper } from '../../../common/services/dataHelper';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { RulesService } from '../../rules/services/RulesSvc';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { PFPlanSelectionComponent } from './pfPlanSelectionCmp';

@Component({
  moduleId: module.id,
  selector: 'app-pf-rebrand-plan-selection-cmp',
  templateUrl: '../views/pfRebrandPlanSelectionCmp.html'
})
export class PFRebrandPlanSelectionComponent extends PFPlanSelectionComponent {
  constructor(
    providerUtilityService: ProviderUtilityService,
    @Inject(AppSession)
    appSession: AppSession,
    @Inject('Window')
    window: IWindow,
    route: ActivatedRoute,
    @Inject(ContentHelper)
    contentHelper: ContentHelper,
    secureStateHandler: SecureStateHandler,
    eventHandler: EventHandler,
    appUtility: AppUtility,
    ruleService: RulesService,
    @Inject(DataHelper)
    dataHelper: DataHelper,
    sidePanel: SidePanel,
    scenarioAlertSvc: ScenarioAlertSvc,
  ) {
    super(
      providerUtilityService,
      appSession,
      window,
      route,
      contentHelper,
      secureStateHandler,
      eventHandler,
      appUtility,
      ruleService,
      dataHelper,
      sidePanel,
      scenarioAlertSvc
    );
  }

  search(value: string): void {
    if (this.rebrandConfig.prefix) {
      this.alphaPrefix = this.rebrandConfig.prefix;
    }
    super.goToSearchCriteria(value);
  }
}
